/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { Checkbox as CheckboxPR } from "primereact/checkbox";

import { cssFormElements } from "../css";
import Icon from "components/icon/icon";

const Checkbox = ({ required, icon, ...data }) => {
  const theme = useTheme();
  // console.log('icon:', icon);

  // console.log('RADIO BTN: data', data);

  const helpertext = data?.helpertext && (
    <div css={cssFormElements(theme).radio.helpertext}>{data.helpertext}</div>
  );

  const getLabel = data?.label && (
    <label css={cssFormElements(theme).radio.label}>
      <div css={cssFormElements(theme).radio.label.title}>
        {data.label}
        {icon && <Icon icon={icon} />}
      </div>

      {helpertext && helpertext}
      {/* {required && <span>*</span>} */}
    </label>
  );

  const elProps = {
    ...Object.keys(data).reduce((acc, key) => {
      if (key !== "data") {
        acc[key] = data[key];
      }
      return acc;
    }, {}),
  };

  const getElement = <CheckboxPR {...elProps} />;

  return (
    <div css={cssFormElements(theme).radio}>
      {getElement}
      {getLabel}
    </div>
  );
};

export default Checkbox;
