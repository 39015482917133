import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

import CustomButton from "components/custom-button/custom-button";

import css from "./confirmation-modal.module.scss";
import CustomModal from "components/custom-modal/custom-modal";

export function useConfirmationModal(onClose = () => {}) {
  const [showModal, setShowModal] = useState(false);

  const handleExit = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    onClose();
  };

  return { showModal, handleExit, handleClose };
}

const ConfirmationModal = ({
  showModal,
  handleClose,
  handleGoBack,
  setShowModal,
  modalHeight,
  customMessage,
  isDefaultMessage,
  handleConfirmation,
}) => {
  useEffect(() => {
    const modalContent = document.querySelector(
      ".confirmation-modal .modal-content",
    );
    if (modalContent) {
      modalContent.style.height = `${modalHeight}px`;
    }
  }, [modalHeight, showModal]);

  const handleCloseModal = () => {
    setShowModal(false);
    handleClose();
  };
  const defaultMessage = (
    <div className={css.content}>
      Are you sure you want to exit?
      <div>Your edits will not be saved.</div>
    </div>
  );
  const message = customMessage || defaultMessage;
  const isCustomMessage = !isDefaultMessage;

  const headerTitle = "Are you sure?";

  const btnBack = (
    <CustomButton
      label="Go back"
      color="secondary"
      onClick={handleGoBack}
      align="left"
    />
  );
  const btnClose = (
    <CustomButton
      key="close"
      label="Close"
      color="tertiary"
      onClick={handleCloseModal}
    />
  );
  const btnCancel = (
    <CustomButton
      key="cancel"
      label="Cancel"
      color="tertiary"
      onClick={handleGoBack}
    />
  );
  const btnSubmit = (
    <CustomButton
      key="submit"
      label="Submit"
      color="secondary"
      onClick={handleConfirmation}
    />
  );

  const btns = isCustomMessage ? [btnBack, btnClose] : [btnCancel, btnSubmit];

  const footerButtons = btns;

  return (
    <CustomModal
      headerTitle={headerTitle}
      footerButtons={footerButtons}
      onHide={handleClose}
      visible={showModal}
    >
      {message}
    </CustomModal>
  );
};

export default ConfirmationModal;
