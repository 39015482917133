/** @jsxImportSource @emotion/react */

import { useEffect } from "react";

import CustomDivTable from "components/custom-table/custom-div-table";
import FormGroupItem from "components/form-group-item/form-group-item";
import FormBlock from "components/form-block/form-block";
import CustomButton from "components/custom-button/custom-button";

// import css from "./form-custodians.module.scss";
import {
  appColors,
  borderRadius,
  flexbox,
  fontbox,
  fullWidth,
  paddingXY,
  theme,
} from "emotion/utils";

import chevronDown from "images/chevrondown.svg";
import FormInput from "components/form-input/form-input";
import { FormElement } from "components/form-element/form-element";
import { FormErrorMessage } from "validation/form-error-message";

import { css } from "./css";

export default function FormCustodians(props) {
  const { formik, name, label, emailFn } = props;

  useEffect(() => {
    if (formik?.values) {
      if (!formik.values.custodians) {
        formik.setFieldValue("custodians", [
          { email: null, relation: relationshipOptions[0].value },
        ]);
      } else if (formik.values.custodians.length < 1) {
        console.log("does this run");
        formik.setFieldValue(
          "custodians",
          formik.values.custodians.concat({
            email: null,
            relation: relationshipOptions[0].value,
          }),
        );
      }
    }
  }, []);

  const columns = [
    {
      name: "First Name",
      valFn: (dataCustodianInvites) => {
        return dataCustodianInvites?.first_name;
      },
      display: "First Name",
    },
    {
      name: "Last Name",
      valFn: (dataCustodianInvites) => {
        return dataCustodianInvites?.last_name;
      },
      display: "Last Name",
    },
    {
      name: "email",
      valFn: (dataCustodianInvites) => {
        return dataCustodianInvites?.email;
      },
      display: "Email",
    },
    {
      name: "relation",
      valFn: (dataCustodianInvites) => {
        return relationshipOptions.find(
          (o) => o.value === dataCustodianInvites?.relation,
        ).display;
      },
      display: "Relation",
    },
    {
      name: "resend",
      valFn: (dataCustodianInvites) => {
        return (
          <CustomButton
            label={"Resend Email"}
            onClick={() => {
              emailFn(dataCustodianInvites);
            }}
            align="right"
          />
        );
      },
      display: "",
    },
  ];

  const relationshipOptions = [
    {
      value: "PARENT",
      display: "Parent",
    },
    {
      value: "GRAND_PARENT",
      display: "Grandparent",
    },
    {
      value: "GUARDIAN",
      display: "Guardian",
    },
  ];

  const assignCustodians = (index, custodian) => {
    let custodians = [];
    custodians = [...formik.values["custodians"]];
    custodians[index] = custodian;
    formik.setFieldValue("custodians", custodians, { shouldDirty: true });
    formik.setTouched("custodians", true);
  };

  const errorClass = formik?.errors[name] ? "error" : "";

  return (
    <div css={css(theme)}>
      {formik?.values?.custodians?.map((custodian, index) => {
        return (
          <>
            {/* // <div
          //   key={index}
          //   // className={css.formCustodian}
          //   css={cssEmo.container}
          // >
            {/* <div
              className={css.titleActions}
            >
              <div
                className={css.title}
              >Custodian #{index + 1}</div>

              <CustomButton
                label="Remove"
                type="link"
                color="tertiary"
                onClick={(evt) => {
                  evt.preventDefault();
                  let custodians = formik.values.custodians;
                  custodians.splice(index, 1);
                  if (custodians.length < 1) {
                    custodians = [
                      { email: null, relation: relationshipOptions[0].value },
                    ];
                  }
                  formik.setFieldValue("custodians", custodians);
                  formik.setTouched("custodians", true);
                }}
                id={index}
                fs={14}
              />
            </div>

            <FormBlock
              style={{
                ...flexbox("column", "flex-start", "flex-start", 20),
              }}
            >
              <FormGroupItem>
                <label htmlFor={`first_name`}>{`First Name`}</label>
                <FormInput
                  name="first_name"
                  onInput={(evt) => {
                    const new_custodian = Object.assign({}, custodian);
                    new_custodian.firstName = evt.target.value;
                    assignCustodians(index, new_custodian);
                  }}
                  value={custodian.firstName || ""}
                />
              </FormGroupItem> */}
            <FormElement
              element="InputText"
              id="first_name"
              name="first_name"
              label="First name"
              placeholder="Guardian first name"
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={(evt) => {
                const new_custodian = Object.assign({}, custodian);
                new_custodian.firstName = evt.target.value;
                assignCustodians(index, new_custodian);
              }}
              value={custodian.firstName || ""}
              required
            />
            <FormErrorMessage formik={formik} name="first_name" />

            {/* <FormGroupItem>
                <label htmlFor={`last_name`}>{`Last Name`}</label>
                <FormInput
                  name="last_name"
                  onInput={(evt) => {
                    const new_custodian = Object.assign({}, custodian);
                    new_custodian.lastName = evt.target.value;
                    assignCustodians(index, new_custodian);
                  }}
                  value={custodian.lastName || ""}
                />
              </FormGroupItem> */}
            <FormElement
              element="InputText"
              id="last_name"
              name="last_name"
              label="Last name"
              placeholder="Guardian last name"
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={(evt) => {
                const new_custodian = Object.assign({}, custodian);
                new_custodian.lastName = evt.target.value;
                assignCustodians(index, new_custodian);
              }}
              value={custodian.lastName || ""}
              required
            />

            <div css={css(theme).relationship}>
              <label htmlFor={name}>Relationship</label>
              <select
                type="text"
                className={errorClass}
                id={name}
                name={name}
                onChange={(evt) => {
                  console.log(evt);
                  custodian.relation = evt.target.value;
                  assignCustodians(index, custodian);
                }}
                value={custodian.relation || relationshipOptions[0].value}
              >
                {relationshipOptions.map((val) => (
                  <option key={val.value} value={val.value}>
                    {val.display}
                  </option>
                ))}
              </select>
            </div>

            {/* <FormGroupItem>
                <label htmlFor={`email`}>{`Email`}</label>
                <FormInput
                  name="custodians"
                  onInput={(evt) => {
                    const new_custodian = Object.assign({}, custodian);
                    new_custodian.email = evt.target.value;
                    assignCustodians(index, new_custodian);
                  }}
                  value={custodian.email || ""}
                />
              </FormGroupItem> */}
            <FormElement
              element="InputText"
              id="email"
              name="email"
              label="Email"
              placeholder="Guardian email"
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={(evt) => {
                const new_custodian = Object.assign({}, custodian);
                new_custodian.email = evt.target.value;
                assignCustodians(index, new_custodian);
              }}
              value={custodian.email || ""}
              required
            />

            <div
              style={{
                ...borderRadius(12),
                ...fullWidth(),
                ...paddingXY(16),
                border: `1px solid ${appColors.blues.blueGray}`,
              }}
            >
              <FormElement
                element="Checkbox"
                id="is_emergency_contact"
                name="is_emergency_contact"
                label="This person is an emergency contact"
                formik={formik}
                onBlur={formik.handleBlur}
                onChange={() => alert("checkbox clicked")}
              />
            </div>
            {/* </FormBlock> */}

            {/* {index === formik?.values?.custodians.length - 1 && (
              <CustomButton
                label="+ Add Another Custodian"
                onClick={(evt) => {
                  evt.preventDefault();
                  formik.setFieldValue(
                    "custodians",
                    formik.values.custodians.concat({
                      email: null,
                      relation: relationshipOptions[0].value,
                    }),
                  );
                }}
              />
            )} */}
            {/* // </div> */}
          </>
        );
      })}

      {formik?.values?.custodian_invitations?.length > 0 && (
        <>
          <div className={css.formCustodian} style={{ marginTop: 20 }}>
            <div className={css.titleActions}>
              <div className={css.title}>Pending Custodians</div>
            </div>
          </div>

          <div style={{ width: "100%", maxWidth: "100%" }}>
            <CustomDivTable
              columns={columns}
              rows={formik?.values?.custodian_invitations}
              emptyMessage="No Guardians Found"
              style
            />
          </div>
        </>
      )}
    </div>
  );
}

// const cssEmo = {
//   container: {
//     ...fullWidth(),

//     "> div[class^='form-block_formBlock']": {
//       "> div:nth-of-type(3)": {
//         "> select": {
//           ...borderRadius("4px!important"),
//           ...fontbox(
//             `${theme.fonts.inter}!important`,
//             "16px!important",
//             "400!important",
//           ),
//           appearance: "none",
//           WebkitAppearance: "none",
//           MozAppearance: "none",
//           backgroundColor: theme.colors.white,
//           backgroundImage: `url(${chevronDown})`,
//           backgroundPosition: "right 10px center",
//           backgroundRepeat: "no-repeat",
//           border: `1px solid ${theme.colors.lightGrey}!important`,
//           height: "40px!important",
//           lineHeight: "16px",
//           paddingLeft: "10px!important",
//         },
//       },
//     },
//   },
// };
