/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { css } from "./css";

const SelectButton = ({ options, selected = "all", setSelected }) => {
  const theme = useTheme();

  return (
    <div css={css(theme).selectContainer}>
      <div css={css(theme).selectContainer.select}>
        <button
          css={[
            css(theme).selectContainer.selectButton,
            selected === "all" &&
              css(theme).selectContainer.selectButton.selected,
          ]}
          onClick={() => setSelected("all")}
        >
          All
        </button>

        {options.map((opt, index) => (
          <button
            key={index}
            css={[
              css(theme).selectContainer.selectButton,
              selected === opt.id &&
                css(theme).selectContainer.selectButton.selected,
            ]}
            onClick={() => setSelected(opt.id)}
          >
            {opt.name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SelectButton;
