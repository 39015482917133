/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import React, { useState, useRef, useEffect } from "react";
import { Form, Formik } from "formik";

import CustomModal from "components/custom-modal/custom-modal";
import { FormElement } from "components/form-element/form-element";

import { css } from "./css";
import Button from "components/button/button";

import StemForm from "./stem-form";

import { useMutation } from "hooks/use-mutation";

import APIClient from "client";

export default function ModalClassStemArchive({
  type,
  data,
  setShowModal,
  refetch,
}) {
  const stemType = type;
  const stemArchived = !!data.archived_at;
  const stemId = data.id;

  const theme = useTheme();

  const conditionalClose = () => {
    setShowModal(false);
  };

  const { mutate: submitStem } = useMutation({
    mutationFn: ({ data }) => {
      const url = `${StemForm.postUrl(stemType)}/${stemId}`;
      return APIClient.patch(url, {
        archive: !stemArchived,
      });
    },
    onSuccess: (res, { data, formik }) => {
      setShowModal(false);
      refetch();
    },
  });

  const headerTitle = stemArchived
    ? "Unarchive class stem"
    : "Archive class stem";

  const btnSave = (formik) => (
    <Button
      key="submit"
      type="submit"
      color="primary"
      label={stemArchived ? "Unarchive" : "Archive"}
      onClick={formik.handleSubmit}
    />
  );

  const btnCancel = (
    <Button
      key="cancel"
      label="Cancel"
      onClick={conditionalClose}
      color="secondary"
      variant="outlined"
    />
  );

  const footerButtons = (formik) => [btnCancel, btnSave(formik)];

  const message = stemArchived
    ? "Are you sure you want to unarchive this stem?"
    : "Are you sure you want to archive this stem?";

  return (
    <Formik
      initialValues={{}}
      onSubmit={(data, formik) => submitStem({ data, formik })}
      css={css(theme)}
    >
      {(formik) => (
        <Form>
          <CustomModal
            headerTitle={headerTitle}
            footerButtons={footerButtons(formik)}
            onHide={conditionalClose}
            size="md"
          >
            <p>{message}</p>
          </CustomModal>
        </Form>
      )}
    </Formik>
  );
}
