/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { InputText } from "primereact/inputtext";
import { IconField as IconFieldPr } from "primereact/iconfield";

import Icon from "components/icon/icon";

import { cssFormElements } from "../css";

const IconField = (data) => {
  const theme = useTheme();

  const elProps = {
    ...Object.keys(data).reduce((acc, key) => {
      if (key !== "data") {
        acc[key] = data[key];
      }
      return acc;
    }, {}),
  };

  const cssIconField = [cssFormElements(theme), cssFormElements().iconField];

  return (
    <div css={cssIconField}>
      <IconFieldPr iconPosition="left">
        <Icon icon="Search" />
        <InputText {...elProps} />
      </IconFieldPr>
    </div>
  );
};

export default IconField;
