import React from "react";

import HhhhLogo from "components/icons/icon4h-logo";

import "./not-found.scss";

export function NotFound() {
  return (
    <div className="not-found-container">
      <HhhhLogo className="logo" />
      <h1>404 Not Found</h1>
      <a href="/">Back</a>
    </div>
  );
}

export default NotFound;
