/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { useState } from "react";

import Menu from "../menu/menu";
import NestedMenu from "../menu/menu-nested";

// import MobTrigger from "./header-trigger";
import Logo from "components/logo/logo";
// import Search from "components/search/search";

import { css } from "./css";
import { css as menuCss } from "../menu/css";

import CurrentUserBlock from "components/current-user-block/current-user-block";

export default function Header({
  hideMenu = false,
  menuTabs,
  nestedMenuTabs,
  nestedMenuTitle,
}) {
  const [navOpen, setNavOpen] = useState(false);

  const theme = useTheme();

  const content = (
    <div css={css(theme).header.content}>
      <Logo />
      {/* <Search /> */}
    </div>
  );

  const submenu = nestedMenuTabs ? (
    <NestedMenu
      currentShow={nestedMenuTabs}
      pages={nestedMenuTabs}
      title={nestedMenuTitle}
    />
  ) : null;

  const user = <CurrentUserBlock />;

  return (
    <header css={css(theme).header}>
      {/* <MobTrigger navOpen={navOpen} setNavOpen={setNavOpen} /> */}
      {content}

      <nav css={menuCss}>
        <Menu
          navOpen={navOpen}
          setNavOpen={setNavOpen}
          hideMenu={hideMenu}
          pages={menuTabs}
        />
        {submenu}
      </nav>

      {user}
    </header>
  );
}
