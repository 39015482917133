import React, { useState, useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import Cookies from "universal-cookie";
import { useQuery } from "@tanstack/react-query";

import Client from "../client";

import AuthContext from "../context/auth-context";

function getNewRefreshTime(exp) {
  return new Date(exp * 1000).getTime() - new Date().getTime() - 1000;
}

function clearAccessToken() {
  const cookies = new Cookies();
  cookies.remove("hhhh_token", { path: "/" });
}

const AuthLayout = ({ children }) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const [refreshTime, setRefreshTime] = useState(false);

  useEffect(() => {
    const checkToken = async () => {
      const token = localStorage.getItem("hhhh_refresh");

      if (token) {
        setRefreshToken(token);
      } else {
        setIsLoading(false);
      }
    };

    checkToken();
  }, []);

  const { refetch, isLoading: isRefreshLoading } = useQuery({
    queryKey: ["get_refresh_token", isLoading],
    queryFn: () => Client.post("/auth/refresh", { token: refreshToken }),
    staleTime: refreshTime,
    refetchInterval: refreshTime,
    enabled: !!refreshToken,
    retry: false,
    onSuccess: (data) => {
      const { userData: user } = data;
      setUserData(user);
      setIsLoading(false);
      setRefreshTime(getNewRefreshTime(user.exp));
    },
    onError: (err) => {
      if (err.status && err.status === 401) {
        logout();
      }
    },
  });

  const login = async (values) => {
    if (!values) {
      return;
    }

    try {
      const { refreshToken: token, userData: user } = values;
      const cookies = new Cookies();
      localStorage.setItem("hhhh_refresh", token);
      localStorage.setItem("user", JSON.stringify(user));
      setUserData(user);
      setRefreshToken(token);
    } catch (error) {
      console.error("Login failed to parse JSON:", error);
    }
  };

  const logout = async () => {
    try {
      console.log("Logging out...");
      clearAccessToken();
      localStorage.removeItem("hhhh_refresh");
      localStorage.removeItem("user");
      setUserData(null);
      setRefreshToken(null);
      setRefreshTime(false);
    } catch (error) {
      console.error("Logout failed to remove user:", error);
    }
  };

  function selectCompany(user) {
    localStorage.setItem("user", JSON.stringify(user));
    setUserData(user);
    setRefreshTime(getNewRefreshTime(user?.exp));
  }

  // whenever window is visible to user, refresh all tokens
  useEffect(() => {
    const onVisibilityChange = () => {
      if (refreshToken) {
        refetch();
      }
    };

    document.addEventListener("visibilitychange", onVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, [userData, refetch, refreshToken]);

  const notLoggedIn = !isLoading && !isRefreshLoading && !refreshToken;
  const tokenUpToDate =
    !isLoading &&
    !isRefreshLoading &&
    userData &&
    getNewRefreshTime(userData.exp) > 0;

  const enabled = notLoggedIn || tokenUpToDate;

  if (isLoading) {
    return null;
  }

  return (
    <AuthContext.Provider
      value={{ refreshToken, userData, login, logout, selectCompany, enabled }}
    >
      <Outlet></Outlet>
    </AuthContext.Provider>
  );
};

export default AuthLayout;
