/** @jsxImportSource @emotion/react */

import Select from "react-select";
import AsyncSelect from "react-select/async";
import CreatableSelect from "react-select/creatable";

import IconTooltip from "components/icons/icon-tooltip";

import { css } from "./css";
import { useTheme } from "@emotion/react";

export default function FormSelect({
  formik,
  name,
  label,
  options = [],
  none,
  all,
  multi,
  labelPosition,
  onChange,
  stack = false,
  tooltipText,
  color,
  size,
  formElSize,
  viewBox,
  icon,
  readOnly,
  helperText,
  showIcon = true,
  style,
  value,
  async,
  loadOptions,
  defaultOptions,
  getOptionLabel,
  getOptionValue,
  placeholder = "Select...",
  isFilter = false,
  required = false,
  editable = false,
}) {
  const errorClass =
    formik?.errors[name] && formik?.touched[name] ? "error" : "";

  const noneEl = { value: "", label: placeholder };
  const allEl = { value: "all", label: "All" };

  const elWidth = `${formElSize || 100}`;

  const theme = useTheme();

  const defaultEls = [];
  if (all) {
    defaultEls.push(allEl);
  }
  if (none) {
    defaultEls.push(noneEl);
  }
  const formatOptions = options.map((opt) => ({
    value: opt.value,
    label: opt.display || opt.label,
  }));

  if (!multi) {
    formatOptions.unshift(...defaultEls);
  }

  const selectProps = {
    className: errorClass,
    id: name,
    name: name,
    isDisabled: readOnly,
    onChange: (val) => {
      if (async && getOptionValue) {
        val = { value: getOptionValue(val) };
      }

      if (onChange) {
        onChange(val, formik, name);
      }
      console.log("OnChange ", val);
      formik?.setFieldTouched(name, true);
      formik?.setFieldValue(name, val.value);
    },
    value: formatOptions.find((opt) => {
      if (formik) {
        if (formik.values[name]) {
          return opt.value === formik.values[name];
        } else {
          return async ? undefined : placeholder;
        }
      } else if (value) {
        return value.value === opt.value;
      }
      return async ? undefined : placeholder;
    }),
    options: formatOptions,
    menuPosition: "fixed",
    // menuIsOpen: true,
  };

  if (multi) {
    selectProps.className = `multiselect formSelect ${errorClass}`;
    // selectProps.className = errorClass;
    selectProps.isMulti = true;
    selectProps.onChange = (values) => {
      onChange && onChange(values);
      formik?.setFieldTouched(name, true);
      formik?.setFieldValue(name, values);
    };
    selectProps.value = formatOptions.filter((opt) => {
      if (value) {
        return opt.value === value;
      }
      return opt.value === formik?.values[name];
    });
  }

  const inputEl = async ? (
    <AsyncSelect
      {...selectProps}
      loadOptions={loadOptions}
      defaultOptions={defaultOptions}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      placeholder={placeholder}
      noOptionsMessage={() => {
        return formik?.values[name]
          ? `Begin typing to reset selection`
          : "Options will show after first character is typed";
      }}
      style={style}
    />
  ) : editable ? (
    <CreatableSelect {...selectProps} />
  ) : (
    <Select {...selectProps} />
  );

  return (
    <div
      // className={`form-group-item ${
      //   labelPosition === "top"
      //     ? "label-top"
      //     : labelPosition === "left"
      //     ? "label-left"
      //     : ""
      // } ${stack ? "stack" : ""}`}
      // style={{ flex: 1 }}
      css={css(theme, elWidth)}
    >
      {(labelPosition === "top" || showIcon) && (
        <div>
          {labelPosition === "top" && (
            <label htmlFor={name}>
              {label}
              {required && <span>*</span>}
            </label>
          )}
          {showIcon && (
            <span className="custom-icon">
              <IconTooltip
                id={`${name}-icon`}
                tooltipText={tooltipText}
                color={color}
                size={size}
                icon={icon}
                viewBox={viewBox}
              />
            </span>
          )}
        </div>
      )}

      <div css={[css.container, isFilter && css.isFilter]}>
        {inputEl}
        {helperText && <p>{helperText}</p>}
      </div>

      {labelPosition !== "top" && label && (
        <label htmlFor={name}>{label}</label>
      )}
    </div>
  );
}
