/** @jsxImportSource @emotion/react */
import { Link } from "react-router-dom";

import showmanshipFooterLogo from "images/showmanship-footer-logo.svg";

import { css } from "./css";

const Footer = () => {
  const mid = (
    <div css={css.midFooter}>
      <img alt="" src={showmanshipFooterLogo} />
      Showmanship
    </div>
  );

  const lower = (
    <div css={css.bottomFooter}>
      <div css={css.bottomFooter.copyright}>
        © 2024 Showmanship. All Rights Reserved
      </div>

      <Link to="/privacy-policy" onClick={() => window.scrollTo(0, 0)}>
        <div css={css.logInWrapper}>Privacy Policy</div>
      </Link>

      <Link to="login">
        <div css={css.logInWrapper}>Log In</div>
      </Link>
    </div>
  );

  return (
    <footer css={css.footer}>
      {mid}
      {lower}
    </footer>
  );
};
export default Footer;
