/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { cssFormElements } from "../css";
import { FormElement } from "../form-element";

const CheckboxGroup = (props) => {
  const { els, label, size, onChange = () => {}, checked, required } = props;

  const checkedMap =
    checked?.reduce((map, c) => {
      map[c] = true;
      return map;
    }, {}) || {};

  const elWidth = `${els?.size || "100"}%`;

  const theme = useTheme();

  const getLabel = label && (
    <label>
      {label}
      {required && <span>*</span>}
    </label>
  );

  return (
    <div css={cssFormElements(theme, elWidth).checkboxGroup}>
      {getLabel}

      {els.map((el) => (
        <FormElement
          key={el.value}
          element="Checkbox"
          id={el.value}
          name={el.name}
          label={el.label || null}
          helpertext={el.helpertext || null}
          required
          value={el.value || ""}
          onChange={() => onChange(el.value, !!checkedMap[el.value])}
          checked={checkedMap[el.value]}
          // size={size || null}
        />
      ))}
    </div>
  );
};

export default CheckboxGroup;
