/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

// import Button from "components/button/button";
import Icon from "components/icon/icon";

import { css } from "./css";

const EmptyBlock = ({
  title,
  subtitle,
  // btnLabel,
  // onClick,
}) => {
  const theme = useTheme();

  const graphic = (
    <div css={css(theme).graphic}>
      <Icon icon="EmptyBgCircle" stroke="transparent" />
      <Icon icon="Cloud" stroke="transparent" />
      <Icon icon="Circle" fill="#A0A9E4" stroke="transparent" />
      <Icon icon="Circle" fill="#A0A9E4" stroke="transparent" />
      <Icon icon="Circle" fill="#A0A9E4" stroke="transparent" />
      <Icon icon="Circle" fill="#A0A9E4" stroke="transparent" />
    </div>
  );

  const content = (
    <div css={css(theme).content}>
      {graphic}

      <div css={css(theme).intro}>
        {title && <h1>{title}</h1>}
        {subtitle && <p>{subtitle}</p>}
      </div>
    </div>
  );

  // const actions = (
  //   <div css={css(theme).actions}>
  //     <Button type="button" label={btnLabel} onClick={onClick} />
  //   </div>
  // );

  const dots = (
    <div css={css(theme).dots}>
      <div css={css(theme).dots.mask} />
      <Icon icon="DotGrid" fill="#A0A9E4" stroke="transparent" />
    </div>
  );

  return (
    <div css={css(theme)}>
      <div css={css(theme).inner}>
        {content}
        {/* {actions} */}

        {dots}
      </div>
    </div>
  );
};

export default EmptyBlock;
