/** @jsxImportSource @emotion/react */

import {
  borderRadius,
  fontbox,
  fullWidth,
  paddingX,
  paddingY,
  theme,
} from "emotion/utils";

const FormTextarea = ({
  formik,
  name,
  onInput,
  readOnly,
  style,
  testChange,
  value,
}) => {
  const errorClass =
    formik?.errors[name] && formik?.touched[name] ? "error" : "";

  const textareaProps = {
    css: css.textarea,
    className: errorClass,
    style: style,
    id: name,
    name,
    onBlur: formik?.handleBlur,
    onChange: formik?.handleChange,
    onInput: onInput,
    readOnly,
    value: value,
  };

  return <textarea {...textareaProps} />;
};

const css = {
  textarea: {
    ...borderRadius(4),
    ...fontbox(theme.fonts.inter, 16, 400, 16),
    ...fullWidth(),
    ...paddingX(10),
    ...paddingY(10),
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.lightGrey}`,
    color: theme.colors.nero,
    minHeight: 80,
    minWidth: "48%",
    outline: "none",
  },
};

export default FormTextarea;
