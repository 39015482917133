import React from "react";
import { useMutation } from "@tanstack/react-query";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import Client from "client";
import CustomButton from "components/custom-button/custom-button";

export const showType = {
  PERFORMANCE: "PERFORMANCE",
};

export default function ShowCancelModal({
  setShowModal = () => {},
  show,
  onAccept = () => {},
}) {
  const { mutateAsync: submitRequest, isLoading } = useMutation({
    mutationKey: "cancel-show",
    networkMode: "always",
    mutationFn: async () => Client.delete(`/shows/${show.id}`),
    onSuccess: (values) => {
      setShowModal(false);
      onAccept();
    },
  });

  return (
    <Modal show={true} onHide={() => setShowModal(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Remove Show {show.name}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        Are you sure you want to cancel show <i>{show.name}</i>?
      </Modal.Body>

      <Modal.Footer>
        <CustomButton
          label="Cancel"
          color="tertiary"
          type="outlined"
          onClick={() => setShowModal(false)}
        />
        <CustomButton
          label="Remove"
          color="tertiary"
          onClick={submitRequest}
          loading={isLoading}
          disabled={isLoading}
        />
      </Modal.Footer>
    </Modal>
  );
}
