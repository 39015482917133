import React, { useEffect } from "react";

import Hero from "./sections/hero/hero";
import Statement from "./sections/statement/statement";
import Comprehensive from "./sections/comprehensive/comprehensive";
import Simple from "./sections/simple/simple";
import Fun from "./sections/fun/fun";
import Subscription from "./sections/subscription/subscription";
import Footer from "./sections/footer/footer";

const Marketing = () => {
  useEffect(() => {
    document.title = "Showmanship - Doing Best, Better";
  }, []);
  return (
    <>
      <Hero />
      <Statement />
      <Comprehensive />
      <Simple />
      <Fun />
      <Subscription />
      <Footer />
    </>
  );
};

export default Marketing;
