/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import { useContext, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Formik, Form } from "formik";
import AuthContext from "../../context/auth-context";
import { useNavigate } from "react-router-dom";

import Client from "client";
import { useMutation } from "hooks";
import * as Yup from "yup";

// import css from "./login.module.scss";'
import { css } from "./css";
import Icon from "components/icon/icon";
import { FormElement } from "components/form-element/form-element";
import Button from "components/button/button";
import Logo from "components/logo/logo";
import { appColors } from "emotion/utils";

const validate = () => ({});

function ResetPassword(props) {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [special, setSpecial] = useState(false);
  const [lengthOk, setLengthOk] = useState(false);
  const [match, setMatch] = useState(false);

  const specialReg =
    /^[0-9A-Za-z]*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?][0-9a-zA-Z]*$/;
  let uid = searchParams.get("userId");
  let token = searchParams.get("token");
  // console.log(uid);

  const initialValues = {
    confirmPassword: "",
    password: "",
    userId: uid,
    token,
  };
  const toastRef = useRef();
  const [hasError, setHasError] = useState(false);

  const theme = useTheme();
  const { login } = useContext(AuthContext);

  const { mutateAsync: onSubmit, isLoading } = useMutation({
    mutationKey: "reset_password",
    mutationFn: async (data) => Client.post("/auth/reset-password", data),
    onSuccess: (data) => {
      alert("Congratulations, your password has been changed!");
      login(data);
      navigate("/login", { replace: true });
    },
    onError: (error) => {
      toastRef.current.show({
        severity: "error",
        summary: "Error",
        detail: error.toString(),
        life: 13000,
      });
      setHasError(true);
    },
  });

  const header = <Logo />;

  const check = (pass, confirmpass) => {
    setLengthOk(pass && pass.length >= 8);
    setMatch(pass === confirmpass && pass && pass.length > 0);
    setSpecial(pass.match(specialReg));
  };

  const getOkBox = (
    <div css={css(theme).okBox}>
      {[
        { label: "Must be at least 8 characters", value: lengthOk },
        { label: "Must contain one special character", value: special },
        { label: "Passwords Match", value: match },
      ].map((item, index) => (
        <div css={css(theme).okBox.box} key={index}>
          <input type="checkbox" checked={item.value} />
          <Icon
            icon="Check"
            fill={
              item.value ? appColors?.greens?.[700] : appColors?.blues?.blueGray
            }
            stroke={appColors?.grays?.[0]}
          />
          <label>{item.label}</label>
        </div>
      ))}
    </div>
  );

  const form = (formik) => (
    <div css={css(theme).formBlock()}>
      <div css={css(theme).formBlock().intro}>
        <h1>Set new password</h1>
        <p>Let's get you back in the saddle.</p>
      </div>

      <div css={css(theme).formBlock().elements}>
        <FormElement
          element="Password"
          elementType="password"
          id="password"
          name="password"
          formik={formik}
          onChange={(evt) => {
            check(evt.target.value, formik.values["confirmPassword"]);
            formik.handleChange(evt);
          }}
          label="Password Reset"
        />
        {formik.touched.password && formik.errors.password && (
          <span css="form-error-msg">{formik.errors.password}</span>
        )}

        <FormElement
          element="Password"
          elementType="password"
          id="confirmPassword"
          name="confirmPassword"
          formik={formik}
          onChange={(evt) => {
            check(formik.values["password"], evt.target.value);
            formik.handleChange(evt);
          }}
          label="Confirm Password"
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <span css="form-error-msg">{formik.errors.confirmPassword}</span>
        )}
      </div>

      {getOkBox}

      <Button
        label="Reset Password"
        loading={isLoading}
        disabled={
          isLoading ||
          Object.values(formik.errors).length ||
          hasError ||
          !formik.dirty
        }
        type="submit"
      />
    </div>
  );

  return (
    <div css={css(theme)}>
      <div css={css(theme).block}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={Yup.object({
            password: Yup.string()
              .label("Password")
              .min(8)
              .required()
              .matches(specialReg, "Need one special character"),
            confirmPassword: Yup.string()
              .label("Confirm Password")
              .required()
              .oneOf([Yup.ref("password")], "Passwords must match"),
          })}
        >
          {(formik) => (
            <Form>
              {header}
              {form(formik)}
            </Form>
            // <Form>
            //   <HhhhLogo css={css.logo} />
            //   Password Reset
            //   <label>Password</label>
            //   <input
            //     type="password"
            //     id="password"
            //     name="password"
            //     onBlur={formik.handleBlur}
            //     onChange={formik.handleChange}
            //     value={formik.values.password}
            //   />
            //   {formik.touched.password && formik.errors.password && (
            //     <span css="form-error-msg">{formik.errors.password}</span>
            //   )}
            //   <label>Confirm Password</label>
            //   <input
            //     type="password"
            //     id="confirmPassword"
            //     name="confirmPassword"
            //     onBlur={formik.handleBlur}
            //     onChange={formik.handleChange}
            //     value={formik.values.confirm_password}
            //   />
            //   {formik.touched.confirmPassword &&
            //     formik.errors.confirmPassword && (
            //       <span css="form-error-msg">
            //         {formik.errors.confirmPassword}
            //       </span>
            //     )}
            //   <button
            //     type="submit"
            // disabled={
            //   isLoading || Object.values(formik.errors).length || hasError
            // }
            //     css={`btn btn-success`}
            //   >
            //     Submit
            //   </button>
            // </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ResetPassword;
