/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useOutletContext, useParams, useSearchParams } from "react-router-dom";
import { Formik, Form } from "formik";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

import { useQuery } from "hooks";
import { truncateText } from "utils";

import CustomDivTable from "components/custom-table/custom-div-table";
import FormSelect from "components/form-select";
import TitleBlock from "components/title-block/title-block";
import Button from "components/button/button";
import Icon from "components/icon/icon";
import CustomDivTableActions from "components/custom-table/custom-div-table-actions";
import ButtonIcon from "components/button-icon/button-icon";
import FilterBtnGroup from "components/filter-btn-group/filter-btn-group";
import FormInput from "components/form-input/form-input";

import ShowStat from "./show-stat";
import ShowRegistrantsModal from "./show-registrants-modal";
import ShowRegistrantsAddModal from "./show-registrants-add-modal";
import ShowClassRidersAddRegistrantModal from "./show-class-riders/show-class-riders-add-registrant.modal";

// import css from "./show.module.scss";
import { css } from "./css";
import Client from "client";

const initialValues = {
  age: "",
  arena: "",
};

export default function ShowRegistrants() {
  const { showId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const { setActiveBtnGroup } = useOutletContext();
  const [filters, setFilters] = useState([]);
  const [editing, setEditing] = useState(false);
  const [showMemberModal, setShowMemberModal] = useState(false);
  const [showAddRegistrantModal, setShowAddRegistrantModal] = useState(false);
  const [showAddRegistrantClassModal, setShowAddRegistrantClassModal] =
    useState(false);
  const [showAddRegistrantClassesModal, setShowAddRegistrantClassesModal] =
    useState(false);
  const [activeRider, setActiveRider] = useState(null);
  const [showStatus, setShowStatus] = useState("pre");
  const [isDirty, setIsDirty] = useState(false);
  const [filteredRegistrants, setFilteredRegistrants] = useState([]);
  const [sort, setSort] = useState(false);
  const [order, setOrder] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState(null);
  const [selected, setSelected] = useState(null);
  const [activeAgeFilter, setActiveAgeFilter] = useState(null);
  const [filteredRegistrantsCopy, setFilteredRegistrantsCopy] = useState([]);
  const [activeBtnGroupAgeCategories, setActiveBtnGroupAgeCategories] =
    useState("all");
  const [filteredCount, setFilteredCount] = useState([]);

  const theme = useTheme();
  const { show } = useOutletContext();

  // Data
  const { data: registrants, refetch } = useQuery(
    `/registrants/show-registrants/${showId}`,
  );

  // const { data: ages } = useQuery(`/class-age-categories`);
  const { data: ages, refetch: refetchLevels } = useQuery(
    "/class-age-categories",
  );

  // Filters
  const filteredAges = useMemo(() => {
    const ageFilter = filters.find((f) => f.id === "age");
    return registrants?.data?.filter(
      (registrant) =>
        !ageFilter ||
        ageFilter.value === -1 ||
        ageFilter.value === registrant.age_category?.id,
    );
  }, [registrants, filters]);

  const data = useMemo(() => filteredAges, [filteredAges]);

  useEffect(() => {
    if (filteredRegistrants)
      setFilteredRegistrantsCopy(cloneDeep(filteredRegistrants));
  }, [filteredRegistrants]);

  const ageCategoriesOptionsFiltered = ages?.data.map((option) => option.name);

  // Calculations
  const totalCost = useMemo(() => {
    const cost = registrants?.data?.reduce(
      (acc, curr) => acc + Number(curr.cost),
      0,
    );
    return cost?.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }, [registrants]);

  const ageOptions = useMemo(() => {
    const defaultOption = {
      value: -1,
      display: "Select Age...",
    };

    if (!ages) {
      return [defaultOption];
    }

    const options = ages?.data.map((d) => ({
      value: d.id,
      display: d.name,
    }));

    return [defaultOption, ...options];
  }, [ages]);

  useEffect(() => {
    if (activeRider) {
      setActiveRider(activeRider);
      setShowAddRegistrantModal(false);
      setShowAddRegistrantClassesModal(true);
    }
  }, [activeRider]);

  useEffect(() => {
    if (data) setFilteredRegistrants([...data]);
    refresh();
  }, [data]);

  useEffect(() => {
    //sortFiltered();
    if (
      (sort && order) ||
      (limit && offset > -1) ||
      search ||
      activeBtnGroupAgeCategories
    ) {
      refresh();
    }
  }, [sort, order, limit, offset, search, activeBtnGroupAgeCategories]);

  const refresh = () => {
    let filtered = cloneDeep(data);
    if (!filtered) return;
    if (search)
      filtered = filtered.filter((f) => {
        return (
          f.name.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          String(f.rider_number).toLowerCase().indexOf(search.toLowerCase()) >
            -1
        );
      });

    if (activeBtnGroupAgeCategories)
      filtered = filtered.filter((f) => {
        return (
          activeBtnGroupAgeCategories.toLowerCase() === "all" ||
          f.age_category?.name.toLowerCase() ===
            activeBtnGroupAgeCategories.toLowerCase()
        );
      });

    setFilteredCount(filtered.length);
    if (order && sort) {
      filtered = [...filtered].sort((a, b) => {
        if (typeof a[sort] === "string" && typeof b[sort] === "string") {
          if (order === "desc") {
            return a[sort].localeCompare(b[sort]);
          } else {
            return b[sort].localeCompare(a[sort]);
          }
        } else {
          if (order === "desc") {
            return a[sort] - b[sort] > 0;
          } else {
            return a[sort] - b[sort] <= 0;
          }
        }
      });
    }

    if (offset && limit) {
      filtered = filtered.splice(offset, limit);
    }

    setFilteredRegistrants(filtered);
  };
  const columns = [
    {
      name: "name",
      valFn: (data) => data.name || "--",
      display: "Rider name",
      sortable: true,
    },
    {
      name: "age_level",
      valFn: (data) => data.age_category?.name || "--",
      display: "Age group",
      sortable: true,
    },
    {
      name: "club_name",
      valFn: (data) => truncateText(data.club_name) || "--",
      display: "Club",
      sortable: true,
    },
    {
      name: "num_custodians",
      valFn: (data) => data.num_custodians || "0",
      display: "# Custodians",
      sortable: true,
      align: "right",
      alignHeader: "right",
    },
    // {
    //   name: "birthday",
    //   valFn: (data) => moment(data.birthday).format("LL") || "--",
    //   display: "Birthday",
    //   sortable: true,
    // align: "right",
    // alignHeader: "right",
    // },
    {
      name: "num_classes",
      valFn: (data) => data.num_classes || "--",
      display: "# Classes",
      sortable: true,
      align: "right",
      alignHeader: "right",
    },
    {
      name: "num_points",
      valFn: (data) => data.num_points || "--",
      display: "Points",
      sortable: true,
      align: "right",
      alignHeader: "right",
    },
    {
      name: "actions",
      valFn: (it) => (
        <CustomDivTableActions>
          {/* <ButtonIcon
            icon={<Icon icon="Trash" />}
            onClick={() => {
              //
              if(window.confirm("Are you sure you want to remove this rider from all non participated classes and the show?"))
                handleMemberDelete(it)
            }}
          /> */}
          <ButtonIcon
            icon={<Icon icon="ChevronRight" />}
            onClick={() => {
              handleMemberSelect(it);
            }}
          />
        </CustomDivTableActions>
      ),
      display: "",
      noSort: true,
      align: "right",
      alignHeader: "right",
    },
  ];
  if (editing) {
    columns.splice(columns.length - 1, 0, {
      name: "rider_number",
      valFn: (data) => (
        <FormInput
          placeholder={String(data.rider_number)}
          onKeyUp={(ev) => {
            const registrantIdx = filteredRegistrantsCopy.findIndex(
              (reg) => reg.member_id === data.member_id,
            );
            const registrant = filteredRegistrantsCopy[registrantIdx];
            if (ev.target.value.length < 1) {
              registrant.rider_number = Number(ev.target.placeholder);
            } else {
              const newrider_number = Number(ev.target.value);
              if (isNaN(newrider_number)) {
                ev.target.value = registrant.rider_number;
                return;
              }
              registrant.rider_number = newrider_number;
            }
            if (isEqual(filteredRegistrants, filteredRegistrantsCopy)) {
              setIsDirty(false);
            } else {
              setIsDirty(true);
            }
          }}
          style={{ maxWidth: 50 }}
        ></FormInput>
      ),
      display: "Rider #",
      sortable: true,
      align: "right",
      alignHeader: "right",
    });
  } else {
    columns.splice(columns.length - 1, 0, {
      name: "rider_number",
      valFn: (data) => data.rider_number || "--",
      display: "Rider #",
      align: "right",
      alignHeader: "right",
    });
  }

  async function handleMemberSelect(member) {
    setSelected(member);
    setShowMemberModal(true);
  }

  // async function handleMemberDelete(member) {
  //   const deleted = await Client.post(`/shows/:${}/delete-registration`)

  // }

  // Handlers
  const handleFilter = useCallback(
    (event, variant) => {
      setFilters((prev) => {
        const newFilters = [...prev];
        const index = newFilters.findIndex((f) => f.id === variant);
        if (index > -1) {
          if (event.value !== "-1") {
            newFilters[index].value = event.value;
          } else {
            newFilters.splice(index, 1);
          }
        } else {
          if (event.value !== "-1") {
            newFilters.push({
              id: variant,
              value: event.value,
            });
          }
        }
        return newFilters;
      });
    },
    [setFilters],
  );

  function handleCreate() {
    setShowAddRegistrantModal(true);
  }

  async function handleEdit() {
    if (editing && isDirty) {
      //Save the new values
      const data = filteredRegistrantsCopy.map((frc) => {
        return { member_id: frc.member_id, rider_number: frc.rider_number };
      });

      const response = await Client.post(
        `/shows/byId/${showId}/update-rider-number`,
        { riders: data },
      );
      refetch();
      //Post to server with data
    }
    setEditing(!editing);
  }

  const handleSetActiveRider = (rider) => {
    setActiveRider(rider);
  };
  const btnCreate = (
    <Button
      icon={<Icon icon="Plus" />}
      iconPos="left"
      label="Add a Rider"
      onClick={handleCreate}
    />
  );

  const btnEdit = (
    <Button
      icon={<Icon icon="Edit" />}
      iconPos="left"
      label={
        editing
          ? isDirty
            ? "Save rider numbers"
            : "Cancel edit numbers"
          : "Edit rider numbers"
      }
      onClick={handleEdit}
      color="secondary"
      variant="outlined"
    />
  );

  const actions = [btnEdit, btnCreate];

  const titleBlock = (
    <TitleBlock>
      <h1 css={css(theme).showTitleInput.title}>{show?.name}</h1>
      {actions.map((btn, index) => (
        <React.Fragment key={index}>{btn}</React.Fragment>
      ))}
    </TitleBlock>
  );

  const statBlock = (
    <>
      {showStatus === "post" && (
        <ShowStat
          label="Total Registered"
          entry={filteredRegistrants?.length || "--"}
        />
      )}
      <ShowStat
        label={showStatus === "pre" ? "Total Registrants" : "Total Registered"}
        entry={filteredRegistrants?.length || "--"}
      />
      <ShowStat label="Income" entry={totalCost ? `${totalCost}` : "--"} />
    </>
  );

  const selectSearch = (
    <>
      <Formik initialValues={initialValues}>
        {(formik) => (
          <Form
            style={{
              maxWidth: "none",
              width: "auto",
            }}
          >
            <FormSelect
              formik={formik}
              name="age"
              id="age"
              options={ageOptions}
              value={formik}
              onChange={(event) => handleFilter(event, "age")}
              showIcon={false}
              isFilter={true}
            />
          </Form>
        )}
      </Formik>
    </>
  );

  const filterBtnGroupAge = (
    <FilterBtnGroup
      options={ageCategoriesOptionsFiltered}
      //setActiveBtnGroup={setActiveAgeFilter}
      //activeBtnGroup={activeAgeFilter}
      activeBtnGroup={activeBtnGroupAgeCategories}
      setActiveBtnGroup={setActiveBtnGroupAgeCategories}
    />
  );

  const modals = (
    <>
      {selected && showMemberModal && (
        <ShowRegistrantsModal
          setShowModal={setShowMemberModal}
          activeMember={selected}
          onSuccess={() => {
            refetch();
            setActiveRider(selected);
          }}
          handleManageClasses={() => {
            setActiveRider(selected);
            setShowMemberModal(false);
            setShowAddRegistrantClassesModal(true);
          }}
        />
      )}

      {(showAddRegistrantModal || searchParams.get("add")) && (
        <ShowRegistrantsAddModal
          setShowModal={() => {
            setSearchParams();
            setShowAddRegistrantModal(false);
          }}
          showId={showId}
          registeredRiders={registrants?.data}
          setActiveRider={handleSetActiveRider}
          onSuccess={refetch}
        />
      )}
      {showAddRegistrantClassesModal && (
        <ShowClassRidersAddRegistrantModal
          setShowModal={(manageActiveRider) => {
            setShowAddRegistrantClassesModal(false);
            if (manageActiveRider) {
              setShowMemberModal(true);
            } else {
              setActiveRider(null);
            }
            setSearchParams();
            setShowAddRegistrantModal(false);
          }}
          showId={showId}
          activeRider={activeRider}
          registeredRiders={registrants?.data}
          onSuccess={refetch}
        />
      )}
    </>
  );

  return (
    <div css={css(theme, true).classRiders}>
      {titleBlock}

      <TitleBlock>{filterBtnGroupAge}</TitleBlock>

      {filteredRegistrants ? (
        <CustomDivTable
          columns={columns}
          rows={filteredRegistrants}
          rowCount={limit}
          count={filteredCount}
          reorderableColumns={true}
          selectionMode="single"
          onPage={(pageObj) => {
            console.log(pageObj);
            if (pageObj.search !== search) {
              setSearch(pageObj.search);
            }
            if (pageObj.sort !== sort) {
              setSort(pageObj.sort);
            }
            if (pageObj.order !== order) {
              setOrder(pageObj.order);
            }
            if (pageObj.offset !== offset) {
              setOffset(pageObj.offset);
            }
            if (pageObj.limit !== limit) {
              setLimit(pageObj.limit);
            }
          }}
          showSearch={true}
          filterKeys={["email", "name", "club"]}
          onSelect={(it) => {
            handleMemberSelect(it);
          }}
          emptyMessage="No Riders Found"
        />
      ) : (
        <div style={{ margin: "20px auto" }}>No registrants found</div>
      )}

      {modals}
    </div>
  );
}
