/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useMutation } from "hooks";
import { Formik } from "formik";
import moment from "moment";
import Client from "client";

//import { required } from "validation/form";

//import { required } from "validation/form";
import ConfirmationModal, {
  useConfirmationModal,
} from "confirmation-modal/confirmation-modal";

import ShowClassModalForm from "./show-class-modal-form";

const validationSchema = Yup.object({
  startAt: Yup.date().label("Start date").required(),
  arenaId: Yup.string().label("Arena").required(),
  disciplineId: Yup.string().label("Discipline").required(),
  classTypeId: Yup.string().label("Class type").required(),
  ageGroupId: Yup.string().label("Age group").required(),
  levels: Yup.array().label("Class levels").min(1),
});

export default function ShowModal({
  setShowModal = () => {},
  showModal = true,
  show,
  locationId,
  dailyOrder,
  showClass,
  onSuccess = () => {},
}) {
  const {
    showModal: showConfirmationModal,
    handleExit,
    handleClose: handleConfirmationClose,
  } = useConfirmationModal();

  const [modalHeight, setModalHeight] = useState(0);

  const theme = useTheme();

  const initialValues = {
    startAt: new Date(show.start_at),
    arenaId: null,
    disciplineId: null,
    classTypeId: null,
    ageGroupId: null,
    levels: [],
    modifiers: [],
    excludeFromHighPt: false,
  };

  const calculateModalHeight = () => {
    setTimeout(() => {
      const modalDialog = document.querySelector(".modal-content");
      if (modalDialog) {
        console.log("This Modal Height = " + modalDialog.offsetHeight + "px");
        setModalHeight(modalDialog.offsetHeight);
      }
    }, 10); // delay
  };
  useEffect(() => {
    if (showConfirmationModal) {
      calculateModalHeight();
    }
  }, [showConfirmationModal]);
  // const twentyFourHoursInMs = 1000 * 60 * 60 * 24;

  const {
    mutateAsync: submitRequest,
    isLoading,
    error,
  } = useMutation({
    mutationKey: "create-show-class",
    networkMode: "always",
    mutationFn: async (data) => {
      const body = {
        ...data,
        showId: show.id,
        startAt: moment(data.startAt, "YYYY-MM-DD").toISOString(),
        arenaId: data.arenaId,
        disciplineId: data.disciplineId,
        classTypeId: data.classTypeId,
        ageGroupId: data.ageGroupId,
      };

      const myMomentObject = moment(data.startAt, "YYYY-MM-DD");
      data.startAt = myMomentObject.toISOString();

      return Client.post("/show-classes/bulk", body);
    },
    onSuccess: (values) => {
      setShowModal(false);
      onSuccess(values);
    },
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={submitRequest}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <ShowClassModalForm
            formik={formik}
            show={show}
            handleExit={handleExit}
            setShowModal={setShowModal}
            isLoading={isLoading}
          />
        )}
      </Formik>

      <ConfirmationModal
        showModal={showConfirmationModal}
        handleExit={handleExit}
        handleClose={handleConfirmationClose}
        handleGoBack={handleConfirmationClose}
        setShowModal={setShowModal}
        modalHeight={modalHeight}
      />
    </>
  );
}
