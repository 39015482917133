/** @jsxImportSource @emotion/react */

import { useTheme } from "@emotion/react";
import {
  appFonts,
  appThemes,
  borderRadius,
  fontbox,
  paddingX,
  paddingY,
} from "emotion/utils";
import selectArrow from "images/selectArrow.svg";

export default function FilterSelect({
  align,
  data,
  disabled = false,
  name,
  label = `Select Your ${name}:`,
  minWidth = 173,
  onChange,
  options = [],
  showLabel = true,
  showOptionAll = true,
  value,
}) {
  let formattedOptions = [];
  if (options.length > 0) {
    formattedOptions = options;
  } else {
    formattedOptions = data?.data.map((opt) => (
      <option key={opt.id} value={opt.id}>
        {opt.name}
      </option>
    ));
  }

  const theme = useTheme();

  const margin = {
    marginLeft: align === "right" ? "auto" : align === "left" ? 0 : "auto",
    marginRight: align === "left" ? "auto" : align === "right" ? 0 : "auto",
  };

  const getLabel = showLabel && <label>{label}</label>;

  const optAll = showOptionAll ? (
    <option value="">All</option>
  ) : (
    <option value="">Select...</option>
  );

  return (
    <div css={css.container(theme, minWidth, margin)}>
      {getLabel}
      <select
        aria-label="select type"
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        {optAll}
        {formattedOptions}
      </select>
    </div>
  );
}

const css = {
  container: (theme, minWidth, margin) => ({
    ...margin,
    width: "auto!important",

    "& label": {
      textTransform: "capitalize",
    },

    "& select": {
      // appearance: "none", // Remove default browser dropdown arrow
      // WebkitAppearance: "none", // For Safari
      // MozAppearance: "none", // For Firefox
      // backgroundImage: `url(${selectArrow})`, // Path to your custom arrow image
      // backgroundRepeat: "no-repeat",
      // backgroundPosition: "right 10px center", // Adjust according to your needs
      // backgroundSize: "8px 5px", // Adjust based on the size of your arrow image
      // ...borderRadius("2px!important"),
      // ...fontbox(theme.fonts.inter, 12, 400, 12),
      // backgroundColor: theme.colors.white,
      // border: `1px solid ${theme.colors.decorationGrey}!important`,
      // color: theme.colors.black,
      // height: "28px!important",
      // minWidth,
      // outline: "none",
      // paddingRight: "30px!important",
      // paddingLeft: `10px!important`,
      ...borderRadius(8),
      ...fontbox(appFonts.inter, 14, 500, 22),
      ...paddingX(14),
      ...paddingY(10),
      border: `1px solid ${theme?.border?.secondary}`,
      boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
      color: theme?.text?.primary,

      "@media screen only and (max-width: 576px)": {
        ...fontbox(appFonts.inter, 16, 500, 24),
      },
    },
  }),
};
