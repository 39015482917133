import { React } from "react";

export default function FormListText(props) {
  const { value, name, errorClass, placeholder, onChange, onBlur } = props;

  return (
    <input
      type="text"
      className={errorClass}
      id={name}
      name={name}
      onChange={onChange}
      value={value || ""}
      placeholder={placeholder || "Type item..."}
      onBlur={onBlur}
    />
  );
}
