/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { Dropdown as DropdownPR } from "primereact/dropdown";

import { cssFormElements } from "../css";
import { FormErrorMessage } from "validation/form-error-message";

const Dropdown = ({ required, formik, size, options, editable, ...data }) => {
  const theme = useTheme();

  const elWidth = `${size || "100"}%`;

  const getLabel = data?.label && (
    <label>
      {data.label}
      {required && <span>*</span>}
    </label>
  );

  // const options2 = options.map((opt) => ({
  //   name: opt.label,
  //   code: opt.value,
  // }));

  const elProps = {
    ...Object.keys(data).reduce((acc, key) => {
      if (key !== "data") {
        acc[key] = data[key];
      }
      return acc;
    }, {}),
  };

  const getElement = (
    <DropdownPR {...elProps} options={options} editable={editable} />
  );

  return (
    <div css={cssFormElements(theme, elWidth).dropdown}>
      {getLabel}
      {getElement}
      {required && <FormErrorMessage name="name" formik={formik} />}
    </div>
  );
};

export default Dropdown;
