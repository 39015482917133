import {
  appFonts,
  borderRadius,
  flexbox,
  fontbox,
  fullWidth,
  paddingX,
  paddingY,
  posAbsolute,
  posRelative,
} from "emotion/utils";

export const css = (theme) => ({
  ...flexbox("column", "flex-start", "flex-start", 4),
  flex: 1,
  marginTop: 24,
  overflowY: "scroll",
  paddingBottom: 16,

  highlight: {
    // ...borderRadius(6),
    // ...posAbsolute(0, 16, 0, 16, -1),
    // backgroundColor: theme?.background?.bgActive,
  },

  li: {
    link: (active) => ({
      ...flexbox("row", "center", "flex-start", 12),
      ...fontbox(appFonts.inter, 16, 600, 24),
      ...paddingX(28),
      ...paddingY(8),
      ...posRelative(),
      color: theme?.text?.secondary,
      cursor: "pointer",

      "&::after": {
        ...borderRadius(6),
        ...posAbsolute(0, 16, 0, 16, -1),
        // backgroundColor: active ? theme?.background?.bgActive : null,
        content: '""',
      },

      "> div:not(:first-of-type)": {
        marginLeft: "auto",
      },
      "div[class$='-MenuItem'] + div:not(:first-of-type)": {
        marginLeft: 0,
      },
    }),
  },

  submenu: (isOpen) => {
    return {
      ...flexbox("column", "flex-start", "flex-start", 4),
      overflow: "hidden",

      li: (active) => ({
        link: {
          ...flexbox("row", "center", "flex-start"),
          ...fontbox(appFonts.inter, 16, 600, 24),
          ...fullWidth(),
          ...paddingY(8),
          ...posRelative(),
          color: theme?.text?.secondary,
          paddingRight: 36,
          paddingLeft: 64,

          "&::after": {
            ...borderRadius(6),
            ...posAbsolute(0, 16, 0, 16, -1),
            backgroundColor: active ? theme?.background?.bgActive : null,
            content: '""',
          },
        },
      }),

      shows: {
        ...posRelative(),
        marginTop: 20,
        paddingTop: 24,

        "&::after": {
          ...posAbsolute(0, 16, null, 16),
          content: '""',
          backgroundColor: theme.border.secondary,
          height: 1,
        },

        title: {
          ...fontbox(appFonts.inter, 16, 600, 24),
          ...fullWidth(),
          ...paddingY(8),
          ...posRelative(),
          // color: "hotpink",
          paddingRight: 28,
          paddingLeft: 28,
        },

        ul: {
          ...flexbox("column", "flex-start", "flex-start", 4),
          // paddingLeft: 36,

          li: (active) => ({
            link: {
              ...flexbox("row", "flex-start", "flex-start"),
              ...fontbox(appFonts.inter, 16, 600, 24),
              ...fullWidth(),
              ...paddingY(8),
              ...posRelative(),
              color: theme?.text?.secondary,
              paddingRight: 36,
              paddingLeft: 64,

              "&::after": {
                ...borderRadius(6),
                ...posAbsolute(0, 16, 0, 16, -1),
                backgroundColor: active ? theme?.background?.bgActive : null,
                content: '""',
              },

              "> div": {
                padding: 0,
              },
            },
          }),
        },
      },
    };
  },
});
