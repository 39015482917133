import React from "react";
import { useSearchParams } from "react-router-dom";

import HhhhLogo from "components/icons/icon4h-logo";
import moment from "moment";
import css from "./confirm-custodian.module.scss";

function ConfirmCustodian(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const name = searchParams.get("name");
  const dob = moment(searchParams.get("dob"));
  const clubName = searchParams.get("club");

  const logo = (
    <div className={css.logo}>
      <HhhhLogo />
    </div>
  );

  const copy = (
    <p>
      You confirmed this is your rider. Next, accept show waivers and pay for
      classes on the{" "}
      <a href="https://apps.apple.com/us/app/showmanship-rider/id6478242089">
        iOS app
      </a>{" "}
      or on the{" "}
      <a href="https://play.google.com/store/apps/details?id=com.themainbranch.showmanship&hl=en_US&pli=1">
        Android app
      </a>
    </p>
  );
  const info = (
    <div className={css.info}>
      <div className={css.row}>
        <div className={css.label}>Name</div>
        <div className={css.val}>{name}</div>
      </div>

      <div className={css.row}>
        <div className={css.label}>DOB</div>
        <div className={css.val}>{dob.format("MM/DD/YYYY")}</div>
      </div>

      <div className={css.row}>
        <div className={css.label}>Club</div>
        <div className={css.val}>{clubName}</div>
      </div>
    </div>
  );

  return (
    <div className={css.Login}>
      <div className={css.container}>
        {logo}
        {copy}
        {info}
      </div>
    </div>
  );
}

export default ConfirmCustodian;
