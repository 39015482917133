/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "hooks";
import { Formik, Form } from "formik";
import moment from "moment";
import * as Yup from "yup";

//import { required } from "validation/form";
import { FormErrorMessage } from "validation/form-error-message";

//import { required } from "validation/form";
import ConfirmationModal, {
  useConfirmationModal,
} from "confirmation-modal/confirmation-modal";
import CustomModal from "components/custom-modal/custom-modal";
import Button from "components/button/button";
import { FormElement } from "components/form-element/form-element";

const validationSchema = Yup.object({});

export default function ShowModalEditForm(props) {
  const {
    formik,
    setShowModal = () => {},
    handleExit = () => {},
    show,
    showClass,
    isLoading,
    isError,
  } = props;

  const conditionalClose = () => {
    if (formik.dirty) {
      handleExit();
    } else {
      setShowModal(false);
    }
  };

  const theme = useTheme();

  const {
    data: arenaData,
    isLoading: arenaDataIsLoading,
    error: arenaDataError,
  } = useQuery(`/arenas?locationId=${show.location_id}`);

  const {
    data: patternData,
    isLoading: patternDataIsLoading,
    error: patternDataError,
  } = useQuery("/patterns");

  if (isLoading || arenaDataIsLoading || patternDataIsLoading) {
    return <div>Loading...</div>;
  }
  if ((isError || arenaDataError, patternDataError)) {
    return <div>Error!!!</div>;
  }

  const headerTitle = `Edit ${showClass.name}`;

  const btnCancel = (
    <Button
      disabled={isLoading}
      label="Cancel"
      onClick={conditionalClose}
      color="secondary"
      variant="outlined"
    />
  );

  const btnUpdateShow = (formik) => (
    <Button
      disabled={!(formik.isValid && formik.dirty)}
      label="Add to show"
      onClick={(e) => {
        formik.handleSubmit(e);
        // calculateModalHeight();
      }}
    />
  );

  const footerButtons = (formik) =>
    [btnCancel, btnUpdateShow(formik)].map((btn, index) => (
      <React.Fragment key={index}>{btn}</React.Fragment>
    ));

  const arenaOptions = arenaData
    ? arenaData?.data.map((a) => ({
        value: a.id,
        label: a.name,
      }))
    : [];

  const patternOptions = patternData
    ? patternData?.data.map((a) => ({
        value: a.id,
        label: a.name,
      }))
    : [];

  return (
    <Form>
      <CustomModal
        headerTitle={headerTitle}
        footerButtons={footerButtons(formik)}
        onHide={conditionalClose}
      >
        <FormElement
          element="Calendar"
          id="startAt"
          name="startAt"
          label="Date"
          formik={formik}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.startAt}
          date
          min={show?.start_at}
          max={show?.end_at}
          required
          size="43"
        />
        <FormErrorMessage name="startAt" formik={formik} />

        <FormElement
          element="Dropdown"
          id="arenaId"
          name="arenaId"
          label="Arena"
          placeholder="Select arena"
          formik={formik}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.arenaId}
          options={arenaOptions}
          required
          size="52"
        />
        <FormErrorMessage name="arenaId" formik={formik} />

        <FormElement
          element="Dropdown"
          id="patternId"
          name="patternId"
          label="Pattern"
          placeholder="Pattern"
          formik={formik}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.patternId}
          options={patternOptions}
          required
          editable
        />
      </CustomModal>
    </Form>
  );
}
