/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import Icon from "components/icon/icon";

import Submenu from "./menu-submenu";

import { css } from "./css";

const MenuItem = ({ page }) => {
  const loc = useLocation();

  const activePage = new RegExp(`^${page.linkTo}`).test(loc.pathname);

  const [isOpen, setIsOpen] = useState(activePage);

  const theme = useTheme();

  const toggle = (isOpen) => (
    <Icon
      icon="ChevronDown"
      isOpen={isOpen}
      stroke={theme?.text?.placeholder}
    />
  );

  const LinkItem = ({ children, to }) => <Link to={to}>{children}</Link>;

  return (
    <li css={css(theme).li}>
      {!page.children ? (
        <LinkItem to={page.linkTo}>
          <div css={css(theme).li.link(activePage)}>
            {page?.icon && (
              <Icon
                icon={page.icon}
                fill={page?.fill || theme?.text?.placeholder}
                stroke={page?.stroke}
              />
            )}
            {page.title}
          </div>
        </LinkItem>
      ) : (
        <div
          css={css(theme).li.link(activePage)}
          onClick={() => setIsOpen(!isOpen)}
        >
          {page?.icon && (
            <Icon icon={page.icon} fill={page?.fill} stroke={page?.stroke} />
          )}
          {page.title}
          {isOpen ? toggle(true) : toggle(false)}
        </div>
      )}

      {isOpen && <Submenu page={page} />}
    </li>
  );
};

export default MenuItem;
