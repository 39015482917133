import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";

// import PageHeader from 'components/page-header/page-header';
import PageSubHeader from "components/page-sub-header/page-sub-header";

export default function ShowReports() {
  const {
    // showData,
    // showName,
    setActiveBtnGroup,
    // onSuccess
  } = useOutletContext();

  return (
    <>
      <PageSubHeader title="High Point Report" />

      <p>
        <strong>* UNDER CONSTRUCTION *</strong>
      </p>
    </>
  );
}
