/**@jsxImportSource @emotion/react */
import React from "react";

import Button from "components/button/button";
import CustomModal from "components/custom-modal/custom-modal";

const ModalDeleteMember = ({ onHide, element = "this element" }) => {
  const headerTitle = `Delete ${element}`;

  const btnCancel = (
    <Button
      label="Cancel"
      onClick={() => onHide(false)}
      color="secondary"
      variant="outlined"
    />
  );

  const btnDelete = () => (
    <Button
      label="Delete"
      onClick={() => {
        alert("Delete");
      }}
      color="danger"
    />
  );

  const footerButtons = () =>
    [btnCancel, btnDelete()].map((btn, index) => (
      <React.Fragment key={index}>{btn}</React.Fragment>
    ));

  return (
    <CustomModal
      headerTitle={headerTitle}
      footerButtons={footerButtons()}
      onHide={onHide}
    >
      <p>
        Are you sure you want to delete <strong>{element}</strong>? This action
        cannot be undone.
      </p>
    </CustomModal>
  );
};

export default ModalDeleteMember;
