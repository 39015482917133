import React, { Component } from "react";
import { Toast } from "primereact/toast";

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./error-boundary.scss";

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.toast = React.createRef();
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.log(error, info);

    this.toast.current.show({
      severity: "error",
      summary: "Error",
      detail: "An unexpected error occurred.",
      life: 5000,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary-container">
          <div className="error-content">
            <h1>Oops. Something went wrong.</h1>
            <a href="/">Back</a>
            <Toast ref={this.toast} />
          </div>
        </div>
      );
    }

    return (
      <>
        {this.props.children}
        <Toast ref={this.toast} />
      </>
    );
  }
}

export default ErrorBoundary;
