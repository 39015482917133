import { maxWidth } from "@mui/system";
import {
  appColors,
  appFonts,
  borderBottomRightRadius,
  borderTopRightRadius,
  flexbox,
  fontbox,
  fullWidth,
  paddingX,
} from "emotion/utils";

export const css = (theme) => ({
  ...flexbox("column", "flex-start", "flex-start", 16),

  // Show Title
  showTitleInput: {
    ...flexbox("row", "center", "flex-start", 16),
    ...fullWidth(),

    title: {
      ...fontbox(appFonts.inter, 20, 700, 30),
      color: theme?.text?.primary,
    },
    subStat: {
      ...fontbox(appFonts.inter, 16, 600, 24),
      borderLeft: `1px solid ${theme?.border?.primary}`,
      color: appColors.grays[1],
      paddingLeft: 16,
    },
  },

  // Class Riders page
  classRiders: {
    ...flexbox("column", "flex-start", "flex-start", 16),
  },

  actions: {
    ...flexbox("row", "center", "flex-end", 16),
    marginLeft: "auto",

    inputGroup: {
      ...flexbox("row", "center", "flex-start"),

      "> div": {
        "> input": {
          ...borderTopRightRadius(0),
          ...borderBottomRightRadius(0),
          borderRight: "none",
          width: 50,
        },

        "+ label": {
          ...borderTopRightRadius(8),
          ...borderBottomRightRadius(8),
          ...flexbox("row", "center", "center"),
          ...fontbox(appFonts.inter, 16, 400, 24),
          ...paddingX(14),
          border: `1px solid ${theme?.border?.primary}`,
          borderLeft: "none",
          color: theme?.text?.secondary,
          height: 46,
        },
      },
    },
  },
});
