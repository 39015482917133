import React from "react";
import { useMutation } from "@tanstack/react-query";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import Client from "client";

export const showType = {
  PERFORMANCE: "PERFORMANCE",
};

export default function LocationRemoveModal({
  setShowModal = () => {},
  location,
  onAccept = () => {},
}) {
  const {
    mutateAsync: submitRequest,
    isLoading,
    // isError,
  } = useMutation({
    mutationKey: "remove-location",
    networkMode: "always",
    mutationFn: async () => Client.delete(`/locations/${location.id}`),
    onSuccess: (values) => {
      setShowModal(false);
      onAccept();
    },
  });

  return (
    <Modal show={true} onHide={() => setShowModal(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {location ? `Edit Show ${location.name}` : "Create Show"}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        Are you sure you want to remove location <i>{location.name}</i>?
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-danger" onClick={() => setShowModal(false)}>
          Cancel
        </Button>

        <Button onClick={submitRequest} variant="danger" disabled={isLoading}>
          Remove
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
