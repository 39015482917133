/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { css } from "../css";
import Accordion from "components/accordion/accordion";
import CustomDivTable from "components/custom-table/custom-div-table";

const MemberAwards = (memberData) => {
  const theme = useTheme();

  const columns = [
    {
      name: "award",
      default: () => "",
      valFn: (data) => data?.base_award?.name || "--",
      display: `Award`,
    },
    {
      name: "class",
      default: () => "",
      valFn: (data) => data?.show_class_results?.show_class?.name || "--",
      display: "Class",
    },
    {
      name: "show",
      default: () => "",
      valFn: (data) => data?.show_class_results?.show_class?.show?.name || "--",
      display: "Show",
    },
    {
      name: "actions",
      // valFn: (it) => (
      //   <CustomDivTableActions>
      //     <ButtonIcon
      //       icon={<Icon icon="Edit" />}
      //       onClick={() => alert('edit member horse functionality')}
      //     />
      //   </CustomDivTableActions>
      // ),
      display: "",
      noSort: true,
      actionCol: true,
    },
  ];

  const table = (
    <CustomDivTable
      columns={columns}
      rows={memberData?.memberData?.member?.awards}
      emptyMessage="No Awards Found"
    />
  );

  const awardsTabs = [
    {
      icon: "Award",
      name: "Awards",
      content: table,
    },
  ];

  return (
    <div css={css(theme).awards}>
      <Accordion tabs={awardsTabs} />
    </div>
  );
};

export default MemberAwards;
