import {
  appColors,
  appFonts,
  borderBottomLeftRadius,
  borderBottomRightRadius,
  borderRadius,
  borderTopLeftRadius,
  borderTopRightRadius,
  flexbox,
  fontbox,
  fullHeight,
  fullWidth,
  marginAuto,
  paddingX,
  paddingY,
  posFixed,
  posStatic,
} from "emotion/utils";

export const css = (theme) => ({
  announcer: {
    ...flexbox("column", "flex-start", "flex-start", 32),
    ...fullHeight(),

    "> *": {
      ...fullWidth(),
    },

    "&.none": {
      ...flexbox("column", "center", "center"),
      ...fullWidth(),
      height: "calc(100% - 50px)",

      h1: {
        ...fontbox(appFonts.inter, 24, 700, 34),
        color: theme?.text?.primary,
        textAlign: "center",
      },
    },
  },

  navBlock: {
    height: 95,

    header: {
      ...flexbox("row", "center", "space-between"),
      ...fullWidth(),
      // ...posFixed(0, 0, "auto", 0),
      ...posStatic(),
      borderRight: "none",
      borderBottom: `1px solid ${theme?.border?.secondary}`,
      paddingTop: 0,

      "> div": {
        "&:last-of-type": {
          // Current User Block
          ...posStatic(),
          marginLeft: "auto",
          width: "auto",
        },
      },
    },
  },

  titleBar: {
    ...flexbox("row", "center", "space-between", 8),
    ...fontbox(appFonts.inter, 24, 700, 24),
    ...marginAuto(),
    // ...posFixed(50, 0, null, 0),
    height: 66,
    // backgroundColor: theme.colors.smoke,
    // padding: '0 36px',
    maxWidth: 1920,
    width: "94%",

    "> div": {
      ...fontbox(appFonts.inter, 24, 700, 24),
    },
  },

  content: {
    ...marginAuto(),
    flexGrow: 1,
    maxWidth: 1920,
    overflow: "hidden",
    // paddingTop: 66,
    width: "94%",

    "> *": {
      ...fullWidth(),
    },
  },

  container: {
    ...flexbox("row", "stretch", "flex-start", 36),
    ...fullHeight(),
    // paddingTop: 16,
  },

  leftColumn: {
    ...flexbox("column", "flex-start", "flex-start", 16),
    width: 430,

    "> *": {
      ...fullWidth(),
    },

    list: {
      ...flexbox("column", "flex-start", "flex-start", 16),
      flex: 1,
      overflowY: "scroll",
      paddingBottom: 36,
    },
  },

  rightColumn: {
    ...flexbox("column", "flex-start", "flex-start"),
    // ...paddingX(18),
    flex: 1,

    "> *": {
      ...fullWidth(),
    },

    "> div": {
      border: `1px solid ${theme?.table?.border}`,
      borderBottomWidth: 0,

      "&:last-of-type": {
        ...borderBottomLeftRadius(12),
        ...borderBottomRightRadius(12),
        borderBottom: `1px solid ${theme?.table?.border}`,
      },
    },

    button: {
      marginTop: 36,
      marginLeft: "auto",
      minWidth: "35%",
      width: "unset",
    },
  },

  headerRow: {
    ...borderRadius("12px 12px 0 0"),
    ...flexbox("row", "center", "flex-start"),
    backgroundColor: theme?.table?.thead,
    borderWidth: "1px 1px 0 1px",
    borderColor: theme?.table?.border,
    borderStyle: "solid",

    "> *": {
      flex: 1,
    },

    headerCol: {
      ...fontbox(appFonts.inter, 12, 500, 18),
      ...paddingX(24),
      ...paddingY(12),
      backgroundColor: theme?.table?.thead,
      borderColor: theme?.table?.border,
      color: theme?.text?.tertiary,

      "&:first-of-type, &:last-of-type": {
        textAlign: "center",
      },
      "&:first-of-type": {
        ...borderTopLeftRadius(11),
        flex: 0.5,
      },
      "&:nth-of-type(3)": {
        flex: 0.75,
        marginRight: 16,
      },
      "&:last-of-type": {
        ...borderTopRightRadius(11),
      },
    },
  },
});
