import {
  appColors,
  appFonts,
  borderRadius,
  flexbox,
  fontbox,
  fullWidth,
  paddingX,
  paddingY,
} from "emotion/utils";

import chevronDown from "images/chevrondown.svg";

export const css = (theme) => ({
  ...flexbox("column", "flex-start", "flex-start", 16),
  ...fullWidth(),

  relationship: {
    ...flexbox("column", "flex-start", "flex-start", 6),
    ...fullWidth(),

    "> label": {
      ...fontbox(appFonts, 14, 500, 20),
      color: appColors.grays[700],
    },

    select: {
      ...borderRadius(8),
      ...fontbox(appFonts.inter, 14, 400, 22),
      ...fullWidth(),
      ...paddingX(14),
      ...paddingY(10),
      border: `1px solid ${appColors?.blues.blueGray}`,
      boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
      color: appColors?.blues?.placeholder,

      "@media screen and (min-width: 576px)": {
        ...fontbox(appFonts.inter, 16, 400, 24),
      },
    },
  },

  container: {
    ...fullWidth(),

    "> div[class^='form-block_formBlock']": {
      "> div:nth-of-type(3)": {
        "> select": {
          ...borderRadius("4px!important"),
          ...fontbox(
            `${appFonts?.inter}!important`,
            "16px!important",
            "400!important",
          ),
          appearance: "none",
          WebkitAppearance: "none",
          MozAppearance: "none",
          backgroundColor: theme?.background?.primary,
          backgroundImage: `url(${chevronDown})`,
          backgroundPosition: "right 10px center",
          backgroundRepeat: "no-repeat",
          border: `1px solid ${theme?.border?.primary}!important`,
          height: "40px!important",
          lineHeight: "16px",
          paddingLeft: "10px!important",
        },
      },
    },
  },
});
