/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import React from "react";

import { css } from "./css";
import { square } from "emotion/utils";

export default function RiderList(props) {
  const { data, isLoading, noClass } = props;

  const theme = useTheme();

  if (noClass) {
    return (
      <div css={css(theme).noRiders}>
        <p>No classes found for show</p>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div css={css(theme).noRiders}>
        <p>Loading riders...</p>
      </div>
    );
  }

  if (!data?.length) {
    return (
      <div css={css(theme).noRiders}>
        <p>Select a class from the list to the left.</p>
      </div>
    );
  }

  const riderEls = data
    .sort((a, b) =>
      a.registrant.rider_number < b.registrant.rider_number ? -1 : 1,
    )
    .map((r) => {
      const awardEl = r.awards.map((aw) => (
        <img key={aw.id} url={aw.img_link} style={{ ...square(32) }} alt="" />
      ));

      return (
        <div key={r.user_id} css={css(theme).rider}>
          <div css={css(theme).col}>{r.registrant.rider_number}</div>
          <div css={css(theme).col}>
            {r.first_name} {r.last_name}
          </div>
          <div css={css(theme).col}>{r.animal.name}</div>
          <div css={css(theme).col}>{awardEl}</div>
        </div>
      );
    });

  return <>{riderEls}</>;
}
