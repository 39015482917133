import { borderRadius, flexbox, paddingXY } from "emotion/utils";

export const css = (theme) => ({
  ...borderRadius(8),
  ...paddingXY(16),
  backgroundColor: theme.background.primary,
  boxShadow: `0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)`,

  ".p-card": {
    "&-body": {
      ...paddingXY(0),
    },

    "&-content": {
      ...flexbox("row", "center", "space-between", 16),
      ...paddingXY(0),
    },
  },
});
