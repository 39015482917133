import {
  appFonts,
  borderRadius,
  flexbox,
  fontbox,
  fullWidth,
  paddingX,
  paddingY,
} from "emotion/utils";

export const css = (theme) => {
  const memberBlockStyles = {
    ".p-accordion-header-link": {
      backgroundColor: "transparent!important",
    },

    ".p-accordion-content": {
      backgroundColor: "transparent",

      "> div": {
        ...borderRadius("0 0 12px 12px"),
        overflow: "hidden",

        table: {
          backgroundColor: "transparent",
          border: "none",

          tr: {
            backgroundColor: "transparent",

            "&:last-of-type": {
              ...borderRadius("0 0 12px 12px"),
            },

            td: {
              backgroundColor: "transparent",
            },
          },
        },
      },
    },
  };

  return {
    ...flexbox("column", "flex-start", "flex-start", 16),

    "> *": {
      ...fullWidth(),
    },

    // "div:not(:first-of-type)": {
    //   ...borderRadius(12),
    //   // border: `1px solid ${theme?.border?.secondary}`,
    // },

    iconItem: {
      ...flexbox("row", "center", "flex-start", 4),
    },

    personal: {
      ...borderRadius(12),
      ...flexbox("row", "center", "space-between", 16),
      ...paddingX(20),
      ...paddingY(24),
      border: `1px solid ${theme?.border?.secondary}`,

      info: {
        ...flexbox("column", "flex-start", "flex-start", 16),

        top: {
          ...flexbox("row", "center", "flex-start", 8),

          h1: {
            ...fontbox(appFonts.inter, 18, 600, 28),
            color: theme?.text?.primary,
          },
        },

        btm: {
          ...flexbox("row", "center", "flex-start", 16),
          ...fontbox(appFonts.inter, 14, 400, 20),
          color: theme?.text?.tertiary,
        },
      },

      address: {
        ...fontbox(appFonts.inter, 14, 400, 20),
        color: theme?.text?.tertiary,
      },
    },

    guardians: {
      ...memberBlockStyles,
    },

    horses: {
      ...memberBlockStyles,
    },

    awards: {
      ...memberBlockStyles,
    },
  };
};
