import {
  appFonts,
  flexbox,
  fontbox,
  fullHeight,
  paddingX,
  paddingY,
} from "emotion/utils";

export const css = (theme) => ({
  ...flexbox("column", "flex-start", "flex-start", 16),
  ...fullHeight(),

  stemEls: {
    ...flexbox("column", "flex-start", "flex-start"),
    borderBottom: `none`,

    stemEl: {
      ...flexbox("row", "center", "flex-start", 16),
      ...paddingX(24),
      ...paddingY(16),
      borderBottom: `1px solid ${theme?.border?.secondary}`,

      title: {
        ...fontbox(appFonts.inter, 14, 500, 20),
        color: theme?.text?.primary,
      },
    },
  },
});
