import {
  appColors,
  appFonts,
  flexbox,
  fontbox,
  fullWidth,
  paddingY,
} from "emotion/utils";

const medGray = appColors?.grays?.[500];

export const css = (theme) => ({
  noRiders: {
    ...flexbox("column", "center", "center"),
    ...fullWidth(),

    p: {
      ...fontbox(appFonts.inter, 24, 700, 24),
      ...paddingY(24),
      color: medGray,
      width: "auto",
    },
  },

  rider: {
    display: "flex",
    alignItems: "flex-start",

    "> *": {
      flex: 1,
    },
  },

  col: {
    padding: "18px",
    backgroundColor: theme?.background?.primary,
    color: medGray,
    fontSize: "16px",
    lineHeight: "24px",
    verticalAlign: "middle",

    "&:first-of-type, &:last-of-type": {
      textAlign: "center",
    },

    "&:first-of-type": {
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
      flex: "0.5",
      fontSize: "24px",
      fontWeight: "700",
      lineHeight: "24px",
    },

    "&:nth-of-type(3)": {
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
      flex: "0.75",
      marginRight: "16px",
    },

    "&:last-of-type": {
      borderRadius: "4px",
    },
  },
});
