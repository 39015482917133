import {
  appColors,
  borderRadius,
  flexbox,
  fullWidth,
  paddingXY,
} from "emotion/utils";

export const css = (theme) => ({
  radioBtns: {
    ...flexbox("column", "flex-start", "flex-start", 12),
    ...fullWidth(),

    "> div": {
      ...borderRadius(12),
      ...fullWidth(),
      ...paddingXY(16),
      border: `1px solid ${theme?.border?.secondary}`,
    },
  },

  formEls: {
    ...flexbox("row", "flex-start", "space-between", 16, "wrap"),
    borderTop: `1px solid ${appColors?.blues?.blueSmoke}`,
    paddingTop: 16,

    // "> *": {
    //   minWidth: "48%",
    // },

    "> div[class$='InputTextarea']": {
      ...fullWidth(),
    },
  },
});
