import React from "react";
import { useMutation } from "@tanstack/react-query";

import Client from "client";

import CustomModal from "components/custom-modal/custom-modal";
import Button from "components/button/button";

export const showType = {
  PERFORMANCE: "PERFORMANCE",
};

export default function ArenaRemoveModal({
  setShowModal = () => {},
  arena,
  onAccept = () => {},
}) {
  const { mutateAsync: submitRequest, isLoading } = useMutation({
    mutationKey: "remove-arena",
    networkMode: "always",
    mutationFn: async () =>
      arena.archived_at
        ? Client.put(`/arenas/${arena.id}`, { archive: false })
        : Client.put(`/arenas/${arena.id}`, { archive: true }),
    onSuccess: () => {
      setShowModal(false);
      onAccept();
    },
  });

  const headerTitle = `${arena.archived_at ? "Activate" : "Archive"} ${
    arena.name
  }`;

  const btnCancel = (
    <Button
      disabled={isLoading}
      label="Cancel"
      onClick={() => setShowModal(false)}
      color="secondary"
      variant="outlined"
    />
  );

  const btnDelete = (
    <Button
      disabled={isLoading}
      label="Archive"
      onClick={() => {
        // alert('Delete');
        submitRequest();
      }}
      color="danger"
    />
  );

  const footerButtons = () =>
    [btnCancel, btnDelete].map((btn, index) => (
      <React.Fragment key={index}>{btn}</React.Fragment>
    ));

  return (
    <CustomModal
      headerTitle={headerTitle}
      footerButtons={footerButtons()}
      onHide={() => setShowModal(false)}
    >
      <p>
        Are you sure you want to archive <strong>{arena.name}</strong>? This
        action cannot be undone.
      </p>
    </CustomModal>
  );
}
