/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { InputText as InputTextPR } from "primereact/inputtext";

import { cssFormElements } from "../css";
import { FormErrorMessage } from "validation/form-error-message";

const InputButtonGroup = ({ required, formik, size, btn, ...data }) => {
  const theme = useTheme();

  console.log("btn", btn);

  const elWidth = `${size || "100"}%`;

  const getLabel = data?.label && (
    <label>
      {data.label}
      {required && <span>*</span>}
    </label>
  );

  const elProps = {
    ...Object.keys(data).reduce((acc, key) => {
      if (key !== "data") {
        acc[key] = data[key];
      }
      return acc;
    }, {}),
  };

  const getElement = <InputTextPR {...elProps} />;

  return (
    <div
      className="p-input"
      css={cssFormElements(theme, elWidth).inputButtonGroup}
    >
      {getLabel}

      <div className="p-inputgroup">
        {/* <span className="p-inputgroup-addon">http(s)://</span> */}
        {getElement}
        {btn}
      </div>
      {required && <FormErrorMessage name="name" formik={formik} />}
    </div>
  );
};

export default InputButtonGroup;
