import React, { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";

import AuthContext from "context/auth-context";
import { NotFound } from "pages/not-found/not-found";
// import pkg from "hhhh-version";

const LOGIN_PATH = "/login";
const ANNOUNCER_PATH = "/announcer";

function ProtectedLayout(props) {
  const { userData } = useContext(AuthContext);

  const { portal } = props;

  if (!userData || !userData.id) {
    return <Navigate to={LOGIN_PATH} replace></Navigate>;
  }

  if (userData.announcer && portal !== "announcer") {
    return <Navigate to={ANNOUNCER_PATH} replace></Navigate>;
  }

  if (!userData[portal]) {
    return <NotFound />;
  }

  return <Outlet />;
}

export default ProtectedLayout;
