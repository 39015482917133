import {
  appColors,
  appFonts,
  flexbox,
  fontbox,
  fullWidth,
  square,
} from "emotion/utils";

export const css = (theme) => ({
  ...flexbox("column", "flex-start", "flex-start", 16),

  // Show Title
  showTitleInput: {
    title: {
      ...fontbox(appFonts.inter, 20, 700, 30),
      color: theme?.text?.primary,
    },
    subStat: {
      ...fontbox(appFonts.inter, 16, 600, 24),
      borderLeft: `1px solid ${theme?.border?.primary}`,
      color: appColors.grays[1],
      paddingLeft: 16,
    },
  },

  // Overview page
  overview: {
    actions: {
      ...flexbox("row", "center", "space-between", 16),

      "> *": {
        flex: 1,
      },
    },

    hero: {
      ...flexbox("row", "stretch", "space-between", 16),

      "> *": {
        flex: 1,
      },

      "> div": {
        "&:first-of-type": {
          ...square(164),
          flex: "unset",
        },
      },
    },

    form: {
      ...flexbox("row", "flex-start", "space-between", 16, "wrap"),

      row: {
        ...flexbox("row", "stretch", "space-between", 16),
        ...fullWidth(),
      },

      col: {
        ...flexbox("column", "flex-start", "flex-start", 16),

        description: {
          ...fullWidth(),
          display: "inline-flex",

          "> div": {
            flex: 1,

            textarea: {
              flex: 1,
            },
          },
        },
      },

      "> p": {
        flex: 2,
      },

      "> div": {
        minWidth: "12%",

        "&:last-of-type": {
          flex: 4,
        },
      },
    },
  },

  // Classes page
  classes: {
    ...flexbox("column", "flex-start", "flex-start", 16),
  },

  // Class Riders page
  classRiders: {
    ...flexbox("column", "flex-start", "flex-start", 16),
  },
});
