import { ReactComponent as Archive } from "./assets/archive.svg";
import { ReactComponent as Award } from "./assets/award.svg";
import { ReactComponent as Check } from "./assets/check.svg";
import { ReactComponent as ChevronDown } from "./assets/chevron-down.svg";
import { ReactComponent as ChevronLeft } from "./assets/chevron-left.svg";
import { ReactComponent as ChevronRight } from "./assets/chevron-right.svg";
import { ReactComponent as Circle } from "./assets/circle.svg";
import { ReactComponent as CloseX } from "./assets/close-x.svg";
import { ReactComponent as Cloud } from "./assets/cloud.svg";
import { ReactComponent as Currency } from "./assets/currency.svg";
import { ReactComponent as Danish } from "./assets/danish.svg";
import { ReactComponent as DotGrid } from "./assets/dot-grid.svg";
import { ReactComponent as Edit } from "./assets/edit.svg";
import { ReactComponent as EmptyBgCircle } from "./assets/empty-bg-circle.svg";
import { ReactComponent as ErrorX } from "./assets/error-x.svg";
import { ReactComponent as Gift3 } from "./assets/gift3.svg";
import { ReactComponent as Horse } from "./assets/horse.svg";
import { ReactComponent as HorseHead } from "./assets/horse-head.svg";
import { ReactComponent as Mail } from "./assets/mail.svg";
import { ReactComponent as Medal } from "./assets/medal.svg";
import { ReactComponent as Logo } from "./assets/logo.svg";
import { ReactComponent as Logout } from "./assets/logout.svg";
import { ReactComponent as Page } from "./assets/page.svg";
import { ReactComponent as Placing } from "./assets/placing.svg";
import { ReactComponent as Plus } from "./assets/plus.svg";
import { ReactComponent as Printer } from "./assets/printer.svg";
import { ReactComponent as Refresh } from "./assets/refresh.svg";
import { ReactComponent as Search } from "./assets/search.svg";
import { ReactComponent as Settings } from "./assets/settings.svg";
import { ReactComponent as Star3 } from "./assets/star3.svg";
import { ReactComponent as Trash } from "./assets/trash.svg";
import { ReactComponent as UploadCloud } from "./assets/upload-cloud.svg";
import { ReactComponent as Users } from "./assets/users.svg";
import { ReactComponent as Users2 } from "./assets/users2.svg";
import { ReactComponent as UserSquare } from "./assets/user-square.svg";

export const icons = {
  Award,
  Archive,
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Circle,
  CloseX,
  Cloud,
  Currency,
  Danish,
  DotGrid,
  Edit,
  EmptyBgCircle,
  ErrorX,
  Gift3,
  Horse,
  HorseHead,
  Mail,
  Medal,
  Logo,
  Logout,
  Page,
  Placing,
  Plus,
  Printer,
  Refresh,
  Search,
  Settings,
  Star3,
  Trash,
  UploadCloud,
  Users,
  Users2,
  UserSquare,
};
