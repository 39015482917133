import {
  appColors,
  appFonts,
  borderRadius,
  borderTopLeftRadius,
  borderTopRightRadius,
  flexbox,
  fontbox,
  paddingX,
  paddingXY,
  paddingY,
  posAbsolute,
  posRelative,
  posStatic,
  square,
} from "emotion/utils";

export const css = (theme, isFullWidth) => ({
  width: isFullWidth ? "100%" : "auto",

  search: {
    marginBottom: 16,
    textAlign: "right",

    "> input": {
      ...borderRadius(8),
      ...paddingX(14),
      ...paddingY(10),
    },
  },

  ".p-toolbar": {
    ...borderRadius(12),
    marginBottom: 16,
    backgroundColor: theme?.background?.primary,
    border: `1px solid ${theme?.table?.border}`,

    "&-group": {
      "&-start > div, &-end > div": {
        ...flexbox("row", "flex-start", "center", 12),
      },
    },
  },

  ".p-datatable": {
    // Wrapper
    // "&-wrapper": {},

    // Table
    "&-table": {
      ...borderRadius(12),
      backgroundColor: theme?.background?.primary,
      border: `1px solid ${theme?.table?.border}`,
    },

    // Thead
    "&-thead": {
      "> tr": {
        "&:hover": {
          backgroundColor: theme?.table?.theadHover,
        },

        "> th": {
          ...fontbox(appFonts.inter, 12, 500, 18),
          ...paddingX(24),
          ...paddingY(12),
          backgroundColor: theme?.table?.thead,
          borderColor: theme?.table?.border,
          color: theme?.text?.tertiary,

          "&:first-of-type": {
            ...borderTopLeftRadius(11),
          },
          "&:last-of-type": {
            ...borderTopRightRadius(11),

            "> div": {
              "> span": {
                "> button": {
                  ...borderRadius(6),
                  ...fontbox(appFonts.inter, 12, 700, 20),
                  ...paddingX(12),
                  ...paddingY(0),
                  ...posRelative(0, -16),
                  height: 26,
                },
              },
            },
          },
          "&:only-of-type": {
            "> div": {
              justifyContent: "flex-start",

              "> span": {
                ...posStatic(),
              },
            },
          },
        },
      },
    },

    // Tbody
    "&-tbody": {
      "> tr": {
        ...fontbox(appFonts.inter, 14, 400, 20),
        backgroundColor: "transparent",
        color: theme?.text?.tertiary,

        "&.p-rowgroup-header": {
          ...fontbox(appFonts.inter, 16, 700, 24),
          backgroundColor: appColors?.blues?.bgActive,

          "> td": {
            ...posRelative(),
            fontWeight: `700!important`,

            "> button": {
              ...borderRadius(0),
              ...flexbox("row", "center", "flex-end"),
              ...posAbsolute(0, 0, 0, 0),
              ...square("100%"),
              backgroundColor: "transparent",
              paddingRight: 16,
            },
          },
        },

        "&:last-of-type": {
          "> td": {
            borderBottom: "none",
          },
        },

        "> td": {
          ...paddingX(24),
          ...paddingY(16),

          "&:first-of-type": {
            color: theme?.text?.primary,
            fontWeight: 500,
          },

          "&:last-of-type": {
            "> div": {
              // ...flexbox("row", "center", "flex-end", 6),

              "> div:not(.p-checkbox-box)": {
                ...paddingXY(10),
                display: "inline-flex",
                width: "auto",
              },
            },
          },

          "input:not([type='checkbox'])": {
            textAlign: "right",
            width: 75,
          },
        },
      },
    },
  },

  actions: {
    ...flexbox("row", "center", "flex-end", 4),
    marginLeft: "auto",
  },

  helper: {
    ...fontbox(appFonts.inter, 10, 400, 10),
    display: "block",
  },
});
