import { flexbox, paddingX, paddingY } from "emotion/utils";

export const css = (theme) => ({
  ...flexbox("row", "center", "flex-start", 24),

  "> button": {
    ...paddingX(14),
    ...paddingY(11),
    height: 42,
    gap: 8,

    "&:first-of-type": {
      marginLeft: "auto",
    },
  },
});
