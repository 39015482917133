import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";

import { useQuery } from "hooks";

import CustomButton from "components/custom-button/custom-button";

import PageHeader from "components/page-header/page-header";

import css from "./pattern.module.scss";

export default function Show() {
  const { patternId } = useParams();
  const navigate = useNavigate();

  const {
    data: pattern,
    refetch,
    isLoading,
    isError,
  } = useQuery(`/patterns/${patternId}`);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error!!!</div>;
  }

  const stepEls = pattern?.data?.pattern_step
    .sort((a, b) => a.order - b.order)
    .map((step) => (
      <li key={step.id} style={{ fontSize: 16 }}>
        {step.text}
      </li>
    ));

  return (
    <div className="container">
      <div className={css.pageHeader}>
        <div className={css.showHeaderSection}>
          <CustomButton
            color="primary"
            type="outlined"
            onClick={() => navigate(-1)}
          >
            <IoIosArrowBack />
          </CustomButton>

          <h1 className={css.pageHeaderTitle}>{pattern?.data?.name}</h1>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: 20,
          width: "100%",
        }}
      >
        <div
          style={{
            width: 400,
            padding: 20,
          }}
        >
          <img
            width="400"
            height="400"
            src={pattern?.data?.image?.link}
            style={{
              height: "auto",
              width: "100%",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: 10,
            width: "100%",
          }}
        >
          <h2
            style={{
              borderBottom: `1px solid rgba(0,0,0,0.05)`,
              paddingBottom: 10,
              width: "100%",
            }}
          >
            Steps
          </h2>
          <ol
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: 10,
              width: "100%",
            }}
          >
            {stepEls}
          </ol>
        </div>
      </div>
    </div>
  );
}
