/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "hooks";
import { Formik, Form } from "formik";
import moment from "moment";
import * as Yup from "yup";
import Client from "client";

import ConfirmationModal, {
  useConfirmationModal,
} from "confirmation-modal/confirmation-modal";

import ShowClassModalEditForm from "./show-class-modal-edit-form";

const validationSchema = Yup.object({});

export default function ShowModalEdit({
  setShowModal = () => {},
  showModal = true,
  show,
  locationId,
  dailyOrder,
  showClass,
  onSuccess = () => {},
}) {
  const {
    showModal: showConfirmationModal,
    handleExit,
    handleClose: handleConfirmationClose,
  } = useConfirmationModal();

  const [modalHeight, setModalHeight] = useState(0);

  const theme = useTheme();

  const initialValues = {
    startAt: new Date(showClass.start_at),
    arenaId: showClass.arena_id,
    patternId: showClass.pattern_id,
  };

  const calculateModalHeight = () => {
    setTimeout(() => {
      const modalDialog = document.querySelector(".modal-content");
      if (modalDialog) {
        console.log("This Modal Height = " + modalDialog.offsetHeight + "px");
        setModalHeight(modalDialog.offsetHeight);
      }
    }, 10); // delay
  };
  useEffect(() => {
    if (showConfirmationModal) {
      calculateModalHeight();
    }
  }, [showConfirmationModal]);
  // const twentyFourHoursInMs = 1000 * 60 * 60 * 24;

  const {
    mutateAsync: submitRequest,
    isLoading,
    isError,
  } = useMutation({
    mutationKey: "create-edit-show-class",
    networkMode: "always",
    mutationFn: async (data) => {
      const body = {
        startAt: moment(data.startAt, "YYYY-MM-DD").toISOString(),
        arenaId: data.arenaId,
        patternId: data.patternId || null,
      };

      return Client.put(`/show-classes/${showClass.id}`, body);
    },
    onSuccess: (values) => {
      setShowModal(false);
      onSuccess(values);
    },
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={submitRequest}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <ShowClassModalEditForm
            formik={formik}
            isLoading={isLoading}
            isError={isError}
            setShowModal={setShowModal}
            handleExit={handleExit}
            show={show}
            showClass={showClass}
          />
        )}
      </Formik>

      <ConfirmationModal
        showModal={showConfirmationModal}
        handleExit={handleExit}
        handleClose={handleConfirmationClose}
        handleGoBack={handleConfirmationClose}
        setShowModal={setShowModal}
        modalHeight={modalHeight}
      />
    </>
  );
}
