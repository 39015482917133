/** @jsxImportSource @emotion/react */

import statementSection from "images/statement-section.jpg";
import devices from "images/statementsection-devices.png";

import { cssMarketing } from "../css";
import { css } from "./css";

const Statement = () => {
  const image = <img alt="" src={devices} css={css.img} />;

  const content = (
    <div css={cssMarketing}>
      <h2>Doing Best, Better</h2>
      <p>
        Showmanship is the heavily anticipated modern solution for competitive
        events, improving the administration experience with products that are
        comprehensive, simple, and fun, Now you can run your competition
        effortlessly, and enjoy the event!
      </p>
    </div>
  );

  return (
    <div css={css.container(statementSection)}>
      <div css={css.container().frame}>
        {image}
        {content}
      </div>
    </div>
  );
};

export default Statement;
