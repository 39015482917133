/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { useState, useEffect, useMemo } from "react";
import { Outlet, useOutletContext, useLocation } from "react-router-dom";

import PageHeader from "components/page-header/page-header";
import PillButtonGroup from "components/pill-button-group/pill-button-group";

import { css } from "./css";
import TitleBlock from "components/title-block/title-block";
import FilterBtnGroup from "components/filter-btn-group/filter-btn-group";

export default function AllUsers() {
  const location = useLocation();

  const path = useMemo(() => location.pathname.match(/(\w+)/gi)[1], [location]);

  const [activeBtnGroup, setActiveBtnGroup] = useState(path);

  const theme = useTheme();

  // console.log('ALL USER: options:', USER_PAGES);

  // const filterBtnGroup = (
  //   <FilterBtnGroup
  //     options={USER_PAGES}
  //     activeBtnGroup={activeBtnGroup}
  //     setActiveBtnGroup={setActiveBtnGroup}
  //   />
  // );

  // const titleBlock = (
  //   <TitleBlock>
  //     {filterBtnGroup}
  //   </TitleBlock>
  // );

  const content = (
    <>
      {/* {titleBlock} */}

      <Outlet context={[activeBtnGroup, setActiveBtnGroup]}></Outlet>
    </>
  );

  return <div css={css(theme)}>{content}</div>;
}
