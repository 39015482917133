import React from "react";
import { Modal } from "react-bootstrap";
import { Form, Formik } from "formik";
import { useOutletContext } from "react-router-dom";
import { useQuery } from "hooks";

import CustomButton from "components/custom-button/custom-button";
import CustomDivTable from "components/custom-table/custom-div-table";
import CustomPdfButton from "components/custom-pdf-button/custom-pdf-button";

import css from "./show-class-results.modal.module.scss";
import ShowClassResultsPrintPDF from "../show-class-results-print-pdf/show-class-results-print-pdf";

const ShowClassResultsModal = ({ setShowModal, showClass }) => {
  const { show, setActiveBtnGroup } = useOutletContext();

  const onHide = () => setShowModal(false);

  const { data, refetch } = useQuery(`/show-classes/${showClass?.id}`);

  console.log(data);

  const memberToFormik = () => {
    return {};
  };
  const initialValues = memberToFormik();

  const columns = [
    {
      name: "num",
      valFn: (data) => data?.rider_number || "--",
      display: "Rider #",
      sortable: true,
    },
    {
      name: "name",
      valFn: (data) => data?.rider_name || "--",
      display: "Rider Name",
      sortable: true,
    },
    {
      name: "horseName",
      valFn: (data) => data?.horse_name || "--",
      display: "Horse Name",
      sortable: true,
    },
    {
      name: "award",
      valFn: (data) => data?.award_names || "--",
      display: "Award",
      sortable: true,
    },
  ];

  const btnSubmit = <CustomButton label="Close" onClick={() => onHide()} />;

  const btnPrintResults = (
    <CustomPdfButton
      label="Print Class Results"
      document={
        <ShowClassResultsPrintPDF
          title={`${showClass.name} Class Results`}
          data={data}
        />
      }
      fileName="class-results"
    />
  );

  const headerTitle = `${showClass.name} Results`;

  const footerButtons = [btnPrintResults, btnSubmit];

  const resultsClassName = {
    number: data?.class_number,
  };

  const tableTitle = (
    <div className={css.tableTitle}>{resultsClassName.number}</div>
  );

  return (
    <Modal show={true} onHide={onHide} centered>
      <Formik initialValues={initialValues}>
        {(formik) => (
          <Form>
            <Modal.Header closeButton>
              <Modal.Title>{headerTitle}</Modal.Title>
            </Modal.Header>

            <Modal.Body
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
              }}
            >
              {tableTitle}

              <CustomDivTable
                columns={columns}
                rows={data?.awards}
                emptyMessage="No Awards Found"
              />
            </Modal.Body>

            <Modal.Footer>
              {footerButtons.map((btn, index) => (
                <React.Fragment key={index}>{btn}</React.Fragment>
              ))}
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ShowClassResultsModal;
