/**@jsxImportSource @emotion/react */

import React, { useRef, useState } from "react";

import FormGroupItem from "components/form-group-item/form-group-item";
import FormSelect from "components/form-select";
import FormTextInput from "components/form-text-input";
import FormSwitch from "components/form-switch";
import FormDateInput from "components/form-date-input";
import { Form, Formik } from "formik";

import { capitalizeFirstLetter } from "utils";
import { useConfirmationModal } from "confirmation-modal/confirmation-modal";
import CustomButton from "components/custom-button/custom-button";
import Client from "client";
import CustomModal from "components/custom-modal/custom-modal";
import { FormElement } from "components/form-element/form-element";
import { flexbox, fullWidth } from "emotion/utils";
import Divider from "components/divider/divider";
import Button from "components/button/button";
import { useQuery } from "hooks";

const MemberModalHorse = ({
  onClick,
  activeHorse,
  setShowModal = () => {},
  onSuccess = () => {},
  memberId,
  horseUseData,
}) => {
  const formRef = useRef();

  const [isFormDirty, setIsFormDirty] = useState(false);
  const [modalHeight, setModalHeight] = useState(0);
  const [modalBodyHeight, setModalBodyHeight] = useState(0);

  const { data: memberData } = useQuery(`/members/${memberId}`);
  console.log("memberData", memberData);

  const {
    showModal: showConfirmationModal,
    handleExit,
    handleClose: handleConfirmationClose,
  } = useConfirmationModal(onSuccess);

  // const errorClass = formik?.errors["use_animals"] ? "error" : "";

  const assignHorse = (horse, formik) => {
    let horses = [];
    horses = [...formik.values["use_animals"]];
    const idx = horses.indexOf((h) => h.id === horse.id);
    if (idx) horses[idx] = horse;
    else horses.push(horse);
    formik.setFieldValue("use_animals", horses, { shouldDirty: true });
  };

  const horseToFormik = () => {
    if (activeHorse) {
      const {
        animal_documents,
        barn_name,
        birthday,
        breed,
        coat_colors,
        comments,
        gender,
        green_horse,
        height,
        id,
        markings,
        name,
        registration_number,
        weight,
      } = activeHorse.animal;
      return {
        animal_documents,
        barn_name,
        birthday,
        breed,
        coat_colors,
        comments,
        gender,
        green_horse,
        height,
        id,
        markings,
        name: name ? `${capitalizeFirstLetter(name)}` : "",
        registration_number,
        weight,
      };
    } else {
      return {};
    }
  };

  const initialValues = horseToFormik();

  const genderOptions = [
    { id: 1, value: "Mare", display: "Mare" },
    { id: 2, value: "Gelding", display: "Gelding" },
  ];

  const arrayCerts = ["Health Certficiation", "Coggins", "Horse Certification"];
  const getCertList = arrayCerts.map((cert, index) => (
    <div key={index}>{cert}</div>
  ));

  const conditionalClose = () => {
    if (isFormDirty) {
      // setCustomMessage();
      // setIsDefaultMessage(false);
      handleExit();
    } else {
      // setCustomMessage(null);
      // setIsDefaultMessage(true);
      onSuccess();
      setShowModal(false);
    }
  };

  const calculateModalHeight = () => {
    setTimeout(() => {
      const modalDialog = document.querySelector(".modal-content");
      const modalBody = document.querySelector(".modal-body");
      if (modalDialog) {
        setModalHeight(modalDialog.offsetHeight);
      }
      if (modalBody) {
        setModalBodyHeight(modalBody.offsetHeight);
      }
    }, 500); // delay
  };

  const headerTitle = activeHorse
    ? `Edit ${activeHorse?.name}`
    : memberData
    ? `Add horse for ${memberData?.member?.user?.first_name} ${memberData?.member?.user?.last_name}`
    : "Add horse";

  const btnRestoreRemove = (formik) => (
    <CustomButton
      label={horseUseData?.end_at ? "Restore" : "Remove"}
      color="tertiary"
      onClick={async () => {
        const statement = `Are you sure you want to ${
          horseUseData?.end_at ? "restore" : "remove"
        } this horse?`;
        const sure = window.confirm(statement);
        if (sure) {
          let serverReturn = null;
          const data = {
            horseId: activeHorse?.id,
            inUse: horseUseData?.end_at ? true : false,
          };
          serverReturn = await Client.put(
            `/members/${memberId}/horse-use/`,
            data,
          );
          if (serverReturn?.success === false) {
            const field = serverReturn?.field;
            formRef?.current?.setErrors({
              [field]: serverReturn?.reason,
            });
          } else {
            setShowModal(false);
            onSuccess();
          }
        }
      }}
      // align="left"
    />
  );

  const btnSubmitCreate = (formik) => (
    <Button
      label={activeHorse ? "Save changes" : "Save"}
      disabled={!formik.isValid || !Object.values(formik.touched).length}
      onClick={(e) => {
        formik.handleSubmit(e);
        calculateModalHeight();
      }}
      align="right"
    />
  );

  const btnCancel = (
    <Button
      label="Cancel"
      onClick={conditionalClose}
      color="secondary"
      variant="outlined"
    />
  );

  // const btns = (formik) =>
  //   activeHorse
  //     ? [btnRestoreRemove(formik), btnSubmitCreate(formik)]
  //     : [btnSubmitCreate(formik)];
  // const btns = (formik) => [btnCancel, btnSubmitCreate(formik)];

  const footerButtons = (formik) =>
    [btnCancel, btnSubmitCreate(formik)].map((btn, index) => (
      <React.Fragment key={index}>{btn}</React.Fragment>
    ));

  return (
    <Formik
      initialValues={initialValues}
      innerRef={formRef}
      onSubmit={async (data) => {
        let serverReturn = null;
        serverReturn = await Client.put(`/members/${memberId}/horse/`, data);
        if (serverReturn?.success === false) {
          const field = serverReturn?.field;
          formRef?.current?.setErrors({
            [field]: serverReturn?.reason,
          });
        } else {
          setShowModal(false);
          onSuccess();
        }
      }}
    >
      {(formik) => (
        <Form
          onChange={(evt) => {
            const clone = structuredClone(formik.values);
            clone[evt.target.name] = evt.target.value;
            // setIsFormDirty(
            //   JSON.stringify(clone) !== JSON.stringify(memberToFormik()),
            // );
          }}
        >
          <CustomModal
            headerTitle={headerTitle}
            footerButtons={footerButtons(formik)}
            onHide={conditionalClose}
            size="md"
          >
            {/* <FormBlock> */}
            {/* <FormTextInput
              className={formik?.errors ? "error" : ""}
              type="text"
              label={`Name*`}
              name={`name`}
              formik={formik}
              onBlur={formik?.handleBlur}
              onChange={(evt) => {
                const new_horse = Object.assign({}, activeHorse);
                new_horse.name = evt.target.value;
                assignHorse(new_horse);
              }}
              value={activeHorse?.name || ""}
            />
            <FormTextInput name="name" label="Name*" formik={formik} value={activeHorse.name} required/>
            {formik.touched.name && formik.errors.name && (
              <span className="form-error-msg">{formik.errors.name}</span>
            )} */}
            <FormElement
              element="InputText"
              id="name"
              name="name"
              label="Name"
              placeholder="Name this horse"
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              // value={formik.values.name}
              // onChange={(evt) => {
              //   const new_horse = Object.assign({}, activeHorse);
              //   new_horse.name = evt.target.value;
              //   assignHorse(new_horse, formik);
              // }}
              // value={activeHorse?.name || ""}
              value={formik.values["name"]}
              required
              size="48"
            />

            {/* <FormTextInput name="barn_name" label="Barn Name" formik={formik} />
            {formik.touched.barnName && formik.errors.barnName && (
              <span className="form-error-msg">{formik.errors.barnName}</span>
            )} */}
            <FormElement
              element="InputText"
              id="barn_name"
              name="barn_name"
              label="Barn name"
              placeholder="Where does this horse reside?"
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.barn_name}
              size="48"
            />

            {/* <FormTextInput name="breed" label="Breed" formik={formik} />
            {formik.touched.breed && formik.errors.breed && (
              <span className="form-error-msg">{formik.errors.breed}</span>
            )} */}
            <FormElement
              element="InputText"
              id="breed"
              name="breed"
              label="Breed"
              placeholder="What breed of horse?"
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.breed}
              size="31"
            />

            <div style={{ ...fullWidth(), maxWidth: "31%" }}>
              <FormSelect
                name="gender"
                label="Gender"
                formik={formik}
                options={genderOptions}
                labelPosition="top"
              />
              {formik.touched.gender && formik.errors.gender && (
                <span className="form-error-msg">{formik.errors.gender}</span>
              )}
            </div>

            <div style={{ ...fullWidth(), maxWidth: "31%" }}>
              <FormDateInput name="birthday" label="Birthday" formik={formik} />
              {formik.touched.age && formik.errors.age && (
                <span className="form-error-msg">{formik.errors.age}</span>
              )}
            </div>

            {/* <FormTextInput
              name="registration_number"
              label="Registration #"
              formik={formik}
            />
            {formik.touched.regNum && formik.errors.regNum && (
              <span className="form-error-msg">{formik.errors.regNum}</span>
            )} */}
            <FormElement
              element="InputText"
              id="registration_number"
              name="registration_number"
              label="Registration number"
              placeholder="[reg # format example]"
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.registration_number}
              size="60"
            />

            {/* <FormSwitch
              name="green_horse"
              label="Green Horse"
              formik={formik}
              size="37"
              style={{ marginTop: 22 }}
            />
            {formik.touched.greenHorse && formik.errors.greenHorse && (
              <span className="form-error-msg">{formik.errors.greenHorse}</span>
            )} */}
            <div
              style={{
                ...flexbox("column", "flex-start", "flex-end", 16),
                ...fullWidth(),
                maxWidth: "37%",
              }}
            >
              <FormElement
                element="Checkbox"
                id="green_horse"
                name="green_horse"
                label="Green horse"
                formik={formik}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.green_horse}
              />
              <FormElement
                element="Checkbox"
                id="visible_to_rider"
                name="visible_to_rider"
                label="Visible to rider"
                formik={formik}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.green_horse}
              />
            </div>

            <Divider />

            {/* <FormTextInput
              name="height"
              label="Height (hands)"
              formik={formik}
            />
            {formik.touched.height && formik.errors.height && (
              <span className="form-error-msg">{formik.errors.height}</span>
            )} */}
            <FormElement
              element="InputText"
              id="height"
              name="height"
              label="Height (hands)"
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.height}
              size="48"
            />

            {/* <FormTextInput name="weight" label="Weight" formik={formik} />
            {formik.touched.weight && formik.errors.weight && (
              <span className="form-error-msg">{formik.errors.weight}</span>
            )} */}
            <FormElement
              element="InputText"
              id="weight"
              name="weight"
              label="Weight (lbs)"
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.weight}
              size="48"
            />

            {/* <FormTextInput
              name="coat_colors"
              label="Coat Colors"
              formik={formik}
            />
            {formik.touched.coatColors && formik.errors.coatColors && (
              <span className="form-error-msg">{formik.errors.coatColors}</span>
            )} */}
            <FormElement
              element="InputText"
              id="coat_colors"
              name="coat_colors"
              label="Coat colors"
              placeholder="What colors are in the coat?"
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.coat_colors}
              size="48"
            />

            {/* <FormTextInput name="markings" label="Markings" formik={formik} />
            {formik.touched.markings && formik.errors.markings && (
              <span className="form-error-msg">{formik.errors.markings}</span>
            )} */}
            <FormElement
              element="InputText"
              id="markings"
              name="markings"
              label="Markings"
              placeholder="Any unique markings?"
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.markings}
              size="48"
            />

            {/* <FormTextInput
              name="comments"
              textarea="true"
              label="Comments"
              formik={formik}
            />
            {formik.touched.comments && formik.errors.comments && (
              <span className="form-error-msg">{formik.errors.comments}</span>
            )} */}
            <FormElement
              element="InputTextarea"
              id="comments"
              name="comments"
              label="Notes (optional)"
              placeholder="Any additional comments?"
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.comments}
            />

            <FormElement
              element="FileUpload"
              id="animal_documents"
              name="animal_documents"
              // label="Documents"
              formik={formik}
              // onBlur={formik.handleBlur}
              // onChange={formik.handleChange}
              // value={formik.values.name}
              // required
            />

            {/* <FormGroupItem>
              <label style={{ color: "#000", fontWeight: "700" }}>
                Certifications
              </label>
              <div
                style={{
                  alignItems: "flex-end",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {getCertList}
              </div>
            </FormGroupItem> */}
            {/* </FormBlock> */}
          </CustomModal>
        </Form>
      )}
    </Formik>
  );
};

export default MemberModalHorse;
