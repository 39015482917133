import React from "react";

import css from "./show.module.scss";

export default function ShowStat({ label, entry, style }) {
  return (
    <div className={css.showStat} style={{ ...style }}>
      <div className={css.label}>{label}</div>
      <div className={css.entry}>{entry}</div>
    </div>
  );
}
