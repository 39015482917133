/** @jsxImportSource @emotion/react */

import { Outlet, useLocation, Navigate, useParams } from "react-router-dom";

import Header from "components/header/header";

import {
  appColors,
  flexbox,
  fullHeight,
  fullWidth,
  paddingY,
  posRelative,
} from "emotion/utils";

import { useQuery } from "hooks";
import { fill } from "lodash";

export const SETUP_PAGES = [
  { title: "Locations", linkTo: "/admin/setup/locations" },
  { title: "Class Stems", linkTo: "/admin/setup/classes" },
  { title: "Awards", linkTo: "/admin/setup/awards" },
  { title: "Patterns", linkTo: "/admin/setup/patterns" },
  { title: "High Point", linkTo: "/admin/setup/high-point" },
];

export const USER_PAGES = [
  { title: "Members", linkTo: "/admin/users/members" },
  { title: "Admins", linkTo: "/admin/users/admins" },
  { title: "Announcers", linkTo: "/admin/users/announcers" },
  { title: "Judges", linkTo: "/admin/users/judges" },
  { title: "Paddock Managers", linkTo: "/admin/users/paddock-managers" },
  { title: "Clubs", linkTo: "/admin/users/clubs" },
];

export const TOP_LEVEL_PAGES = [
  {
    title: "Shows",
    linkTo: "/admin/shows",
    icon: "Horse",
    fill: appColors?.blues?.placeholder,
    stroke: null,
  },
  {
    title: "Setup",
    linkTo: "/admin/setup",
    icon: "Settings",
    children: SETUP_PAGES,
    fill: null,
    stroke: appColors?.blues?.placeholder,
  },
  {
    title: "Users",
    linkTo: "/admin/users",
    icon: "Users",
    children: USER_PAGES,
    fill: null,
    stroke: appColors?.blues?.placeholder,
  },
];

const AdminLayout = () => {
  const loc = useLocation();

  const { showId, classId } = useParams();

  const {
    data: show,
    // refetch,
    isLoading,
    error,
  } = useQuery(`/shows/byId/${showId || classId}`, {
    enabled: !!(showId || classId),
  });

  if ((showId || classId) && isLoading) {
    return false;
  }

  if (error) {
    return false;
  }

  if (loc.pathname === "/admin") {
    return <Navigate to="/admin/shows" replace />;
  }
  if (loc.pathname === `/admin/shows/${showId}`) {
    return <Navigate to={`/admin/shows/${showId}/overview`} replace />;
  }

  const nestedMenuTabs =
    showId || classId
      ? [
          { title: "Overview", linkTo: `/admin/shows/${show.id}/overview` },
          {
            title: "Classes",
            linkTo: `/admin/shows/${show.id}/classes`,
            highlight: [`/admin/show-classes/${classId}`],
          },
          {
            title: "Riders",
            linkTo: `/admin/shows/${show.id}/registrants`,
          },
          // { title: "Reports", linkTo: "reports" },
        ]
      : null;

  return (
    <div css={css.site}>
      <Header
        menuTabs={TOP_LEVEL_PAGES}
        nestedMenuTabs={nestedMenuTabs}
        nestedMenuTitle={show?.name}
      />

      <main css={css.site.main}>
        <Outlet />
      </main>
    </div>
  );
};

const css = {
  site: {
    ...flexbox("row", "stretch", "flex-start", 0),
    ...fullHeight(),
    ...posRelative(),

    main: {
      ...fullHeight(),
      ...fullWidth(),
      ...paddingY(40),
      flex: 1,
      paddingRight: 40,
      paddingLeft: 352,
    },
  },
};

export default AdminLayout;
