import React from "react";

import InputText from "./elements/inputtext";
import IconField from "./elements/iconfield";
import Password from "./elements/password";
import InputTextarea from "./elements/inputtextarea";
import FileUpload from "./elements/fileupload";
import RadioButton from "./elements/radiobutton";
import CurrencyInput from "./elements/currency-input";
import Calendar from "./elements/calendar";
import Dropdown from "./elements/dropdown";
import InputGroup from "./elements/inputgroup";
import ColorPicker from "./elements/color-picker";
import InputButtonGroup from "./elements/input-button-group";
import Checkbox from "./elements/checkbox";

export const FormElement = ({
  element = "InputText",
  id,
  name,
  label,
  onBlur,
  onChange,
  value,
  helpertext = null,
  placeholder = null,
  required = false,
  formik = null,
  errorcomponent = null,
  displaytype,
  readOnly = false,
  disabled = false,
  max = null,
  min = null,
  date,
  size,
  checked,
  options,
  icon,
  btn,
  editable = null,
}) => {
  const components = {
    Checkbox,
    ColorPicker,
    Dropdown,
    FileUpload,
    InputButtonGroup,
    InputGroup,
    InputText,
    InputTextarea,
    IconField,
    Password,
    RadioButton,
    Calendar,
    CurrencyInput,
  };

  const formElprops = {
    element,
    id,
    name,
    onBlur,
    onChange,
    value,
    label,
    helpertext,
    placeholder,
    required,
    formik,
    errorcomponent,
    displaytype,
    readOnly,
    disabled,
    max,
    min,
    date,
    size,
    checked,
    options,
    icon,
    btn,
    editable,
  };
  // console.log("formElprops", formElprops);

  const getFormElement = (type) => {
    // console.log('type', type);

    const Component = components[type] || InputText;

    return <Component {...formElprops} />;
  };

  return <>{getFormElement(element)}</>;
};
