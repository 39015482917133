/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { useNavigate } from "react-router-dom";

import Icon from "components/icon/icon";

import { css } from "./css";
import Card from "components/card/card";

const ShowsShowCard = ({ data, onClick }) => {
  const navigate = useNavigate();

  const theme = useTheme();

  const handleToShow = () => {
    navigate(`/admin/shows/${data?.id}`);
  };

  const formatDateRange = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const options = { month: "long", day: "numeric", year: "numeric" };

    if (
      startDate.getMonth() === endDate.getMonth() &&
      startDate.getFullYear() === endDate.getFullYear()
    ) {
      return `${startDate.toLocaleDateString("en-US", {
        month: "long",
      })} ${startDate.getDate()}-${endDate.getDate()}, ${startDate.getFullYear()}`;
    } else {
      return `${startDate.toLocaleDateString(
        "en-US",
        options,
      )} - ${endDate.toLocaleDateString("en-US", options)}`;
    }
  };

  const formattedShowType =
    data?.show_type.charAt(0).toUpperCase() +
    data?.show_type.slice(1).toLowerCase();

  const intro = (
    <div css={css(theme).showCard.intro} onClick={handleToShow}>
      <div css={css().showCard.intro.title}>{data?.name}</div>
      <div css={css(theme).showCard.infoActions.info}>
        <span>{formatDateRange(data?.start_at, data?.end_at)}</span>
        <span>{formattedShowType}</span>
      </div>
    </div>
  );

  const btnEdit = (
    <div onClick={onClick} css={css(theme).showCard.infoActions.actions.btn}>
      <Icon icon="Edit" stroke={theme?.button?.primary?.outlined?.svgColor} />
    </div>
  );

  const btnGo = (
    <div
      onClick={handleToShow}
      css={css(theme).showCard.infoActions.actions.btn}
    >
      <Icon
        icon="ChevronRight"
        stroke={theme?.button?.primary?.outlined?.svgColor}
      />
    </div>
  );

  const infoActions = (
    <div css={css(theme).showCard.infoActions}>
      <div css={css(theme).showCard.infoActions.actions}>
        {btnEdit}
        {btnGo}
      </div>
    </div>
  );

  return (
    <Card css={css(theme).showCard}>
      {intro}
      {infoActions}
    </Card>
  );
};

export default ShowsShowCard;
