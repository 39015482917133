/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { PDFDownloadLink } from "@react-pdf/renderer";

import { css } from "../button/css";

const CustomPdfButton = ({
  document,
  label = "Export to PDF",
  loadingLabel = "Loading document...",
  fileName = "file",
}) => {
  const theme = useTheme();

  const cssContainer = css(theme, "primary", "outlined", false);

  return (
    <PDFDownloadLink
      document={document}
      fileName={`${fileName}.pdf`}
      // className={css.pdfButton}
      css={cssContainer}
    >
      {({ loading }) => (loading ? loadingLabel : label)}
    </PDFDownloadLink>
  );
};

export default CustomPdfButton;
