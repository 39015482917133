import {
  appColors,
  appFonts,
  borderBottomLeftRadius,
  borderBottomRightRadius,
  borderRadius,
  borderTopLeftRadius,
  borderTopRightRadius,
  // darken,
  flexbox,
  fontbox,
  fullHeight,
  paddingX,
  paddingY,
  posAbsolute,
  posRelative,
} from "emotion/utils";

export const css = (theme) => ({
  ...borderRadius(8),
  ...flexbox("row", "center", "flex-start", 0),
  background: appColors.white,
  border: `1px solid ${theme.border.primary}`,
  boxShadow: `0px 1px 2px 0px rgba(16, 24, 40, 0.05)`,
  overflow: "hidden",

  button: {
    ...borderRadius(0),
    ...paddingX(16),
    ...paddingY(8),
    ...posRelative(),
    backgroundColor: "transparent!important",
    border: 0,
    borderRight: `1px solid ${theme.border.primary}`,
    height: 40,
    marginLeft: -1,

    "&::before": {
      ...fullHeight(),
      ...posAbsolute(0, 0, null, 0, 1),
      content: '""',
      backgroundColor: theme?.border?.primary,
      width: 1,
    },

    "&.active": {
      backgroundColor: `${theme?.background?.bgActive}!important`,
      // borderLeft: `1px solid ${theme.border.primary}`,
    },

    "&:first-of-type": {
      ...borderTopLeftRadius(8),
      ...borderBottomLeftRadius(8),
    },

    "&:last-of-type": {
      ...borderTopRightRadius(8),
      ...borderBottomRightRadius(8),
      borderRight: 0,
    },

    "> span": {
      ...fontbox(appFonts.secondary, 14, 600, 20),
      color: theme.text.secondary,
    },

    // "&:hover": {
    //   "> span": {
    //     color: darken(theme.text.secondary, 0.1),
    //   },
    // },
  },
});
