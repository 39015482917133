import React from "react";
import Image from "react-bootstrap/Image";

import CustomButton from "components/custom-button/custom-button";

import bgLogo from "images/dim.svg";

import css from "./base-award.module.scss";

export default function BaseAwardCard(props) {
  const { data, onEdit, onArchive, activeBtnGroup } = props;

  const image = (
    <Image
      src={data.image ? data.image.link : bgLogo}
      className={`${css.img} ${!data.image ? css.plcImg : null}`}
    />
  );

  const getButton =
    activeBtnGroup === "active" ? (
      <CustomButton
        label="Archive"
        color="tertiary"
        type="outlined"
        onClick={() => onArchive(data, true)}
      />
    ) : (
      <CustomButton
        label="Reactivate"
        type="outlined"
        onClick={() => onArchive(data, false)}
      />
    );

  return (
    <div key={data.id} className={css.award}>
      {image}

      <h3>{data.name}</h3>
      <p>{data.points} pts</p>

      <div className={css.buttonBlock}>
        {getButton}

        <CustomButton label="Edit" onClick={() => onEdit(data)} />
      </div>
    </div>
  );
}
