import React, { useState } from "react";
import { Form, Formik } from "formik";
import { useQuery } from "hooks/use-query";
import { ProgressSpinner } from "primereact/progressspinner";

import CustomPdfButton from "components/custom-pdf-button/custom-pdf-button";
import CustomModal from "components/custom-modal/custom-modal";
import Button from "components/button/button";

import ShowRegistrantsPanelDetails from "./show-registrants-panel-details";
import ShowRegistrantClassSchedulePrintPDF from "./show-registrant-class-schedule-print-pdf/show-registrant-class-schedule-print-pdf";

const ShowRegistrantsModal = (props) => {
  const { setShowModal, activeMember, handleManageClasses } = props;

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const { data, isLoading, error } = useQuery(
    `/registrants/show-registrant-details/${activeMember.show_registrant_id}`,
  );
  // const data = {};
  // const isLoading = false;
  // const error = false;

  const onHide = () => setShowModal(false);

  const handleSubmitDetails = () => {
    console.log("handle details submit functionality");
    alert("handle details submit functionality");
  };
  const handleSubmitHorses = () => {
    console.log("handle horses submit functionality");
    alert("handle horses submit functionality");
  };

  const handleSubmit = (activeTabIndex) => {
    activeTabIndex === 0 ? handleSubmitDetails() : handleSubmitHorses();
  };

  const btnClose = (
    <Button key="closeBtn" label="Close" onClick={() => setShowModal(false)} />
  );

  const btnPrintSchedule = (
    // <CustomButton
    //   label="Print Class Schedule"
    //   onClick={() => console.log('print class schedule')}
    //   align="left"
    // />
    <CustomPdfButton
      key="print-schedule"
      label="Print Class Schedule"
      fileName="rider-schedule"
      document={
        <ShowRegistrantClassSchedulePrintPDF
          title="Print Class Schedule"
          key="show_print-schedule"
          show={data?.show}
          rider={data}
          classes={data?.show_classes?.filter(
            (show_classobj) =>
              show_classobj.show_class.show_id === data.show.id,
          )}
        />
      }
    />
  );

  const headerTitle = `Member: ${activeMember?.name} | Rider # ${activeMember?.rider_number}`;

  // const footerButtons =
  // activeTabIndex === 0 ? [btnPrintSchedule, btnClose] : [btnClose];

  // const btns = (activeTabIndex) =>
  //   activeTabIndex !== 0
  //     ? [btnClose]
  //     : [btnPrintSchedule, btnClose];

  const footerButtons =
    activeTabIndex !== 0 ? [btnClose] : [btnPrintSchedule, btnClose];

  const memberToFormik = () => {
    return {};
  };
  const initialValues = memberToFormik();

  return (
    <Formik initialValues={initialValues}>
      {(formik) => (
        <Form>
          <CustomModal
            headerTitle={headerTitle}
            footerButtons={footerButtons}
            onHide={onHide}
            size="xl"
          >
            {isLoading || !data ? (
              <div style={{ alignSelf: "center", textAlign: "center" }}>
                <ProgressSpinner style={{ height: 40, width: 40 }} />
              </div>
            ) : (
              <ShowRegistrantsPanelDetails
                dataIn={data}
                formik={formik}
                handleManageClasses={handleManageClasses}
              />
            )}
          </CustomModal>
        </Form>
      )}
    </Formik>
  );
};

export default ShowRegistrantsModal;
