import React from "react";

import { Button } from "primereact/button";

import css from "./custom-button.module.scss";

const CustomButton = ({
  children,
  label,
  customType = "button",
  type = "contained",
  color = "secondary",
  loading = false,
  onClick = () => {},
  id,
  fullWidth = false,
  fs = 12,
  lh = fs,
  align = "right",
  sty,
  disabled = false,
  isInTable = false,
}) => {
  const getButtonColor = (color) => {
    const colors = {
      primary:
        type === "outlined"
          ? css.primaryOutlined
          : type === "link"
          ? css.primaryLink
          : css.primary,

      secondary:
        type === "outlined"
          ? css.secondaryOutlined
          : type === "link"
          ? css.secondaryLink
          : css.secondary,

      tertiary:
        type === "outlined"
          ? css.tertiaryOutlined
          : type === "link"
          ? css.tertiaryLink
          : css.tertiary,

      quarternary:
        type === "outlined"
          ? css.quarternaryOutlined
          : type === "link"
          ? css.quarternaryLink
          : css.quarternary,

      white:
        type === "outlined"
          ? css.whiteOutlined
          : type === "link"
          ? css.whiteLink
          : css.white,
    };

    return colors[color];
  };

  const fontSize = fs || 12;
  const lineHeight = lh;

  const getButtonAlignment = (align) => {
    if (align === "unset") {
      return false;
    }

    const alignments = {
      left: { marginRight: "auto" },
      center: { marginRight: "auto", marginLeft: "auto" },
      right: { marginLeft: "auto" },
    };

    return alignments[align];
  };

  const classes = `
    ${css.button}
    ${isInTable ? css.inTable : null}
    ${getButtonColor(color)}
  `;

  const styles = {
    fontSize,
    lineHeight: `${lineHeight}px`,
    width: fullWidth ? "100%" : null,
    ...getButtonAlignment(align),
    ...sty,
  };

  return (
    <Button
      className={classes}
      loading={loading}
      onClick={onClick}
      id={id}
      type={customType}
      style={styles}
      disabled={disabled}
    >
      {children}
      {label}
    </Button>
  );
};

export default CustomButton;
