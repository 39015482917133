import {
  borderRadius,
  // darken,
  flexbox,
  fontbox,
  fullWidth,
  paddingX,
  paddingY,
  posRelative,
  theme,
  transition,
} from "emotion/utils";

export const css = {
  container: (image) => ({
    ...flexbox("column", "center", "center", 45),
    ...paddingY(48),
    ...paddingX(16),
    backgroundColor: theme.colors.primary,
    backgroundImage: `url(${image})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top",
    textAlign: "left",

    "@media screen and (min-width: 576px)": {
      ...paddingY(75),
      ...paddingX(38),
      gap: 70,
    },
    "@media screen and (min-width: 768px)": {
      ...paddingY(145),
      gap: 70,
    },
    "@media screen and (min-width: 992px)": {
      gap: 100,
    },

    "> *": {
      maxWidth: 890,
    },

    "h2, p": {
      color: theme.colors.white,
    },

    form: {
      ...flexbox("column", "flex-start", "flex-start", 16),
      ...fullWidth(),

      "@media screen and (min-width: 576px)": {
        ...flexbox("row", "center", "space-between", 20),
      },
      "@media screen and (min-width: 768px)": {
        ...flexbox("row", "center", "space-between", 45),
      },

      input: {
        ...borderRadius(5),
        ...fontbox(theme.fonts.inter, 16, 400, 16),
        ...fullWidth(),
        ...paddingY(0),
        ...paddingX(25),
        ...posRelative(),
        backgroundColor: theme.colors.white,
        border: "none",
        height: 50,
        outline: "none",

        "@media screen and (min-width: 576px)": {
          ...fontbox(theme.fonts.inter, 20, 400, 20),
          width: 638,
        },
      },

      button: {
        ...borderRadius(5),
        ...fontbox(theme.fonts.inter, 16, 700, 50),
        ...fullWidth(),
        ...transition(),
        border: "none",
        color: theme.colors.white,
        cursor: "pointer",
        backgroundColor: theme.colors.secondary,

        "@media screen and (min-width: 576px)": {
          ...fontbox(theme.fonts.inter, 20, 700, 50),
          width: 200,
        },

        // "&:hover": {
        //   backgroundColor: darken(theme.colors.secondary, -0.1),
        // },
      },
    },
  }),
};
