import React from "react";
import { StyleSheet, Text, Page, View } from "@react-pdf/renderer";

import PrintPDF from "components/print-pdf/print-pdf";

const ShowClassesPrintPDF = ({ title, data }) => {
  // get unique days
  let uniqueDays = [...new Set(data?.data.map((item) => item.start_at))];

  const w1 = "25%";
  const w3 = "50%";

  const getDayArenaData = (day, idx) => {
    let itemsWithDay = data?.data.filter((item) => item.start_at === day);
    let uniqueArenas = [
      ...new Set(itemsWithDay.map((item) => item.arena_name)),
    ];

    return (
      <React.Fragment key={idx}>
        {uniqueArenas.map((arena, index) => {
          let itemsWithArena = itemsWithDay.filter(
            (item) => item.arena_name === arena,
          );

          return (
            <View key={index} style={css.arenaBlock.arena}>
              <Text style={css.arenaBlock.arenaName}>{arena}</Text>

              <View style={css.table}>
                <View style={css.table.row}>
                  <Text
                    style={[css.table.cell, { textAlign: "right", width: w1 }]}
                  >
                    Class Number
                  </Text>
                  <Text style={[css.table.cell, { width: w1 }]}>
                    Abbreviation
                  </Text>
                  <Text style={[css.table.cell, { width: w3 }]}>Name</Text>
                </View>

                <React.Fragment key={index}>
                  {itemsWithArena.map((item, index) => {
                    const classNumber = item?.class_number || "---";
                    const classAbbreviation = item?.name || "---";
                    const longName = item?.long_name || "---";
                    const bgColor = index % 2 === 0 ? "#ffffff" : "#f6f6f6";

                    return (
                      <View
                        key={index}
                        style={[
                          css.table.dataRow,
                          { backgroundColor: bgColor },
                        ]}
                      >
                        <Text
                          style={[
                            css.table.dataCell,
                            {
                              textAlign: "right",
                              width: w1,
                            },
                          ]}
                        >
                          {classNumber}
                        </Text>
                        <Text style={[css.table.dataCell, { width: w1 }]}>
                          {classAbbreviation}
                        </Text>
                        <Text
                          style={[
                            css.table.dataCell,
                            {
                              whiteSpace: "pre-line",
                              width: w3,
                            },
                          ]}
                        >
                          {longName}
                        </Text>
                      </View>
                    );
                  })}
                </React.Fragment>
              </View>
            </View>
          );
        })}
      </React.Fragment>
    );
  };

  return (
    <PrintPDF
      headerText={title}
      splitPageBy={uniqueDays}
      showQR
      showSubHeader
      getChildren={getDayArenaData}
    ></PrintPDF>
  );
};

export default ShowClassesPrintPDF;

const css = StyleSheet.create({
  arenaBlock: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 20,

    arena: {
      borderColor: "#000000",
      borderStyle: "solid",
      borderWidth: 1,
      alignItems: "center",
      width: "48%",
    },
    arenaName: {
      color: "#1D1D1D",
      fontSize: 14,
      fontWeight: "700",
      paddingVertical: 10,
    },
  },

  table: {
    width: "100%",

    row: {
      alignItems: "center",
      borderBottomColor: "#000000",
      borderBottomStyle: "solid",
      borderBottomWidth: 1,
      flexDirection: "row",
      gap: 10,
      justifyContent: "flex-start",
    },
    cell: {
      color: "#000000",
      fontSize: 6,
      fontWeight: "700",
      paddingHorizontal: 10,
      paddingVertical: 2,
    },

    dataRow: {
      alignItems: "center",
      flexDirection: "row",
      gap: 10,
      justifyContent: "flex-start",
    },
    dataCell: {
      color: "#000000",
      fontSize: 5,
      fontWeight: "700",
      paddingHorizontal: 10,
      paddingVertical: 4,
    },
  },
});
