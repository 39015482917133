import React, { useRef } from "react";
import { useMutation } from "@tanstack/react-query";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Toast } from "primereact/toast";

import Client from "client";

export default function ShowCancelModal({
  setShowModal = () => {},
  club,
  onAccept = () => {},
}) {
  const toastRef = useRef();
  const { mutateAsync: submitRequest, isLoading } = useMutation({
    mutationKey: "patch-club",
    networkMode: "always",
    mutationFn: async () =>
      await Client.patch(`/clubs/${club.data.id}`, {
        archived: club.archive,
      }),
    onSuccess: () => {
      setShowModal(false);
      onAccept();
    },
    onError: (error) =>
      toastRef.current.show({
        severity: "error",
        summary: "Error",
        detail: error.toString(),
        life: 3000,
      }),
  });

  return (
    <>
      <Toast ref={toastRef} position="top-center" />
      <Modal show={true} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {club.archive ? "Archive" : "Reactivate"} Club
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          Are you sure you want to {club.archive ? "archive" : "Reactivate"}{" "}
          <strong>{club.data.name}</strong>?
        </Modal.Body>

        <Modal.Footer>
          <Button variant="outline-danger" onClick={() => setShowModal(false)}>
            Close
          </Button>

          <Button onClick={submitRequest} variant="danger" disabled={isLoading}>
            {club.archive ? "Archive" : "Reactivate"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
