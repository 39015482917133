/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { css } from "./css";

const LocationTitle = ({ loc, actions }) => {
  const theme = useTheme();

  const getTitle = (loc) => (
    <div css={css(theme).titleBlock}>
      <h1>{loc?.name}</h1>
    </div>
  );

  const content = <div css={css().content}>{getTitle(loc)}</div>;

  return (
    <div css={css(theme)}>
      {content}

      <div css={css(theme).actions}>{actions}</div>
    </div>
  );
};

export default LocationTitle;
