/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import { cssFormElements } from "components/form-element/css";

import { React } from "react";

export default function FormListSelect(props) {
  const {
    value,
    options,
    selected,
    name,
    errorClass,
    placeholder,
    onChange,
    onBlur,
  } = props;

  const theme = useTheme();

  const assignOptionsMap = selected.reduce((map, id) => {
    map[id] = true;
    return map;
  }, {});

  const optionsEls = options
    .filter((v) => !assignOptionsMap[v.id] || v.id === value)
    .map((val) => {
      return (
        <option key={val?.id} value={val?.id}>
          {val?.user?.first_name} {val?.user?.last_name}
        </option>
      );
    });

  return (
    <select
      type="text"
      className={errorClass}
      id={name}
      name={name}
      onChange={onChange}
      value={value || ""}
      onBlur={onBlur}
      css={cssFormElements(theme).formList.select}
    >
      {value ? null : (
        <option value="">{placeholder || "Select option..."}</option>
      )}
      {optionsEls}
    </select>
  );
}
