/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { useState, useEffect } from "react";
import { useQuery } from "hooks";
import { Form, Formik } from "formik";

import Client from "client";

import FormSelect from "components/form-select";
import CustomModal from "components/custom-modal/custom-modal";
import CustomDivTable from "components/custom-table/custom-div-table";
import Button from "components/button/button";
import CustomDivTableActions from "components/custom-table/custom-div-table-actions";
import Icon from "components/icon/icon";
import ButtonIcon from "components/button-icon/button-icon";

import { appColors } from "emotion/utils";

import { css } from "./css";

const ShowClassRidersAddRegistrantModal = (props) => {
  const {
    setShowModal = () => {},
    showId,
    onSuccess,
    activeRider,
    registeredRiders,
  } = props;

  const theme = useTheme();

  const [modalHeight, setModalHeight] = useState(0);
  const [classSelectionOptions, setClassSelectionOptions] = useState([]);
  const [activeClasses, setActiveClasses] = useState([]);
  const [activeHorse, setActiveHorse] = useState(null);
  const [activeClass, setActiveClass] = useState(null);
  const [classes, setClasses] = useState(null);
  const [saveError, setSaveError] = useState(null);

  console.log("activeClasses", activeClasses);

  const handleExit = () => {
    onSuccess();
    setShowModal(activeRider);
  };

  const calculateModalHeight = () => {
    setTimeout(() => {
      const modalDialog = document.querySelector(".modal-content");
      if (modalDialog) {
        console.log("This Modal Height = " + modalDialog.offsetHeight + "px");
        setModalHeight(modalDialog.offsetHeight);
      }
    }, 10); // delay
  };

  const handleAdd = async () => {
    //We need member_id, show_class_id, horse_id
    const postResult = await Client.post(
      `/show-classes/${activeClass?.value}`,
      {
        member_id: activeRider?.member_id,
        animal_id: activeHorse?.value,
        admin_override: true,
      },
    );
    onSuccess();
    setActiveClass(null);
    refetch();
  };

  const handleRemove = async (show_class) => {
    const postResult = await Client.post(
      `/show-classes/${show_class.show_class.id}/delete`,
      {
        member_id: activeRider?.member_id,
        animal_id: activeHorse?.value,
        admin_override: true,
      },
    );
    onSuccess();
    refetch();
  };

  const { data: animalData, refetch: refetchAnimals } = useQuery(
    `/members/${activeRider?.member_id}/horses`,
  );
  const {
    data: showData,
    isLoading,
    refetch,
  } = useQuery(
    `/registrants/show-registrant-details/${activeRider?.show_registrant_id}`,
  );

  useEffect(() => {
    const fetchIt = async () => {
      const data = await Client.get(
        `/show-classes/?showId=${showData?.show?.id}`,
      );
      setClasses(data);
    };
    if (showData?.show?.id) {
      fetchIt();
    }
  }, [showData]);

  useEffect(() => {
    if (!showData) return;
    if (!showData.show_classes) {
      setActiveClasses([]);
      return;
    }
    if (activeHorse) {
      //filter for horse/user combo
      const ac = showData.show_classes.filter((show_class) => {
        return show_class.animal.id === activeHorse.value;
      });
      setActiveClasses(ac);
    } else {
      setActiveClasses([]);
    }
  }, [showData, activeHorse]);

  useEffect(() => {
    if (classes) {
      setClassSelectionOptions(
        classes?.data
          ?.filter((show_class) => {
            if (activeClasses) {
              const hasIt = activeClasses.find((active) => {
                return active.show_class.id === show_class.id;
              });
              return !hasIt;
            }
            return false;
          })
          .map((show_class) => {
            return {
              value: show_class.id,
              display: `${show_class.class_number} - ${show_class?.name}`,
            };
          }),
      );
    }
  }, [classes, activeClasses]);

  const initialValues = {};

  const btnCancel = (
    <Button
      label="Cancel"
      onClick={() => setShowModal(false)}
      color="secondary"
      variant="outlined"
    />
  );

  const btnSubmit = (formik) => (
    <Button
      label="Done"
      onClick={(e) => {
        handleExit();
      }}
    />
  );

  const btns = (formik, saveError) =>
    saveError ? (
      <span className="form-error-msg">{saveError}</span>
    ) : (
      [btnCancel, btnSubmit(formik)]
    );

  const footerButtons = (formik) => btns(formik, saveError);

  const columns = [
    {
      name: "Class Number",
      valFn: (show_class) => {
        return show_class.class_number;
      },
      display: "Class Number",
    },
    {
      name: "Class Name",
      valFn: (show_class) => {
        console.log("show_class", show_class);
        return show_class?.show_class?.long_name;
      },
      display: "Class Name",
    },
    {
      name: "buttons",
      valFn: (it) => {
        return (
          <CustomDivTableActions>
            {/* <CustomButton
              label="Remove"
              color="tertiary"
              onClick={() => handleRemove(it)}
              isInTable={true}
              type="outlined"
              fs={10}
            /> */}
            <ButtonIcon
              icon={<Icon icon="Trash" stroke={appColors?.reds?.error} />}
              onClick={() => handleRemove(it)}
              color="danger"
            />
          </CustomDivTableActions>
        );
      },
      display: "",
      actionCol: true,
      exportable: false,
    },
  ];

  const headerTitle = `Manage classes for ${
    activeRider?.name || ""
  } | Rider # ${activeRider?.rider_number || ""}`;

  return (
    <div css={css(theme.modalClassesForRider)}>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validate={(values) => {
          const errors = {};
          if (!values.name) {
            errors.name = "Required";
          } else if (!values.animal_name) {
            errors.animal_name = "Required";
          }
          return errors;
        }}
        style={{ width: "100%" }}
      >
        {(formik) => (
          <Form>
            <CustomModal
              headerTitle={headerTitle}
              footerButtons={footerButtons(formik, saveError)}
              onHide={handleExit}
              size="xl"
            >
              <p>
                Total Classes for all horses:{" "}
                {showData?.show_classes?.length || 0}
              </p>

              <FormSelect
                name="animal_id"
                label="Horse Name"
                onChange={async (val) => {
                  if (val.value) {
                    if (val.label === val.value) {
                      //New horse
                      const horsePut = await Client.put(
                        `/members/${activeRider.member_id}/horse`,
                        { name: val.label },
                      );
                      if (horsePut) {
                        refetchAnimals();
                        setActiveHorse({
                          value: horsePut.animal,
                          label: val.label,
                        });
                      }
                    } else {
                      setActiveHorse(val);
                    }
                  } else {
                    setActiveHorse(null);
                  }
                }}
                formik={formik}
                options={animalData?.data?.map((r) => ({
                  value: r.id,
                  label: `${r.name}`,
                }))}
                none
                labelPosition="top"
                placeholder="Select horse"
                required
                editable
              />

              {activeHorse && (
                <p css={css(theme).modalClassesForRider.p}>
                  Classes with {activeHorse?.label}
                </p>
              )}

              <div css={css(theme).modalClassesForRider.addNew}>
                <FormSelect
                  name="class_number"
                  label="Add class"
                  labelPosition="top"
                  none
                  //formik={formik}
                  value={activeClass}
                  placeholder="Class number"
                  options={classSelectionOptions}
                  onChange={(val) => {
                    if (val.value) {
                      setActiveClass(val);
                    } else {
                      setActiveClass(null);
                    }
                  }}
                  style={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                />
                {/* <CustomButton
                  label={`Add`}
                  onClick={() => handleAdd()}
                  sty={{
                    flex: "unset",
                    width: "auto",
                  }}
                  align="right"
                  disabled={!activeClass}
                /> */}
                <Button
                  label="Add"
                  onClick={() => handleAdd()}
                  color="secondary"
                  variant="outlined"
                  icon={<Icon icon="Plus" />}
                />
              </div>

              <CustomDivTable
                columns={columns}
                rows={activeClasses || []}
                style
              />
            </CustomModal>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ShowClassRidersAddRegistrantModal;
