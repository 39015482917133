import {
  appColors,
  borderRadius,
  dimensions,
  flexbox,
  fullHeight,
} from "emotion/utils";

export const css = (theme) => ({
  ...flexbox("column", "flex-start", "flex-start", 16),
  ...fullHeight(),

  modalRow: {
    ...flexbox("row", "flex-start", "flex-start", 16),

    "> *": {
      flex: 1,
    },
  },

  content: {
    ...flexbox("row", "stretch", "flex-start", 16),

    img: {
      ...borderRadius(8),
      ...dimensions("100%", "auto"),
      backgroundColor: appColors.blues.blueSmoke,
      display: "block",
      width: 200,
    },

    "> div": {
      flex: 1,

      textarea: {
        flex: 1,
      },
    },
  },

  location: {
    ...flexbox("column", "flex-start", "flex-start", 16),
    borderBottom: `1px solid ${theme?.border?.primary}`,
    marginBottom: 16,
    paddingBottom: 32,
  },

  actions: {
    ...flexbox("row", "flex-end", "center", 4),
  },
});
