import {
  appColors,
  appFonts,
  borderBottomLeftRadius,
  borderBottomRightRadius,
  borderTopLeftRadius,
  borderTopRightRadius,
  flexbox,
  fontbox,
  fullWidth,
} from "emotion/utils";

export const css = (theme) => ({
  ...flexbox("column", "flex-start", "flex-start", 16),

  // Show Title
  showTitleInput: {
    title: {
      ...fontbox(appFonts.inter, 20, 700, 30),
      color: theme?.text?.primary,
    },
    subStat: {
      ...fontbox(appFonts.inter, 16, 600, 24),
      borderLeft: `1px solid ${theme?.border?.primary}`,
      color: appColors.grays[1],
      paddingLeft: 16,
    },
  },

  // Class Riders page
  classRiders: {
    ...flexbox("column", "flex-start", "flex-start", 16),
  },

  // Modal Classes for Rider
  modalClassesForRider: {
    ...fullWidth(),

    "div[data-pc-section='content'] p": {
      ...fullWidth(),
      fontWeight: 700,
    },

    addNew: {
      ...flexbox("row", "flex-end", "flex-start", 0),
      ...fullWidth(),

      "> div > div:last-of-type > div > div": {
        ...borderTopRightRadius(0),
        ...borderBottomRightRadius(0),
      },

      "> button": {
        ...borderTopLeftRadius(0),
        ...borderBottomLeftRadius(0),
        borderLeft: "none",
        height: 44,
      },
    },
  },
});
