export default function IconEdit(props) {
  const { color = "#FFFFFF" } = props;

  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      style={{
        height: 60,
        width: 60,
      }}
    >
      <path
        opacity="0.6"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60ZM38.2315 15.9724C39.5281 14.6759 41.6239 14.6759 42.9205 15.9724L44.0276 17.0736C45.3241 18.3702 45.3241 20.466 44.0276 21.7625L42.2456 23.5446L36.4495 17.7485L38.2315 15.9724ZM24.4075 30.2938C24.5673 29.8024 24.8456 29.3584 25.2067 28.9972L35.1115 19.0865L40.9135 24.8885L31.0087 34.7992C30.6416 35.1603 30.1976 35.4386 29.7121 35.5984L24.4548 37.3509C23.9398 37.5226 23.3773 37.3923 22.9984 37.0075C22.6195 36.6227 22.4833 36.0602 22.655 35.5511L24.4075 30.2938ZM15 24.1603C15 21.0225 17.5458 18.4767 20.6836 18.4767H26.3671C27.415 18.4767 28.2616 19.3234 28.2616 20.3713C28.2616 21.4192 27.415 22.2658 26.3671 22.2658H20.6836C19.6357 22.2658 18.789 23.1124 18.789 24.1603V39.3164C18.789 40.3643 19.6357 41.211 20.6836 41.211H35.8397C36.8876 41.211 37.7342 40.3643 37.7342 39.3164V33.6329C37.7342 32.585 38.5808 31.7384 39.6287 31.7384C40.6767 31.7384 41.5233 32.585 41.5233 33.6329V39.3164C41.5233 42.4542 38.9775 45 35.8397 45H20.6836C17.5458 45 15 42.4542 15 39.3164V24.1603Z"
        fill={color}
      />
    </svg>
  );
};
