import { appColors, borderRadius, flexbox, square } from "emotion/utils";

export const css = (theme) => ({
  ...borderRadius(8),
  ...flexbox("column", "center", "center"),
  ...square(164),
  backgroundColor: appColors?.blues?.blueGray,

  img: {
    ...square(164),
    objectFit: "contain",
  },
});
