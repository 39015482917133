import {
  flexbox,
  fullWidth,
  paddingX,
  paddingY,
  posRelative,
} from "emotion/utils";

export const css = {
  container: (image) => ({
    ...flexbox("column", "center", "center"),
    ...paddingX(16),
    ...paddingY(48),
    ...posRelative(),
    backgroundImage: `url(${image})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top",

    "@media screen and (min-width: 576px)": {
      ...paddingX(38),
      ...paddingY(64),
    },

    frame: {
      ...flexbox("column", "center", "flex-start", 45),
      maxWidth: 1400,

      "@media screen and (min-width: 992px)": {
        ...flexbox("row", "center", "flex-start", 68),
        ...paddingY(128),
      },
    },
  }),

  img: {
    ...fullWidth(),
    ...posRelative(),
    flex: 1,
    maxHeight: "100%",
    maxWidth: 650,
    objectFit: "cover",
    overflow: "hidden",
  },
};
