/**@jsxImportSource @emotion/react */
import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import Button from "components/button/button";
import CustomModal from "components/custom-modal/custom-modal";
import Client from "client";

const ResetPasswordModal = ({ setShowModal, user, memberEmail }) => {
  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object({});

  const onSubmit = (values) => {
    alert("form submitted");
  };

  const btnCancel = (
    <Button
      label="Cancel"
      onClick={() => setShowModal(false)}
      color="secondary"
      variant="outlined"
    />
  );

  console.log("user", user);
  const btnSend = (user) => {
    const data = { username: user?.username };
    console.log(data);

    return (
      <Button
        label="Send"
        onClick={async () => {
          let serverReturn = await Client.post(`/auth/forgot-password`, data);

          if (serverReturn?.success === false) {
            //const field = serverReturn?.field;
            //formRef.current?.setErrors({ [field]: serverReturn?.reason });
          } else {
            setShowModal(false);
            //onSuccess(serverReturn);
          }
        }}
      />
    );
  };

  const btns = (user) => [btnCancel, btnSend(user.user)];

  const footerButtons = (user) =>
    btns(user).map((btn, index) => (
      <React.Fragment key={index}>{btn}</React.Fragment>
    ));

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(formik) => (
        <Form>
          <CustomModal
            headerTitle="Password Reset"
            footerButtons={footerButtons(user)}
            onHide={() => setShowModal(false)}
          >
            <p>
              Send password reset email to{" "}
              <strong>{user?.email || memberEmail}</strong>?
            </p>
          </CustomModal>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPasswordModal;
