/** @jsxImportSource @emotion/react */

import funSection from "images/frame-427318309@3x.png";

import { cssMarketing } from "../css";
import { css } from "./css";

const Fun = () => {
  const image = <div css={css.image} />;

  const content = (
    <div css={cssMarketing}>
      <h2>Fun</h2>
      <p>
        Effortless registration and notifications allow competitors to focus on
        their performance instead of watching their schedule. Chat, alerts, and
        location maps ensure competitors are in the right place, at the right
        time.
      </p>
    </div>
  );

  return (
    <div css={css.container(funSection)}>
      <div css={css.container().frame}>
        {content}
        {image}
      </div>
    </div>
  );
};

export default Fun;
