/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import Button from "components/button/button";
import CustomModal from "components/custom-modal/custom-modal";
import { FormElement } from "components/form-element/form-element";
import { Form, Formik } from "formik";
import { useQuery } from "hooks";
import React from "react";

const ModalAddRiderToClass = ({ setShowModal, show }) => {
  const theme = useTheme();

  const { data: horsesData } = useQuery("/horses");

  const horsesOptions = horsesData
    ? horsesData?.data?.map((horse) => ({
        label: horse.horseName,
        value: horse.horseId,
      }))
    : [];

  const { data: membersData } = useQuery("/members");
  const membersOptions = membersData
    ? membersData?.data?.map((member) => ({
        label: `${member?.user?.first_name} ${member?.user?.last_name}`,
        value: member?.user_id,
      }))
    : [];

  const initialValues = {
    memberName: "",
    riderNumber: "",
    horse: "",
  };

  const validate = (values) => {
    const fields = {
      memberName: "Member name is required.",
      riderNumber: "Rider number is required.",
      horse: "Horse is required.",
    };

    const errors = {};
    Object.keys(fields).forEach((field) => {
      if (!values[field]?.toString("MM/DD/YYY")) {
        errors[field] = fields[field];
      }
    });
    return errors;
  };

  const onSubmit = () => {
    alert("Submit functionality");
  };

  const headerTitle = "Add rider to class";

  const btnCancel = (
    <Button
      label="Cancel"
      onClick={() => setShowModal(false)}
      color="secondary"
      variant="outlined"
    />
  );

  const btnAddToClass = (formik) => (
    <Button
      disabled={!(formik.isValid && formik.dirty)}
      label="Add to class"
      onClick={(e) => {
        formik.handleSubmit(e);
        // calculateModalHeight();
      }}
    />
  );

  const footerButtons = (formik) =>
    [btnCancel, btnAddToClass(formik)].map((btn, index) => (
      <React.Fragment key={index}>{btn}</React.Fragment>
    ));

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      enableReinitialize
    >
      {(formik) => (
        <Form>
          <CustomModal
            headerTitle={headerTitle}
            footerButtons={footerButtons(formik)}
            onHide={() => setShowModal(false)}
          >
            <FormElement
              element="Dropdown"
              id="members"
              name="members"
              label="Member name"
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.members}
              options={membersOptions}
              required
              optionValue="value"
              optionLabel="label"
            />

            <FormElement
              element="InputText"
              id="riderNumber"
              name="riderNumber"
              label="Rider number"
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.riderNumber}
              required
            />

            <FormElement
              element="Dropdown"
              id="horse"
              name="horse"
              label="Horse"
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.horse}
              options={horsesOptions}
              required
            />
          </CustomModal>
        </Form>
      )}
    </Formik>
  );
};

export default ModalAddRiderToClass;
