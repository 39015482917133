/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { DataScroller as PrDataScroller } from "primereact/datascroller";

import { css } from "./css";

const DataScroller = ({ value, itemTemplate, rows }) => {
  const theme = useTheme();

  return (
    <PrDataScroller
      value={value}
      itemTemplate={itemTemplate}
      rows={rows}
      css={css(theme)}
    />
  );
};

export default DataScroller;
