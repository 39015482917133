const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const PHONE_REGEX = /^(\+\d+)? ?(\d{3}|\(\d{3}\))( |-)?\d{3}( |-)?\d{4}$/i;
const STATE_REGEX =
  /^(([Aa][EeLlKkSsZzRr])|([Cc][AaOoTt])|([Dd][EeCc])|([Ff][MmLl])|([Gg][AaUu])|([Hh][Ii])|([Ii][DdLlNnAa])|([Kk][SsYy])|([Ll][Aa])|([Mm][EeHhDdAaIiNnSsOoTt])|([Nn][EeVvHhJjMmYyCcDd])|([Mm][Pp])|([Oo][HhKkRr])|([Pp][WwAaRr])|([Rr][Ii])|([Ss][CcDd])|([Tt][NnXx])|([Uu][Tt])|([Vv][TtIiAa])|([Ww][AaVvIiYy]))$/i;

const evaluate = (testFn, msg, noEmpty) => (errors, values, field) => {
  const str = values?.[field] || "";
  let error = evaluateRaw(str);
  if (error) {
    errors[field] = error;
  }
};

const evaluateRaw = (testFn, msg, noEmpty) => (str) => {
  const showMsg = noEmpty || str;
  const isValid = testFn(str);
  if (!isValid && showMsg) {
    return msg;
  }
};

export const emailValid = evaluateRaw(
  (str) => EMAIL_REGEX.test(str),
  "Invalid email address",
);

export const email = evaluate(
  (str) => EMAIL_REGEX.test(str),
  "Invalid email address",
);

export const phoneValid = evaluateRaw(
  (str) => PHONE_REGEX.test(str),
  "Invalid phone number",
);

export const phone = evaluate(
  (str) => PHONE_REGEX.test(str),
  "Invalid phone number",
);

export const state = evaluate(
  (str) => STATE_REGEX.test(str),
  "Invalid state acronym",
);

export const latitude = evaluate(
  (str) => str >= -90 || str <= 90,
  "Invalid latitude",
);

export const longitude = evaluate(
  (str) => str >= -180 || str <= 180,
  "Invalid longitude",
);

export const password = evaluate(
  (str) => str.length >= 8,
  "Must be at least 8 characters long",
);

//export const required = evaluate((str) => !!str, "Required", true);
export const required = (msg) => evaluate((str) => !!str, msg, true);

export const match = (errors, values, field1, field2) => {
  const str1 = values[field1] || "";
  const str2 = values[field2] || "";
  const showMsg = str1 && str2;
  const isValid = str1 === str2;
  if (!isValid && showMsg) {
    errors[field2] = `Must match ${field1}`;
  }
};
