import {
  appColors,
  appFonts,
  borderRadius,
  // darken,
  flexbox,
  fontbox,
  fullWidth,
  marginAuto,
  paddingX,
  paddingY,
  posAbsolute,
  posRelative,
  themes,
  transition,
} from "emotion/utils";

export const css = (theme) => ({
  backgroundColor: theme?.background?.primary,
  minHeight: "100vh",
  paddingTop: 32,

  "@media screen and (min-width: 576px)": {
    paddingTop: 64,
  },

  block: {
    ...marginAuto(),
    maxWidth: 360,
    width: "80%",

    "> form": {
      ...flexbox("column", "center", "center", 32),
      ...posRelative(),

      "> *": {
        ...fullWidth(),
      },

      "> div": {
        "> div": {
          justifyContent: "center",
        },
      },
    },
  },

  formBlock: (isResettingPassword) => ({
    ...flexbox("column", "center", "center", 24),
    display: isResettingPassword ? "none" : "flex",

    "> *": {
      ...fullWidth(),
    },

    intro: (theme) => {
      // console.log('login cs: intro - theme', theme);

      return {
        ...flexbox("column", "center", "center", 12),

        h1: {
          ...fontbox(appFonts.inter, 20, 600, 28),
          color: theme?.text?.primary,
          textAlign: "center",

          "@media screen and (min-width: 576px)": {
            ...fontbox(appFonts.inter, 30, 600, 38),
          },
        },

        p: {
          ...fontbox(appFonts.inter, 12, 400, 20),
          color: theme?.text?.primary,
          textAlign: "center",

          "@media screen and (min-width: 576px)": {
            ...fontbox(appFonts.inter, 16, 400, 24),
          },
        },
      };
    },

    elements: {
      ...flexbox("column", "center", "flex-start", 20),

      "> *": {
        ...fullWidth(),
      },
    },
  }),

  errorBox: {
    ...borderRadius(12),
    ...flexbox("column", "center", "space-between", 55),
    ...paddingX(24),
    ...paddingY(36),
    ...posAbsolute(48, -12, -12, -12, 1),
    backgroundColor: appColors?.grays?.[0],
    border: `2px solid ${appColors?.reds?.error}`,
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.25)",
    minHeight: 300,
    width: "calc(100% + 20px)!important",

    "> *": {
      width: "auto",
    },

    "> div:first-of-type": {
      "> svg circle": {
        fill: appColors?.reds?.error,
      },
    },

    msg: {
      ...flexbox("column", "center", "center", 8),

      "> *": {
        width: "auto",
      },

      h2: {
        ...fontbox(appFonts?.inter, 30, 600, 38),
        color: theme?.text?.danger,
      },

      p: {
        // ...fontbox(appFonts.inter, 16, 400, 24),
        // color: theme?.text?.primary,
        // "@media (min-width: 768px)": {
        //   ...fontbox(appFonts.inter, 20, 400, 28),
        // },
      },
    },

    button: {
      ...fullWidth(),
    },

    btn: {
      ...fullWidth(),
    },
  },

  okBox: {
    ...flexbox("column", "flex-start", "flex-start", 12),
    ...posRelative(),

    box: {
      ...flexbox("row", "flex-start", "flex-start", 8),

      "> input": {
        display: "none",

        "&:checked": {
          backgroundColor: appColors?.greens?.success,
          borderColor: appColors?.greens?.success,
        },
      },

      "> label": {
        ...fontbox(appFonts?.inter, 12, 400, 20),
        color: theme?.text?.primary,
      },
    },
  },

  account: (theme) => ({
    ...fontbox(themes.fonts.inter, 12, 400, 20),
    color: theme.text.tertiary,

    "@media screen and (min-width: 576px)": {
      ...fontbox(themes.fonts.inter, 14, 400, 20),
    },

    "> span": {
      ...fontbox(themes.fonts.inter, 12, 600, 20),
      ...transition(),
      color: theme.button.primary.background,
      cursor: "pointer",

      "@media screen and (min-width: 576px)": {
        ...fontbox(appFonts.inter, 14, 600, 20),
      },
    },
  }),
});
