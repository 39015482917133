import React from "react";
import { StyleSheet, Text, Page, View } from "@react-pdf/renderer";
import moment from "moment";
import PrintPDF from "components/print-pdf/print-pdf";

const PaddockSheetsPrintPDF = ({ title, data }) => {
  // get unique days
  let uniqueArenaDayClasses = [
    ...new Set(
      data?.data
        .filter((item) => item.riders.length > 0 && item.riders[0])
        .map((item) =>
          item.riders.map(
            (r) =>
              item.start_at +
              "\n" +
              item.arena_name +
              "\n" +
              item.class_number +
              "\n" +
              r.lot_num,
          ),
        )
        .flat(),
    ),
  ];

  const w1 = "25%";
  const w3 = "50%";

  const getDayArenaData = (arenaDayClass, idx) => {
    const splits = arenaDayClass.split("\n");
    const adc = {
      start_at: splits[0],
      arena_name: splits[1],
      class_number: splits[2],
      lot_num: splits[3],
    };

    let classForDayAndArena = data?.data.filter(
      (item) =>
        item.start_at === adc.start_at &&
        item.arena_name === adc.arena_name &&
        item.class_number === adc.class_number,
    );
    if (!classForDayAndArena || classForDayAndArena.length < 1) return;
    let riders = classForDayAndArena[0].riders;
    if (!riders || riders.length < 1 || !riders[0]) return;
    riders = riders.filter((r) => r.lot_num === Number(adc.lot_num));
    if (!riders || riders.length < 1 || !riders[0]) return;

    return (
      <React.Fragment key={idx}>
        {
          <View key={idx} style={css.arenaBlock.arena}>
            <Text style={css.arenaBlock.arenaName}>
              {riders.length + " Riders"}
            </Text>
            <Text style={css.arenaBlock.arenaName}>
              {classForDayAndArena[0].arena_name} -{" "}
              {classForDayAndArena[0].class_number} - {"Lot:"}
              {riders[0].lot_num === 0 ? 1 : riders[0].lot_num} -{" "}
              {moment(classForDayAndArena[0].start_at).format("MM-DD")}
            </Text>
            <Text style={css.arenaBlock.arenaName}>
              {classForDayAndArena[0].name}
            </Text>
            <View style={css.table}>
              <View style={css.table.row}>
                <Text style={[css.table.cell, { width: w1 }]}>Rider #</Text>
                <Text
                  style={[css.table.cell, { textAlign: "left", width: w1 }]}
                >
                  Rider Name
                </Text>
                <Text style={[css.table.cell, { width: w1 }]}>Horse</Text>
              </View>

              <React.Fragment key={idx + "-A"}>
                {riders?.map((item, index) => {
                  const riderName = item?.rider_name || "---";
                  const horseName = item?.name || "---";
                  const riderNumber = item?.rider_number || "---";
                  const bgColor = index % 2 === 0 ? "#ffffff" : "#f6f6f6";

                  return (
                    <View
                      key={index + "|" + idx}
                      style={[css.table.dataRow, { backgroundColor: bgColor }]}
                    >
                      <Text
                        style={[
                          css.table.dataCell,
                          { whiteSpace: "pre-line", width: w1 },
                        ]}
                      >
                        {riderNumber}
                      </Text>
                      <Text
                        style={[
                          css.table.dataCell,
                          { textAlign: "left", width: w1 },
                        ]}
                      >
                        {riderName}
                      </Text>
                      <Text style={[css.table.dataCell, { width: w1 }]}>
                        {horseName}
                      </Text>
                    </View>
                  );
                })}
              </React.Fragment>
            </View>
          </View>
        }
      </React.Fragment>
    );
  };
  //console.log(uniqueArenas[x], uniqueClassesForDayAndArena)

  return (
    <PrintPDF
      headerText={title}
      splitPageBy={uniqueArenaDayClasses}
      showQR={false}
      showSubHeader={false}
      getChildren={getDayArenaData}
    ></PrintPDF>
  );
};

export default PaddockSheetsPrintPDF;

const css = StyleSheet.create({
  arenaBlock: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 20,

    arena: {
      borderColor: "#000000",
      borderStyle: "solid",
      borderWidth: 1,
      alignItems: "center",
      width: "98%",
    },
    arenaName: {
      color: "#1D1D1D",
      fontSize: 14,
      fontWeight: "700",
      paddingVertical: 10,
    },
  },

  table: {
    width: "100%",

    row: {
      alignItems: "center",
      borderBottomColor: "#000000",
      borderBottomStyle: "solid",
      borderBottomWidth: 1,
      flexDirection: "row",
      gap: 10,
      justifyContent: "flex-start",
    },
    cell: {
      color: "#000000",
      fontSize: 12,
      fontWeight: "700",
      paddingHorizontal: 10,
      paddingVertical: 2,
    },

    dataRow: {
      alignItems: "center",
      flexDirection: "row",
      gap: 10,
      justifyContent: "flex-start",
    },
    dataCell: {
      color: "#000000",
      fontSize: 10,
      fontWeight: "700",
      paddingHorizontal: 10,
      paddingVertical: 4,
    },
  },
  page: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    paddingHorizontal: 30,
    paddingTop: 30,
    paddingBottom: 60,
  },

  header: {
    gap: 4,
    marginBottom: 25,
    width: "100%",

    title: {
      color: "#202B72",
      fontSize: 22,
      fontWeight: "700",
    },

    day: {
      color: "#D52C51",
      fontSize: 16,
      fontWeight: "700",
    },
  },

  footer: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "absolute",
    bottom: 30,
    left: 30,
    right: 30,

    qr: {
      alignItems: "center",
      flexDirection: "row",
      gap: 10,
    },
    qrImg: {
      height: 50,
      width: 50,
    },
    qrText: {
      color: "#202B72",
      fontSize: 14,
      fontWeight: "700",
      maxWidth: 200,
    },

    logo: {
      height: 40,
      marginLeft: "auto",
      width: 40,
    },
  },
});
