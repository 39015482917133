import React from "react";
import { Document, Image, Page, Text, View } from "@react-pdf/renderer";

import imgQr from "images/qr.png";
import imgLogo from "images/logo.png";

import { getBgColor } from "utils";

import { css } from "./styles";

function getDay(isoStr) {
  const date = new Date(isoStr);
  const offset = date.getTimezoneOffset() * 60 * 1000;
  return Math.floor((date.getTime() - offset) / (1000 * 60 * 60 * 24));
}

const ShowRegistrantClassSchedulePrintPDF = (props) => {
  const { title, show, classes, rider } = props;

  if (!show) {
    return null;
  }

  const dayOne = getDay(show.start_at);

  const rowEls = Object.entries(
    classes?.reduce((map, cls) => {
      // group shows by day
      const dayNum = getDay(cls.show_class.start_at) - dayOne;

      if (!map[dayNum]) {
        map[dayNum] = [];
      }

      map[dayNum].push(cls);

      return map;
    }, {}) || [],
  ).map((entry, index) => {
    const [day, classes] = entry;

    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const title = (
      <Text style={css.tableBlock.title}>
        Day {Number(day) + 1} &mdash;{" "}
        {new Date(classes[0].show_class.start_at).toLocaleDateString(
          "en-US",
          options,
        )}
      </Text>
    );

    const headers = ["Class #", "Lot #", "Horse", "Name", "Arena"];
    const columns = (
      <View style={css.table.row}>
        {headers.map((header) => (
          <Text key={header} style={css.table.cell}>
            {header}
          </Text>
        ))}
      </View>
    );

    const rows = classes
      .sort((a, b) => {
        const textA = a.class_number.toUpperCase();
        const textB = b.class_number.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      })
      .sort((a, b) => (a.arena.name < b.arena.name ? -1 : 1))
      .map((cl, index) => {
        return (
          <View
            key={index}
            style={[
              css.table.dataRow,
              {
                backgroundColor: getBgColor(index),
              },
            ]}
          >
            <Text style={css.table.dataCell}>{cl.class_number}</Text>
            <Text style={css.table.dataCell}>{cl.lot_num}</Text>
            <Text style={css.table.dataCell}>{cl.animal.name}</Text>
            <Text style={css.table.dataCell}>{cl.show_class.name}</Text>
            <Text style={css.table.dataCell}>{cl.arena.name}</Text>
          </View>
        );
      });

    return (
      <View key={index} style={css.tableBlock}>
        {title}

        <View style={css.table}>
          {columns}
          {rows}
        </View>
      </View>
    );
  });

  return (
    <Document>
      <Page size="A4" style={css.page}>
        <View style={css.header}>
          <Text style={css.header.title}>{show.name}</Text>
          <Text style={css.header.day}>
            {rider.first_name} {rider.last_name} &mdash; {rider.rider_number}
          </Text>
        </View>

        <View style={css.body}>{rowEls}</View>

        {/* <View style={css.footer}>
          <View style={css.footer.qr}>
            <Image source={imgQr} style={css.footer.qrImg} />
            <Text style={css.footer.qrText}>
              Scan the QR code to download the Showmanship app!
            </Text>
          </View>
          <Image source={imgLogo} style={css.footer.logo} />
        </View> */}
      </Page>
    </Document>
  );
};

export default ShowRegistrantClassSchedulePrintPDF;
