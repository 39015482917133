import React, { useRef, useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import Client from "client";
import { capitalizeFirstLetter } from "utils";
import { emailValid } from "validation/form";
import { FormErrorMessage } from "validation/form-error-message";

import ConfirmationModal, {
  useConfirmationModal,
} from "confirmation-modal/confirmation-modal";
import CustomModal from "components/custom-modal/custom-modal";
import { FormElement } from "components/form-element/form-element";
import Button from "components/button/button";

export default function AdminModal({
  setShowModal = () => {},
  showModal = true,
  user,
  onSuccess = () => {},
}) {
  const {
    showModal: showConfirmationModal,
    handleExit,
    handleClose: handleConfirmationClose,
  } = useConfirmationModal();
  const [modalHeight, setModalHeight] = useState(0);
  const calculateModalHeight = () => {
    setTimeout(() => {
      const modalDialog = document.querySelector(".modal-content");
      if (modalDialog) {
        setModalHeight(modalDialog.offsetHeight);
      }
    }, 10); // delay
  };
  useEffect(() => {
    if (showConfirmationModal) {
      calculateModalHeight();
    }
  }, [showConfirmationModal]);
  const formRef = useRef();

  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isDefaultMessage, setIsDefaultMessage] = useState(true);
  const [customMessage, setCustomMessage] = useState(null);
  const [currentAction, setCurrentAction] = useState(null);
  const conditionalClose = () => {
    if (isFormDirty) {
      setCustomMessage();
      setIsDefaultMessage(false);
      handleExit();
    } else {
      setCustomMessage(null);
      setIsDefaultMessage(true);
      setShowModal(false);
    }
  };

  // const handleRemove = () => {
  //   setCustomMessage(
  //     <div className={css.remove}>
  //       <p>Are you sure you want to remove this admin?</p>
  //       <p className="remove">
  //         {" "}
  //         {user.first_name} {user.last_name}
  //       </p>
  //       <p>This action cannot be undone.</p>
  //     </div>,
  //   );
  //   setCurrentAction("remove");
  //   handleExit();
  // };

  const handleRemoveConfirmation = async () => {
    try {
      await Client.delete("/admins/admin?id=" + user?.id);
      onSuccess();
      setShowModal(false);
    } catch (err) {
      console.log(err);
      alert("Error occured trying to send remove the admin user");
    }
  };

  // const handleResetPassword = async () => {
  //   setCustomMessage(
  //     <>
  //       <p>Are you sure you want to send a password reset email to:</p>
  //       <p className="remove"> {user?.email}</p>
  //     </>,
  //   );
  //   setCurrentAction("resetPassword");
  //   handleExit();
  // };

  const handleResetConfirmation = async () => {
    try {
      const postData = {
        email: user.email,
      };
      await Client.post("/auth/forgot-password", postData);
      alert("Email Sent!");
    } catch (err) {
      console.log(err);
      alert(
        "Error occured trying to send password reset email, the email was not sent",
      );
    }
  };

  const handleConfirmation = () => {
    if (currentAction === "resetPassword") {
      handleResetConfirmation();
    } else if (currentAction === "remove") {
      handleRemoveConfirmation();
    }
    setShowModal(false);
  };

  // const d = new Date().toISOString();
  const adminToFormik = () => {
    return {
      firstName: user?.first_name || "",
      lastName: user?.last_name || "",
      email: user?.email || "",
      username: user?.username || "",
      phone: user?.phone || "",
      // birthAt: user?.birth_at || "",
      // createdAt: user?.created_at
      //   ? moment(user.created_at).format("MMM DD, YYYY")
      //   : moment(d).format("MMM DD, YYYY"),
    };
  };
  const initialValues = adminToFormik();

  const headerTitle = `${
    user
      ? `Edit ${capitalizeFirstLetter(user.first_name)} ${capitalizeFirstLetter(
          user.last_name,
        )}`
      : "Add admin"
  }`;

  // const btnRemove = (
  //   <CustomButton
  //     label="Remove"
  //     color="tertiary"
  //     type="outlined"
  //     onClick={handleRemove}
  //   />
  // );
  // const btnResetPassword = (
  //   <CustomButton
  //     label="Reset Password"
  //     color="primary"
  //     type="outlined"
  //     onClick={handleResetPassword}
  //   />
  // );

  const btnCancel = (
    <Button
      label="Cancel"
      onClick={() => setShowModal(false)}
      color="secondary"
      variant="outlined"
    />
  );

  const btnSubmit = (formik) => (
    <Button
      type="submit"
      label={user ? "Save changes" : "Add admin"}
      disabled={!(formik.isValid && formik.dirty)}
      onClick={(e) => {
        formik.handleSubmit(e);
        calculateModalHeight();
      }}
    />
  );

  const footerButtons = (formik) =>
    [btnCancel, btnSubmit(formik)].map((btn, index) => (
      <React.Fragment key={index}>{btn}</React.Fragment>
    ));

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={async (data) => {
          let serverReturn = null;
          if (user?.id) {
            serverReturn = await Client.put(`/admins/${user?.id}`, data);
          } else {
            serverReturn = await Client.post("/admins", data);
          }
          if (serverReturn?.success === false) {
            const field = serverReturn?.field;
            formRef.current?.setErrors({
              [field]: serverReturn?.reason,
            });
          } else {
            setShowModal(false);
            onSuccess(serverReturn);
          }
        }}
        innerRef={formRef}
        validationSchema={Yup.object({
          firstName: Yup.string().label("First Name").required(),
          lastName: Yup.string().label("Last Name"),
          username: Yup.string().label("Username").required(),
          email: Yup.string()
            .label("Email")
            .required()
            .test("email_rule", "Valid Email is required", (data) => {
              return !emailValid(data);
            }),
          createdAt: Yup.string().label("Date Added"),
        })}
      >
        {(formik) => (
          <Form
            onChange={(evt) => {
              const clone = structuredClone(formik.values);
              clone[evt.target.name] = evt.target.value;
              setIsFormDirty(
                JSON.stringify(clone) !== JSON.stringify(adminToFormik()),
              );
            }}
          >
            <CustomModal
              headerTitle={headerTitle}
              footerButtons={footerButtons(formik)}
              onHide={conditionalClose}
            >
              <FormElement
                element="InputText"
                id="firstName"
                name="firstName"
                label="First name"
                formik={formik}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.firstName}
                required
              />
              <FormErrorMessage name="firstName" formik={formik} />

              <FormElement
                element="InputText"
                id="lastName"
                name="lastName"
                label="Last name"
                formik={formik}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.lastName}
                required
              />
              <FormErrorMessage name="lastName" formik={formik} />

              <FormElement
                element="InputText"
                id="username"
                name="username"
                label="Username"
                formik={formik}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.username}
                required
              />
              <FormErrorMessage name="username" formik={formik} />

              <FormElement
                element="InputText"
                id="email"
                name="email"
                label="Email"
                formik={formik}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
                required
              />
              <FormErrorMessage name="email" formik={formik} />

              <FormElement
                element="InputText"
                id="phone"
                name="phone"
                label="Phone (optional)"
                formik={formik}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.phone}
              />

              {/* <FormTextInput
                name="createdAt"
                label="Date Added"
                readOnly
                formik={formik}
              /> */}
            </CustomModal>
          </Form>
        )}
      </Formik>

      <ConfirmationModal
        showModal={showConfirmationModal}
        handleExit={handleExit}
        handleClose={handleConfirmationClose}
        handleGoBack={handleConfirmationClose}
        setShowModal={setShowModal}
        modalHeight={modalHeight}
        customMessage={customMessage}
        isDefaultMessage={isDefaultMessage}
        handleConfirmation={handleConfirmation}
      />
    </>
  );
}
