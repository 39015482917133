import React, { useRef, useState, useEffect } from "react";
import { Formik, Form } from "formik";
import Modal from "react-bootstrap/Modal";
import * as Yup from "yup";
import moment from "moment";

import Client from "client";
import { capitalizeFirstLetter } from "utils";
import { emailValid, phoneValid } from "validation/form";
import ConfirmationModal, {
  useConfirmationModal,
} from "confirmation-modal/confirmation-modal";

import css from "./user.module.scss";
import FormTextInput from "../form-text-input";
import CustomButton from "../custom-button/custom-button";
import CustomModal from "../custom-modal/custom-modal";
import { FormElement } from "components/form-element/form-element";
import { FormErrorMessage } from "validation/form-error-message";
import Button from "components/button/button";

export default function UserModal(props) {
  const {
    setShowModal = () => {},
    showModal = true,
    user,
    onSuccess = () => {},
    userRoute,
    nameOfUser,
  } = props;

  const {
    showModal: showConfirmationModal,
    handleExit,
    handleClose: handleConfirmationClose,
  } = useConfirmationModal();
  const [modalHeight, setModalHeight] = useState(0);
  const calculateModalHeight = () => {
    setTimeout(() => {
      const modalDialog = document.querySelector(".modal-content");
      if (modalDialog) {
        setModalHeight(modalDialog.offsetHeight);
      }
    }, 10); // delay
  };
  useEffect(() => {
    if (showConfirmationModal) {
      calculateModalHeight();
    }
  }, [showConfirmationModal]);

  const toastRef = useRef();
  const formRef = useRef();

  const handleClose = () => [setShowModal(false)];
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isDefaultMessage, setIsDefaultMessage] = useState(true);
  const [customMessage, setCustomMessage] = useState(null);
  const [currentAction, setCurrentAction] = useState(null);
  const conditionalClose = () => {
    if (isFormDirty) {
      setCustomMessage();
      setIsDefaultMessage(false);
      handleExit();
    } else {
      setCustomMessage(null);
      setIsDefaultMessage(true);
      setShowModal(false);
    }
  };

  const handleRemove = () => {
    setCustomMessage(
      <div className={css.remove}>
        <p>Are you sure you want to remove this {nameOfUser.toLowerCase()}?</p>
        <p className={css.emp}>
          {" "}
          {user?.user.first_name} {user?.user.last_name}
        </p>
        <p>This action cannot be undone.</p>
      </div>,
    );
    setCurrentAction("remove");
    handleExit();
  };

  const handleRemoveConfirmation = async () => {
    try {
      await Client.delete("/admins/admin?id=" + user?.user.id);
      onSuccess();
      setShowModal(false);
      handleConfirmationClose();
    } catch (err) {
      console.log(err);
      alert("Error occured trying to send remove the admin user");
    }
  };
  const handleResetPassword = async () => {
    setCustomMessage(
      <div className={css.remove}>
        <p>Are you sure you want to send a password reset email to:</p>
        <p className={css.emp}>{user?.user.email}</p>
      </div>,
    );
    setCurrentAction("resetPassword");
    handleExit();
  };
  const handleResetConfirmation = async () => {
    try {
      const postData = {
        email: user.user.email,
      };
      await Client.post("/auth/forgot-password", postData);
      alert("Email Sent!");
    } catch (err) {
      console.log(err);
      alert(
        "Error occured trying to send password reset email, the email was not sent",
      );
    }
  };
  const handleConfirmation = () => {
    if (currentAction === "resetPassword") {
      handleResetConfirmation();
    } else if (currentAction === "remove") {
      handleRemoveConfirmation();
    }
    setShowModal(false);
  };

  const d = new Date().toISOString();
  const judgeToFormik = () => {
    return {
      username: user?.user.username || "",
      firstName: user?.user.first_name || "",
      lastName: user?.user.last_name || "",
      email: user?.user.email || "",
      phone: user?.user?.phone || "",
      notes: user?.notes,
      createdAt: user?.created_at
        ? moment(user.created_at).format("MMM DD, YYYY")
        : moment(d).format("MMM DD, YYYY"),
    };
  };
  const initialValues = judgeToFormik();

  const headerTitle = `${
    user
      ? `Edit ${capitalizeFirstLetter(
          initialValues.firstName,
        )} ${capitalizeFirstLetter(initialValues.lastName)}`
      : `Create ${nameOfUser.toLowerCase()}`
  }`;

  const btnSubmit = (formik) => (
    <Button
      type="submit"
      label={user ? `Save changes` : `Add ${nameOfUser}`}
      disabled={!(formik.isValid && formik.dirty)}
      onClick={(e) => {
        formik.handleSubmit(e);
        calculateModalHeight();
      }}
    />
  );

  const btnRemove = (
    <CustomButton
      label="Remove"
      color="tertiary"
      type="outlined"
      onClick={handleRemove}
    />
  );

  const btnResetPassword = (
    <CustomButton
      label="Reset Password"
      color="primary"
      type="outlined"
      onClick={handleResetPassword}
      align="left"
    />
  );

  const btnCancel = (
    <Button
      label="Cancel"
      onClick={() => setShowModal(false)}
      color="secondary"
      variant="outlined"
    />
  );

  const btns = (formik) =>
    user
      ? // ? [btnRemove, btnResetPassword, btnSubmit(formik)]
        [btnCancel, btnSubmit(formik)]
      : [btnCancel, btnSubmit(formik)];

  const footerButtons = (formik) =>
    btns(formik).map((btn, index) => (
      <React.Fragment key={index}>{btn}</React.Fragment>
    ));

  return (
    <>
      <Formik
        initialValues={initialValues}
        innerRef={formRef}
        onSubmit={async (data) => {
          let serverReturn = null;
          if (user?.id) {
            serverReturn = await Client.put(`${userRoute}/${user?.id}`, data);
          } else {
            serverReturn = await Client.post(userRoute, data);
          }

          if (serverReturn?.success === false) {
            const field = serverReturn?.field;
            formRef.current?.setErrors({ [field]: serverReturn?.reason });
          } else {
            setShowModal(false);
            onSuccess(serverReturn);
          }
        }}
        validationSchema={Yup.object({
          firstName: Yup.string().label("First Name").required(),
          lastName: Yup.string().label("Last Name").required(),
          username: Yup.string().label("Username").required(),
          email: Yup.string()
            .label("Email")
            .required()
            .test("email", "Email is invalid", (data) => {
              return !emailValid(data);
            }),
          phone: Yup.string()
            .label("Phone")
            .nullable()
            .optional()
            .test("phone", "Phone number is invalid", (data) => {
              return !phoneValid(data);
            }),
          createdAt: Yup.string().label("Date Added"),
        })}
      >
        {(formik) => (
          <Form
            onChange={(evt) => {
              const clone = structuredClone(formik.values);
              clone[evt.target.name] = evt.target.value;
              setIsFormDirty(
                JSON.stringify(clone) !== JSON.stringify(judgeToFormik()),
              );
            }}
          >
            <CustomModal
              headerTitle={headerTitle}
              footerButtons={footerButtons(formik)}
              onHide={conditionalClose}
            >
              {/* <FormTextInput
                name="firstName"
                label="First Name*"
                formik={formik}
              />
              {formik.touched.firstName && formik.errors.firstName && (
                <span className="form-error-msg">
                  {formik.errors.firstName}
                </span>
              )} */}
              <FormElement
                element="InputText"
                id="firstName"
                name="firstName"
                label="First name"
                formik={formik}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.firstName}
                required
              />
              <FormErrorMessage name="firstName" formik={formik} />

              {/* <FormTextInput
                name="lastName"
                label="Last Name*"
                formik={formik}
              />
              {formik.touched.lastName && formik.errors.lastName && (
                <span className="form-error-msg">{formik.errors.lastName}</span>
              )} */}
              <FormElement
                element="InputText"
                id="lastName"
                name="lastName"
                label="Last name"
                formik={formik}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.lastName}
                required
              />
              <FormErrorMessage name="lastName" formik={formik} />

              {/* <FormTextInput
                name="username"
                label="Username*"
                formik={formik}
              />
              {formik.touched.username && formik.errors.username && (
                <span className="form-error-msg">{formik.errors.username}</span>
              )} */}
              <FormElement
                element="InputText"
                id="username"
                name="username"
                label="Username"
                formik={formik}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.username}
                required
              />
              <FormErrorMessage name="username" formik={formik} />

              {/* <FormTextInput name="email" label="Email*" formik={formik} />
              {formik.touched.email && formik.errors.email && (
                <span className="form-error-msg">{formik.errors.email}</span>
              )} */}
              <FormElement
                element="InputText"
                id="email"
                name="email"
                label="Email"
                formik={formik}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
                required
              />
              <FormErrorMessage name="email" formik={formik} />

              {/* <FormTextInput name="phone" label="Phone" formik={formik} />
              {formik.touched.phone && formik.errors.phone && (
                <span className="form-error-msg">{formik.errors.phone}</span>
              )} */}
              <FormElement
                element="InputText"
                id="phone"
                name="phone"
                label="Phone (optional)"
                formik={formik}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.phone}
              />

              {/* <FormTextInput
                name="createdAt"
                label="Date Added"
                readOnly
                formik={formik}
              /> */}
              <FormElement
                element="InputText"
                id="createdAt"
                name="createdAt"
                label="Date Added"
                readOnly
                formik={formik}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.createdAt}
              />

              <FormElement
                element="InputTextarea"
                id="notes"
                name="notes"
                label="Notes (optional)"
                formik={formik}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.notes}
              />
            </CustomModal>
          </Form>
        )}
      </Formik>

      <ConfirmationModal
        showModal={showConfirmationModal}
        handleExit={handleExit}
        handleClose={handleConfirmationClose}
        handleGoBack={handleConfirmationClose}
        setShowModal={setShowModal}
        modalHeight={modalHeight}
        customMessage={customMessage}
        isDefaultMessage={isDefaultMessage}
        handleConfirmation={handleConfirmation}
      />
    </>
  );
}
