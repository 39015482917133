import { flexbox, fontbox, posRelative, theme } from "emotion/utils";

export const cssMarketing = {
  ...flexbox("column", "flex-start", "flex-start", 20),
  flex: 1,

  h2: {
    ...fontbox(theme.fonts.korolev, 20, 700, 26),
    ...posRelative(),

    "@media screen and (min-width: 576px)": {
      ...fontbox(theme.fonts.korolev, 40, 700, 46),
    },
    "@media screen and (min-width: 768px)": {
      ...fontbox(theme.fonts.korolev, 48, 700, 54),
    },
  },

  p: {
    ...fontbox(theme.fonts.inter, 18, 400, 18 * 1.5),

    "@media screen and (min-width: 576px)": {
      ...fontbox(theme.fonts.inter, 24, 400, 24 * 1.5),
    },
    "@media screen and (min-width: 768px)": {
      ...fontbox(theme.fonts.inter, 32, 400, 32 * 1.5),
      margin: 0,
      textAlign: "left",
    },
  },
};
