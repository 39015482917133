import { Form, Formik } from "formik";
import { capitalize } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import * as Yup from "yup";

import { useQuery } from "hooks/use-query";
import { capitalizeFirstLetter } from "utils";
import Client from "client";
import { emailValid } from "validation/form";

import FormCustodians from "components/form-custodians/form-custodians";
import ConfirmationModal, {
  useConfirmationModal,
} from "confirmation-modal/confirmation-modal";
import FormBlock from "components/form-block/form-block";
import CustomButton from "components/custom-button/custom-button";
import CustomModal from "components/custom-modal/custom-modal";
import Button from "components/button/button";
import { useTheme } from "@emotion/react";

export default function BaseClassModal({
  setShowModal = () => {},
  showModal = true,
  activeMember,
  onSuccess = () => {},
  onClose = () => {},
}) {
  const {
    showModal: showConfirmationModal,
    handleExit,
    handleClose: handleConfirmationClose,
  } = useConfirmationModal(onClose);

  const [modalHeight, setModalHeight] = useState(0);
  const [modalBodyHeight, setModalBodyHeight] = useState(0);

  const { data, isLoading } = useQuery(`/members/${activeMember?.id}`, {
    enabled: !!activeMember?.id,
  });

  const member = data?.member;

  const theme = useTheme();

  const calculateModalHeight = () => {
    setTimeout(() => {
      const modalDialog = document.querySelector(".modal-content");
      const modalBody = document.querySelector(".modal-body");
      if (modalDialog) {
        setModalHeight(modalDialog.offsetHeight);
      }
      if (modalBody) {
        setModalBodyHeight(modalBody.offsetHeight);
      }
    }, 500); // delay
  };
  useEffect(() => {
    calculateModalHeight();
  }, []);
  useEffect(() => {
    if (showConfirmationModal) {
      calculateModalHeight();
    }
  }, [showConfirmationModal]);
  const formRef = useRef();

  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isDefaultMessage, setIsDefaultMessage] = useState(true);
  const [customMessage, setCustomMessage] = useState(null);

  const conditionalClose = () => {
    if (isFormDirty) {
      setCustomMessage();
      setIsDefaultMessage(false);
      handleExit();
      onSuccess();
    } else {
      setCustomMessage(null);
      setIsDefaultMessage(true);
      onClose();
      setShowModal(false);
    }
  };

  async function onSubmit(data, { setErrors }) {
    try {
      let originalCustodians = data.custodians;
      let cleanedCustodians = [];
      for (let x = 0; x < data.custodians.length; x++) {
        let custodian = data.custodians[x];
        if (custodian.email && custodian.relation) {
          cleanedCustodians.push(custodian);
        }
      }
      data.custodians = cleanedCustodians;

      let serverReturn = null;
      serverReturn = await Client.put(
        `/members/custodians/${member?.id}`,
        data,
      );

      if (serverReturn?.success === false) {
        //Add an empty custodian row back in
        data.custodians = originalCustodians;
        const field = serverReturn?.field;
        setErrors({ [field]: serverReturn?.reason });
      } else {
        setShowModal(false);
        onSuccess(serverReturn);
      }
    } catch (e) {
      setErrors({ submit: e.message });
    }
  }

  // const handleConfirmation = () => {
  //   if (currentAction === "resetPassword") {
  //     handleResetConfirmation();
  //   } else if (currentAction === "remove") {
  //     //handleRemoveConfirmation(); // Use if remove is needed
  //   }
  //   setShowModal(false);
  // };

  function getCustodiansFromMember(member) {
    const custodians = (member?.member_custodians || []).map((mc) => {
      return {
        email: mc.custodian.user.email,
        relation: mc.relation,
        firstName: `${capitalizeFirstLetter(mc.custodian.user.first_name)}`,
        lastName: `${capitalizeFirstLetter(mc.custodian.user.last_name)}`,
      };
    });

    custodians.push(
      ...(member?.accepted_invitations || []).map((mc) => {
        return {
          email: mc.email,
          relation: mc.relation,
          firstName: `${capitalizeFirstLetter(mc.first_name)}`,
          lastName: `${capitalizeFirstLetter(mc.last_name)}`,
        };
      }),
    );

    return custodians;
  }

  //console.log(member)
  const memberToFormik = () => {
    return {
      custodians: getCustodiansFromMember(member),
      custodian_invitations: member?.custodian_invitations || [],
    };
  };

  const handleClose = () => [setShowModal(false)];

  const initialValues = memberToFormik();

  const emailFn = async (dataCustodianInvite) => {
    let serverReturn = null;

    try {
      const data = {
        member_id: member?.id,
        custodian_email: dataCustodianInvite.email,
        id: dataCustodianInvite.id,
      };
      serverReturn = await Client.put(
        `/members/${member?.id}/resend-invite`,
        data,
      );

      if (serverReturn?.success === false) {
        alert("Email could not be sent");
      } else {
        alert("Email invitation sent!");
      }
    } catch (err) {
      console.log(err);
      alert("Email couldn't be sent");
    }
  };

  const headerTitle = `${
    activeMember
      ? `Custodians for ${capitalize(
          activeMember?.user.first_name.trim(),
        )} ${capitalize(activeMember?.user.last_name.trim())}`
      : "Create Member"
  }`;

  const btnCancel = (
    <Button
      label="Cancel"
      onClick={conditionalClose}
      color="secondary"
      variant="outlined"
    />
  );

  const btnSubmitCreate = (formik) => (
    <Button
      label={activeMember ? "Submit" : "Add guardian"}
      disabled={!formik.isValid || !Object.values(formik.touched).length}
      onClick={(e) => {
        formik.handleSubmit(e);
        calculateModalHeight();
      }}
    />
  );

  const footerButtons = (formik) =>
    [btnCancel, btnSubmitCreate(formik)].map((btn, index) => (
      <React.Fragment key={index}>{btn}</React.Fragment>
    ));

  return (
    <>
      <Formik
        initialValues={initialValues}
        innerRef={formRef}
        onSubmit={onSubmit}
        validationSchema={Yup.object({
          custodians: Yup.array()
            .label("Custodian email")
            .test(
              "email_custodian_rules",
              "There is a problem with a custodian email",
              (data) => {
                for (let x = 0; x < data.length; x++) {
                  const custodian = data[x];
                  if (custodian.email) {
                    if (emailValid(custodian.email)) {
                      return false;
                    } else {
                      //return false;
                    }
                  } else {
                    //return true;
                  }
                }
                return true;
              },
            ),
        })}
      >
        {(formik) => (
          <Form
            onChange={(evt) => {
              const clone = structuredClone(formik.values);
              clone[evt.target.name] = evt.target.value;
              setIsFormDirty(
                JSON.stringify(clone) !== JSON.stringify(memberToFormik()),
              );
            }}
          >
            <CustomModal
              headerTitle={headerTitle}
              footerButtons={footerButtons(formik)}
              onHide={conditionalClose}
            >
              <FormCustodians
                name="custodians"
                label="Custodians"
                formik={formik}
                labelPosition="top"
                emailFn={emailFn}
              />

              {formik.errors.submit && (
                <div
                  style={{
                    backgroundColor: "rgba(255, 0, 0, 0.1)",
                    marginTop: 15,
                    paddingTop: 15,
                    paddingBottom: 15,
                    paddingRight: 20,
                    paddingLeft: 20,
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: 5,
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      color: "red",
                      fontSize: 14,
                      fontWeight: 600,
                      lineHeight: "20px",
                      textAlign: "center",
                      margin: "0 auto",
                      maxWidth: 400,
                    }}
                  >
                    {formik.errors.submit}
                  </p>
                </div>
              )}
            </CustomModal>
          </Form>
        )}
      </Formik>

      <ConfirmationModal
        showModal={showConfirmationModal}
        handleExit={handleExit}
        handleClose={handleConfirmationClose}
        handleGoBack={handleConfirmationClose}
        setShowModal={setShowModal}
        modalHeight={modalHeight}
        customMessage={customMessage}
        isDefaultMessage={isDefaultMessage}
        //handleConfirmation={handleConfirmation}
      />
    </>
  );
}
