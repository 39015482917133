import {
  appColors,
  borderRadius,
  flexbox,
  square,
  transition,
} from "emotion/utils";

export const css = (theme) => ({
  ...borderRadius(20),
  ...flexbox("column", "center", "center"),
  ...square(40),
  ...transition("all", 0.3),
  backgroundColor: "transparent",
  border: `1px solid transparent`,
  cursor: "pointer",

  "&:hover": {
    backgroundColor: appColors.grays[100],
    borderColor: `${appColors.grays[1]}08`,
  },

  "> div": {
    width: "auto",
  },
});
