/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { useRef, useState } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import moment from "moment";
import { Toolbar } from "primereact/toolbar";

import { useQuery, useMutation } from "hooks";
import Client from "client";

import ShowClassRemoveModal from "./show-class-remove-modal";
import ShowClassModal from "./show-class-modal/show-class-modal";
import ShowClassModalEdit from "./show-class-modal/show-class-modal-edit";
import ShowClassResultsModal from "./show-class-results/show-class-results-modal";
import ShowClassEditAwardsModal from "./show-class-edit-awards-modal";
import DuplicateModal from "./duplicate-modal";

import CustomDivTable from "components/custom-table/custom-div-table";
import CustomPdfButton from "components/custom-pdf-button/custom-pdf-button";
import ShowClassesPrintPDF from "pages/admin/show/show-classes-print-pdf/show-classes-print-pdf";
import PaddockSheetsPrintPDF from "./paddock-sheets/paddock-sheets-print-pdf";
import TitleBlock from "components/title-block/title-block";
import Button from "components/button/button";
import Icon from "components/icon/icon";
import FilterBtnGroup from "components/filter-btn-group/filter-btn-group";
import CustomDivTableActions from "components/custom-table/custom-div-table-actions";
import ButtonIcon from "components/button-icon/button-icon";
import FormInput from "components/form-input/form-input";
import Tag from "components/tag/tag";

import { css } from "./css";

export default function ShowClasses() {
  const [showShowClassModal, setShowShowClassModal] = useState(false);
  const [showShowClassModalEdit, setShowShowClassModalEdit] = useState(false);
  const [showShowClassRemoveModal, setShowShowClassRemoveModal] =
    useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [showResultsModal, setShowResultsModal] = useState(false);
  const [showClassEditAwardsModals, setShowClassEditAwardsModals] =
    useState(false);
  const [classNumberMap, setClassNumberMap] = useState({});
  const [filters, setFilters] = useState([]);
  const [ageCategory, setAgeCategory] = useState(null);
  const [activeFilterGroup, setActiveFilterGroup] = useState("-1");
  //const [selectedShowClass, setSelectedShowClass] = useState(null);
  const [activeBtnGroupArenaType, setActiveBtnGroupArenaType] = useState("all");
  const [activeBtnGroupAgeGroup, setActiveBtnGroupAgeGroup] = useState("all");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [editClassNumber, setEditClassNumber] = useState(false);

  const activeShowClass = useRef();

  const navigate = useNavigate();

  const { show, setActiveBtnGroup } = useOutletContext();

  const theme = useTheme();

  // Data
  const { data, refetch } = useQuery(`/show-classes?showId=${show.id}`);
  const { data: ages } = useQuery(`/class-age-categories`);
  const { data: arenas } = useQuery(`/arenas`);

  const [rows, setRows] = useState([]);

  const filteredRows = {
    data: data?.data
      .filter((row) => {
        if (!ageCategory) return true;
        return row.age_category_id === ageCategory.id;
      })
      .filter((row) => {
        if (activeBtnGroupArenaType === "all") {
          return true;
        }

        return (
          row.arena_name.toLowerCase().replace(" ", "-") ===
          activeBtnGroupArenaType
        );
      })
      .sort((a, b) => (a.daily_order < b.daily_order ? -1 : 1))
      .sort(
        (a, b) => moment(a.start_at).valueOf() - moment(b.start_at).valueOf(),
      ),
  };

  const { mutate: updateClassOrder } = useMutation({
    mutationFn: (body) => Client.put("/show-classes/reorder", body),
    onSuccess: () => {
      refetch();
    },
  });

  const handleRowReorder = async (e) => {
    const rowToMove = filteredRows.data[e.dragIndex];

    let nextRow =
      filteredRows.data[
        e.dragIndex < e.dropIndex ? e.dropIndex + 1 : e.dropIndex
      ];
    if (!nextRow) {
      nextRow = filteredRows.data[e.dropIndex];
    }

    updateClassOrder({ fromId: rowToMove.id, toId: nextRow?.id });
  };

  const { mutate: updateClassNumbers } = useMutation({
    mutationFn: (classes) => Client.put("/show-classes", { classes }),
    onSuccess: () => {
      refetch();
    },
  });

  const handleEditClassNumber = () => {
    if (!editClassNumber) {
      setClassNumberMap({});
    } else {
      updateClassNumbers(
        Object.entries(classNumberMap).map(([id, v]) => ({
          id,
          manual_class_number: v,
        })),
      );
    }

    setEditClassNumber(!editClassNumber);
  };

  function editClass(val) {
    if (val) {
      activeShowClass.current = val;
      setShowShowClassModalEdit(true);
    }
  }

  function viewRiders(it) {
    navigate(`/admin/show-classes/${it.id}`);
  }

  function removeClass(val) {
    activeShowClass.current = val;
    setShowShowClassRemoveModal(true);
  }

  function handlePlacingsAwards(val) {
    activeShowClass.current = val;
    setShowClassEditAwardsModals(true);
  }

  const btnExportPDF = (
    <CustomPdfButton
      document={
        <ShowClassesPrintPDF title={`${show.name} Schedule`} data={data} />
      }
      fileName="schedule"
    />
  );

  const btnPaddockPDF = (
    <CustomPdfButton
      label="Print Paddock Sheets"
      document={
        <PaddockSheetsPrintPDF
          title={`${show.name} Paddock Sheets`}
          data={
            selectedProducts.length > 0
              ? { data: selectedProducts.map((sp) => sp.data) }
              : filteredRows.data
              ? filteredRows
              : null
          }
          showId={show?.id}
        />
      }
      fileName="schedule"
    />
  );

  const columns = [
    { name: "id", selector: (row) => row.id, omit: true, hidden: true },
    {
      name: "name",
      valFn: (it) => {
        return it.long_name || "--";
      },
      filter: true,
      filterPlaceholder: "Search by name",
      display: "Class name",
      sortable: false,
    },
    {
      name: "pattern",
      valFn: (it) => {
        return it.pattern ? <Tag tag="Yes" /> : <Tag tag="No" />;
      },
      display: "Pattern",
      sortable: false,
      align: "center",
      alignHeader: "center",
    },
    {
      name: "class_number",
      valFn: (it) => {
        if (editClassNumber) {
          return (
            <FormInput
              placeholder={it.class_number}
              value={classNumberMap[it.id] || ""}
              onChange={(e) => {
                const { value } = e.target;

                let newMap = { ...classNumberMap };
                if (!value) {
                  delete newMap[it.id];
                } else {
                  newMap[it.id] = value;
                }

                setClassNumberMap(newMap);
              }}
            ></FormInput>
          );
        }
        return it.class_number || "--";
      },
      filter: true,
      display: "Class #",
      align: "right",
      alignHeader: "right",
    },
    {
      name: "lotNo",
      valFn: (it) => {
        return it.num_lots || "--";
      },
      display: "# Lots",
      align: "right",
      alignHeader: "right",
    },
    // {
    //   name: "age",
    //   valFn: (it) => {
    //     const age = it.age_name;
    //     return age || "--";
    //   },
    //   display: "Age Group",
    // },
    {
      name: "arena",
      valFn: (it) => {
        let arenaName = it.arena_name?.substring(0, 15);
        if (arenaName) {
          if (it.arena_name?.length > 15) arenaName = arenaName + "...";
        } else {
          arenaName = "--";
        }
        return arenaName;
      },
      display: "Arena",
    },
    // {
    //   name: "start_at",
    //   valFn: (it) => {
    //     return it.start_at;
    //   },
    //   display: "Date",
    //   hidden: true,
    // },
    // {
    //   name: "entryCost",
    //   valFn: (it) => {
    //     return it.cost ? `$${it.cost}` : "--";
    //   },
    //   display: "Pre-Entry Cost",
    // },
    // {
    //   name: "lateCharge",
    //   valFn: (it) => {
    //     return it.post_entry_fee ? `$${it.post_entry_fee}` : "--";
    //   },
    //   display: "Post-Entry Fee",
    // },
    {
      name: "numRegistered",
      valFn: (it) => {
        return it.num_registrants || "0";
      },
      display: "# Riders",
      align: "right",
      alignHeader: "right",
    },
    // {
    //   name: "numConfirmed",
    //   valFn: (it) => {
    //     return it.num_confirmed || "0";
    //   },
    //   display: "# Confirmed",
    // },
    {
      name: "dailyOrder",
      valFn: (it) => {
        return it.daily_order;
      },
      display: "Order",
    },
    {
      name: "actions",
      valFn: (it) => {
        let disabled = it.is_finished;
        if (disabled) {
          return (
            <CustomDivTableActions>
              <ButtonIcon
                icon={<Icon icon="Award" />}
                onClick={(e) => {
                  e.stopPropagation();
                  handlePlacingsAwards(it);
                }}
              />
            </CustomDivTableActions>
          );
        }

        return (
          <CustomDivTableActions>
            <ButtonIcon
              icon={<Icon icon="Trash" />}
              onClick={(e) => {
                e.stopPropagation();
                removeClass(it);
              }}
            />

            <ButtonIcon
              icon={<Icon icon="Award" />}
              onClick={(e) => {
                e.stopPropagation();
                handlePlacingsAwards(it);
              }}
            />

            <ButtonIcon
              icon={<Icon icon="Users2" />}
              onClick={(e) => {
                e.stopPropagation();
                // alert("users functionality");
                navigate(`/admin/show-classes/${it.id}`);
              }}
            />

            <ButtonIcon
              icon={<Icon icon="Edit" />}
              onClick={(e) => {
                e.stopPropagation();
                editClass(it);
              }}
            />
          </CustomDivTableActions>
        );
      },
      display: "",
      align: "right",
      alignHeader: "right",
      exportable: false,
    },
    // {
    //   name: "go",
    //   valFn: () => <IconGo />,
    //   display: "",
    //   actionCol: true,
    //   exportable: false,
    // },
  ];

  const headerTemplate = (data) => {
    return <div>{moment(data?.data.start_at).format("dddd  MM/DD/YYYY")}</div>;
  };

  const modals = (
    <>
      {showShowClassModal && (
        <ShowClassModal
          setShowModal={setShowShowClassModal}
          show={show}
          refresh={() => {
            refetch();
          }}
          onSuccess={() => refetch()}
        />
      )}

      {showShowClassModalEdit && (
        <ShowClassModalEdit
          setShowModal={setShowShowClassModalEdit}
          show={show}
          showClass={activeShowClass.current}
          refresh={() => {
            refetch();
          }}
          onSuccess={() => refetch()}
        />
      )}

      {showShowClassRemoveModal && (
        <ShowClassRemoveModal
          setShowModal={setShowShowClassRemoveModal}
          showClasses={
            selectedProducts.length > 0
              ? selectedProducts
              : activeShowClass.current
          }
          refresh={() => {
            refetch();
          }}
          onAccept={() => refetch()}
        />
      )}

      {/* {showShowClassSplitModal && (
        <ClassSplitModal
          setShowModal={setShowShowClassSplitModal}
          showClass={activeShowClass.current}
          onSuccess={() => refetch()}
        />
      )} */}

      {showDuplicateModal && (
        <DuplicateModal
          setShowModal={setShowDuplicateModal}
          show={show}
          onSuccess={() => refetch()}
        />
      )}

      {showResultsModal && (
        <ShowClassResultsModal
          setShowModal={setShowResultsModal}
          show={true}
          showClass={activeShowClass.current}
          onAccept={() => refetch()}
        />
      )}

      {showClassEditAwardsModals && (
        <ShowClassEditAwardsModal
          setShowModal={setShowClassEditAwardsModals}
          show={true}
          refresh={() => refetch()}
          showClass={activeShowClass.current}
          onAccept={() => refetch()}
          onSuccess={() => refetch()}
        />
      )}
    </>
  );

  // const btnAddShowClass = (
  //   <Button
  //     key="add-show-class"
  //     icon={<Icon icon="Plus" />}
  //     iconPos="left"
  //     label="Add class"
  //     onClick={handleCreate}
  //   />
  // );

  //const actions = [btnAddShowClass];

  const titleBlock = (
    <TitleBlock>
      <h1 css={css(theme).showTitleInput.title}>{show.name}</h1>
      <div css={css(theme).showTitleInput.subStat}>
        Total classes: {data?.data?.length}
      </div>
      {/* {actions} */}
    </TitleBlock>
  );

  const statsBlock = (
    <TitleBlock>
      <FilterBtnGroup
        options={[...new Set(data?.data?.map((arena) => arena?.arena_name))]}
        activeBtnGroup={activeBtnGroupArenaType}
        setActiveBtnGroup={setActiveBtnGroupArenaType}
      />
      <FilterBtnGroup
        options={["Junior", "Intermediate", "Senior"]}
        activeBtnGroup={activeBtnGroupAgeGroup}
        setActiveBtnGroup={(e) => {
          setActiveBtnGroupAgeGroup(e);
          setAgeCategory(
            ages.data.find((a) => a?.name.toLowerCase() === e.toLowerCase()),
          );
        }}
      />
    </TitleBlock>
  );

  //console.log(selectedProducts)
  const leftToolbarTemplate = () => {
    return (
      <CustomDivTableActions>
        <Button
          label="Add Class"
          icon={<Icon icon="Plus" />}
          onClick={openNew}
        />
        <Button
          label={editClassNumber ? "Save Class # Edit" : "Edit Class #s"}
          icon={<Icon icon="Edit" />}
          variant="outlined"
          onClick={handleEditClassNumber}
        />
        <Button
          label="Delete"
          icon={<Icon icon="Trash" />}
          color="danger"
          onClick={removeClass}
          disabled={!selectedProducts || !selectedProducts.length}
        />
      </CustomDivTableActions>
    );
  };

  const openNew = () => {
    activeShowClass.current = null;
    setShowShowClassModal(true);
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const rightToolbarTemplate = () => {
    return (
      <CustomDivTableActions>
        {btnPaddockPDF}
        <Button
          label="Export"
          icon={<Icon icon="UploadCloud" />}
          onClick={exportCSV}
          disabled={!selectedProducts || !selectedProducts.length}
        />
        {btnExportPDF}
        {/* <Button label="Print" icon="pi pi-print" className="p-button-help" onClick={btnExportPDF} disabled={!selectedProducts || !selectedProducts.length}/> */}
      </CustomDivTableActions>
    );
  };
  const toolbar = (
    <Toolbar
      className="mb-4"
      start={leftToolbarTemplate}
      end={rightToolbarTemplate}
    />
  );

  const dt = useRef(null);
  return (
    <div css={css(theme).classes}>
      {titleBlock}
      {statsBlock}

      <CustomDivTable
        table="showClasses"
        columns={columns}
        rows={filteredRows.data}
        reorderableColumns
        reorderableRows
        selectionMode="checkbox"
        selection={selectedProducts}
        onSelectionChange={(e) => {
          setSelectedProducts(e.value);
        }}
        rowGroupMode="subheader"
        groupBy="data.start_at"
        //sortOrder={0}
        //sortField="data.arena_name"
        onSelect={(it) => viewRiders(it)}
        //str
        onRowReorder={handleRowReorder}
        tableStyle={{ minWidth: "50rem" }}
        noTablerSort={true}
        //multiSortMeta={[{ field: "daily_order" }, { field: "start_at" }]}
        rowGroupHeaderTemplate={headerTemplate}
        filterKeys={["name", "class_number"]}
        showSearch={true}
        toolbar={toolbar}
        dtRef={dt}
        onRowClick={(e) => viewRiders(e.data)}
        expandableRowGroups
      />

      {/* {data && (
        <div className="buttonBar">
          {btnExportCSV}
          {btnExportPDF}
          {btnDuplicate}
          {btnCreate}
        </div>
      )} */}

      {modals}
    </div>
  );
}
