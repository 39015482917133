import React, { useState } from "react";
import { InputSwitch } from "primereact/inputswitch";
import { classNames } from "primereact/utils";

import css from "./form-input-switch.scss";

const FormInputSwitch = (props) => {
  const {
    readonly = false,
    isCard = true,
    direction = "column",
    checked = false,
    onInput = () => {},
  } = props;

  const label = checked ? "Yes" : "No";

  const isCardClass = isCard ? "card" : "";

  const switchClasses = classNames("inputSwitch", isCardClass);

  const styles = {
    flexDirection: direction === "column" ? "column" : "row",
    justifyContent: direction === "column" ? "center" : "flex-start",
  };

  return (
    <div className={switchClasses} style={{ ...styles }}>
      <InputSwitch
        disabled={readonly}
        checked={checked}
        onChange={(e) => onInput(e.value)}
      />
      {label}
    </div>
  );
};

export default FormInputSwitch;
