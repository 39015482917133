import {
  flexbox,
  fontbox,
  fullWidth,
  paddingX,
  paddingY,
  square,
  theme,
} from "emotion/utils";

export const css = {
  footer: {
    ...flexbox("column", "center", "flex-start"),
  },

  midFooter: {
    ...flexbox("column", "center", "center", 8),
    ...fontbox(theme.fonts.korolev, 16, 400, 16),
    ...fullWidth(),
    ...paddingY(36),
    backgroundColor: theme.colors.headerBg,
    borderBottom: `10px solid ${theme.colors.secondary}`,
    color: theme.colors.white,

    "@media screen and (min-width: 576px)": {
      ...fontbox(theme.fonts.korolev, 34, 400, 34),
    },

    "> img": {
      ...square(35),

      "@media screen and (min-width: 576px)": {
        ...square(100),
      },
    },
  },

  bottomFooter: {
    ...flexbox("column", "center", "center", 35),
    ...fontbox(theme.fonts.inter, 14, 400, 16),
    ...fullWidth(),
    ...paddingY(32),
    ...paddingX(16),
    color: theme.colors.headerBg,

    "@media screen and (min-width: 576px)": {
      ...flexbox("row", "center", "flex-end", 35),
      ...fontbox(theme.fonts.inter, 18, 400, 20),
      padding: 40,
    },

    copyright: {
      textAlign: "center",

      "@media screen and (min-width: 576px)": {
        marginRight: "auto",
        textAlign: "left",
      },
    },

    "> a": {
      width: "auto",

      "> div": {
        textWrap: "nowrap",
      },
    },
  },
};
