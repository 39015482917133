import { useMutation } from "@tanstack/react-query";
import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";

import Client from "client";
import { emailValid } from "validation/form";
import { FormErrorMessage } from "validation/form-error-message";

import FormDateInput from "components/form-date-input";
import FormSwitch from "components/form-switch";
import ConfirmationModal, {
  useConfirmationModal,
} from "confirmation-modal/confirmation-modal";
import CustomModal from "components/custom-modal/custom-modal";
import Button from "components/button/button";
import { FormElement } from "components/form-element/form-element";
import { fullWidth } from "emotion/utils";

export default function ClubModal({
  setShowModal = () => {},
  showModal = true,
  club,
  onSuccess = () => {},
}) {
  const {
    showModal: showConfirmationModal,
    handleExit,
    handleClose: handleConfirmationClose,
  } = useConfirmationModal();
  const [modalHeight, setModalHeight] = useState(0);
  const calculateModalHeight = () => {
    setTimeout(() => {
      const modalDialog = document.querySelector(".modal-content");
      if (modalDialog) {
        console.log("This Modal Height = " + modalDialog.offsetHeight + "px");
        setModalHeight(modalDialog.offsetHeight);
      }
    }, 10); // delay
  };
  useEffect(() => {
    if (showConfirmationModal) {
      calculateModalHeight();
    }
  }, [showConfirmationModal]);
  const toastRef = useRef();

  const { mutateAsync: submitRequest, isLoading } = useMutation({
    mutationKey: "create-edit-club",
    mutationFn: async (data) => {
      data.archivedAt = data.status;

      //TODO: This is a bit confusing - STATUS = TRUE actually means INACTIVE right now..
      data.status = data.status ? "INACTIVE" : "ACTIVE";

      if (club?.id) {
        return Client.put(`/clubs/${club.id}`, data);
      } else {
        return Client.post("/clubs", data);
      }
    },
    onSuccess: (values) => {
      setShowModal(false);
      onSuccess(values.body);
    },
    onError: (error) =>
      toastRef.current?.show({
        severity: "error",
        summary: "Error",
        detail: error.toString(),
        life: 3000,
      }),
  });

  const onSubmit = async (values) => {
    submitRequest(values);
  };

  const handleClose = () => [setShowModal(false)];
  const [isFormDirty, setIsFormDirty] = useState(false);
  const conditionalClose = () => {
    if (isFormDirty) {
      handleExit();
    } else {
      setShowModal(false);
    }
  };

  const clubToFormik = () => {
    return {
      name: club?.name || "",
      leaderName: club?.leader_name || "",
      leaderEmail: club?.leader_email || "",
      leaderPhone: club?.leader_phone || "",
      foundedAt: club?.founded_at || "",
      comments: club?.comments || "",
      //TODO: If we change FormSwitch CSS to say inactive when checked = false.. switch these. Right now, its backwards.

      status: club?.archived_at ? true : false,
      stableName: club?.stable_name || "",
      townState: club?.town_state || "",
      addedAt: club?.added_at || "",
      avatar: club?.avatar?.link || null,
    };
  };
  const initialValues = clubToFormik();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const headerTitle = `${club ? `Edit ${initialValues.name}` : "Add club"}`;

  const btnCancel = (
    <Button
      label="Cancel"
      onClick={() => setShowModal(false)}
      color="secondary"
      variant="outlined"
    />
  );

  const btnUpdateCreate = (formik) => (
    <Button
      label={club ? "Update club" : "Add club"}
      disabled={!(formik.isValid && formik.dirty)}
      onClick={(e) => {
        formik.handleSubmit(e);
        calculateModalHeight();
      }}
    />
  );

  const footerButtons = (formik) =>
    [btnCancel, btnUpdateCreate(formik)].map((btn, index) => (
      <React.Fragment key={index}>{btn}</React.Fragment>
    ));

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={Yup.object({
          name: Yup.string().label("Name").required(),
          foundedAt: Yup.string().label("Date Founded"),
          leaderPhone: Yup.string().label("Leader Phone"),
          leaderName: Yup.string().label("Leader Name").required(),
          leaderEmail: Yup.string()
            .label("Leader Email")
            .required()
            .test("leader_email", "Leaders email is required", (data) => {
              return !emailValid(data);
            }),
          comments: Yup.string().label("Comments"),
        })}
      >
        {(formik) => (
          <Form
            onChange={(evt) => {
              const clone = structuredClone(formik.values);
              clone[evt.target.name] = evt.target.value;
              setIsFormDirty(
                JSON.stringify(clone) !== JSON.stringify(clubToFormik()),
              );
            }}
          >
            <CustomModal
              headerTitle={headerTitle}
              footerButtons={footerButtons(formik)}
              onHide={conditionalClose}
            >
              <FormElement
                element="InputText"
                id="name"
                name="name"
                label="Club name"
                formik={formik}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                required
              />
              <FormErrorMessage name="name" formik={formik} />

              <FormDateInput
                name="foundedAt"
                label="Date Founded"
                formik={formik}
                size="48"
              />
              {/* {formik.touched.foundedAt && formik.errors.foundedAt && (
                <span className="form-error-msg">
                  {formik.errors.foundedAt}
                </span>
              )}
              {/* <FormElement
                element="Calendar"
                id="foundedAt"
                name="foundedAt"
                label="Date founded"
                formik={formik}
                onChange={formik.handleChange}
                value={formik.values["foundedAt"]}
              /> */}

              <FormSwitch
                name="status"
                label="Status"
                formik={formik}
                size="47"
              />

              <FormElement
                element="InputText"
                id="townState"
                name="townState"
                label="Town, State"
                formik={formik}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.townState}
                required
              />

              <FormElement
                element="InputText"
                id="stableName"
                name="stableName"
                label="Stable name"
                placeholder="What's the stable name?"
                formik={formik}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.stableName}
                required
              />

              <FormElement
                element="InputText"
                id="leaderName"
                name="leaderName"
                label="Club owner name"
                placeholder="First and last name"
                formik={formik}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.leaderName}
                required
              />
              <FormErrorMessage name="leaderName" formik={formik} />

              <FormElement
                element="InputText"
                id="leaderEmail"
                name="leaderEmail"
                label="Enter club owner email"
                placeholder="Enter owner email"
                formik={formik}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.leaderEmail}
                required
              />
              <FormErrorMessage name="leaderEmail" formik={formik} />

              <FormElement
                element="InputText"
                id="leaderPhone"
                name="leaderPhone"
                label="Club owner phone (optional)"
                placeholder="Enter owner phone including area code"
                formik={formik}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.leaderPhone}
              />

              {/* <FormFileUpload
                name="avatar"
                label="Cover Image"
                formik={formik}
                placeholder="Add Club Image"
              /> */}

              <FormElement
                element="InputTextarea"
                id="comments"
                name="comments"
                label="Description (optional)"
                formik={formik}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.comments}
              />
            </CustomModal>
          </Form>
        )}
      </Formik>

      <ConfirmationModal
        showModal={showConfirmationModal}
        handleExit={handleExit}
        handleClose={handleConfirmationClose}
        handleGoBack={handleConfirmationClose}
        setShowModal={setShowModal}
        modalHeight={modalHeight}
      />
    </>
  );
}
