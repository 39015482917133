import { flexbox, paddingX, posFixed } from "emotion/utils";

export const css = (theme) => {
  // console.log("Header, theme:", theme);

  return {
    header: {
      ...flexbox("column", "flex-start", "flex-start"),
      ...posFixed(0, null, 0, 0, 100),
      backgroundColor: theme.background.primary,
      borderRight: `1px solid ${theme.border.secondary}`,
      paddingTop: 32,
      width: 312,

      content: {
        ...flexbox("column", "flex-start", "flex-start", 24),

        "> div": {
          "&:first-of-type, &:nth-of-type(2)": {
            ...paddingX(24),
          },
        },
      },
    },
  };
};
