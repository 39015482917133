import React, { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import moment from "moment";

import { useQuery } from "hooks";

import PageSubHeader from "components/page-sub-header/page-sub-header";
import CustomDivTable from "components/custom-table/custom-div-table";
import CustomButton from "components/custom-button/custom-button";
import FormSwitch from "components/form-switch";

import ShowStat from "../show-stat";
import RidersFeesModal from "./show-class-riders-fees-modal";
import ShowClassRidersAddRegistrantModal from "./show-class-riders-add-registrant.modal";

import css from "./show-class-riders.module.scss";
import { Form, Formik } from "formik";
import ShowClassSplitModal from "../show-class-split-modal";
import FormInputSwitch from "components/form-input-switch/form-input-switch";
import Client from "client";
import CustomDivTableActions from "components/custom-table/custom-div-table-actions";
import ButtonIcon from "components/button-icon/button-icon";
import Icon from "components/icon/icon";

const ShowClassRiders = (targetLots) => {
  const [showFeesModal, setShowFeesModal] = useState(false);
  const [showAddRiderModal, setShowAddRiderModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const { classId } = useParams();

  // Data
  const { refetch } = useQuery(`/registrants/class-registrants/${classId}`, {
    onSuccess: (data) => {
      if (data) {
        setRows(data.map((r) => ({ ...r, lot_num: r.registrant.lot_num })));
      }
    },
  });

  const { data: classData } = useQuery(`/show-classes/${classId}`);

  const disableChanges =
    classData?.time_started || new Date(classData?.start_at) < new Date();

  const [rows, setRows] = useState([]);
  const [showSplitClass, setShowSplitClass] = useState(false);

  useEffect(() => {
    if (!targetLots) {
      return;
    }
    const num = Math.min(targetLots, rows?.length || 1);
    const newRows = rows?.map((row, i) => ({
      ...row,
      lot_num: (i % num) + 1,
    }));
    setRows(newRows);
  }, [targetLots]);

  const removeRider = async (val) => {
    const filtered = rows
      ?.filter((el) => el !== val)
      .map((r) => ({
        id: r.registrant.show_class_registration_id,
        lot_num: r.lot_num,
      }));
    try {
      await Client.put(`/registrants/class-registrants/${classId}`, filtered);
      refetch();
    } catch (err) {
      alert(err);
    }
  };

  const columns = [
    {
      name: "name",
      valFn: (it) => {
        return `${it.first_name} ${it.last_name}`;
      },
      display: "Rider name",
    },
    {
      name: "horse_name",
      valFn: (it) => it?.registrant?.animal.name,
      display: "Horse",
    },
    {
      name: "rider_num",
      valFn: (it) => it?.registrant?.rider_number,
      display: "Rider #",
    },
    {
      name: "club",
      valFn: (it) => {
        return it.club_name || "--";
      },
      display: "Club",
    },
    // {
    //   name: "score",
    //   valFn: (it) => {
    //     const result = classData.results.find(
    //       (m) => m.member_id === it.member_id,
    //     );
    //     return result?.score || "--";
    //   },
    //   display: "Score",
    // },
    // {
    //   name: "custodian_approved",
    //   // valFn: (it) => {
    //   //   return it.custodian_approved || 0;
    //   // },
    //   valFn: (it) => (
    //     <FormInputSwitch
    //       checked={!!it.custodian_waivers?.find((cw) => cw.custodian_signed_at)}
    //       readonly
    //       direction="row"
    //       isCard={false}
    //     />
    //   ),
    //   display: "Custodian Approved",
    // },
    {
      name: "payment_status",
      valFn: (it) => {
        return it.registrant.paid_verification_code
          ? "Paid"
          : it.registrant.paid_date
          ? "Paid (admin)"
          : "Unpaid";
      },
      display: "Payment status",
    },
    // {
    //   name: "actions",
    //   valFn: (it) => (
    //     <div
    //       style={{
    //         display: "flex",
    //         flexDirection: "row",
    //         gap: 8,
    //         justifyContent: "flex-end",
    //       }}
    //     >
    //       {it.registrant.paid_date ? (
    //         <></>
    //       ) : (
    //         <CustomButton
    //           label="Mark Paid"
    //           color="quarternary"
    //           type="outlined"
    //           align="unset"
    //           onClick={() => {
    //             handleMarkPaid(it);
    //           }}
    //           isInTable
    //           fs={10}
    //         />
    //       )}
    // <CustomButton
    //   label="Remove"
    //   color="tertiary"
    //   type="outlined"
    //   align="unset"
    //   disabled={disableChanges}
    //   onClick={() => {
    //     removeRider(it);
    //   }}
    //   isInTable
    //   fs={10}
    // />
    //     </div>
    //   ),
    //   display: "",
    //   actionCol: true,
    // },
    {
      name: "actions",
      valFn: (it) => (
        <CustomDivTableActions>
          <ButtonIcon
            icon={<Icon icon="Trash" />}
            disabled={disableChanges}
            onClick={() => {
              removeRider(it);
            }}
          />
        </CustomDivTableActions>
      ),
      display: "",
      actionCol: true,
    },
  ];

  const contentHeader = (
    <div className={css.contentHeader}>
      <ShowStat label="Total Riders" entry={rows?.length || 0} />
    </div>
  );

  const headerTemplate = (data) => {
    return <div>Lot # {data?.data.lot_num || "--"}</div>;
  };

  const handleRowReorder = (e) => {
    let draggedRow = rows[e.dragIndex];
    let droppedRow = rows[e.dropIndex];

    draggedRow.lot_num = droppedRow.lot_num;
    const reorderedRows = [...rows];
    setRows(reorderedRows);
  };

  const ridersTable = (
    <CustomDivTable
      columns={columns}
      rows={rows}
      rowGroupMode="subheader"
      groupBy="data.lot_num"
      reorderableColumns
      selectionMode="single"
      // noTableSort={true}
      sortField="data.lot_num"
      tableStyle={{ minWidth: "50rem" }}
      rowGroupHeaderTemplate={headerTemplate}
      //multiSortMeta={[{ field: "name" }, { field: "lot_num" }]}
      // enableReinitialize={false}
      reorderableRows
      sortOrder={1}
      onRowReorder={handleRowReorder}
      noTableSort={true}
      multiSortMeta={[{ field: "name" }, { field: "lot_num" }]}
      emptyMessage="No riders found"
    />
  );

  const handleAddRider = () => {
    setShowAddRiderModal(true);
  };

  const handleMarkPaid = async (it) => {
    //mark paid
    const postData = [{ id: it.registrant.show_class_registration_id }];
    try {
      const showClassRiders = await Client.post(
        `/registrants/class-registrants-payments/${classId}`,
        postData,
      );
      refetch();
    } catch (err) {
      alert(err);
    }
  };

  //const btnCreate = (
  // <CustomButton
  //   label="+ Add a Rider"
  //   disabled={disableChanges}
  //   onClick={handleAddRider}
  // />
  //);

  const btnPrint = (
    <CustomButton
      label="Print"
      disabled={disableChanges}
      onClick={() => {
        window.print();
      }}
    />
  );

  const disabledTxt = disableChanges ? (
    <span>Class has started, changes have been disabled</span>
  ) : null;

  return (
    <>
      {/* {contentHeader} */}
      {/* {disabledTxt} */}
      {ridersTable}

      {/* <div className="buttonBar">
        <CustomButton
          label="Split"
          align="left"
          disabled={disableChanges}
          onClick={() => setShowSplitClass(true)}
        />
        {btnPrint}
      </div> */}

      {showAddRiderModal && (
        <ShowClassRidersAddRegistrantModal
          registeredRiders={rows}
          setShowModal={setShowAddRiderModal}
          showClassId={classId}
          onSuccess={refetch}
        />
      )}

      {showSplitClass && (
        <ShowClassSplitModal
          setShowModal={setShowSplitClass}
          onSuccess={() => refetch}
          showClass={classData}
          registeredRiders={rows}
        />
      )}

      {showRemoveModal && <p>remove</p>}
    </>
  );
};

export default ShowClassRiders;
