/** @jsxImportSource @emotion/react */

import React from "react";

import MenuItem from "./menu-item";

import { css } from "./css";
import { useTheme } from "@emotion/react";

function Menu(props) {
  const { activePage, hideMenu, pages = [] } = props;

  const theme = useTheme();

  const menuItem = (page) => <MenuItem page={page} activePage={activePage} />;

  return (
    <ul css={css(theme).mainMenu}>
      {!hideMenu &&
        pages.map((page, index) => (
          <React.Fragment key={index}>{menuItem(page)}</React.Fragment>
        ))}
    </ul>
  );
}

export default Menu;
