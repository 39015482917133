import {
  borderRadius,
  flexbox,
  fontbox,
  fullWidth,
  marginAuto,
  marginY,
  paddingX,
  posAbsolute,
  posRelative,
  square,
  theme,
  transition,
} from "emotion/utils";

export const css = {
  section: (bgImg) => ({
    ...flexbox("column", "flex-start", "space-between"),
    ...fullWidth(),
    ...paddingX(16),
    ...posRelative(),
    backgroundImage: `url(${bgImg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top right",
    height: "100vh",
    maxHeight: 500,
    paddingTop: 32,
    paddingBottom: 48,
    overflow: "hidden",
    textAlign: "left",

    "@media screen and (min-width: 576px)": {
      ...paddingX(38),
      paddingTop: 32,
      paddingBottom: 64,
    },
    "@media screen and (min-width: 768px)": {
      height: "90vh",
      minHeight: 800,
    },
  }),

  content: {
    ...flexbox("column", "flex-start", "flex-start", 15),
    ...fullWidth(),
    ...marginAuto(),
    color: theme.colors.white,
    maxWidth: 1400,

    "@media screen and (min-width: 576px)": {
      gap: 30,
    },
    "@media screen and (min-width: 768px)": {
      ...marginY("auto"),
    },

    h1: {
      ...fontbox(theme.fonts.korolev, 24, 700, 30),
      ...posRelative(),

      "@media screen and (min-width: 576px)": {
        ...fontbox(theme.fonts.korolev, 42, 700, 48),
      },
      "@media screen and (min-width: 768px)": {
        ...fontbox(theme.fonts.korolev, 52, 700, 58),
      },
      "@media screen and (min-width: 992px)": {
        ...fontbox(theme.fonts.korolev, 66, 700, 74),
      },
    },

    p: {
      ...fontbox(theme.fonts.inter, 15, 400, 15 * 1.5),
      ...posRelative(),

      "@media screen and (min-width: 576px)": {
        ...fontbox(theme.fonts.inter, 24, 400, 24 * 1.5),
        ...fullWidth(),
        flex: 1,
        maxWidth: 623,
      },
    },
  },

  header: {
    ...flexbox("row", "center", "space-between", 20),
    ...fullWidth(),
  },

  logo: {
    ...flexbox("row", "center", "flex-start", 20),

    svg: {
      ...square(34),

      "@media screen and (min-width: 576px)": {
        ...square(44),
      },
      "@media screen and (min-width: 768px)": {
        ...square(50),
      },
    },

    p: {
      ...fontbox(theme.fonts.korolev, 30, 700, 34),
      ...posRelative(4),
      color: theme.colors.white,
      display: "none",
      paddingLeft: 15,

      "@media screen and (min-width: 576px)": {
        display: "block",
      },

      "&::before": {
        ...posAbsolute(0, null, 6, 0),
        backgroundColor: `${theme.colors.white}75`,
        content: `""`,
        transform: "translate(-50%, 0)",
        width: 2,
      },
    },
  },

  btnLogin: {
    ...borderRadius(4),
    ...flexbox("row", "center", "center"),
    ...fontbox(theme.fonts.inter, 12, 700, 12),
    ...paddingX(16),
    ...transition(),
    border: `2px solid ${theme.colors.white}`,
    boxSizing: "border-box",
    color: theme.colors.white,
    height: 34,
    zIndex: 1,

    "@media screen and (min-width: 576px)": {
      ...fontbox(theme.fonts.inter, 16, 700, 16),
      height: 44,
    },
    "@media screen and (min-width: 768px)": {
      height: 50,
      width: 140,
    },
    "@media screen and (min-width: 992px)": {
      width: 190,
    },

    "&:hover": {
      backgroundColor: `${theme.colors.white}25`,
      color: theme.colors.white,
    },
  },
};
