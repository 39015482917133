/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { ButtonGroup } from "primereact/buttongroup";
import Button from "../button/button";

import { css } from "./css";

export default function FilterBtnGroup(props) {
  const {
    options = [],
    objectOptions,
    activeBtnGroup = "All",
    setActiveBtnGroup = () => {},
    style,
  } = props;

  const theme = useTheme();

  let btnEls = null;
  if (objectOptions) {
    btnEls = objectOptions.map((option) => {
      const opt = JSON.parse(option);
      const buttonKey = opt.id;
      return (
        <Button
          key={buttonKey}
          label={opt?.value}
          variant={buttonKey === activeBtnGroup ? "active" : null}
          onClick={() => setActiveBtnGroup(buttonKey)}
        />
      );
    });
  } else {
    btnEls = options.map((opt) => {
      const buttonKey = opt.replace(" ", "-").toLowerCase();
      return (
        <Button
          label={opt}
          key={buttonKey}
          className={buttonKey === activeBtnGroup ? "active" : null}
          onClick={() => setActiveBtnGroup(buttonKey)}
        />
      );
    });
  }

  return (
    <ButtonGroup css={css(theme)}>
      <Button
        label="All"
        onClick={() => setActiveBtnGroup("all")}
        className={activeBtnGroup === "all" ? "active" : null}
      />
      {btnEls}
    </ButtonGroup>
  );
}
