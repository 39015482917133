import {
  appColors,
  appFonts,
  borderRadius,
  flexbox,
  fontbox,
  fullWidth,
  paddingX,
  // theme,
} from "emotion/utils";

export const css = (modalSize, theme) => ({
  ...borderRadius(12),
  ...flexbox("column", "flex-start", "flex-start"),
  ...modalSize,
  backgroundColor: appColors?.grays[0],
  boxShadow: `0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)`,
  maxHeight: "94%",

  div: {
    // Header
    "&[data-pc-section='header']": {
      ...flexbox("row", "center", "flex-start", 16),
      ...paddingX(24),
      paddingTop: 24,
      paddingBottom: 20,
    },

    // Header Title
    "&[data-pc-section='headertitle']": {
      ...fontbox(appFonts?.inter, 18, 600, 28),
      color: appColors?.grays[900],
    },

    // Header Icons
    "&[data-pc-section='headericons']": {
      marginLeft: "auto",

      // Close Button
      "[data-pc-section='closebutton']": {
        ...paddingX(8),
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      },
    },

    // Content
    "&[data-pc-section='content']": {
      ...flexbox("row", "stretch", "space-between", 16, "wrap"),
      ...paddingX(24),
      flex: 1,
      overflowY: "scroll",
      paddingBottom: 35,

      p: {
        ...fontbox(appFonts?.inter, 14, 400, 20),
        color: appColors?.grays?.[600],

        "&.directions": {
          ...fontbox(appFonts?.inter, 14, 500, 20),
          ...fullWidth(),
          color: appColors.grays[1],
        },
      },
    },

    // Footer
    "&[data-pc-section='footer']": {
      ...flexbox("row", "center", "flex-end", 12),
      ...paddingX(24),
      paddingTop: 32,
      paddingBottom: 24,

      "> button, > a": {
        flex: 1,
      },
    },
  },
});
