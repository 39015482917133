/** @jsxImportSource @emotion/react */

import { useState } from "react";

const { Outlet, useLocation } = require("react-router");

const Setup = () => {
  const loc = useLocation();
  const [activePage] = useState(loc.pathname.slice(1));

  return <Outlet context={[activePage]} />;
};

export default Setup;
