/** @jsxImportSource @emotion/react */

import { useTheme } from "@emotion/react";
import {
  appColors,
  appFonts,
  borderRadius,
  fontbox,
  paddingX,
  paddingY,
} from "emotion/utils";
import { capitalizeFirstLetter } from "utils";

const Tag = ({ tag }) => {
  const theme = useTheme();

  const createTagStyle = (color) => ({
    backgroundColor: appColors?.[color]?.[50],
    border: `1px solid ${appColors?.[color]?.[200]}`,
    color: appColors?.[color]?.[700],
  });

  const tagStyle = {
    Active: createTagStyle("greens"),
    active: createTagStyle("greens"),
    Yes: createTagStyle("greens"),
    yes: createTagStyle("greens"),
    Paid: createTagStyle("greens"),
    paid: createTagStyle("greens"),
    "Paid (admin)": createTagStyle("greens"),
    "paid (admin)": createTagStyle("greens"),
    No: createTagStyle("blues"),
    no: createTagStyle("blues"),
    Unpaid: createTagStyle("reds"),
    unpaid: createTagStyle("reds"),
    Inactive: createTagStyle("blues"),
    inactive: createTagStyle("blues"),
    default: createTagStyle("blues"),
  };

  const getTagStyle = (tag) => {
    return tagStyle[tag] || tagStyle.default;
  };

  return (
    <span css={[css(theme), getTagStyle(tag)]}>
      {capitalizeFirstLetter(tag)}
    </span>
  );
};

const css = (theme) => ({
  ...borderRadius(15),
  ...paddingX(10),
  ...paddingY(2),
  ...fontbox(appFonts.inter, 14, 500, 20),
  display: "inline-block",
  height: 24,
});

export default Tag;
