/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import { useLocation } from "react-router-dom";

import { Link } from "react-router-dom";

import { css } from "./css";

const Submenu = ({ page }) => {
  const theme = useTheme();

  const loc = useLocation();

  const linkEls = page?.children?.map((child) => {
    const activePage = loc.pathname === child.linkTo;

    return (
      <li key={child.linkTo} css={css(theme).submenu().li}>
        <Link to={child.linkTo} css={css(theme).submenu().li(activePage).link}>
          {child.title}
        </Link>
      </li>
    );
  });

  return <ul css={css(theme).submenu()}>{linkEls}</ul>;
};

export default Submenu;
