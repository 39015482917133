/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import { marginY } from "emotion/utils";

export default function Divider() {
  const theme = useTheme();

  return <div css={css(theme)} />;
}

const css = (theme) => ({
  ...marginY(16),
  height: 1,
  width: "100%",
  backgroundColor: theme?.border?.secondary,
});
