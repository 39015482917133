/**@jsxImportSource @emotion/react */
import React from "react";
import * as Yup from "yup";

import { FormElement } from "components/form-element/form-element";

import { useQuery } from "hooks/use-query";

export const initialValues = {
  name: "",
  preReqAwardNumber: 3,
  preReqAward: { name: "None", code: null },
  preReqClassType: { name: "None", code: null },
  alias: "",
  description: "",
};

export const existingValues = (data) => ({
  name: data.name,
  preReqAwardNumber: data.prereq_base_award_number,
  preReqAward: data.prereq_base_award
    ? { name: data.prereq_base_award.name, code: data.prereq_base_award.id }
    : { name: "None", code: null },
  preReqClassType: data.prereq_event_type
    ? { name: data.prereq_event_type.name, code: data.prereq_event_type.id }
    : { name: "None", code: null },
  alias: data.alias,
  medal: data.medal_id,
  description: data.description,
});

export const validationSchema = Yup.object({
  name: Yup.string().label("Level Name").required(),
  alias: Yup.string().label("Abbreviation").min(1).max(3).required(),
});

export const postUrl = `/class-levels`;

export const body = (data) => ({
  ...data,
  preReqAward: data.preReqAward?.value || null,
  preReqClassType: data.preReqClassType?.value || null,
});

export default function ClassLevelFrom(props) {
  const { formik } = props;

  const { data: classTypes } = useQuery("/event-types");

  const { data: medals } = useQuery("/base-awards?medals=true");

  const { data: awards } = useQuery("/base-awards?primary=false");

  const prereqOptions = [
    { name: "None", id: null },
    ...(medals?.data || []),
    ...(awards?.data || []),
  ].map((a) => ({ label: a.name, value: a.id }));

  const medalOptions = medals?.data?.map((m) => ({
    value: m.id,
    label: m.name,
  }));
  medalOptions?.unshift({ value: -1, label: "None" });

  const prereqTypeOptions = [
    { name: "None", id: null },
    ...(classTypes?.data || []),
  ].map((a) => ({ label: a.name, value: a.id }));

  return (
    <>
      <FormElement
        element="InputText"
        id="name"
        name="name"
        label="Level name"
        formik={formik}
        onBlur={formik?.handleBlur}
        onChange={formik?.handleChange}
        value={formik?.values?.name || ""}
        required
        size="54"
      />

      <FormElement
        element="InputText"
        id="alias"
        name="alias"
        label="Abbreviation (up to 3 characters)"
        formik={formik}
        onBlur={formik?.handleBlur}
        onChange={formik?.handleChange}
        value={formik?.values?.alias}
        required
        size="43"
      />

      <FormElement
        element="Dropdown"
        id="preReqClassType"
        name="preReqClassType"
        label="Pre-requisite class type (optional)"
        placeholder="Select a class type"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.preReqClassType}
        options={prereqTypeOptions}
        size="48"
      />

      <FormElement
        element="Dropdown"
        id="preReqAward"
        name="preReqAward"
        label="Pre-requisite awards (optional)"
        placeholder="Select award"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.preReqAward}
        options={prereqOptions}
        size="48"
      />

      <FormElement
        element="InputText"
        id="preReqAwardNumber"
        name="preReqAwardNumber"
        label="Pre-requisite awards number (optional)"
        onBlur={formik.handleBlur}
        onChange={(v) => {
          if (!v.target.value) {
            formik.setFieldValue("preReqAwardNumber", "");
            return;
          }

          const n = parseInt(v.target.value);
          if (isNaN(n)) {
            formik.setFieldValue("preReqAwardNumber", "");
            return;
          }

          formik.setFieldValue("preReqAwardNumber", n);
        }}
        value={formik.values.preReqAwardNumber?.toString() || ""}
        size="48"
      />

      <FormElement
        element="Dropdown"
        id="medal"
        name="medal"
        label="A medals level? (optional)"
        placeholder="Select a medal"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.medal}
        options={medalOptions}
        size="48"
      />

      <FormElement
        element="InputTextarea"
        id="description"
        name="description"
        label="Description (optional)"
      />
    </>
  );
}
