/**@jsxImportSource @emotion/react */
import React from "react";
import * as Yup from "yup";

import { FormElement } from "components/form-element/form-element";

export const initialValues = {
  name: "",
  description: "",
  alias: "",
};

export const existingValues = (data) => ({
  name: data.name,
  description: data.description,
  alias: data.alias,
});

export const validationSchema = Yup.object({
  name: Yup.string().label("Modifier name").required(),
  description: Yup.string().label("Description").optional(),
  alias: Yup.string().label("Abbreviation").min(1).max(3).required(),
});

export const postUrl = `/modifiers`;

export const body = (data) => ({ ...data });

export default function ClassModifierForm(props) {
  const { formik } = props;

  return (
    <>
      <FormElement
        element="InputText"
        id="name"
        name="name"
        label="Modifier name"
        formik={formik}
        onBlur={formik?.handleBlur}
        onChange={formik?.handleChange}
        value={formik?.values?.name || ""}
        required
        size="48"
      />

      <FormElement
        element="InputText"
        id="alias"
        name="alias"
        label="Abbreviation (up to 3 characters)"
        formik={formik}
        onBlur={formik?.handleBlur}
        onChange={formik?.handleChange}
        value={formik?.values?.alias}
        required
        size="48"
      />

      <FormElement
        element="InputTextarea"
        id="description"
        name="description"
        label="Description (optional)"
        onBlur={formik?.handleBlur}
        onChange={formik?.handleChange}
        value={formik?.values?.description}
      />
    </>
  );
}
