/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import PropTypes from "prop-types";
import { createNumberMask } from "text-mask-addons";
import MaskedInput from "react-text-mask";

import { FormErrorMessage } from "validation/form-error-message";

import { cssFormElements } from "../css";

const defaultMaskOptions = {
  prefix: "$",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

const CurrencyInput = ({ required, formik, size, maskOptions, ...data }) => {
  const theme = useTheme();

  const elWidth = `${size || "100"}%`;

  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  });

  const getLabel = data?.label && (
    <label>
      {data.label}
      {required && <span>*</span>}
    </label>
  );

  const elProps = {
    ...Object.keys(data).reduce((acc, key) => {
      if (key !== "data") {
        acc[key] = data[key];
      }
      return acc;
    }, {}),
  };

  const getElement = <MaskedInput mask={currencyMask} {...elProps} />;

  return (
    <div css={cssFormElements(theme, elWidth).inputNumber}>
      {getLabel}
      {getElement}
      {required && <FormErrorMessage name="name" formik={data?.formik} />}
    </div>
  );
};

CurrencyInput.defaultProps = {
  required: false,
  inputMode: "numeric",
  maskOptions: {},
};

CurrencyInput.propTypes = {
  inputmode: PropTypes.string,
  maskOptions: PropTypes.shape({
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    includeThousandsSeparator: PropTypes.bool,
    thousandsSeparatorSymbol: PropTypes.string,
    allowDecimal: PropTypes.bool,
    decimalSymbol: PropTypes.string,
    decimalLimit: PropTypes.string,
    requireDecimal: PropTypes.bool,
    allowNegative: PropTypes.bool,
    allowLeadingZeroes: PropTypes.bool,
    integerLimit: PropTypes.number,
  }),
};

export default CurrencyInput;
