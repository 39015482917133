import {
  appColors,
  appFonts,
  borderRadius,
  flexbox,
  fontbox,
  fullWidth,
  paddingX,
  paddingXY,
  paddingY,
  posAbsolute,
  posRelative,
} from "emotion/utils";

const labelStyles = (theme) => ({
  ...fontbox(appFonts.inter, 12, 500, 18),
  color: theme?.text?.secondary,
  textAlign: "left",

  "@media screen and (min-width: 576px)": {
    ...fontbox(appFonts.inter, 14, 500, 20),
  },

  "> span": {
    color: appColors?.reds?.base,
  },
});

export const cssFormElements = (theme, elWidth) => {
  // console.log("form elements theme:", theme);

  return {
    ...flexbox("column", "flex-start", "flex-start", 3),
    ...fullWidth(),
    maxWidth: `${elWidth}`,

    "@media screen and (min-width: 576px)": {
      gap: 6,
    },

    "> *, input": {
      ...fullWidth(),
    },

    label: {
      ...labelStyles(theme),
    },

    // Input
    input: {
      ...borderRadius(8),
      ...fontbox(appFonts.inter, 14, 400, 22),
      ...paddingX(14),
      ...paddingY(10),
      boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",

      "@media screen and (min-width: 576px)": {
        ...fontbox(appFonts.inter, 16, 400, 24),
      },
    },

    // Input Number
    inputNumber: {
      ...fullWidth(),
      maxWidth: `${elWidth}`,

      "@media screen and (min-width: 576px)": {
        ...fontbox(appFonts.inter, 16, 400, 24),
        gap: 6,
      },

      label: {
        ...labelStyles(theme),
      },

      input: {
        ...borderRadius(8),
        ...fontbox(appFonts.inter, 14, 400, 22),
        ...fullWidth(),
        ...paddingX(14),
        ...paddingY(10),
        border: `1px solid ${theme?.border?.primary}`,
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        color: theme?.text?.placeholder,

        "@media screen and (min-width: 576px)": {
          ...fontbox(appFonts.inter, 16, 400, 24),
        },
      },
    },

    // Icon Field
    iconField: {
      "> div": {
        ...posRelative(),

        "> div": {
          ...posAbsolute("50%", null, null, 14),
          transform: "translateY(-50%)",
        },

        input: {
          paddingLeft: 42,
        },
      },
    },

    // Textarea
    textarea: {
      ...borderRadius(8),
      ...fontbox(appFonts.inter, 14, 400, 22),
      ...paddingX(14),
      ...paddingY(10),
      boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
      minHeight: 112,

      "@media screen and (min-width: 576px)": {
        ...fontbox(appFonts.inter, 16, 400, 24),
      },
    },

    // Checkbox Group
    checkboxGroup: {
      ...flexbox("row", "flex-start", "flex-start", 12, "wrap"),

      "> div": {
        ...fullWidth(),
        maxWidth: `48%`,

        "> label": {
          ...fontbox(appFonts, 14, 500, 20),
          color: theme?.text?.secondary,
        },
      },

      label: {
        // ...flexbox("column", "flex-start", "flex-start"),
        // ...fontbox(appFonts.inter, 14, 600, 20),
        // ...fullWidth(),
        // color: theme?.text?.secondary,

        // title: {
        //   ...flexbox("row", "center", "flex-start", 4),
        // },
        ...fullWidth(),
        ...labelStyles(theme),
      },
    },

    // Radio Button Group
    radioBtnGroup: {
      ...flexbox("column", "flex-start", "flex-start", 12),

      "> div": {
        ...borderRadius(12),
        ...fullWidth(),
        ...paddingXY(16),
        border: `1px solid ${theme?.border?.secondary}`,
      },
    },

    // Radio
    radio: {
      ...flexbox("row", "flex-start", "flex-start", 8),

      label: {
        ...flexbox("column", "flex-start", "flex-start"),
        ...fontbox(appFonts.inter, 14, 500, 20),
        color: theme?.text?.secondary,

        title: {
          ...flexbox("row", "center", "flex-start", 4),
        },
      },

      helpertext: {
        color: theme?.text?.tertiary,
        fontWeight: 400,
      },
    },

    // Error
    error: {
      ...fontbox(appFonts.inter, 12, 500, 18),
      color: appColors?.reds?.base,
      marginLeft: "auto",

      "@media screen and (min-width: 576px)": {
        ...fontbox(appFonts.inter, 14, 500, 20),
      },
    },

    // Calendar
    calendar: {
      ...flexbox("column", "flex-start", "flex-start", 3),
      ...fullWidth(),
      maxWidth: `${elWidth}`,

      "@media screen and (min-width: 576px)": {
        gap: 6,
      },

      label: {
        // ...flexbox("column", "flex-start", "flex-start"),
        ...fontbox(appFonts.inter, 14, 500, 20),
        color: theme?.text?.secondary,

        "> span": {
          color: appColors?.reds?.base,
        },
      },

      "> span": {
        ...borderRadius(8),
        ...flexbox("row", "stretch", "flex-start", 0),
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",

        "> input": {
          ...borderRadius("0 8px 8px 0"),
          ...fontbox(appFonts.inter, 14, 400, 22),
          ...paddingY(10),
          border: `solid ${theme?.border?.primary}`,
          borderWidth: "1px 1px 1px 0",
          color: theme?.text?.placeholder,
          order: 1,
          paddingLeft: 4,
          paddingRight: 14,

          "@media screen and (min-width: 576px)": {
            ...fontbox(appFonts.inter, 16, 400, 24),
          },
        },

        "> button": {
          ...borderRadius("8px 0 0 8px"),
          ...paddingY(10),
          background: appColors?.grays[0],
          border: `solid ${theme?.border?.primary}`,
          borderWidth: "1px 0 1px 1px",
          order: 0,
          paddingLeft: 14,

          "> svg path": {
            fill: theme?.text?.secondary,
          },
        },
      },
    },

    // Dropdown
    dropdown: {
      ...flexbox("column", "flex-start", "flex-start", 3),
      ...fullWidth(),
      maxWidth: `${elWidth}`,

      "@media screen and (min-width: 576px)": {
        gap: 6,
      },

      label: {
        ...labelStyles(theme),
      },

      ".p-dropdown": {
        ...borderRadius(8),
        ...fontbox(appFonts.inter, 14, 400, 22),
        ...paddingX(14),
        ...paddingY(10),
        border: `1px solid ${theme?.border?.primary}`,
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",

        "&-label": {
          padding: 0,
        },

        "&-trigger": {
          width: "auto",
        },
      },
    },

    // Input Group
    inputGroup: {
      ...flexbox("column", "flex-start", "flex-start", 3),
      ...fullWidth(),
      maxWidth: `${elWidth}`,

      "@media screen and (min-width: 576px)": {
        gap: 6,
      },

      label: {
        ...labelStyles(theme),
      },

      ".p-inputgroup-addon": {
        ...borderRadius("8px 0 0 8px"),
        ...fontbox(appFonts.inter, 14, 400, 22),
        ...paddingX(14),
        ...paddingY(10),
      },

      input: {
        ...borderRadius("0 8px 8px 0"),
      },
    },

    // Input Button Group
    inputButtonGroup: {
      ...flexbox("column", "flex-start", "flex-start", 3),
      ...fullWidth(),
      maxWidth: `${elWidth}`,

      "@media screen and (min-width: 576px)": {
        gap: 6,
      },

      label: {
        ...labelStyles(theme),
      },

      ".p-inputgroup-addon": {
        ...borderRadius("8px 0 0 8px"),
        ...fontbox(appFonts.inter, 14, 400, 22),
        ...paddingX(14),
        ...paddingY(10),
      },

      input: {
        ...borderRadius("8px 0 0 8px"),
        borderWidth: "1px 0 1px 1px",
      },

      button: {
        ...borderRadius("0 8px 8px 0!important"),
      },
    },

    // Color Picker
    colorPicker: {
      ...borderRadius(8),
      ...fullWidth(),
      ...paddingXY(10),
      border: `1px solid ${theme?.border?.primary}`,

      ".sketch-picker": {
        ...paddingXY("0!important"),
        backgroundColor: "transparent!important",
        border: "none",
        boxShadow: "none!important",
        width: "100%!important",

        ">div": {
          "&:first-of-type": {
            ...borderRadius(6),
            height: 150,
            paddingBottom: "0!important",
          },

          "&:nth-of-type(2)": {
            ">div": {
              "&:first-of-type": {
                order: 1,
              },

              "&:nth-of-type(2)": {
                marginRight: 4,
                marginLeft: "0!important",
                order: 0,
              },
            },
          },
        },
      },
    },

    // Form List
    formList: {
      label: {
        ...labelStyles(theme),
      },

      actions: {
        ...fullWidth(),
        maxWidth: "none",
        // width: "75%",

        inputDivs: {
          ...flexbox("column", "flex-start", "flex-end", 20),

          inputEl: {},
        },
      },

      select: {
        ...borderRadius(8),
        ...fontbox(appFonts.inter, 14, 400, 22),
        ...fullWidth(),
        ...paddingX(14),
        ...paddingY(10),
        border: `1px solid ${theme?.border?.primary}`,
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        color: theme?.text?.placeholder,

        "@media screen and (min-width: 576px)": {
          ...fontbox(appFonts.inter, 16, 400, 24),
        },
      },
    },

    // File Upload
    fileUpload: {
      ...flexbox("column", "flex-start", "flex-start", 3),
      ...fullWidth(),
      maxWidth: `${elWidth}`,

      "@media screen and (min-width: 576px)": {
        gap: 6,
      },

      label: {
        ...labelStyles(theme),
      },

      "> div": {
        flex: 1,
        //   ...flexbox("row", "flex-start", "flex-start", 3),
        //   ...fullWidth(),
        //   maxWidth: `${elWidth}`,

        //   "> label": {
        //     ...borderRadius(8),
        //     ...fontbox(appFonts.inter, 14, 400, 22),
        //     ...paddingX(14),
        //     ...paddingY(10),
        //     border: `1px solid ${theme?.border?.primary}`,
        //     boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        //     cursor: "pointer",
        //     display: "flex",
        //     justifyContent: "center",
        //     alignItems: "center",
        //     width: "100%",

        //     "&:hover": {
        //       backgroundColor: theme?.background?.secondary,
        //     },
        //   },

        //   "> input": {
        //     display: "none",
        //   },
      },
    },
  };
};
