import React, { useEffect, useState } from "react";
import moment from "moment";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Client from "client";

import css from "./show.module.scss";
import CustomDivTable from "components/custom-table/custom-div-table";
import CustomButton from "components/custom-button/custom-button";
import CustomModal from "components/custom-modal/custom-modal";

export default function ClassSplitModal({
  setShowModal = () => {},
  showClass,
  registeredRiders,
  onSuccess = () => {},
}) {
  const existingLotNum =
    Object.keys(
      registeredRiders.reduce((map, r) => {
        map[r.registrant.lot_num] = true;
        return map;
      }, {}),
    )?.length || 1;

  const [targetLots, setTargetLots] = useState(existingLotNum);
  const [rows, setRows] = useState(
    registeredRiders.map((r) => ({ ...r, lot_num: r.registrant.lot_num })),
  );

  useEffect(() => {
    if (showClass?.num_lots) {
      setTargetLots(showClass?.num_lots);
    }
  }, [showClass]);

  const handleClose = () => [setShowModal(false)];

  const splitClass = (e) => {
    const targetLots = e.target.value;
    if (!targetLots) {
      setTargetLots("");
      return;
    }

    const num = Math.min(e.target.value, rows?.length || 1);

    const newRows = rows.map((row, i) => ({
      ...row,
      lot_num: (i % num) + 1,
    }));

    setTargetLots(num.toString());
    setRows(newRows);
  };

  const handleRowReorder = (e) => {
    let draggedRow = rows[e.dragIndex];
    let droppedRow = rows[e.dropIndex];

    draggedRow.lot_num = droppedRow.lot_num;
    const reorderedRows = [...rows];
    setRows(reorderedRows);
  };

  const saveLots = async () => {
    try {
      await Client.put(
        `/registrants/class-registrants/${showClass.id}`,
        rows.map((r) => ({
          id: r.registrant.show_class_registration_id,
          lot_num: r.lot_num,
        })),
      );

      handleClose();
      onSuccess();
    } catch (err) {
      alert(err);
    }
  };

  const columns = [
    {
      name: "name",
      valFn: (it) => {
        return it.first_name + " " + it.last_name || "--";
      },
      display: "Rider",
    },
    {
      name: "birthday",
      valFn: (it) => {
        return it.birth_at ? moment().diff(it.birth_at, "years") : "--";
      },
      display: "Age",
    },
    {
      name: "horse",
      valFn: (it) => {
        return it.registrant.animal.name || "--";
      },
      display: "Riding",
    },
    {
      name: "club",
      valFn: (it) => {
        return it.club_name || "--";
      },
      display: "Club",
    },
    {
      name: "lot_num",
      valFn: (it) => {
        return it.lot_num || "--";
      },
      display: "Lot",
    },
  ];

  const contentHeader = (
    <div className={css.contentHeader}>
      <div className={css.contentHeaderLeft}>
        <h3>{showClass.long_name}</h3>
        <p>Total Riders: {rows?.length}</p>
      </div>

      <div className={css.contentHeaderRight}>
        <p>Split into</p>
        <input
          type="number"
          value={targetLots}
          onChange={(e) => splitClass(e)}
          min={1}
          max={rows?.length}
        />
        <p>Lot{targetLots > 1 ? "s" : <span>&nbsp;&nbsp;</span>}</p>
      </div>
    </div>
  );

  const headerTemplate = (data) => {
    return <div>Lot # {data.lot_num}</div>;
  };

  const btnSubmit = (
    <CustomButton
      label="Submit"
      onClick={() => {
        saveLots();
      }}
    />
  );

  const footerButtons = [btnSubmit];

  return (
    <CustomModal
      headerTitle="Class Split"
      footerButtons={footerButtons}
      onHide={handleClose}
      size="lg"
      // className={css.modalSplitClass}
    >
      {contentHeader}

      <CustomDivTable
        columns={columns}
        rows={rows}
        enableReinitialize={false}
        reorderableColumns
        reorderableRows
        selectionMode="single"
        rowGroupMode="subheader"
        groupBy="lot_num"
        sortOrder={1}
        sortField="lot_num"
        onRowReorder={handleRowReorder}
        noTableSort={true}
        tableStyle={{ minWidth: "50rem" }}
        //multiSortMeta={[{ field: "name" }, { field: "lot_num" }]}
        rowGroupHeaderTemplate={headerTemplate}
      />
    </CustomModal>
  );
}
