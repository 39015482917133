/** @jsxImportSource @emotion/react */

import PropTypes from "prop-types";
import { createNumberMask } from "text-mask-addons";
import MaskedInput from "react-text-mask";

import {
  borderRadius,
  fontbox,
  fullWidth,
  paddingX,
  theme,
} from "emotion/utils";

const defaultMaskOptions = {
  prefix: "$",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

export default function FormCurrencyInput(props) {
  const { name, label, maskOptions, formik, helperText = "", style } = props;

  const errorClass =
    formik?.errors[name] && formik?.touched[name] ? "error" : "";

  const width = "100%";

  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...(maskOptions || defaultMaskOptions),
  });

  return (
    <div className="form-group-item" style={style}>
      <div className="form-group-item__helper-text">
        <label htmlFor={name}>{label}</label>
        {helperText}
      </div>
      <MaskedInput
        id={name}
        // className={`${errorClass} maskedInput`}
        // style={{ width }}
        name={name}
        mask={currencyMask}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values[name] || ""}
        type="text"
        css={css.input}
      />
    </div>
  );
}

const css = {
  input: {
    ...borderRadius(4),
    ...fontbox(theme.fonts.inter, 16, 400, 16 * 1.44),
    ...fullWidth(),
    ...paddingX(10),
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.lightGrey}`,
    color: theme.colors.nero,
    height: "40px!important",
  },
};

FormCurrencyInput.defaultProps = {
  inputMode: "numeric",
  maskOptions: {},
};

FormCurrencyInput.propTypes = {
  inputmode: PropTypes.string,
  maskOptions: PropTypes.shape({
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    includeThousandsSeparator: PropTypes.bool,
    thousandsSeparatorSymbol: PropTypes.string,
    allowDecimal: PropTypes.bool,
    decimalSymbol: PropTypes.string,
    decimalLimit: PropTypes.string,
    requireDecimal: PropTypes.bool,
    allowNegative: PropTypes.bool,
    allowLeadingZeroes: PropTypes.bool,
    integerLimit: PropTypes.number,
  }),
};
