/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import { Accordion as AccordionPR, AccordionTab } from "primereact/accordion";

import { css } from "./css";

import Icon from "components/icon/icon";

const Accordion = ({ tabs = [] }) => {
  const theme = useTheme();

  const getTabs = tabs.map((tab, index) => {
    const header = (
      <div css={css(theme).header}>
        {tab?.icon && <Icon icon={tab?.icon} nostroke={true} />}

        <span>{tab?.name}</span>
      </div>
    );

    return (
      <AccordionTab key={index} header={header}>
        {tab?.content}
      </AccordionTab>
    );
  });

  if (!tabs.length) {
    return null;
  }

  return (
    <AccordionPR css={css(theme)} activeIndex={0}>
      {getTabs}
    </AccordionPR>
  );
};

export default Accordion;
