/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import React, { useState } from "react";

import TitleBlock from "components/title-block/title-block";
import Button from "components/button/button";
import Icon from "components/icon/icon";
import CustomDivTable from "components/custom-table/custom-div-table";
import ButtonIcon from "components/button-icon/button-icon";
import CustomDivTableActions from "components/custom-table/custom-div-table-actions";

import HighPointModal from "./high-point-modal";

import { css } from "./css";

const HighPoint = () => {
  const [showHighPointModal, setShowHighPointModal] = useState(false);

  const theme = useTheme();

  // const { data, refetch } = useQuery();
  const data = [
    {
      id: 1,
      name: "Report 1",
    },
    {
      id: 2,
      name: "Report 2",
    },
    {
      id: 3,
      name: "Report 3",
    },
    {
      id: 4,
      name: "Report 4",
    },
  ];

  const handleCreateReport = (it) => {
    setShowHighPointModal(true);
  };

  const columns = [
    {
      name: "reportName",
      valFn: (it) => `${it.name ? it.name : "--"}`,
      display: "Report name",
    },
    {
      name: "actions",
      valFn: (it) => (
        <CustomDivTableActions>
          <ButtonIcon
            icon={<Icon icon="Edit" />}
            onClick={() => handleCreateReport(it)}
          />
        </CustomDivTableActions>
      ),
      align: "right",
      alignHeader: "right",
    },
  ];

  const btnCreateReport = (
    <Button
      icon={<Icon icon="Plus" />}
      iconPos="left"
      label="Create report criteria"
      onClick={handleCreateReport}
    />
  );

  const actions = [btnCreateReport].map((btn, index) => (
    <React.Fragment key={index}>{btn}</React.Fragment>
  ));

  const titleBlock = <TitleBlock>{actions}</TitleBlock>;

  const content = (
    <>
      {titleBlock}

      <CustomDivTable
        columns={columns}
        rows={data}
        selectionMode="single"
        // onSelect={(it) => navigate(`/admin/patterns/${it.id}`)}
        // onSelect={(it) => alert(`Selected report: ${it.name}`)}
        onSelect={(it) => handleCreateReport(it)}
        filterKeys={["name"]}
        showSearch={false}
        emptyMessage="No High Point Reports Found"
      />
    </>
  );

  const modals = (
    <>
      {showHighPointModal && (
        <HighPointModal
          setShowModal={setShowHighPointModal}
          show={showHighPointModal}
        />
      )}
    </>
  );

  return (
    <div css={css(theme)}>
      {content}
      {modals}
    </div>
  );
};

export default HighPoint;
