/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import Icon from "components/icon/icon";

import { css } from "./css";
import { appColors } from "emotion/utils";

const Logo = () => {
  const theme = useTheme();

  return (
    <div css={css(theme)}>
      <div css={css(theme).logo(theme)}>
        <Icon icon="Logo" stroke="" fill={appColors?.grays?.[0]} />
        <p>Showmanship</p>
      </div>
    </div>
  );
};

export default Logo;
