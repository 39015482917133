import {
  flexbox,
  fullWidth,
  paddingX,
  paddingY,
  posRelative,
  theme,
} from "emotion/utils";

export const css = {
  container: {
    ...posRelative(),
    backgroundColor: "#d72d51",

    frame: {
      ...flexbox("column", "flex-start", "flex-start"),

      "@media screen and (min-width: 992px)": {
        ...flexbox("row", "center", "flex-start", 68),
      },

      "> div:not(:first-of-type)": {
        ...fullWidth(),
        ...paddingX(16),
        ...paddingY(48),

        "@media screen and (min-width: 576px)": {
          ...paddingX(38),
          ...paddingY(64),
        },
        "@media screen and (min-width: 992px)": {
          paddingLeft: 0,
          maxWidth: 560,
        },

        "h2, p": {
          color: theme.colors.white,
        },
      },
    },
  },

  image: (image) => ({
    ...fullWidth(),
    backgroundImage: `url(${image})`,
    backgroundSize: "cover",
    height: 250,

    "@media screen and (min-width: 420px)": {
      height: 400,
    },
    "@media screen and (min-width: 992px)": {
      width: "35%",
      height: 550,
    },
    "@media screen and (min-width: 1200px)": {
      width: "45%",
    },
    "@media screen and (min-width: 1400px)": {
      height: 650,
      width: "50%",
    },
  }),
};
