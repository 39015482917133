import DisciplineForm, {
  initialValues as disciplineInitialValues,
  existingValues as disciplineExistingValues,
  validationSchema as disciplineValidationSchema,
  postUrl as disciplinePostUrl,
  body as disciplineBody,
} from "./discipline-form";

import ClassTypeForm, {
  initialValues as typeInitialValues,
  existingValues as typeExistingValues,
  validationSchema as typeValidationSchema,
  postUrl as typePostUrl,
  body as typeBody,
} from "./class-type-form";

import ClassLevelFrom, {
  initialValues as levelInitialValues,
  existingValues as levelExistingValues,
  validationSchema as levelValidationSchema,
  postUrl as levelPostUrl,
  body as levelBody,
} from "./class-level-form";

import AgeGroupForm, {
  initialValues as ageGroupInitialValues,
  existingValues as ageGroupExistingValues,
  validationSchema as ageGroupValidationSchema,
  postUrl as ageGroupPostUrl,
  body as ageGroupBody,
} from "./age-group-form";

import ClassModifierForm, {
  initialValues as modifierInitialValues,
  existingValues as modifierExistingValues,
  validationSchema as modifierValidationSchema,
  postUrl as modifierPostUrl,
  body as modifierBody,
} from "./class-modifier-form";

const component = (formik, type) => {
  switch (type) {
    case "discipline":
      return <DisciplineForm formik={formik} />;
    case "classType":
      return <ClassTypeForm formik={formik} />;
    case "level":
      return <ClassLevelFrom formik={formik} />;
    case "ageGroup":
      return <AgeGroupForm formik={formik} />;
    case "modifier":
      return <ClassModifierForm formik={formik} />;
    default:
      return null;
  }
};

const existingValues = (type, data) => {
  const map = {
    discipline: disciplineExistingValues(data),
    classType: typeExistingValues(data),
    level: levelExistingValues(data),
    ageGroup: ageGroupExistingValues(data),
    modifier: modifierExistingValues(data),
  };

  return map[type];
};

const initialValues = (type) => {
  const map = {
    discipline: disciplineInitialValues,
    classType: typeInitialValues,
    level: levelInitialValues,
    ageGroup: ageGroupInitialValues,
    modifier: modifierInitialValues,
  };

  return map[type];
};

const validationSchema = (type) => {
  const map = {
    discipline: disciplineValidationSchema,
    classType: typeValidationSchema,
    level: levelValidationSchema,
    ageGroup: ageGroupValidationSchema,
    modifier: modifierValidationSchema,
  };

  return map[type];
};

const postUrl = (type) => {
  const map = {
    discipline: disciplinePostUrl,
    classType: typePostUrl,
    level: levelPostUrl,
    ageGroup: ageGroupPostUrl,
    modifier: modifierPostUrl,
  };

  return map[type];
};

const body = (type, data) => {
  const map = {
    discipline: disciplineBody,
    classType: typeBody,
    level: levelBody,
    ageGroup: ageGroupBody,
    modifier: modifierBody,
  };

  return map[type](data);
};

const StemForm = {
  component,
  initialValues,
  existingValues,
  validationSchema,
  postUrl,
  body,
};

export default StemForm;
