/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { Password as PasswordPR } from "primereact/password";

import { cssFormElements } from "../css";

const Password = (data) => {
  const theme = useTheme();

  const getLabel = data?.label && <label>{data.label}</label>;

  const elProps = {
    ...Object.keys(data).reduce((acc, key) => {
      if (key !== "data") {
        acc[key] = data[key];
      }
      return acc;
    }, {}),
  };

  const getElement = <PasswordPR {...elProps} feedback={false} />;

  return (
    <div css={cssFormElements(theme)}>
      {getLabel}
      {getElement}
    </div>
  );
};

export default Password;
