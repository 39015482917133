/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { css } from "../css";

import Accordion from "components/accordion/accordion";
import CustomDivTable from "components/custom-table/custom-div-table";
import CustomDivTableActions from "components/custom-table/custom-div-table-actions";
import ButtonIcon from "components/button-icon/button-icon";
import Icon from "components/icon/icon";
import Tag from "components/tag/tag";

// const MemberHorses = (memberData) => {
const MemberHorses = ({
  memberData,
  setShowModal,
  setActiveHorse = () => {},
}) => {
  const theme = useTheme();

  const columns = [
    {
      name: "name",
      default: () => "",
      valFn: (data) => data.animal.name,
      display: `Horse name`,
    },
    {
      name: "default",
      default: () => "",
      valFn: (data) => "--",
      display: "Default horse",
      align: "center",
      alignHeader: "center",
    },
    {
      name: "visible",
      default: () => "",
      valFn: (data) => (data.end_at ? <Tag tag="No" /> : <Tag tag="Yes" />),
      display: "Visible to rider",
      align: "center",
      alignHeader: "center",
    },
    {
      name: "documentation",
      default: () => "",
      valFn: (data) =>
        data.animal.animal_document.length > 0 ? (
          <Tag tag="Yes" />
        ) : (
          <Tag tag="No" />
        ),
      display: "Documentation (click to view)",
    },
    {
      name: "actions",
      valFn: (it) => (
        <CustomDivTableActions>
          <ButtonIcon
            icon={<Icon icon="Edit" />}
            onClick={() => {
              setActiveHorse(it);
              setShowModal(true);
            }}
          />
        </CustomDivTableActions>
      ),
      display: "",
      noSort: true,
      align: "right",
      alignHeader: "right",
    },
  ];

  const rows = memberData?.member?.use_animals;

  const table = <CustomDivTable columns={columns} rows={rows} />;

  const horsesTabs = [
    {
      icon: "HorseHead",
      name: "Horses",
      content: table,
    },
  ];

  return (
    <div css={css(theme).horses}>
      <Accordion tabs={horsesTabs} />
    </div>
  );
};

export default MemberHorses;
