/** @jsxImportSource @emotion/react */

import { Link } from "react-router-dom";

import heroBg from "images/hero-section.jpg";

import Icon from "components/icon/icon";

import { css } from "./css";

const Hero = () => {
  const logo = (
    <Link to="/" css={css.logo}>
      <Icon icon="Logo" stroke={null} />
      <p>Showmanship</p>
    </Link>
  );

  const btnLogin = (
    <Link to="login" css={css.btnLogin}>
      Log In
    </Link>
  );

  const header = (
    <div css={css.header}>
      {logo}
      {btnLogin}
    </div>
  );

  const content = (
    <div css={css.content}>
      <h1>Simplify Showmanship</h1>
      <p>
        Take your competition to the next level with the Showmanship Solution,
        designed to ease administration efforts and delight competitors.
      </p>
    </div>
  );

  return (
    <div css={css.section(heroBg)}>
      {header}
      {content}
    </div>
  );
};

export default Hero;
