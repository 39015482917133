/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { css } from "../css";
import { capitalizeFirstLetter, formattedPhone } from "utils";

import Accordion from "components/accordion/accordion";
import CustomDivTable from "components/custom-table/custom-div-table";
import CustomDivTableActions from "components/custom-table/custom-div-table-actions";
import ButtonIcon from "components/button-icon/button-icon";
import Icon from "components/icon/icon";
import Tag from "components/tag/tag";

const MemberGuardians = (memberData) => {
  const theme = useTheme();

  const columns = [
    {
      name: "name",
      default: () => "",
      valFn: (data) =>
        data?.user?.first_name && data?.user?.last_name
          ? `${capitalizeFirstLetter(
              data?.user?.first_name,
            )} ${capitalizeFirstLetter(data?.user?.last_name)}`
          : "--",
      display: `Guardian name`,
    },
    {
      name: "email",
      default: () => "",
      valFn: (data) => data?.user?.email,
      display: "Email",
    },
    {
      name: "phone",
      default: () => "",
      valFn: (data) =>
        data?.user?.phone ? formattedPhone(data.user.phone) : "--",
      display: "Phone",
    },
    {
      name: "emergencyContact",
      default: () => "",
      valFn: (data) =>
        data?.user?.emergency_contact ? <Tag tag="Yes" /> : <Tag tag="No" />,
      display: "Emergency contact",
    },
    {
      name: "verified",
      default: () => "",
      valFn: (data) => (data?.user?.verified ? "Verified" : "Not verified"),
      display: "Verified",
    },
    {
      name: "actions",
      valFn: (it) => (
        <CustomDivTableActions>
          <ButtonIcon
            icon={<Icon icon="Trash" />}
            onClick={() => alert("trash member guardian functionality")}
          />
          <ButtonIcon
            icon={<Icon icon="Edit" />}
            onClick={() => alert("edit member guardian functionality")}
          />
        </CustomDivTableActions>
      ),
      display: "",
      noSort: true,
      align: "right",
      alignHeader: "right",
    },
  ];

  const rows =
    memberData?.memberData?.member?.member_custodians?.length > 0
      ? [memberData?.memberData?.member?.member_custodians]
      : [];

  const table = (
    <CustomDivTable
      columns={columns}
      rows={rows}
      emptyMessage="No guardians found."
    />
  );

  const guardianTabs = [
    {
      icon: "UserSquare",
      name: "Guardians",
      content: table,
    },
  ];

  return (
    <div css={css(theme).guardians}>
      <Accordion tabs={guardianTabs} />
    </div>
  );
};

export default MemberGuardians;
