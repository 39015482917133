/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import React, { useState } from "react";

import ClassTile from "./components/class-tile";
import SelectButton from "./components/arena-select/arena-select";
import Header from "components/header/header";
import AnnouncerModal from "./announcer-modal";

// import css from "./announcer.module.scss";
import RiderList from "./components/rider-list/rider-list";
import CustomButton from "components/custom-button/custom-button";

import { useQuery } from "hooks/use-query";
import { useMutation } from "hooks/use-mutation";

import Client from "client";

import { css } from "./css";
import Button from "components/button/button";

const Announcer = () => {
  const [canAnnounce, setCanAnnounce] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState("all");
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedShow, setSelectedShow] = useState(null);

  const theme = useTheme();

  const { data: shows, isLoading: isLoadingShows } = useQuery(
    "/announcer/shows",
    {
      onSuccess: (res) => {
        setSelectedShow(res.data[0] || null);
      },
    },
  );

  const { data, isLoading, refetch } = useQuery(
    `/announcer/shows/${selectedShow?.id}/classes`,
    {
      onSuccess: (res) => {
        if (!res.data?.length) return;
        setSelectedClass(res.data[0].id);
      },
      enabled: !!selectedShow,
    },
  );

  const { data: riders, isLoading: isRidersLoading } = useQuery(
    `/announcer/shows/${selectedShow?.id}/classes/${selectedClass}/riders`,
    { enabled: !!selectedClass },
  );

  const arenas = data?.data
    .sort((a, b) => a.order - b.order)
    .reduce((map, cls) => {
      if (!map[cls.arena.id]) {
        map[cls.arena.id] = { arena: cls.arena, classes: [] };
      }

      map[cls.arena.id].classes.push(cls);

      return map;
    }, {});

  const filteredClasses =
    selected === "all" ? data?.data : arenas[selected]?.classes;

  const { shareScores, isLoading: isSharingScores } = useMutation({
    mutationFn: () =>
      Client.post(`/announcer/classes/${selectedClass}/share-scores`),
    onSuccess: () => {
      refetch();
    },
  });

  function handleArenaSelect(id) {
    const cls = id === "all" ? data?.data[0]?.id : arenas[id]?.classes[0]?.id;

    setSelectedClass(cls);
    setSelected(id);
  }

  if (!canAnnounce) {
    return (
      <>
        <div style={{ height: 50 }}>
          <Header hideMenu={true} />
        </div>
        <div
          css={`
            ${css(theme).announcer} ${css(theme).none}
          `}
        >
          <h1>
            Announcing is not available at the moment.
            <br />
            It will become available at the start of the next show.
          </h1>
        </div>
      </>
    );
  }

  const navBlock = (
    <div css={css(theme).navBlock}>
      <Header hideMenu={true} />
    </div>
  );

  const titleBar = (
    <div css={css(theme).titleBar}>
      <div>Announcing</div>
      <div>{selectedShow?.name || "No show selected"}</div>
    </div>
  );

  const left = (
    <div css={css(theme).leftColumn}>
      <SelectButton
        options={Object.values(arenas || {}).map((a) => a.arena)}
        selected={selected}
        setSelected={handleArenaSelect}
      />

      <div css={css(theme).leftColumn.list}>
        {(filteredClasses || []).map((cls, index) => (
          <ClassTile
            key={index}
            data={cls}
            selected={selectedClass === cls.id}
            onClick={() => setSelectedClass(cls.id)}
          />
        ))}
      </div>
    </div>
  );

  const cols = ["Rider #", "Name", "Horse Name", "Award"];
  const headCols = (
    <div css={css(theme).headerRow}>
      {cols.map((col, index) => (
        <div key={index} css={css(theme).headerRow.headerCol}>
          {col}
        </div>
      ))}
    </div>
  );

  const selectedData = data?.data.find((cls) => cls.id === selectedClass);
  const canAnnounceScores =
    selectedData?.time_scores_available && !selectedData?.time_scores_announced;

  const right = (
    <div css={css(theme).rightColumn}>
      {headCols}

      <RiderList
        data={riders?.data}
        isLoading={isRidersLoading}
        noClass={!selectedClass}
      />

      {/* <CustomButton
        label="Share Scores"
        color="secondary"
        onClick={shareScores}
        disabled={!canAnnounceScores || isSharingScores}
        fs={16}
        align="right"
        sty={{
          marginLeft: "auto",
          width: "unset",
        }}
      /> */}
      <Button
        label="Share Scores"
        onClick={shareScores}
        disabled={!canAnnounceScores || isSharingScores}
      />
    </div>
  );

  const content = (
    <div css={css(theme).container}>
      {left}
      {right}
    </div>
  );

  const modal = showModal && <AnnouncerModal />;

  return (
    <div css={css(theme).announcer}>
      {navBlock}
      {titleBar}

      <div css={css(theme).content}>
        {content}
        {modal}
      </div>
    </div>
  );
};

export default Announcer;
