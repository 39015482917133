import React from "react";
import { Text, View } from "@react-pdf/renderer";

import PrintPDF from "components/print-pdf/print-pdf";

import { css } from "./styles";

const ShowClassResultsPrintPDF = ({ title, data }) => {
  console.log("data", data);

  const colRiderNumber = "15%";
  const colRiderName = "25%";
  const colHorseName = "20%";
  const colAwards = "40%";

  return (
    <PrintPDF headerText={title}>
      <View style={css.body}>
        <View style={css.tableBlock}>
          <Text style={css.tableBlock.title}>{data?.class_number}</Text>

          <View style={css.table}>
            <View style={css.table.row}>
              <Text style={[css.table.cell, { width: colRiderNumber }]}>
                Rider Number
              </Text>
              <Text style={[css.table.cell, { width: colRiderName }]}>
                Rider Name
              </Text>
              <Text style={[css.table.cell, { width: colHorseName }]}>
                Horse Name
              </Text>
              <Text
                style={[
                  css.table.cell,
                  {
                    textAlign: "center",
                    width: colAwards,
                  },
                ]}
              >
                Award
              </Text>
            </View>

            {data?.awards?.map((award, index) => {
              const bgColor = index % 2 === 0 ? "#ffffff" : "#f6f6f6";
              return (
                <View
                  key={index}
                  style={[css.table.dataRow, { backgroundColor: bgColor }]}
                >
                  <Text style={[css.table.cell, { width: colRiderNumber }]}>
                    {award?.rider_number}
                  </Text>
                  <Text style={[css.table.cell, { width: colRiderName }]}>
                    {award?.rider_name}
                  </Text>
                  <Text style={[css.table.cell, { width: colHorseName }]}>
                    {award?.horse_name}
                  </Text>
                  <Text
                    style={[
                      css.table.cell,
                      {
                        textAlign: "center",
                        width: colAwards,
                      },
                    ]}
                  >
                    {award?.award_names}
                  </Text>
                </View>
              );
            })}
          </View>
        </View>
      </View>
    </PrintPDF>
  );
};

export default ShowClassResultsPrintPDF;
