/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { Button as PrButton } from "primereact/button";

import { css } from "./css";

const Button = ({
  type = "button",
  isLink = false,
  color = "primary",
  icon = null,
  iconPos = "left",
  label = null,
  onClick,
  variant = "contained",
  disabled = false,
  className = null,
}) => {
  const theme = useTheme();

  const cssContainer = css(theme, color, variant, disabled);

  return (
    <PrButton
      unstyled
      type={type}
      css={cssContainer}
      icon={icon}
      iconPos={iconPos}
      label={label}
      onClick={onClick}
      disabled={disabled}
      className={className}
    />
  );
};

export default Button;
