import {
  appColors,
  appFonts,
  borderRadius,
  flexbox,
  fontbox,
  posRelative,
  square,
} from "emotion/utils";

export const css = (theme) => ({
  ...flexbox("column", "center", "center", 32),

  logo: (theme) => ({
    ...flexbox("row", "center", "flex-start", 10),

    "> div": {
      ...borderRadius(8),
      ...flexbox("row", "center", "center"),
      ...square(32),
      background:
        theme.title === "light"
          ? "linear-gradient(315deg, #122EEC 0%, #D62C50 100%)"
          : "transparent",

      "> svg": {
        ...square(26),
      },
    },

    p: {
      ...posRelative(2),
      ...fontbox(appFonts.korolev, 22, 700, 22),
      color: theme.title === "light" ? appColors.grays[1] : appColors.grays[0],
    },
  }),
});
