/** @jsxImportSource @emotion/react */

import React, { forwardRef } from "react";

import { appColors } from "emotion/utils";

import { Dialog } from "primereact/dialog";

import Icon from "components/icon/icon";

import { css } from "./css";

const CustomModal = (props, ref) => {
  const {
    draggable = false,
    children,
    headerTitle,
    onHide,
    resizable = false,
    showHeader = true,
    footerButtons,
    style,
    size = "sm",
    visible = true,
  } = props;

  const closeIcon = <Icon icon="CloseX" />;

  const header = headerTitle && headerTitle;

  const footer = footerButtons;

  const modalSize = {
    sm: { width: 400 },
    md: { width: 640 },
    lg: { width: 740 },
    xl: { width: 1000 },
  };

  const dialogProps = {
    blockScroll: true,
    closable: true,
    closeIcon: closeIcon,
    dismissableMask: true,
    draggable: draggable,
    resizable: resizable,
    showHeader: showHeader,
    unstyled: true,
    visible: visible,

    pt: {
      mask: () => ({
        style: {
          backgroundColor: `${appColors.grays[1]}85`,
        },
      }),
    },

    header: header,
    footer: footer,

    onHide: onHide,

    css: css(modalSize[size]),
  };

  return (
    <Dialog ref={ref} {...dialogProps}>
      {children}
    </Dialog>
  );
};

export default forwardRef(CustomModal);
