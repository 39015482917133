import { Font, StyleSheet } from "@react-pdf/renderer";

export const css = StyleSheet.create({
  page: {
    gap: 25,
    paddingHorizontal: 30,
    paddingVertical: 30,
  },

  header: {
    gap: 4,

    title: {
      color: "#202B72",
      fontSize: 22,
      fontWeight: "700",
    },

    day: {
      color: "#D52C51",
      fontSize: 16,
      fontWeight: "700",
    },
  },

  body: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 20,
  },

  classBlock: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 20,
  },

  tableBlock: {
    borderColor: "#000000",
    borderStyle: "solid",
    borderWidth: 1,
    alignItems: "center",
    width: "100%",

    title: {
      color: "#D52C51",
      fontSize: 14,
      fontWeight: "700",
      paddingVertical: 10,
    },
  },
  table: {
    width: "100%",

    row: {
      alignItems: "center",
      borderBottomColor: "#000000",
      borderBottomStyle: "solid",
      borderBottomWidth: 1,
      flexDirection: "row",
      gap: 10,
      justifyContent: "flex-start",
      width: "100%",
    },
    cell: {
      color: "#000000",
      flex: 1,
      fontSize: 14,
      fontWeight: "700",
      paddingVertical: 4,
      paddingHorizontal: 10,
    },

    dataRow: {
      alignItems: "center",
      flexDirection: "row",
      gap: 10,
      justifyContent: "flex-start",
      width: "100%",
    },
    dataCell: {
      color: "#000000",
      flex: 1,
      fontSize: 12,
      fontWeight: "700",
      paddingVertical: 4,
      paddingHorizontal: 10,
    },
  },

  footer: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "absolute",
    bottom: 30,
    left: 30,
    right: 30,

    qr: {
      alignItems: "center",
      flexDirection: "row",
      gap: 10,
    },
    qrImg: {
      height: 50,
      width: 50,
    },
    qrText: {
      color: "#202B72",
      fontSize: 14,
      fontWeight: "700",
      maxWidth: 200,
    },

    logo: {
      height: 40,
      marginLeft: "auto",
      width: 40,
    },
  },
});
