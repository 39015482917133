import React, { useState, useEffect, useMemo } from "react";

import CustomButton from "components/custom-button/custom-button";
import CustomDivTable from "components/custom-table/custom-div-table";
import FilterSelect from "components/filter-select/filter-select";
import CustomModal from "components/custom-modal/custom-modal";

import ConfirmationModal, {
  useConfirmationModal,
} from "confirmation-modal/confirmation-modal";

import { useQuery } from "hooks/use-query";
import FilterBtnGroup from "components/filter-btn-group/filter-btn-group";
import { fullWidth } from "emotion/utils";

const AwardsModal = ({
  setShowModal = () => {},
  showModal = true,
  activeMember,
  onSuccess = () => {},
  onClose = () => {},
}) => {
  const {
    data: memberData,
    isLoading,
    refetch,
  } = useQuery(`/members/${activeMember.id}`, {
    cacheTime: 0,
    enabled: !!activeMember.id,
  });

  const member = memberData?.member;

  const {
    showModal: showConfirmationModal,
    handleExit,
    handleClose: handleConfirmationClose,
  } = useConfirmationModal(onClose);

  const [isFormDirty, setIsFormDirty] = useState(false);
  const [customMessage, setCustomMessage] = useState(null);
  const [isDefaultMessage, setIsDefaultMessage] = useState(true);
  const [modalHeight, setModalHeight] = useState(0);
  const [awardType, setAwardType] = useState(1);
  const [activeBtnGroupAwards, setActiveBtnGroupAwards] = useState("Placings");

  const conditionalClose = () => {
    if (isFormDirty) {
      setCustomMessage();
      setIsDefaultMessage(false);
      handleExit();
    } else {
      setCustomMessage(null);
      setIsDefaultMessage(true);
      onClose();
      setShowModal(false);
    }
  };

  //Award, class, show
  const columns = [
    {
      name: "award_image",
      valFn: (data) => (
        //Need image
        <img src={data.base_award.image.link} style={{ height: "30px" }}></img>
      ),
      display: "",
      sortable: false,
    },
    {
      name: "award",
      valFn: (data) => data.base_award.name,
      display: "Award",
      sortable: false,
    },
    {
      name: "class",
      valFn: (data) => data.show_class_results.show_class.short_name,
      display: "Class",
    },
    {
      name: "show",
      valFn: (data) => data.show_class_results.show_class.show.name,
      display: "Show",
    },
  ];

  const title = `${activeMember?.user?.first_name} ${activeMember?.user?.last_name}`;

  const data = useMemo(() => {
    return member?.awards?.filter((r) => {
      if (awardType == 1) {
        return r.base_award.primary;
      } else if (awardType == 2) {
        return !r.base_award.primary && !r.base_award.medal;
      } else if (awardType == 3) {
        return r.base_award.medal;
      }
      return true;
    });
  }, [awardType, member]);

  const awardData = ["Placings", "Danish Awards", "Medals"];

  const filterBtnGroupAwards = (
    <div style={{ width: "auto" }}>
      <FilterBtnGroup
        options={awardData}
        activeBtnGroup={activeBtnGroupAwards}
        setActiveBtnGroup={setActiveBtnGroupAwards}
      />
    </div>
  );

  return (
    <>
      <CustomModal
        headerTitle={`${title} Awards`}
        onHide={conditionalClose}
        size="md"
      >
        {filterBtnGroupAwards}
        {/* <FilterSelect
          align="right"
          data={awardData}
          name="award type"
          onChange={(el) => {
            setAwardType(el.target.value);
          }}
          showLabel={false}
          showOptionAll={false}
        /> */}

        <div style={{ ...fullWidth() }}>
          <CustomDivTable
            columns={columns}
            rows={data}
            noTableSort="true"
            emptyMessage="No Awards Found"
            //filterKeys={["name", "email"]}
            //showSearch={true}
          />
        </div>
      </CustomModal>

      <ConfirmationModal
        showModal={showConfirmationModal}
        handleExit={handleExit}
        handleClose={handleConfirmationClose}
        handleGoBack={handleConfirmationClose}
        setShowModal={setShowModal}
        modalHeight={modalHeight}
        customMessage={customMessage}
        isDefaultMessage={isDefaultMessage}
        // handleConfirmation={handleConfirmation}
      />
    </>
  );
};

export default AwardsModal;
