import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import Hero from "./sections/hero/hero";
// import Statement from "./sections/statement/statement";
// import Comprehensive from "./sections/comprehensive/comprehensive";
// import Fun from "./sections/fun/fun";
// import Simple from "./sections/simple/simple";
// import Subscription from "./sections/subscription/subscription";
import Footer from "../marketing/sections/footer/footer";
import css from "./privacy-policy.module.scss";

const PrivacyPolicy = () => {
  useEffect(() => {
    document.title = "Showmanship - Doing Best, Better";
  }, []);
  return (
    <>
      {/* <Hero /> */}
      <div>
        <article className={css.article1}>
          <div className={css.div}>
            <header className={css.header1}>
              <Link to="/">
                <div className={css.logInWrapper}>
                  <a className={css.back}>Back</a>
                </div>
              </Link>
              <h1 className={css.h1}>Privacy Policy</h1>
            </header>
          </div>
          <div className={css.div}>
            Showmanship LLC strongly believes in the importance of your privacy.
            This Privacy Policy describes how Showmanship LLC treats the
            personal information we collect through the Showmanship LLC website
            (the “Website”) and what we do with that information. By using or
            accessing the Website, you are accepting the practices described in
            this Privacy Policy. This Privacy Statement applies only to
            information collected online through the Website and does not
            describe the ways in which we may collect or use information
            obtained offline or through any means other than our website or
            other entity. We may amend this Privacy Policy at any time.
            <h3 className={css.h3}>
              How, When, and Why We Collect Information
            </h3>
            <ul className={css.ul}>
              <li className={css.li}>
                What we collect about you when browsing our website.&nbsp;You
                can view content on the Website without registering or providing
                any personal information. When you enter the Website via a
                computer, tablet, application, mobile phone, or other internet
                portal, we use a variety of tracking tools to collect
                information such as your browser and operating system type and
                IP address to optimize your experience on the Website, comply
                with applicable laws, and to track aggregate Website usage. The
                information sent to us through the Website includes, but is not
                limited to, the following: data about the pages you access,
                computer IP address, device ID or unique identifier, device
                type, geo-location information, computer and connection
                information, mobile network information, statistics on page
                views, traffic to and from the sites, referral URL, ad data, and
                standard web log data and other information. We also collect
                anonymous information through our use of cookies and web
                beacons, and other similar technology.
              </li>
              <li className={css.li}>
                Initiation of Contact by Us.&nbsp;We may use any information you
                provide to us to contact you.
              </li>
              <li className={css.li}>
                Activity Requiring Registration.&nbsp;Certain activities on the
                Website such as becoming a customer of Showmanship LLC may
                require you to register. To become a registered user, we may ask
                you to provide your name and email address, as well as your
                birthday to assign you to a competitor age group, and address or
                phone number to enable event administrators to contact you. If
                you decide to register, we use a persistent cookie that stores
                certain information to make it easier for you to login when you
                come back to the Website. However, none of your personal
                information is stored in that cookie. Furthermore, Showmanship
                LLC has no access to any payment or financial information
                provided through this Website.
              </li>
              <li className={css.li}>
                Usage Information.&nbsp;We may record information about your
                usage of the Website, such as when you use the site, the areas
                of the site you click on and/or participate in, the tags you
                search for, and whether or not you subscribe to the RSS feed. A
                persistent cookie may be used to track this information, but
                will not track financial information entered. We may use pixel
                tags and/or trackable links in HTML-based emails sent to our
                users to track which emails are opened and/or clicked on by
                recipients. Furthermore, if you choose to provide us with access
                to certain personal information stored by third parties such as
                social media sites (e.g., Facebook and Twitter), then you must
                know that the information we receive varies by site and is
                controlled by that site. By associating an account managed by a
                third party with usage of the Website you are authorizing
                Showmanship LLC to have access to this information, and you
                agree that Showmanship LLC may collect, store and use this
                information in accordance with this Privacy Policy.
              </li>
              <li className={css.li}>
                Polls and Surveys.&nbsp;From time to time, we may conduct polls
                and surveys. Information collected through our polls, surveys,
                and questionnaires is used in the aggregate, unless we contact
                you to request permission to use your individual responses for a
                particular purpose.
              </li>
              <li className={css.li}>
                We do not knowingly collect any information from children under
                the age of 13.
              </li>
            </ul>
            <h3 className={css.h3}>Uses of Personal Information</h3>
            <ul className={css.ul}>
              <li className={css.li}>
                We may share the personal information that we collect about you
                with other Showmanship LLC-related organizations or parent
                companies, as well as third parties that perform services on our
                behalf. Additionally, subject to applicable law, we may share
                any of the personal information that we collect about you with
                certain third parties.&nbsp;
              </li>
              <li className={css.li}>
                We will use your email address to communicate with you about
                Showmanship LLC’s activities and offerings, unless you opt-out
                from receiving such messages. We will also use your email
                address for administrative purposes, such as notifying you of
                major Website changes, sending messages related to actions you
                have taken on the site or for customer service purposes.
                Although we hope you’ll find these communications informative
                and useful, if you don’t, you can always unsubscribe by
                following the simple instructions included in each email.
              </li>
              <li className={css.li}>
                When you send email or other communications to us, we may retain
                those communications in order to process your inquiries, respond
                to your requests and improve our services.
              </li>
              <li className={css.li}>
                We use both your personally identifying information and certain
                non-personally-identifying information (such as anonymous usage
                data, IP addresses, browser type, clickstream data, etc.) to
                improve the quality of your user experience and the design of
                the Website and to create new features, functionality, and
                services by storing, tracking, and analyzing user behavior,
                preferences, trends, and actions.
              </li>
            </ul>
            <h3 className={css.h3}>Other Disclosures</h3>
            We may be required to disclose user information pursuant to lawful
            requests, such as subpoenas or court orders, or in compliance with
            applicable laws. If we receive a subpoena requesting information
            about you and if you have provided us with your email address, we
            will attempt to notify you of the subpoena at the email address that
            you have provided. Additionally, we may share account or other
            information when we believe it is necessary to comply with the law,
            to protect our interests or property, to prevent fraud or other
            illegal activity perpetrated through the Website or using the
            Showmanship LLC name, to prevent bodily harm, to enforce our User
            Agreement, or to protect the rights, property or safety of visitors
            to our site, our members, the public or Showmanship LLC. In the
            unlikely event that Showmanship LLC (or substantially all of its
            assets) is merged with another entity, information on our visitors
            would be among the transferred assets. Furthermore, Showmanship LLC
            may offer the ability for users to connect with the Website using a
            mobile device, either through a mobile application or via a mobile
            optimized website. The provisions of this Privacy Policy apply to
            all such mobile access and use of mobile devices. When you download
            or use our mobile applications, or access one of our mobile
            optimized sites, we may receive information about your location and
            your mobile device, including a unique identifier for your device.
            We may use this information to provide you with location-based
            services, such as advertising, search results, and other
            personalized content. Most mobile devices allow you to control or
            disable location services in the device’s settings menu. If you have
            questions about how to disable your device’s location services, we
            recommend you contact your mobile service carrier or the
            manufacturer of your particular device.
            <h3 className={css.h3}>Information Security and Data Integrity</h3>
            We will delete your personal information from our records upon
            request, unless an exception applies. We may deny your deletion
            request if retaining the information is necessary for us to: Detect
            security incidents, protect against malicious, deceptive,
            fraudulent, or illegal activity, or prosecute those responsible for
            such activities. Debug products to identify and repair errors that
            impair existing intended functionality. Comply with the California
            Electronic Communications Privacy Act (Cal. Penal Code § 1546 et.
            seq.) Comply with a legal obligation.<br></br>
            Showmanship LLC takes security measures to protect against
            unauthorized access to or unauthorized alteration, disclosure or
            destruction of data. These include internal reviews of our data
            collection, storage and processing practices and security measures,
            as well as physical security measures to guard against unauthorized
            access to systems where we store personal data.
            <h3 className={css.h3}>More Information</h3>
            Our Website may also permit you to access non-Showmanship LLC sites.
            It is important to remember that, if you link to a non-Showmanship
            LLC site from our Website, that party’s privacy policy and its user
            agreement apply to you. We encourage you to learn about each third
            party’s privacy policy before giving personal information to them.
            Showmanship LLC reserves the right to change this Privacy Policy at
            any time. We will post any changes to this Privacy Policy on this
            page, so we encourage you to check this page regularly. Your
            continued use of this Website following any changes to this Privacy
            Policy will constitute your acceptance of such changes. If you have
            any questions about this Privacy Policy, the Website, or your
            account, please feel free to{" "}
            <a href="mailto:support@showmanship.app" className={css.a1}>
              contact us
            </a>
            .
          </div>
        </article>
      </div>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
