/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import moment from "moment";

import { css } from "../css";

import Icon from "components/icon/icon";
import Button from "components/button/button";
import Tag from "components/tag/tag";

const MemberPersonalInfo = ({ setShowModal = () => {}, memberData }) => {
  const theme = useTheme();

  const badges = [
    {
      type: "age_group",
      label: memberData?.member?.age_group?.name,
      color: "ageGroup",
    },
    {
      type: "status",
      label: memberData?.member?.user.banned ? "Inactive" : "Active",
      color: "success",
    },
  ];

  const badgeEls = badges && (
    <div css={css(theme).badges}>
      {badges.map((badge, index) => {
        if (!badge.label) return null;

        return <Tag key={index} tag={badge?.label} />;
      })}
    </div>
  );

  // const getBirthday = (date) => {
  //   return <>{moment(date).format("MMM D, YYYY")}</>;
  // };

  const arrPersonalInfo = [
    {
      icon: "Star3",
      label: memberData?.member?.user?.clubs[0].club?.name,
    },
    {
      icon: "Gift3",
      label: moment(memberData?.member?.user?.birth_at).format("MMM D, YYYY"),
    },
    {
      icon: "Mail",
      label: memberData?.member?.user?.email,
    },
  ];

  const personalInfoBtm = arrPersonalInfo
    ? arrPersonalInfo.map((item, index) => (
        <div key={index} css={css(theme).iconItem}>
          <Icon icon={item?.icon} color="#98A2B3" />
          {item?.label}
        </div>
      ))
    : null;

  const addressBlock = (
    <div css={css(theme).personal.address}>
      {memberData?.member?.user?.address} {memberData?.member?.user?.address2},
      <br />
      {memberData?.member?.user?.address_city},{" "}
      {memberData?.member?.user?.address_state}{" "}
      {memberData?.member?.user?.address_zip}
    </div>
  );

  return (
    <div css={css(theme).personal}>
      <div css={css(theme).personal.info}>
        <div css={css(theme).personal.info.top}>
          <h1>
            {memberData?.member?.user?.first_name}{" "}
            {memberData?.member?.user?.last_name}
          </h1>

          {badgeEls}
        </div>

        <div css={css(theme).personal.info.btm}>
          {personalInfoBtm}

          <Button
            icon={<Icon icon="Refresh" />}
            iconPos="left"
            label="Reset Password"
            color="primary"
            variant="link"
            onClick={() => setShowModal(true)}
          />
        </div>
      </div>

      {addressBlock}
    </div>
  );
};

export default MemberPersonalInfo;
