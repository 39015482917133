/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { css } from "./css";

const TitleBlock = ({ children }) => {
  const theme = useTheme();

  return <div css={css(theme)}>{children}</div>;
};

export default TitleBlock;
