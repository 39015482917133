import React from "react";

import { FormElement } from "components/form-element/form-element";
import FormSelect from "components/form-select";

const FormAddressBlock = ({
  // name,
  label,
  formik,
  data,
}) => {
  const states = [
    { code: "", name: "" },
    { code: "AL", name: "Alabama" },
    { code: "AK", name: "Alaska" },
    { code: "AZ", name: "Arizona" },
    { code: "AR", name: "Arkansas" },
    { code: "CA", name: "California" },
    { code: "CO", name: "Colorado" },
    { code: "CT", name: "Connecticut" },
    { code: "DE", name: "Delaware" },
    { code: "DC", name: "District Of Columbia" },
    { code: "FL", name: "Florida" },
    { code: "GA", name: "Georgia" },
    { code: "HI", name: "Hawaii" },
    { code: "ID", name: "Idaho" },
    { code: "IL", name: "Illinois" },
    { code: "IN", name: "Indiana" },
    { code: "IA", name: "Iowa" },
    { code: "KS", name: "Kansas" },
    { code: "KY", name: "Kentucky" },
    { code: "LA", name: "Louisiana" },
    { code: "ME", name: "Maine" },
    { code: "MD", name: "Maryland" },
    { code: "MA", name: "Massachusetts" },
    { code: "MI", name: "Michigan" },
    { code: "MN", name: "Minnesota" },
    { code: "MS", name: "Mississippi" },
    { code: "MO", name: "Missouri" },
    { code: "MT", name: "Montana" },
    { code: "NE", name: "Nebraska" },
    { code: "NV", name: "Nevada" },
    { code: "NH", name: "New Hampshire" },
    { code: "NJ", name: "New Jersey" },
    { code: "NM", name: "New Mexico" },
    { code: "NY", name: "New York" },
    { code: "NC", name: "North Carolina" },
    { code: "ND", name: "North Dakota" },
    { code: "OH", name: "Ohio" },
    { code: "OK", name: "Oklahoma" },
    { code: "OR", name: "Oregon" },
    { code: "PA", name: "Pennsylvania" },
    { code: "RI", name: "Rhode Island" },
    { code: "SC", name: "South Carolina" },
    { code: "SD", name: "South Dakota" },
    { code: "TN", name: "Tennessee" },
    { code: "TX", name: "Texas" },
    { code: "UT", name: "Utah" },
    { code: "VT", name: "Vermont" },
    { code: "VA", name: "Virginia" },
    { code: "WA", name: "Washington" },
    { code: "WV", name: "West Virginia" },
    { code: "WI", name: "Wisconsin" },
    { code: "WY", name: "Wyoming" },
  ];
  const stateOptions = states
    ? states.map((state) => ({
        value: state.code,
        label: state.name,
      }))
    : [];

  const fldStreet = (
    // <FormTextInput
    //   name="address"
    //   label="Street"
    //   formik={formik}
    //   labelPosition="top"
    // />
    <FormElement
      element="InputText"
      id="address"
      name="address"
      label="Street address"
      formik={formik}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      value={formik.values.address}
      size="63"
    />
  );

  // const fldStreet2 = (
  //   <FormTextInput
  //     name="address2"
  //     label="Street 2"
  //     formik={formik}
  //     labelPosition="top"
  //   />
  // );
  const unitNumber = (
    <FormElement
      element="InputText"
      id="address2"
      name="address2"
      label="Unit number"
      formik={formik}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      value={formik.values.address2}
      size="33"
    />
  );

  const fldCity = (
    // <FormTextInput name="address_city" label="Town/City" formik={formik} />
    <FormElement
      element="InputText"
      id="address_city"
      name="address_city"
      label="Town/City"
      formik={formik}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      value={formik.values.address_city}
      size="48"
    />
  );

  const fldState = (
    // <FormTextInput name="address_state" label="State" formik={formik} />
    // <FormElement
    //   element="InputText"
    //   id="address_state"
    //   name="address_state"
    //   label="State"
    //   formik={formik}
    //   onBlur={formik.handleBlur}
    //   onChange={formik.handleChange}
    //   value={formik.values.address_state}
    //   size="23"
    // />
    // <FormElement
    //   element="Dropdown"
    //   id="address_state"
    //   name="address_state"
    //   label="State"
    //   formik={formik}
    //   onBlur={formik.handleBlur}
    //   onChange={formik.handleChange}
    //   value={formik.values.address_state}
    //   options={stateOptions}
    //   size="23"
    // />
    <FormSelect
      id="address_state"
      name="address_state"
      label="State"
      none
      formik={formik}
      options={stateOptions}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      value={formik.values.address_state}
      labelPosition="top"
      formElSize="23"
    />
  );

  const fldZip = (
    // <FormTextInput name="address_zip" label="Zip" formik={formik} />
    <FormElement
      element="InputText"
      id="address_zip"
      name="address_zip"
      label="Zip"
      formik={formik}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      value={formik.values.address_zip}
      size="23"
    />
  );

  return (
    <>
      <p className="directions">Address fields are optional</p>

      {fldStreet}
      {unitNumber}
      {fldCity}
      {fldState}
      {fldZip}
    </>
  );
};

export default FormAddressBlock;
