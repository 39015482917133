import {
  appFonts,
  borderRadius,
  flexbox,
  fontbox,
  fullHeight,
  fullWidth,
  paddingX,
  paddingXY,
  paddingY,
} from "emotion/utils";

export const css = (theme) => ({
  ...flexbox("column", "flex-start", "flex-start", 16),
  ...fullHeight(),

  radioBtns: {
    ...flexbox("column", "flex-start", "flex-start", 12),
    ...fullWidth(),

    "> div": {
      ...borderRadius(12),
      ...fullWidth(),
      ...paddingXY(16),
      border: `1px solid ${theme?.border?.secondary}`,
    },
  },

  awardEls: {
    ...flexbox("column", "flex-start", "flex-start"),
    borderBottom: `none`,

    awardEl: {
      ...flexbox("row", "center", "flex-start", 16),
      ...paddingX(24),
      ...paddingY(16),
      borderBottom: `1px solid ${theme?.border?.secondary}`,

      title: {
        ...fontbox(appFonts.inter, 14, 500, 20),
        color: theme?.text?.primary,
      },
    },
  },
});
