/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import React, { useEffect, useState, useRef } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { useConfirmationModal } from "confirmation-modal/confirmation-modal";

import CustomButton from "components/custom-button/custom-button";
import FormSelect from "components/form-select";
import CustomModal from "components/custom-modal/custom-modal";

import { useMutation } from "hooks/use-mutation";
import { useQuery } from "hooks/use-query";
import Client from "client";
import FormInput from "components/form-input/form-input";
import Button from "components/button/button";
import { FormElement } from "components/form-element/form-element";
import Icon from "components/icon/icon";
import MemberModal from "../members/member-modal";

const ShowRegistrantsAddModal = (props) => {
  const {
    setShowModal = () => {},
    showId,
    show,
    onSuccess,
    setActiveRider,
    registeredRiders,
  } = props;

  const {
    showModal: showConfirmationModal,
    handleExit,
    handleClose: handleConfirmationClose,
  } = useConfirmationModal();

  const timeoutRef = useRef();

  //const { allRiders = { data: [] }, isLoading } = useQuery("/members?ageFilter=true", {select: ((data) => {console.log(data);data?.data.filter((r) => {console.log(r);return !(registeredRiders.contains(r))})})});

  const {
    mutate: onSubmit,
    isLoading: isSubmitLoading,
    error,
  } = useMutation({
    mutationFn: (values) => {
      values.admin_override = true;
      return Client.post(`/shows/byId/${showId || show?.id}/register`, values);
    },
    onSuccess: (data) => {
      setActiveRider(data.data);
      onSuccess(true);
    },
  });

  const [modalHeight, setModalHeight] = useState(0);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [riderData, setRiderData] = useState([]);
  const [showNewMemberModal, setShowNewMemberModal] = useState(false);
  const [createdMember, setCreatedMember] = useState(null);

  const conditionalClose = () => {
    if (isFormDirty) {
      handleExit();
    } else {
      setShowModal(false);
    }
  };

  const initialValues = {
    member_id: createdMember
      ? {
          value: createdMember.id,
          label: `${createdMember.user.first_name} ${createdMember.last_name}`,
        }
      : "",
    member_accept_waiver: true,
  };

  const validationSchema = Yup.object({
    member_id: Yup.string()
      .label("Rider")
      .required()
      .test(
        "not-duplicate",
        "Member has already been added",
        (v) => !registeredRiders?.some((r) => r.member_id === v),
      ),
  });

  const calculateModalHeight = () => {
    setTimeout(() => {
      const modalDialog = document.querySelector(".modal-content");
      if (modalDialog) {
        console.log("This Modal Height = " + modalDialog.offsetHeight + "px");
        setModalHeight(modalDialog.offsetHeight);
      }
    }, 10); // delay
  };
  useEffect(() => {
    if (showConfirmationModal) {
      calculateModalHeight();
    }
  }, [showConfirmationModal]);

  function updateRiderList(chars, callback) {
    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(async () => {
      const data = await Client.get(
        `/members/search?ageFilter=true&chars=${chars || "a"}`,
      );
      callback(data.data);
    }, 300);
  }

  const btnCreate = (formik) => (
    <Button
      label="Submit"
      disabled={
        !formik.isValid ||
        !Object.values(formik.touched).length ||
        isSubmitLoading
      }
      onClick={(e) => {
        formik.handleSubmit(e);
        calculateModalHeight();
      }}
    />
  );

  const btnCancel = (
    <Button
      label="Cancel"
      onClick={conditionalClose}
      color="secondary"
      variant="outlined"
    />
  );

  const footerButtons = (formik) =>
    [btnCancel, btnCreate(formik)].map((btn, index) => (
      <React.Fragment key={index}>{btn}</React.Fragment>
    ));

  const riderOptionLabel = (opt) => (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span style={{ flexBasis: "50%", fontSize: 16 }}>
        {opt.first_name} {opt.last_name}
      </span>
      {/*<span style={{ flexBasis: '50%', color: 'gray', fontSize: 14 }}>{opt.username}</span>*/}
    </div>
  );

  console.log(createdMember);

  const modals = (
    <>
      {showNewMemberModal && (
        <MemberModal
          setShowModal={(_, addedMember) => {
            console.log("setShow called");
            if (addedMember) {
              onSuccess();
              addedMember.member_id = addedMember.id;
              setShowNewMemberModal(false);
              setCreatedMember(addedMember);
              onSubmit(addedMember);
            } else {
              setShowNewMemberModal(false);
            }
          }}
        />
      )}
    </>
  );
  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form>
            <CustomModal
              headerTitle="Add rider to show"
              footerButtons={footerButtons(formik)}
              onHide={conditionalClose}
            >
              <FormSelect
                name="member_id"
                label="Rider Name"
                formik={formik}
                labelPosition="top"
                none
                async
                placeholder="Begin typing to select a rider"
                defaultOptions={true}
                value={formik.values.member_id}
                loadOptions={updateRiderList}
                getOptionLabel={riderOptionLabel}
                getOptionValue={(opt) => opt.member_id}
              />

              <Button
                icon={<Icon icon="Plus" />}
                iconPos="left"
                label="Add new member"
                variant="link"
                onClick={() => {
                  setShowNewMemberModal(true);
                }}
              />

              <FormElement
                element="InputText"
                id="rider_id"
                name="rider_id"
                label="Rider number"
                formik={formik}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.rider_id}
                placeholder="Input a rider number or leave empty to auto generate"
              />
            </CustomModal>
          </Form>
        )}
      </Formik>
      {modals}
    </>
  );
};

export default ShowRegistrantsAddModal;
