/** @jsxImportSource @emotion/react */

// import Select from "react-select";

import { useQuery } from "hooks";

import { FormElement } from "components/form-element/form-element";

export default function LocationInput(props) {
  const { id, label, name, formik, readOnly } = props;

  const { data: locations } = useQuery("/locations");

  const options = (locations?.data || []).map((l) => ({
    value: l.id,
    label: l.name,
  }));

  options.unshift({
    value: "",
    label: "None",
  });

  // const getLabel = label && <h3>{label}</h3>;

  const value = options.find((opt) => opt.value === formik.values[name]);
  console.log("value", value);

  return (
    <>
      {/* <Select
        id={name}
        name={name}
        options={options}
        onChange={(val) => {
          formik.setFieldValue(name, val.value);
          formik.setFieldTouched(name);
        }}
        formik={formik}
        value={value}
        isDisabled={readOnly}
      /> */}
      <FormElement
        id={id}
        name={name}
        element="InputText"
        label={label}
        formik={formik}
        readOnly={readOnly}
        disabled={true}
        value={value?.label}
      />
    </>
  );
}
