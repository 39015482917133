import { flexbox } from "../../emotion/utils";

export const css = (fill, stroke, isOpen) => ({
  transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",

  svg: {
    ...flexbox("column", "center", "center", 0),

    path: {
      fill,
      stroke,
    },
  },
});
