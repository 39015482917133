import React from "react";
import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import moment from "moment";

import imgLogo from "images/logo.png";
import imgQr from "images/qr.png";

const PrintPDF = ({
  getChildren,
  children,
  size = "A4",

  pageStyle,
  splitPageBy = [],

  headerStyle,
  headerText,
  headerTextStyle,

  showSubHeader = false,
  subHeaderTextStyle,
  showQR = false,

  header = null,
}) => {
  const cssPage = [css.page, { ...pageStyle }];

  const getHeader = (subheader, num) => {
    const cssHeader = [css.header, { ...headerStyle }];
    const cssHeaderText = [css.header.title, { ...headerTextStyle }];
    const cssSubHeaderText = [css.header.day, { ...subHeaderTextStyle }];

    return (
      <>
        {headerText && headerText.trim() !== "" ? (
          <View style={cssHeader}>
            <Text style={cssHeaderText}>{headerText}</Text>
            {showSubHeader && (
              <Text style={cssSubHeaderText}>
                Day {num} &mdash;{" "}
                {moment(subheader).format("dddd MMMM D, YYYY")}
              </Text>
            )}
          </View>
        ) : null}
      </>
    );
  };

  const headerMethod = header ? header : getHeader;

  const footer = (
    <View style={css.footer}>
      {showQR && (
        <View style={css.footer.qr}>
          <Image source={imgQr} style={css.footer.qrImg} />
          <Text style={css.footer.qrText}>
            Scan the QR code to download the Showmanship app!
          </Text>
        </View>
      )}

      <Image source={imgLogo} style={css.footer.logo} />
    </View>
  );

  let page;
  if (splitPageBy.length > 0) {
    page = (
      <>
        {splitPageBy.map((el, index) => (
          <Page key={index} size={size} style={cssPage}>
            {headerMethod(el, index + 1)}

            {splitPageBy && <>{getChildren(el, index)}</>}

            {footer}
          </Page>
        ))}
      </>
    );
  } else {
    page = (
      <Page size={size} style={cssPage}>
        {headerMethod()}

        {splitPageBy && <>{children}</>}

        {/* {footer} */}
      </Page>
    );
  }

  return <Document>{page}</Document>;
};

export default PrintPDF;

const css = StyleSheet.create({
  page: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    paddingHorizontal: 30,
    paddingTop: 30,
    paddingBottom: 60,
  },

  header: {
    gap: 4,
    marginBottom: 25,
    width: "100%",

    title: {
      color: "#202B72",
      fontSize: 22,
      fontWeight: "700",
    },

    day: {
      color: "#D52C51",
      fontSize: 16,
      fontWeight: "700",
    },
  },

  footer: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "absolute",
    bottom: 30,
    left: 30,
    right: 30,

    qr: {
      alignItems: "center",
      flexDirection: "row",
      gap: 10,
    },
    qrImg: {
      height: 50,
      width: 50,
    },
    qrText: {
      color: "#202B72",
      fontSize: 14,
      fontWeight: "700",
      maxWidth: 200,
    },

    logo: {
      height: 40,
      marginLeft: "auto",
      width: 40,
    },
  },
});
