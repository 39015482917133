/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import Icon from "components/icon/icon";

import { css } from "./css";
import { useContext } from "react";
import AuthContext from "context/auth-context";

const CurrentUserBlock = () => {
  const theme = useTheme();

  const { logout, userData } = useContext(AuthContext);

  const avatar = (
    <div css={css(theme).avatar}>
      <Icon icon="Users" />
    </div>
  );

  const info = (
    <div css={css(theme).info}>
      <p>
        {userData?.first_name} {userData?.last_name}
      </p>
      <p>{userData?.email || ""}</p>
    </div>
  );

  const btnLogout = (
    <div css={css(theme).btnLogout} onClick={logout}>
      <Icon icon="Logout" />
    </div>
  );

  return (
    <div css={css(theme)}>
      {avatar}
      {info}
      {btnLogout}
    </div>
  );
};

export default CurrentUserBlock;
