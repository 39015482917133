import React from "react";

import css from "./form-item-group.module.scss";

const FormItemGroup = ({
  children,
  stacked = false,
  showTitle = true,
  labelSize = 12,
  padx = 10,
  pady = 10,
  width = 100,
  type = "default",
  title = " ",
  bgColor = "#f8f8f8",
  gap = null,
  maxWidth = null,
  align = "left",
}) => {
  // console.log("FormItemGroup: stacked", stacked);

  const getAlignItems = () => {
    const alignments = {
      left: "flex-start",
      center: "center",
      right: "flex-end",
    };

    return alignments[align];
  };

  const styles = {
    alignItems: getAlignItems(),
    backgroundColor: bgColor,
    gap,
    maxWidth,
    paddingTop: padx,
    paddingBottom: padx,
    paddingRight: pady,
    paddingLeft: pady,
    width: `${width}%`,
  };

  const labelStyles = {
    alignItems: getAlignItems(align),
    fontSize: labelSize,
  };

  const isStacked = stacked ? css.stacked : css.notStacked;

  const isTypeCustodian = type === "custodian" && css.typeCustodian;
  const classes = `${css.formItemGroup} ${isStacked} ${isTypeCustodian}`;

  return (
    <div className={classes} style={{ ...styles }}>
      {showTitle && (
        <div className={css.title} style={{ ...labelStyles }}>
          {title}
        </div>
      )}
      {children}
      {stacked}
    </div>
  );
};

export default FormItemGroup;
