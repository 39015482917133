import React from "react";
import { useMutation } from "@tanstack/react-query";

import Client from "client";

import CustomModal from "components/custom-modal/custom-modal";
import Button from "components/button/button";

export default function UserRemoveModal(props) {
  const {
    setShowModal = () => {},
    user,
    onAccept = () => {},
    userRoute,
    nameOfUser,
  } = props;

  const { mutateAsync: submitRequest, isLoading } = useMutation({
    networkMode: "always",
    mutationFn: async () =>
      Client.patch(`${userRoute}/${user.data.id}`, { archived: user.archive }),
    onSuccess: () => {
      setShowModal(false);
      onAccept();
    },
  });

  const headerTitle = `Remove ${nameOfUser?.toLowerCase()} ${user?.data?.name}`;

  const btnCancel = (
    <Button
      label="Close"
      color="secondary"
      variant="outlined"
      onClick={() => setShowModal(false)}
    />
  );

  const btnAction = (
    <Button
      // label={user.archive ? "Remove" : "Reactivate"}
      label="Remove"
      color="danger"
      onClick={submitRequest}
      loading={isLoading}
      disabled={isLoading}
    />
  );

  const footerButtons = [btnCancel, btnAction].map((btn, index) => (
    <React.Fragment key={index}>{btn}</React.Fragment>
  ));

  return (
    <CustomModal
      headerTitle={headerTitle}
      footerButtons={footerButtons}
      onHide={() => setShowModal(false)}
    >
      <p>
        Are you sure you want to {user?.archive ? "reactivate" : "remove"}{" "}
        {nameOfUser?.toLowerCase()} <strong>{user?.data?.name}</strong>?
      </p>
    </CustomModal>
  );
}
