import {
  appFonts,
  borderBottomLeftRadius,
  borderBottomRightRadius,
  borderRadius,
  borderTopLeftRadius,
  borderTopRightRadius,
  flexbox,
  fontbox,
  paddingX,
  paddingXY,
  paddingY,
} from "emotion/utils";

export const css = (theme) => ({
  selectContainer: {
    select: {
      ...borderRadius(8),
      ...flexbox("row", "stretch", "flex-start", 1, "wrap"),
      backgroundColor: theme?.border?.primary,
      border: `1px solid ${theme?.border?.primary}`,
    },

    selectButton: {
      ...fontbox(appFonts.inter, 14, 600, 20),
      ...paddingXY(8),
      backgroundColor: theme?.background?.primary,
      border: "none",
      color: theme?.text?.secondary,
      cursor: "pointer",
      flex: 1,

      "&:first-of-type": {
        ...borderTopLeftRadius(8),
        ...borderBottomLeftRadius(8),
      },

      "&:last-of-type": {
        ...borderTopRightRadius(8),
        ...borderBottomRightRadius(8),
      },

      selected: {
        backgroundColor: theme?.background?.bgActive,
      },
    },
  },
});
