/**@jsxImportSource @emotion/react */
import React from "react";
import { FormElement } from "components/form-element/form-element";
import * as Yup from "yup";

export const initialValues = {
  name: "",
  startAge: "",
  endAge: "",
  alias: "", // 2 chars
};

export const existingValues = (data) => ({
  name: data.name,
  startAge: data.start_age,
  endAge: data.end_age,
  alias: data.alias, // 2 chars
});

export const validationSchema = Yup.object({
  name: Yup.string().label("Age group name").required(),
  startAge: Yup.number().label("Age range start").required(),
  endAge: Yup.number().label("Age range end").required(),
  alias: Yup.string().label("Abbreviation").min(1).max(2).required(),
});

export const postUrl = `/class-age-categories`;

export const body = (data) => ({ ...data });

export default function AgeGroupForm(props) {
  const { formik } = props;

  const updateNumberField = (name) => (v) => {
    if (!v.target.value) {
      formik.setFieldValue(name, "");
      return;
    }

    const n = parseInt(v.target.value);
    if (isNaN(n)) {
      formik.setFieldValue(name, "");
      return;
    }

    formik.setFieldValue(name, n);
  };

  return (
    <>
      <FormElement
        element="InputText"
        id="name"
        name="name"
        label="Age group name"
        formik={formik}
        onBlur={formik?.handleBlur}
        onChange={formik?.handleChange}
        value={formik?.values?.name || ""}
        required
        size="25"
      />

      <FormElement
        element="InputText"
        id="startAge"
        name="startAge"
        label="Age range start"
        formik={formik}
        onBlur={formik?.handleBlur}
        onChange={updateNumberField("startAge")}
        value={formik.values.startAge?.toString() || ""}
        required
        size="25"
      />

      <FormElement
        element="InputText"
        id="endAge"
        name="endAge"
        label="Age range end"
        formik={formik}
        onBlur={formik?.handleBlur}
        onChange={updateNumberField("endAge")}
        value={formik.values.endAge?.toString() || ""}
        required
        size="25"
      />

      <FormElement
        element="InputText"
        id="alias"
        name="alias"
        label="Abbreviation (up to 3 characters)"
        formik={formik}
        onBlur={formik?.handleBlur}
        onChange={formik?.handleChange}
        value={formik?.values?.alias}
        required
        size="43"
      />
    </>
  );
}
