/** @jsxImportSource @emotion/react */

import {
  borderRadius,
  fontbox,
  fullWidth,
  paddingX,
  theme,
} from "emotion/utils";

const FormInput = ({
  formik,
  name,
  number,
  onInput,
  onKeyUp,
  readOnly,
  style,
  placeholder,
  testChange,
  onChange,
  value,
}) => {
  const errorClass =
    formik?.errors[name] && formik?.touched[name] ? "error" : "";

  const inputProps = {
    css: css.input,
    className: errorClass,
    style: style,
    type: number ? "number" : "text",
    pattern: number ? "[0-9]*" : null,
    id: name,
    readOnly,
    name,
    placeholder,
    onBlur: formik?.handleBlur,
    onChange: formik?.handleChange || onChange,
    onInput: onInput,
    onKeyUp,
    value: value,
  };

  return <input {...inputProps} />;
};

const css = {
  input: {
    ...borderRadius(4),
    ...fontbox(theme.fonts.inter, 16, 400, 16 * 1.44),
    ...fullWidth(),
    ...paddingX(10),
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.lightGrey}`,
    color: theme.colors.nero,
    height: "40px!important",
  },
};

export default FormInput;
