/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import React from "react";
import { Form } from "formik";

//import { required } from "validation/form";
import { FormErrorMessage } from "validation/form-error-message";

//import { required } from "validation/form";
import useShowClassCreate from "./use-show-class-create";
import CustomModal from "components/custom-modal/custom-modal";
import Button from "components/button/button";
import { FormElement } from "components/form-element/form-element";
import CheckboxGroup from "components/form-element/elements/checkbox-group";
import Divider from "components/divider/divider";

export default function ShowModalClassForm(props) {
  const { formik, show, handleExit, setShowModal } = props;

  const data = useShowClassCreate({ show, formik });

  const conditionalClose = () => {
    if (formik.dirty) {
      handleExit();
    } else {
      setShowModal(false);
    }
  };

  if (data.isLoading) {
    return <div>Loading...</div>;
  }

  if (data.error) {
    return <div>Error!!!</div>;
  }

  const headerTitle = "Build classes";

  const btnCancel = (
    <Button
      key="cancel"
      disabled={data.isLoading}
      label="Cancel"
      onClick={conditionalClose}
      color="secondary"
      variant="outlined"
    />
  );

  const btnAddToShow = (formik) => (
    <Button
      key="add-to-show"
      disabled={!(formik.isValid && formik.dirty)}
      label="Add to show"
      onClick={(e) => {
        formik.handleSubmit(e);
        // calculateModalHeight();
      }}
    />
  );

  const footerButtons = (formik) => [btnCancel, btnAddToShow(formik)];

  const levelCheckboxGroup = (els, formik, label, size, required = false) => (
    <CheckboxGroup
      label={label}
      els={els}
      onChange={(id) => {
        const { levels } = formik.values;

        const index = levels.findIndex((l) => l === id);
        const newLevels =
          index < 0
            ? [...levels, id]
            : [...levels.slice(0, index), ...levels.slice(index + 1)];

        formik.setFieldValue("levels", newLevels);
      }}
      checked={formik.values.levels}
      size={size}
      required={required}
    />
  );

  const modifierCheckboxGroup = (els, formik, label, size) => (
    <CheckboxGroup
      label={label}
      els={els}
      onChange={(id) => {
        const { modifiers } = formik.values;

        const index = modifiers.findIndex((l) => l === id);
        const newModifiers =
          index < 0
            ? [...modifiers, id]
            : [...modifiers.slice(0, index), ...modifiers.slice(index + 1)];

        formik.setFieldValue("modifiers", newModifiers);
      }}
      checked={formik.values.modifiers}
      formik={formik}
      size={size}
    />
  );

  return (
    <Form>
      <CustomModal
        headerTitle={headerTitle}
        footerButtons={footerButtons(formik)}
        onHide={conditionalClose}
      >
        <FormElement
          element="Calendar"
          id="startAt"
          name="startAt"
          label="Date"
          formik={formik}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.startAt}
          date="true"
          min={show?.start_at}
          max={show?.end_at}
          required
          size="43"
        />
        <FormErrorMessage name="startAt" formik={formik} />

        <FormElement
          element="Dropdown"
          id="arenaId"
          name="arenaId"
          label="Arena"
          placeholder="Select arena"
          formik={formik}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.arenaId}
          options={data.arenaOptions}
          required
          size="52"
        />
        <FormErrorMessage name="arenaId" formik={formik} />

        <FormElement
          element="Dropdown"
          id="disciplineId"
          name="disciplineId"
          label="Discipline"
          placeholder="Select discipline"
          formik={formik}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.disciplineId}
          options={data.disciplineOptions}
          required
          editable={true}
        />

        <FormElement
          element="Dropdown"
          id="classTypeId"
          name="classTypeId"
          label="Class type"
          placeholder="Select class type or begin typing"
          formik={formik}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.classTypeId}
          options={data.classTypeOptions}
          required
          editable
        />

        <FormElement
          element="Dropdown"
          id="ageGroupId"
          name="ageGroupId"
          label="Age group"
          placeholder="Select age group or begin typing"
          formik={formik}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.ageGroupId}
          options={data.ageGroupOptions}
          required
          editable
        />

        {levelCheckboxGroup(data.levelOptions, formik, "Level", null, true)}

        {modifierCheckboxGroup(data.modifierOptions, formik, "Modifiers")}

        <Divider />

        <FormElement
          element="Checkbox"
          id="excludeFromHighPt"
          name="excludeFromHighPt"
          label="Exclude from High Point"
          formik={formik}
          onChange={formik.handleChange}
          checked={formik.values.excludeFromHighPt}
          // size={size || null}
        />
      </CustomModal>
    </Form>
  );
}
