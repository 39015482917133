import {
  appFonts,
  flexbox,
  fontbox,
  fullHeight,
  marginX,
  paddingXY,
} from "emotion/utils";

export const css = (theme) => ({
  ...flexbox("column", "flex-start", "flex-start", 16),
  ...fullHeight(),

  showCard: {
    intro: {
      ...flexbox("row", "center", "space-between", 16),
      flex: 1,

      title: {
        ...fontbox(appFonts.inter, 20, 700, 30),
        color: theme?.text?.primary,
      },
    },

    infoActions: {
      ...flexbox("row", "center", "space-between", 16),

      info: {
        span: {
          ...fontbox(appFonts.inter, 16, 500, 24),
          color: theme?.text?.primary,

          "&:first-of-type::after": {
            ...marginX(12),
            content: '"|"',
          },

          "&:last-of-type": {
            textTransform: "capitalize",
          },
        },
      },

      actions: {
        ...flexbox("row", "center", "space-between", 0),

        btn: {
          ...paddingXY(8),
          cursor: "pointer",
        },
      },
    },
  },
});
