import {
  appFonts,
  flexbox,
  fontbox,
  fullWidth,
  paddingX,
  paddingXY,
  paddingY,
} from "emotion/utils";

export const css = (theme) => ({
  ...flexbox("row", "center", "space-between", 50),
  ...fullWidth(),

  content: {
    ...flexbox("row", "center", "flex-start", 8),
    flex: 1,

    "> button": {
      ...paddingX(12),
      ...paddingY(8),
    },
  },

  titleBlock: {
    ...flexbox("row", "center", "flex-start", 8),
    // flex: 1,

    h1: {
      ...fontbox(appFonts.inter, 20, 700, 40),
      color: theme?.text?.primary,
    },

    "> div": {
      display: "inline-block",
      // flex: 1,
    },

    input: {
      ...fontbox(
        appFonts.inter,
        "20px!important",
        "700!important",
        "40px!important",
      ),
      ...paddingXY(0),
      // ...paddingY(2),
      // border: "none",
      color: theme?.text?.primary,
      border: `1px solid ${theme?.border?.primary}`,
      // borderRadius: 8,
      // flex: 1,
      height: 40,
      // marginTop: -3,
      marginLeft: -15,
      // minWidth: 315,
      // padding: "10px 16px",
      width: "100%",
    },
  },

  actions: {
    ...flexbox("row", "center", "flex-end", 16),
    // flex: 1,

    // "> button": {
    //   ...paddingX(12),
    //   ...paddingY(8),
    // },
  },
});
