/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import React, { useState } from "react";
import { Outlet, useParams, useNavigate } from "react-router-dom";

import { useQuery } from "hooks";

import TitleBlock from "components/title-block/title-block";
import Button from "components/button/button";
import Icon from "components/icon/icon";
import { FormElement } from "components/form-element/form-element";
import CustomDivTable from "components/custom-table/custom-div-table";
import CustomDivTableActions from "components/custom-table/custom-div-table-actions";
import ButtonIcon from "components/button-icon/button-icon";
import Tag from "components/tag/tag";

import ClassTitleInput from "./class-title-input";

import { css } from "./css";

import ModalAddRiderToClass from "./modals/modal-add-rider-to-class";

import Client from "client";

export default function ShowClass() {
  const { classId } = useParams();
  const navigate = useNavigate();

  const theme = useTheme();

  const {
    data: showClass,
    refetch,
    isLoading,
    isError,
  } = useQuery(`/show-classes/${classId}`);

  const { refetch: getRegistrants } = useQuery(
    `/registrants/class-registrants/${classId}`,
    {
      onSuccess: (data) => {
        if (data) {
          setRows(data.map((r) => ({ ...r, lot_num: r.registrant.lot_num })));
          const existingLot =
            Object.keys(
              data?.reduce((map, r) => {
                map[r.registrant.lot_num] = true;
                return map;
              }, {}),
            )?.length || 1;
          setExistingLotNum(existingLot);
          //setTargetLots(existingLot)
        }
      },
    },
  );

  // console.log('classId', classId);
  const [existingLotNum, setExistingLotNum] = useState();
  const [targetLots, setTargetLots] = useState(existingLotNum); //existingLotNum
  const [rows, setRows] = useState([]);

  const [showAddRiderToClassModal, setShowAddRiderToClassModal] =
    useState(false);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error!!!d</div>;
  }

  // const btnPaddockPDF = (
  //   <CustomPdfButton
  //     label="Print Paddock Sheets"
  //     document={
  //       <PaddockSheetsPrintPDF
  //         title={`${show.name} Paddock Sheet`}
  //         data={selectedProducts.length > 0 ? {data: selectedProducts.map((sp) => sp.data)} : filteredRows.data ? filteredRows : null}
  //       />
  //     }
  //     fileName="schedule"
  //   />
  // );

  // const btnPrintPaddockSheet = (
  //   <Button
  //     icon={<Icon icon="Printer" />}
  //     iconPos="left"
  //     label="Print paddock sheet"
  //     color="primary"
  //     variant="outlined"
  //     onClick={() => {
  //       // alert("Print paddock sheet functionality")
  //       window.print();
  //     }}
  //   />
  // );

  const btnAddRider = (
    <Button
      icon={<Icon icon="Plus" />}
      iconPos="left"
      label="Add rider"
      color="primary"
      variant="outlined"
      onClick={() => {
        // alert("Add rider functionality");
        setShowAddRiderToClassModal(true);
      }}
    />
  );

  const actions = [];

  const saveLots = async (rows) => {
    try {
      const data = rows.map((r) => ({
        id: r.registrant.show_class_registration_id,
        lot_num: r.lot_num,
      }));
      console.log(data);
      await Client.put(`/registrants/class-registrants/${showClass.id}`, data);
    } catch (err) {
      alert(err);
    }
  };

  const splitClass = (e) => {
    const targetLots = e.target.value;
    if (!targetLots) {
      setTargetLots("");
      return;
    }

    const num = Math.min(e.target.value, rows?.length || 1);

    const newRows = rows.map((row, i) => ({
      ...row,
      lot_num: (i % num) + 1,
    }));

    setTargetLots(num.toString());
    setRows(newRows);
    saveLots(newRows);
  };

  const actionsBlock = (
    <TitleBlock>
      <div>
        <Button
          icon={<Icon icon="ChevronLeft" />}
          iconPos="left"
          label="Return to classes"
          color="primary"
          variant="outlined"
          onClick={() => navigate(-1)}
        />
      </div>

      <div css={css(theme).actions}>
        {actions.map((btn, index) => (
          <React.Fragment key={index}>{btn}</React.Fragment>
        ))}

        <div css={css(theme).actions.inputGroup}>
          <FormElement
            element="InputText"
            id="numLots"
            name="numLots"
            placeholder="1"
            value={targetLots}
            onChange={(e) => splitClass(e)}
            min={1}
            max={showClass.registrants?.length}
          />
          <label>Lots</label>
        </div>
      </div>
    </TitleBlock>
  );

  const titleBlock = (
    <TitleBlock>
      <ClassTitleInput showClass={showClass} refetch={refetch} />
    </TitleBlock>
  );

  const modals = (
    <>
      {showAddRiderToClassModal && (
        <ModalAddRiderToClass
          setShowModal={setShowAddRiderToClassModal}
          show={showAddRiderToClassModal}
        />
      )}
    </>
  );

  const removeRider = async (val) => {
    const filtered = rows
      ?.filter((el) => el !== val)
      .map((r) => ({
        id: r.registrant.show_class_registration_id,
        lot_num: r.lot_num,
      }));
    try {
      await Client.put(`/registrants/class-registrants/${classId}`, filtered);
      refetch();
    } catch (err) {
      alert(err);
    }
  };

  const disableChanges =
    showClass?.time_started || new Date(showClass?.start_at) < new Date();

  const columns = [
    {
      name: "name",
      valFn: (it) => {
        return `${it.first_name} ${it.last_name}`;
      },
      display: "Rider name",
    },
    {
      name: "horse_name",
      valFn: (it) => it?.registrant?.animal.name,
      display: "Horse",
    },
    {
      name: "rider_num",
      valFn: (it) => it?.registrant?.rider_number,
      display: "Rider #",
      align: "right",
      alignHeader: "right",
    },
    {
      name: "club",
      valFn: (it) => {
        return it.club_name || "--";
      },
      display: "Club",
    },
    // {
    //   name: "score",
    //   valFn: (it) => {
    //     const result = classData.results.find(
    //       (m) => m.member_id === it.member_id,
    //     );
    //     return result?.score || "--";
    //   },
    //   display: "Score",
    // },
    // {
    //   name: "custodian_approved",
    //   // valFn: (it) => {
    //   //   return it.custodian_approved || 0;
    //   // },
    //   valFn: (it) => (
    //     <FormInputSwitch
    //       checked={!!it.custodian_waivers?.find((cw) => cw.custodian_signed_at)}
    //       readonly
    //       direction="row"
    //       isCard={false}
    //     />
    //   ),
    //   display: "Custodian Approved",
    // },
    {
      name: "payment_status",
      valFn: (it) => {
        return it.registrant.paid_verification_code ? (
          <Tag tag="Paid" />
        ) : it.registrant.paid_date ? (
          <Tag tag="Paid (admin)" />
        ) : (
          <Tag tag="Unpaid" />
        );
      },
      display: "Payment status",
    },
    // {
    //   name: "actions",
    //   valFn: (it) => (
    //     <div
    //       style={{
    //         display: "flex",
    //         flexDirection: "row",
    //         gap: 8,
    //         justifyContent: "flex-end",
    //       }}
    //     >
    //       {it.registrant.paid_date ? (
    //         <></>
    //       ) : (
    //         <CustomButton
    //           label="Mark Paid"
    //           color="quarternary"
    //           type="outlined"
    //           align="unset"
    //           onClick={() => {
    //             handleMarkPaid(it);
    //           }}
    //           isInTable
    //           fs={10}
    //         />
    //       )}
    // <CustomButton
    //   label="Remove"
    //   color="tertiary"
    //   type="outlined"
    //   align="unset"
    //   disabled={disableChanges}
    //   onClick={() => {
    //     removeRider(it);
    //   }}
    //   isInTable
    //   fs={10}
    // />
    //     </div>
    //   ),
    //   display: "",
    //   actionCol: true,
    // },
    {
      name: "actions",
      valFn: (it) => (
        <CustomDivTableActions>
          <ButtonIcon
            icon={<Icon icon="Trash" />}
            disabled={disableChanges}
            onClick={() => {
              removeRider(it);
            }}
          />
        </CustomDivTableActions>
      ),
      display: "",
      actionCol: true,
    },
  ];

  const handleRowReorder = (e) => {
    let draggedRow = rows[e.dragIndex];
    let droppedRow = rows[e.dropIndex];
    console.log("Drag Idx", e.dragIndex);
    console.log("Drop Idx", e.dropIndex);

    console.log("drag", draggedRow);
    console.log("drop", droppedRow);
    draggedRow.lot_num = droppedRow.lot_num;
    console.log("dragged", draggedRow);
    const reorderedRows = [...rows];
    console.log("Reordered", reorderedRows);
    setRows(reorderedRows);
    saveLots(reorderedRows);
  };

  const headerTemplate = (data) => {
    return <div>Lot # {data?.data.lot_num || "--"}</div>;
  };

  const ridersTable = (
    <CustomDivTable
      columns={columns}
      rows={rows}
      rowGroupMode="subheader"
      groupBy="data.lot_num"
      reorderableColumns
      selectionMode="single"
      noTableSort={true}
      sortField="data.lot_num"
      tableStyle={{ minWidth: "50rem" }}
      rowGroupHeaderTemplate={headerTemplate}
      //multiSortMeta={[{ field: "name" }, { field: "lot_num" }]}
      enableReinitialize={false}
      reorderableRows
      sortOrder={1}
      onRowReorder={handleRowReorder}
      //noTableSort={true}
      multiSortMeta={[{ field: "data.name" }, { field: "data.lot_num" }]}
      emptyMessage="No riders found"
    />
  );

  return (
    <div css={css(theme)}>
      {actionsBlock}
      {titleBlock}
      {modals}

      {/* <Outlet context={{ showClass }} numLots={targetLots}></Outlet> */}
      {ridersTable}
    </div>
  );
}
