import React from "react";

import BaseAwardCard from "./base-award-card";
import CustomButton from "components/custom-button/custom-button";

import css from "./base-award.module.scss";

export default function BaseAwardSection(props) {
  const {
    data = [],
    label,
    buttonLabel,
    onCreate,
    onEdit,
    onArchive,
    activeBtnGroup,
  } = props;

  const handleCreate = () => {
    onCreate(null);
  };

  return (
    <div className={css.awards}>
      <div className={css.awardsTitle}>
        <h1>{label}</h1>
        <CustomButton label={buttonLabel} onClick={handleCreate} />
      </div>

      <div className={css.awardsBlock}>
        {data.map((datum) => (
          <BaseAwardCard
            key={datum.id}
            data={datum}
            onEdit={onEdit}
            onArchive={onArchive}
            activeBtnGroup={activeBtnGroup}
          />
        ))}
      </div>
    </div>
  );
}
