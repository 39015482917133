/** @jsxImportSource @emotion/react */

import CustomButton from "components/custom-button/custom-button";
import { colors, flexbox, square } from "emotion/utils";

const AwardsBtnGroup = ({ arrBtns = [], rider, onSelect, disabled }) => {
  console.log("awards btn group", rider?.show_class_result);

  if (rider?.show_class_result?.disqualified) {
    return <p>&mdash;</p>;
  }

  return (
    <div css={css.container}>
      {arrBtns.map((btn, i) => {
        return (
          <CustomButton
            key={i}
            onClick={() => onSelect(rider, btn.danish_id)}
            type="outlined"
            color="white"
            isInTable={true}
            sty={
              btn.danish_id === rider?.show_class_result?.danish
                ? css.selected_btn
                : css.btn
            }
            align="center"
          >
            {btn.img}
          </CustomButton>
        );
      })}
    </div>
  );
};

const css = {
  container: {
    ...flexbox("row", "center", "flex-start", 8),
  },

  btn: {
    ...flexbox("row", "center", "center"),
    ...square(24),
    border: `1px solid ${colors.decorationGrey}`,
    boxSizing: "border-box",
    margin: 0,
    padding: 0,
  },

  selected_btn: {
    ...flexbox("row", "center", "center"),
    ...square(24),
    border: `2px solid ${colors.darkGrey}`,
    boxSizing: "border-box",
    margin: 0,
    padding: 0,
  },
};

export default AwardsBtnGroup;
