/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import React, { useRef, useEffect } from "react";
import { Form, Formik } from "formik";

import CustomModal from "components/custom-modal/custom-modal";

import { css } from "./css";
import Button from "components/button/button";

import StemForm from "./stem-form";

import { useMutation } from "hooks/use-mutation";

import APIClient from "client";

export default function ModalClassStemEdit(props) {
  const { type, data, setShowModal, refetch } = props;

  const stemId = data.id;
  const stemType = type;

  const scrollRef = useRef();
  const contentRef = useRef();

  const theme = useTheme();

  const conditionalClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    scrollRef.current?.getContent()?.scrollTo({
      top: contentRef.current.offsetTop,
      behavior: "smooth",
    });
  }, [stemType]);

  const { mutate: submitStem } = useMutation({
    mutationFn: ({ data }) => {
      const url = `${StemForm.postUrl(stemType)}/${stemId}`;
      return APIClient.put(url, StemForm.body(stemType, data));
    },
    onSuccess: (res, { data, formik }) => {
      setShowModal(false);
      refetch();
    },
  });

  const headerTitle = "Edit class stem";

  const btnSave = (formik) => (
    <Button
      key="submit"
      type="submit"
      color="primary"
      label="Save"
      onClick={formik.handleSubmit}
      disabled={!(formik.isValid && formik.dirty)}
    />
  );

  const btnCancel = (
    <Button
      key="cancel"
      label="Cancel"
      onClick={conditionalClose}
      color="secondary"
      variant="outlined"
    />
  );

  const footerButtons = (formik) => [btnCancel, btnSave(formik)];

  const getStemTypeContent = (type, formik) => {
    return (
      <div ref={contentRef} css={css(theme).formEls}>
        {StemForm.component(formik, type) || null}
      </div>
    );
  };

  return (
    <Formik
      initialValues={StemForm.existingValues(stemType, data)}
      validationSchema={StemForm.validationSchema(stemType)}
      onSubmit={(data, formik) => submitStem({ data, formik })}
      css={css(theme)}
    >
      {(formik) => (
        <Form>
          <CustomModal
            ref={scrollRef}
            headerTitle={headerTitle}
            footerButtons={footerButtons(formik)}
            onHide={conditionalClose}
            size="md"
          >
            {getStemTypeContent(stemType, formik)}
          </CustomModal>
        </Form>
      )}
    </Formik>
  );
}
