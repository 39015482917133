import {
  appFonts,
  borderBottomLeftRadius,
  borderBottomRightRadius,
  borderRadius,
  borderTopLeftRadius,
  borderTopRightRadius,
  flexbox,
  fontbox,
  paddingX,
  paddingY,
} from "emotion/utils";

export const css = (theme) => ({
  ...borderRadius(12),
  border: `1px solid ${theme?.border?.secondary}`,
  background: theme?.background?.primary,
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",

  header: {
    ...flexbox("row", "center", "flex-start", 8),
  },

  ".p-accordion": {
    // Tab
    "&-tab": {
      "&:first-of-type": {
        ".p-accordion": {
          "&-header-link": {
            ...borderTopLeftRadius(12),
            ...borderTopRightRadius(12),
          },
        },
      },
      "&:last-of-type": {
        ".p-accordion": {
          "&-header-link": {
            ...borderBottomLeftRadius(12),
            ...borderBottomRightRadius(12),
          },
        },
      },
      "&:only-of-type": {
        ".p-accordion": {
          "&-header-link": {
            ...borderRadius("12px 12px 0 0"),
          },
        },
      },
    },

    // Tab Header
    "&-header": {
      ...borderRadius(0),
    },

    // Tab Header Link
    "&-header-link": {
      ...borderRadius(0),
      ...flexbox("row", "center", "flex-start", 24),
      ...paddingX(24),
      ...paddingY(12),
      background: theme?.accordion?.tabBg,
      border: 0,
      borderBottom: `1px solid ${theme?.border?.secondary}`,

      "> svg": {
        order: 1,
      },

      "> span": {
        ...fontbox(appFonts.inter, 16, 700, 24),
        color: theme?.text?.tertiary,
        order: 0,
      },
    },

    // Tab Content
    "&-content": {
      ...borderRadius(0),
      background: theme?.background?.primary,
      border: 0,
      padding: 0,
    },
  },
});
