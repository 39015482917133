import React from "react";
import * as Yup from "yup";

import { FormElement } from "components/form-element/form-element";

export const initialValues = {
  name: "",
  alias: "",
  description: "",
};

export const existingValues = (data) => ({
  name: data.name,
  alias: data.alias,
  description: data.description,
});

export const validationSchema = Yup.object({
  name: Yup.string().label("Discipline name").required(),
  alias: Yup.string().label("Abbreviation").min(1).max(3).required(),
  description: Yup.string().label("Description").optional(),
});

export const postUrl = `/class-disciplines`;

export const body = (data) => ({ ...data });

export default function DisciplineForm(props) {
  const { formik } = props;

  return (
    <>
      <FormElement
        element="InputText"
        id="name"
        name="name"
        label="Discipline name"
        formik={formik}
        onBlur={formik?.handleBlur}
        onChange={formik?.handleChange}
        value={formik?.values?.name || ""}
        required
        size="54"
      />

      <FormElement
        element="InputText"
        id="alias"
        name="alias"
        label="Abbreviation (up to 3 characters)"
        formik={formik}
        onBlur={formik?.handleBlur}
        onChange={formik?.handleChange}
        value={formik?.values?.alias}
        required
        size="43"
      />

      <FormElement
        element="InputTextarea"
        id="description"
        name="description"
        label="Description (optional)"
        formik={formik}
        onBlur={formik?.handleBlur}
        onChange={formik?.handleChange}
        value={formik?.values?.description}
      />
    </>
  );
}
