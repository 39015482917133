/** @jsxImportSource @emotion/react */
import { Checkbox } from "primereact/checkbox";

const FormCheckbox = ({ checked = false, onChange, align = "left" }) => {
  const cssCheckbox = {
    margin:
      align === "center" ? "0 auto" : align === "right" ? "0 0 0 auto" : "0",
  };

  return <Checkbox checked={checked} onChange={onChange} css={cssCheckbox} />;
};

export default FormCheckbox;
