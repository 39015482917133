import { colors } from "emotion/utils";
import React from "react";

const IconRibbon = ({
  fill = colors.secondary,
  stroke = colors.secondary,
  style,
}) => {
  return (
    <svg width="9" height="17" viewBox="0 0 9 17" fill="none" style={style}>
      <path
        d="M1 2.5L1 15.5L4.5 13.25L8 15.5L8 2.5C8 1.39543 7.10457 0.5 6 0.5L3 0.5C1.89543 0.5 1 1.39543 1 2.5Z"
        fill={fill}
        stroke={stroke}
      />
    </svg>
  );
};

export default IconRibbon;
