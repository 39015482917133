import React from "react";
import { Route } from "react-router-dom";

import UnProtectedLayout from "layouts/unprotected";

import Login from "pages/login/login";
import ConfirmCustodian from "pages/custodian/confirm-custodian";
import CustodianConfirmed from "pages/custodian/custodian-confirmed";
import ResetPassword from "pages/login/reset-password";
// import AcceptInvite from 'login/accept-invite';

const LoginRouter = (
  <Route path="">
    <Route element={<UnProtectedLayout />}>
      <Route path="/login" element={<Login />} />
    </Route>

    <Route
      path="/reset-password"
      element={<ResetPassword></ResetPassword>}
    ></Route>
    {/*
        <Route
            path="/accept-invite/:id"
            element={<AcceptInvite></AcceptInvite>}></Route>*/}
    <Route path="/custodians" element={<ConfirmCustodian />} />
    <Route path="/custodian-confirmed" element={<CustodianConfirmed />} />
  </Route>
);

export default LoginRouter;
