/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { css } from "./css";

export default function ButtonIcon(props) {
  const theme = useTheme();

  if (!props?.icon) {
    return null;
  }

  return (
    <div css={css(theme)} onClick={props?.onClick}>
      {props?.icon}
    </div>
  );
}
