/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import { useMutation } from "hooks";

import { useEffect, useState, useRef, useMemo } from "react";
import { useOutletContext } from "react-router-dom";
import moment from "moment";
import Client from "client";
import { capitalize } from "lodash";

// import { capitalizeFirstLetter } from "../../utils";
// import { USER_PAGES_DIC } from "components/constants";
// import { useQuery } from "hooks";

// import CustomDivTable from "components/custom-table/custom-div-table";
// import IconGo from "components/icons/icon-go";
// import CustomButton from "components/custom-button/custom-button";

import UserModal from "./user-modal";
import UserRemoveModal from "./user-remove-modal";
import { capitalizeFirstLetter, formattedPhone } from "utils";
import CustomDivTable from "components/custom-table/custom-div-table";
import { useQuery } from "hooks";
// import IconGo from "components/icons/icon-go";
// import CustomButton from "components/custom-button/custom-button";
import TitleBlock from "components/title-block/title-block";
import Button from "components/button/button";
import Icon from "components/icon/icon";
import CustomDivTableActions from "components/custom-table/custom-div-table-actions";
import ButtonIcon from "components/button-icon/button-icon";
import React from "react";
import ResetPasswordModal from "./user-reset-password-modal";

export default function UserPageTemplate(props) {
  const { userRoute, nameOfUser } = props;

  const [showUserModal, setShowUserModal] = useState(false);
  const [userRemoveModal, setUserRemoveModal] = useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState(false);

  const activeUser = useRef(null);
  const toastRef = useRef();

  const theme = useTheme();

  const { data, refetch } = useQuery(userRoute);

  const rows = useMemo(
    () =>
      data?.data.map((row) => ({
        ...row,
        name: `${capitalize(row.user.first_name)} ${capitalize(
          row.user.last_name,
        )}`,
        email: row.user.email,
      })),
    [data?.data],
  );

  function editUser(data) {
    activeUser.current = data;
    setShowUserModal(true);
  }

  function resetUser(data) {
    activeUser.current = data;
    setResetPasswordModal(true);
  }

  function removeUser(data, archive) {
    activeUser.current = { data, archive };
    setUserRemoveModal(true);
  }

  const columns = [
    {
      name: "name",
      default: () => "",
      valFn: (data) =>
        `${capitalizeFirstLetter(
          data?.user.first_name,
        )} ${capitalizeFirstLetter(data?.user.last_name)}`,
      display: `${nameOfUser} name`,
      sortable: false,
    },
    {
      name: "email",
      default: () => "",
      valFn: (data) => `${data.user.email ? data.user.email : "--"}`,
      display: "Email",
    },
    {
      name: "phone",
      default: () => "",
      valFn: (data) =>
        `${data.user.phone ? formattedPhone(data.user.phone) : "--"}`,
      display: "Phone",
    },
    {
      name: "joinDate",
      default: () => "",
      valFn: (data) =>
        data.start_at ? moment(data.start_at).format("MM/DD/YY") : "--",
      display: "Date Added",
      sortable: false,
      align: "right",
      alignHeader: "right",
    },
    {
      name: "status",
      default: () => "",
      valFn: (data) => `${data.user.status ? data.user.status : "--"}`,
      display: "Status",
    },
    {
      name: "actions",
      valFn: (it) => (
        <CustomDivTableActions>
          <ButtonIcon
            icon={<Icon icon="Trash" />}
            onClick={() => {
              alert(`remove ${nameOfUser} functionality`);
            }}
          />
          <ButtonIcon
            icon={<Icon icon="Refresh" />}
            onClick={() => {
              // alert(`reset password of ${nameOfUser} functionality`);
              resetUser(it);
            }}
          />
          <ButtonIcon
            icon={<Icon icon="Edit" />}
            onClick={() => editUser(it)}
          />
        </CustomDivTableActions>
      ),
      display: "",
      noSort: true,
      align: "right",
      alignHeader: "right",
    },
  ];

  const handleCreate = () => {
    activeUser.current = null;
    setShowUserModal(true);
  };

  const btnCreate = (
    <Button
      icon={<Icon icon="Plus" />}
      iconPos="left"
      label={`Add ${nameOfUser}`}
      onClick={handleCreate}
    />
  );

  const actions = [btnCreate];

  const titleBlock = (
    <TitleBlock>
      {actions.map((btn, index) => (
        <React.Fragment key={index}>{btn}</React.Fragment>
      ))}
    </TitleBlock>
  );

  const modals = (
    <>
      {showUserModal && (
        <UserModal
          setShowModal={setShowUserModal}
          user={activeUser.current}
          onSuccess={refetch}
          userRoute={userRoute}
          nameOfUser={nameOfUser}
        />
      )}

      {userRemoveModal && (
        <UserRemoveModal
          setShowModal={setUserRemoveModal}
          user={activeUser.current}
          onAccept={refetch}
          userRoute={userRoute}
          nameOfUser={nameOfUser}
        />
      )}

      {resetPasswordModal && (
        <ResetPasswordModal
          setShowModal={setResetPasswordModal}
          user={activeUser.current}
          nameOfUser={nameOfUser}
        />
      )}
    </>
  );

  return (
    <>
      {titleBlock}

      <CustomDivTable
        columns={columns}
        rows={rows}
        rowCount={10}
        // selectionMode="single"
        // onSelect={(it) => {
        //   editJudge(it);
        // }}
        filterKeys={["name", "email"]}
        showSearch={false}
        emptyMessage={`No ${nameOfUser}s found`}
      />

      {modals}
    </>
  );
}
