import React from "react";
import { Route } from "react-router-dom";

import ProtectedLayout from "layouts/protected";

import AdminLayout from "layouts/admin-layout";

import Members from "pages/admin/members/members";
import Judges from "pages/admin/judges/judges";
import Announcers from "pages/admin/announcers/announcers";
import PaddockManagers from "pages/admin/paddock-managers/paddock-managers";
import Admins from "pages/admin/admins/admins";
import AllUsers from "pages/admin/users/all-users";
import BaseAwards from "pages/admin/base-awards/base-awards";
import Clubs from "pages/admin/clubs/clubs";
import BaseClasses from "pages/admin/base-classes/base-class-disciplines";
import Locations from "pages/admin/locations/locations";
import Shows from "pages/admin/shows/shows";
import Show from "pages/admin/show/show";
import ShowClass from "pages/admin/show-class";
import ShowOverview from "pages/admin/show/show-overview";
import ShowClasses from "pages/admin/show/show-classes";
import ShowRegistrants from "pages/admin/show/show-registrants";
import ShowReports from "pages/admin/show/show-reports";
import Announcer from "pages/announcer/announcer";
import Rider from "pages/rider/rider";
import ShowClassRiders from "pages/admin/show/show-class-riders/show-class-riders";
import Patterns from "pages/admin/patterns/patterns";
import Pattern from "pages/admin/pattern/pattern";

import "scss/main.scss";
import HighPoint from "pages/admin/high-point/high-point";
import Setup from "pages/admin/setup/setup";
import Member from "pages/admin/member/member";

const AdminRouter = (
  <Route path="">
    <Route element={<ProtectedLayout portal="announcer" />}>
      <Route path="/announcer" element={<Announcer />} />
    </Route>

    <Route element={<ProtectedLayout portal="member" />}>
      <Route path="/rider" element={<Rider />} />
    </Route>

    <Route element={<ProtectedLayout portal="admin" />}>
      <Route path="/admin" element={<AdminLayout />}>
        <Route path="shows" element={<Shows />} />

        <Route path="shows/:showId" element={<Show />}>
          <Route index path="overview" element={<ShowOverview />} />
          {/* <Route path="overview" element={<ShowOverview />} /> */}
          <Route path="classes" element={<ShowClasses />} />
          <Route path="registrants" element={<ShowRegistrants />} />
          <Route path="reports" element={<ShowReports />} />
        </Route>

        <Route path="setup" element={<Setup />}>
          <Route index element={<Locations />} />
          <Route path="locations" element={<Locations />} />
          <Route path="classes" element={<BaseClasses />} />
          <Route path="awards" element={<BaseAwards />} />
          <Route path="patterns" element={<Patterns />} />
          <Route path="patterns/:patternId" element={<Pattern />} />
          <Route path="high-point" element={<HighPoint />} />
        </Route>

        <Route path="show-classes" element={<ShowClass />}>
          <Route path=":classId" element={<ShowClassRiders />} />
        </Route>

        {/* <Route path="classes" element={<BaseClasses />} /> */}
        {/* <Route path="locations" element={<Locations />} /> */}
        {/* <Route path="clubs" element={<Clubs />} /> */}
        {/* <Route path="patterns" element={<Patterns />} /> */}
        {/* <Route path="awards" element={<BaseAwards />} /> */}
        {/* <Route path="high-point" element={<HighPoint />} /> */}

        <Route path="users" element={<AllUsers />}>
          <Route index element={<Members />} />
          <Route path="members" element={<Members />} />
          <Route path="members/:memberId" element={<Member />} />
          <Route path="judges" element={<Judges />} />
          <Route path="announcers" element={<Announcers />} />
          <Route path="paddock-managers" element={<PaddockManagers />} />
          <Route path="admins" element={<Admins />} />
          <Route path="clubs" element={<Clubs />} />
        </Route>
      </Route>
    </Route>
  </Route>
);

export default AdminRouter;
