/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import { useLocation } from "react-router-dom";

import { Link } from "react-router-dom";

import { css } from "./css";

const MenuNested = (props) => {
  const { pages = [], title } = props;

  const loc = useLocation();

  const theme = useTheme();

  const pageEls = pages.map((page, index) => {
    const activePage =
      loc.pathname === `${page.linkTo}` ||
      page?.highlight?.some((l) => l === loc.pathname);

    return (
      // <li key={index} css={css(theme).submenu().shows.ul.li()}>
      <li key={index} css={css(theme).submenu().shows.ul.li(activePage)}>
        <Link
          to={page.linkTo}
          css={css(theme).submenu().shows.ul.li(activePage).link}
        >
          <div css={css(theme).submenu().li(activePage).link}>{page.title}</div>
        </Link>
      </li>
    );
  });

  return (
    <div css={css(theme).submenu().shows}>
      <div css={css(theme).submenu().shows.title}>{title}</div>
      <ul css={css(theme).submenu().shows.ul}>{pageEls}</ul>
    </div>
  );
};

export default MenuNested;
