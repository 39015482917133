/** @jsxImportSource @emotion/react */

import { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { Form, Formik } from "formik";

import { useQuery } from "hooks";
import Client from "client";

import CustomDivTable from "components/custom-table/custom-div-table";
import FormCheckbox from "components/form-checkbox/form-checkbox";
import AwardsBtnGroup from "components/awards-btn-group/awards-btn-group";
import IconRibbon from "components/icons/icon-ribbon";
import FilterSelect from "components/filter-select/filter-select";
import CustomModal from "components/custom-modal/custom-modal";
import Button from "components/button/button";
import { FormElement } from "components/form-element/form-element";

import { colors } from "emotion/utils";

const ShowClassEditAwardsModal = ({ setShowModal, showClass, refresh }) => {
  const onHide = () => setShowModal(false);

  // const { data, refetch } = useQuery(`/show-classes/results/${showClass.id}`);
  // console.log(data);

  const memberToFormik = () => {
    return {};
  };
  const initialValues = memberToFormik();

  const { show } = useOutletContext();
  const { data, refetch } = useQuery(`/show-classes/${showClass?.id}/`);
  const { data: award_data, award_refetch } = useQuery(`/base-awards/`);
  const [riders, setRiders] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  //const { data: awards, refetchAwards } = useQuery(`/awards`);

  useEffect(() => {
    if (data) {
      if (!data.event_type?.placings) {
        setErrorMessage(
          "This Class Type is not associated with a placing. As a result the placings dropdown will be empty. To change this go to setup->class stems->class types",
        );
      }
      setRiders(
        data?.registrants?.map((r) => {
          const show_registrant = show?.registrants?.find(
            (r2) => r2.member_id === r.member_id,
          );
          //const show_class_registrant = showClass?.registrants?.find((r3) => r3.member_id === r.member_id)

          r.rider_number = show_registrant.rider_number;
          if (!r.show_class_result) r.show_class_result = {};
          //Copy over awards from server format
          if (data?.results) {
            const existing_class_results = data?.results?.find((re) => {
              return re.member_id === r.member_id;
            });
            const existing_awards = existing_class_results?.awards;
            r.show_class_result.disqualified =
              existing_class_results?.disqualified || false;
            existing_awards?.forEach((ea) => {
              //What type of award?
              //Look for placement
              if (ea.base_award.primary && !ea.base_award.medal) {
                r.show_class_result.placement = ea.base_award.id;
              }
              //Look for danish
              if (!ea.base_award.primary && !ea.base_award.medal) {
                r.show_class_result.danish = ea.base_award.id;
              }
              //Look for medal
              if (ea.base_award.medal) {
                r.show_class_result.medal = ea.base_award.id;
              }
            });
          }
          if (!r.show_class_result) {
            r.results = {
              rider_number: 1,
              awards: [],
            };
          }
          return r;
        }),
      );
    }
  }, [data]);

  const all_placings = award_data?.data
    ?.sort((a, b) => a.points < b.points)
    .filter((ad) => {
      return ad.primary && !ad.medal;
    });
  const placements =
    data?.event_type?.placings === "ONE_EIGHT"
      ? all_placings.slice(0, 8)
      : data?.event_type?.placings === "ONE_TEN"
      ? all_placings
      : [];
  const danishes = award_data?.data?.filter((a) => !a.primary && !a.medal);
  console.log(data);
  const hasDanishes = data?.event_type?.has_danish || false;
  const medal = data?.level?.medal_id;
  const isMedals = data?.level?.medal_id?.length > 0 || false;

  const updateRiders = (rider) => {
    const newRiders = [...riders];
    const idx = newRiders?.findIndex((r) => r.id === rider.id);
    newRiders[idx] = rider;
    setRiders(newRiders);
  };

  const onMedalChange = (evt, rider, medal) => {
    if (!rider.show_class_result) rider.show_class_result = {};
    rider.show_class_result.medal = evt.target.checked ? medal : null;
    updateRiders(rider);
  };

  const onPlacementSelect = (evt, rider) => {
    if (!rider.show_class_result) rider.show_class_result = {};
    rider.show_class_result.placement = evt.target.value;
    updateRiders(rider);
  };

  const onDanishSelect = (rider, danish) => {
    if (!rider.show_class_result) rider.show_class_result = {};
    rider.show_class_result.danish = danish;
    updateRiders(rider);
  };

  const onDisqualifiedChange = (evt, rider) => {
    if (!rider.show_class_result) rider.show_class_result = {};
    rider.show_class_result.disqualified = evt.target.checked;
    updateRiders(rider);
  };

  const columns = [
    {
      name: "placing",
      valFn: (dat) => {
        const formattedOptions = placements?.map((opt) => (
          <option key={opt.id} value={opt.id}>
            {opt.name}
          </option>
        ));

        return (
          <FilterSelect
            align="left"
            disabled={data?.show_class_result?.disqualified}
            minWidth={86}
            name="placement"
            onChange={(evt) => onPlacementSelect(evt, dat)}
            options={formattedOptions}
            showLabel={false}
            showOptionAll={false}
            value={dat?.show_class_result.placement}
          />
        );
      },
      display: "Placing",
      sortable: false,
    },
    {
      name: "num",
      valFn: (data) =>
        (
          <FormElement
            element="InputText"
            id="rider_number"
            name="rider_number"
            value={data?.rider_number}
            readOnly={true}
          />
        ) || "--",
      display: "Rider #",
      sortable: true,
    },
    {
      name: "disqualified",
      valFn: (data) => (
        <FormCheckbox
          checked={data?.show_class_result?.disqualified}
          onChange={(evt) => onDisqualifiedChange(evt, data)}
          align="center"
        />
      ),
      display: "Disqualified",
      sortable: false,
      align: "center",
      alignHeader: "center",
    },
  ];

  const updateServer = async () => {
    //Sent on path
    // class_id

    const upRiders = riders?.map((r) => {
      return {
        member_id: r.member_id,
        rider_number: r.rider_number,
        animal_id: r.animal_id,
        show_class_result: r.show_class_result,
      };
    });
    const serverCall = await Client.put(
      `/show-classes/${showClass?.id}/score-class`,
      { riders: upRiders },
    );
    refresh();
    onHide();
  };

  if (hasDanishes && columns.indexOf((c) => c.name === "award") < 0) {
    arrBtns.map((ab) => {
      const thisOne = danishes?.find((d) => d.name === ab.label);
      ab.danish_id = thisOne?.id;
      return ab;
    });
    columns.splice(2, 0, {
      name: "award",
      valFn: (data) => (
        <AwardsBtnGroup
          arrBtns={arrBtns}
          rider={data}
          onSelect={onDanishSelect}
          disabled={data?.show_class_result?.disqualified}
        />
      ),
      display: "Award",
      sortable: false,
    });
  }

  if (isMedals && columns.indexOf((c) => c.name === "Medals") < 0) {
    columns.splice(3, 0, {
      name: "medal",
      valFn: (data) => (
        <FormCheckbox
          checked={data?.show_class_result?.medal?.length > 0 || false}
          onChange={(evt) => onMedalChange(evt, data, medal)}
          align="center"
        />
      ),
      display: "Medal",
      sortable: false,
    });
  }

  const btnCancel = (
    <Button
      label="Cancel"
      onClick={() => onHide()}
      color="secondary"
      variant="outlined"
    />
  );

  const btnSave = (
    <Button label="Finalize results" onClick={async () => updateServer()} />
  );

  const clsName = `${showClass?.long_name || "[CLS NAME]"}`;
  // const clsNumber = `${showClass?.class_number || "[CLS NUMBER]"}`;
  const headerTitle = `Placings & Awards for ${clsName}`;

  const footerButtons = [btnCancel, btnSave];

  return (
    <Formik initialValues={initialValues}>
      {(formik) => (
        <Form>
          <CustomModal
            headerTitle={headerTitle}
            onHide={onHide}
            footerButtons={footerButtons}
            size="lg"
          >
            <CustomDivTable
              columns={columns}
              rows={riders}
              isFullWidth={true}
              emptyMessage="No riders registered."
            />
            <div
              style={{
                color: "red",
                fontWeight: "bold",
                marginLeft: "15px",
                marginRight: "15px",
              }}
            >
              {errorMessage}
            </div>
          </CustomModal>
        </Form>
      )}
    </Formik>
  );
};

const arrBtns = [
  {
    label: "Blue Danish",
    img: <IconRibbon />,
    onClick: () => console.log("blue"),
  },
  {
    label: "Red Danish",
    img: <IconRibbon fill={colors.redDanish} stroke={colors.redDanish} />,
    onClick: () => console.log("red"),
  },
  {
    label: "White Danish",
    img: <IconRibbon fill={colors.white} stroke={colors.lightGrey} />,
    onClick: () => console.log("white"),
  },
];

export default ShowClassEditAwardsModal;
