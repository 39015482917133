import {
  appColors,
  appFonts,
  borderRadius,
  flexbox,
  fontbox,
  fullWidth,
  paddingX,
  paddingY,
  square,
  theme,
} from "emotion/utils";

import chevronDown from "images/chevrondown.svg";
import selectArrow from "images/selectArrow.svg";

export const css = (theme, elWidth) => ({
  ...flexbox("column", "flex-start", "flex-start", 3),
  ...fullWidth(),
  maxWidth: `${elWidth}%`,

  "@media screen and (min-width: 576px)": {
    gap: 6,
  },

  label: {
    ...fontbox(appFonts.inter, 12, 500, 18),
    color: theme?.text?.secondary,
    textAlign: "left",

    "@media screen and (min-width: 576px)": {
      ...fontbox(appFonts?.inter, 14, 500, 20),
    },

    "> span": {
      color: appColors?.reds?.base,
    },
  },

  "> div": {
    "&:last-of-type": {
      "> div": {
        "> div": {
          ...borderRadius(8),
          ...fontbox(appFonts.inter, 14, 400, 22),
          ...paddingX(14),
          ...paddingY(10),
          border: `1px solid ${theme?.colors?.primary}`,
          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",

          "> div": {
            padding: 0,

            "> div": {
              margin: 0,
              padding: 0,

              "&[class$='-placeholder']": {
                ...fontbox(appFonts.inter, 12, 500, 18),
                color: theme?.text?.secondary,
                textAlign: "left",

                "@media screen and (min-width: 576px)": {
                  ...fontbox(appFonts.inter, 14, 500, 20),
                },

                "> span": {
                  color: appColors?.reds?.base,
                },
              },

              "&[class$='-Input'] input": {
                ...fullWidth(),
              },
            },
          },
        },
      },
    },
  },

  container: {
    ...fullWidth(),

    "> div": {
      "> div[class$='-control']": {
        backgroundColor: appColors.grays[1],
        border: `1px solid ${theme?.colors?.lightGrey}`,
        height: 40,

        "&[aria-disabled='true']": {
          cursor: "not-allowed",
          opacity: 0.5,
        },

        // "> div[class$='-ValueContainer']": {
        "> div:first-of-type": {
          ...paddingY(0),
          paddingRight: 0,
          paddingLeft: 10,

          "> div[class$='-placeholder']": {
            ...fontbox(appFonts?.inter, 16, 400, 16 * 1.44),
            color: theme?.text?.placeholder,
          },

          // "> div[class$='-singleValue']": {
          "> div:first-of-type": {
            ...fontbox(appFonts.inter, 16, 400, 16 * 1.44),
            color: theme?.text?.placeholder,
            marginLeft: 0,
          },

          // "> div[class$='-Input']": {
          "> div:last-of-type": {
            ...paddingY(0),
            margin: 0,

            "> input": {
              ...fontbox(
                `${appFonts?.inter}!important`,
                "16px!important",
                "400!important",
              ),
              ...fullWidth(),
              color: `${theme?.text?.placeholder}!important`,
              cursor: "pointer",
              lineHeight: `${16 * 1.44}px!important`,
              minWidth: `200px!important`,
              outline: "none!important",
            },
          },
        },

        // "> div[class$='-IndicatorsContainer']": {
        "> div:last-of-type": {
          width: 40,

          "> span[class$='-indicatorSeparator']": {
            display: "none",
          },

          "> div[class$='-indicatorContainer']": {
            ...square(38),
            backgroundImage: `url(${chevronDown})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            padding: 0,

            svg: {
              display: "none",
            },
          },
        },
      },

      "> div[class$='-MenuPortal']": {
        ...fontbox(appFonts?.inter, 16, 400, 16 * 1.44),
      },
    },
  },

  isFilter: {
    "> div[class$='-container']": {
      "> div[class$='-control']": {
        height: 28,
        minHeight: 0,

        "&[aria-disabled='true']": {
          cursor: "not-allowed",
          opacity: 0.5,
        },

        // "> div[class$='-ValueContainer']": {
        "> div:first-of-type": {
          // "> div[class$='-singleValue']": {
          "> div:first-of-type": {
            ...fontbox(appFonts?.inter, 12, 400, 12),
            color: appColors?.grays[1],
          },

          "> div[class$='-Input']": {
            "> input": {
              ...fontbox(
                `${appFonts?.inter}!important`,
                "12px!important",
                "400!important",
              ),
              ...fullWidth(),
              color: `${theme?.text?.placeholder}!important`,
              cursor: "pointer",
              lineHeight: `12px!important`,
              minWidth: `200px!important`,
              outline: "none!important",
            },
          },
        },

        // "> div[class$='-IndicatorsContainer']": {
        "> div:last-of-type": {
          width: 26,

          // "> span[class$='-indicatorSeparator']": {
          //   display: "none",
          // },

          "> div[class$='-indicatorContainer']": {
            ...square(26),
            backgroundImage: `url(${selectArrow})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            padding: 0,

            svg: {
              display: "none",
            },
          },
        },
      },

      "> div[class$='-MenuPortal']": {
        ...fontbox(appFonts?.inter, 12, 400, 12 * 1.44),
      },
    },
  },
});
