/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { css } from "./css";

export default function FormSwitch(props) {
  const { formik, name, label, onChange, size, style } = props;

  const theme = useTheme();

  const elWidth = `${size || 100}%`;

  // const errorClass = formik.errors[name] ? 'error' : '';

  return (
    <div className="form-group-item" css={css(theme, elWidth)}>
      <label htmlFor={name}>{label}</label>
      <label className="switch">
        <input
          type="checkbox"
          id={name}
          name={name}
          onBlur={formik.handleBlur}
          onChange={(values) => {
            onChange && onChange(values);
            formik.setFieldValue(name, values.target.checked);
          }}
          checked={formik.values[name] === true}
        />
        <span className="slider statusSwitch" />
      </label>
      {/* <label htmlFor={name}>
        {label}
      </label> */}
    </div>

    // <div css={css(theme, elWidth)} style={{ ...style }}>
    //   <input
    //     type="checkbox"
    //     id={name}
    //     name={name}
    //     onBlur={formik.handleBlur}
    //     onChange={(values) => {
    //       onChange && onChange(values);
    //       formik.setFieldValue(name, values.target.checked);
    //     }}
    //     checked={formik.values[name] === true}
    //   />
    //   <label htmlFor={name}>{label}</label>
    // </div>
  );
}
