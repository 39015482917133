import { flexbox, paddingXY } from "emotion/utils";

export const css = (theme) => ({
  ".p-datascroller": {
    "&-content": {
      ...paddingXY(0),
    },

    "&-list": {
      ...flexbox("column", "flex-start", "flex-start", 16),
    },
  },
});
