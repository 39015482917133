import { Font, StyleSheet } from "@react-pdf/renderer";

export const css = StyleSheet.create({
  body: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 20,
  },

  tableBlock: {
    borderColor: "#000000",
    borderStyle: "solid",
    borderWidth: 1,
    alignItems: "center",
    width: "100%",

    title: {
      color: "#D52C51",
      fontSize: 14,
      fontWeight: "700",
      paddingVertical: 10,
    },
  },

  table: {
    width: "100%",

    row: {
      alignItems: "center",
      borderBottomColor: "#000000",
      borderBottomStyle: "solid",
      borderBottomWidth: 1,
      flexDirection: "row",
      gap: 10,
      justifyContent: "flex-start",
    },
    cell: {
      color: "#000000",
      fontSize: 6,
      fontWeight: "700",
      paddingVertical: 8,
      paddingHorizontal: 10,
    },

    dataRow: {
      alignItems: "center",
      flexDirection: "row",
      gap: 10,
      justifyContent: "flex-start",
    },
    dataCell: {
      color: "#000000",
      fontSize: 5,
      fontWeight: "700",
      paddingVertical: 4,
    },
  },
});
