/**@jsxImportSource @emotion/react */

import Button from "components/button/button";
import CustomModal from "components/custom-modal/custom-modal";
import { FormElement } from "components/form-element/form-element";
import { Form, Formik } from "formik";
import React from "react";

export default function HighPointModal({ setShowModal = () => {}, show }) {
  const conditionalClose = () => {
    setShowModal(false);
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    club: "",
    birthDate: "",
    status: "",
    streetAddress: "",
    unitNumber: "",
    city: "",
    state: "",
    zip: "",
  };

  const submitRequest = () => {
    alert("submit high point criteria");
  };

  const btnCancel = (
    <Button
      label="Cancel"
      onClick={conditionalClose}
      color="secondary"
      variant="outlined"
    />
  );

  const btnCreate = (
    <Button
      label="Create"
      onClick={() => alert("create high point criteria")}
    />
  );

  const footerButtons = () =>
    [btnCancel, btnCreate].map((btn, index) => (
      <React.Fragment key={index}>{btn}</React.Fragment>
    ));

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitRequest}
      enableReinitialize
      // validationSchema={validationSchema}
    >
      {(formik) => (
        <Form>
          <CustomModal
            headerTitle="Create high point criteria"
            // footerButtons={footerButtons(formik)}
            footerButtons={footerButtons()}
            onHide={conditionalClose}
          >
            <FormElement
              element="InputText"
              id="name"
              name="name"
              label="Name"
              placeholder="Name your high point report"
              // formik={formik}
              // onBlur={formik.handleBlur}
              // onChange={formik.handleChange}
              // value={formik.values.name}
              required
            />

            <FormElement
              element="Dropdown"
              id="discipline"
              name="discipline"
              label="Discipline"
              placeholder="Select discipline"
              // formik={formik}
              // onBlur={formik.handleBlur}
              // onChange={formik.handleChange}
              // value={formik.values.name}
              required
            />

            <FormElement
              element="Dropdown"
              id="ageGroup"
              name="ageGroup"
              label="Age group"
              placeholder="Select age group"
              // formik={formik}
              // onBlur={formik.handleBlur}
              // onChange={formik.handleChange}
              // value={formik.values.name}
              required
            />

            <FormElement
              element="Dropdown"
              id="level"
              name="level"
              label="Level"
              placeholder="Select level"
              // formik={formik}
              // onBlur={formik.handleBlur}
              // onChange={formik.handleChange}
              // value={formik.values.name}
              required
            />

            <FormElement
              element="Dropdown"
              id="classType"
              name="classType"
              label="Class type for tie breaker"
              placeholder="Select level"
              // formik={formik}
              // onBlur={formik.handleBlur}
              // onChange={formik.handleChange}
              // value={formik.values.name}
              required
            />

            <FormElement
              element="InputText"
              id="keywords"
              name="keywords"
              label="Class keywords"
              placeholder="Keywords to include separated by comma"
              // formik={formik}
              // onBlur={formik.handleBlur}
              // onChange={formik.handleChange}
              // value={formik.values.name}
              required
            />

            <FormElement
              element="InputText"
              id="negativeKeywords"
              name="negativeKeywords"
              label="Class negative keywords"
              placeholder="Keywords to exclude separated by comma"
              // formik={formik}
              // onBlur={formik.handleBlur}
              // onChange={formik.handleChange}
              // value={formik.values.name}
              required
            />
          </CustomModal>
        </Form>
      )}
    </Formik>
  );
}
