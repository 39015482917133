/** @jsxImportSource @emotion/react */

import laptopImage from "images/showmanship-app-section3-laptop.jpg";

import { cssMarketing } from "../css";
import { css } from "./css";

const Simple = () => {
  const image = <div css={css.image(laptopImage)} />;

  const content = (
    <div css={cssMarketing}>
      <h2>Simple</h2>
      <p>
        Create the event schedule and let Showmanship do all the work on event
        day. Schedules, awards, alerts, all managed automatically in one place
        so administrators can watch the show, not the screen.
      </p>
    </div>
  );

  return (
    <div css={css.container}>
      <div css={css.container.frame}>
        {image}
        {content}
      </div>
    </div>
  );
};

export default Simple;
