import React from "react";

import CustomModal from "components/custom-modal/custom-modal";
import Button from "components/button/button";

export default function AnnouncerModal({ setShowModal }) {
  const onHide = () => setShowModal(false);

  const btnComplete = <Button label="Complete" onClick={onHide} />;

  const headerTitle = "Announcer Instruction";

  const footerButtons = [btnComplete].map((btn, index) => (
    <React.Fragment key={index}>{btn}</React.Fragment>
  ));

  return (
    <CustomModal
      headerTitle={headerTitle}
      footerButtons={footerButtons}
      onHide={onHide}
      size="md"
    >
      <h1>Riders please walk your horses.</h1>
    </CustomModal>
  );
}
