import {
  appColors,
  appFonts,
  borderRadius,
  flexbox,
  fontbox,
  fullHeight,
  fullWidth,
  posAbsolute,
  posRelative,
  square,
  transition,
} from "emotion/utils";
import { color } from "framer-motion";

export const css = (theme, elWidth) => ({
  ...flexbox("column", "flex-start", "flex-start", 3),
  ...fullWidth(),
  maxWidth: `${elWidth}`,

  "@media screen and (min-width: 576px)": {
    gap: 6,
  },

  input: {},

  label: {
    ...fontbox(appFonts.inter, 12, 500, 18),
    color: theme?.text?.secondary,
    textAlign: "left",

    "@media screen and (min-width: 576px)": {
      ...fontbox(appFonts?.inter, 14, 500, 20),
    },

    "&.switch": {
      ...posRelative(),
      display: "inline-block",
      height: 44,
      width: 174,

      "> input": {
        ...square(0),
        opacity: 0,

        "&:checked + .slider": {
          backgroundColor: appColors?.blues?.bgActive,
          color: theme?.text?.secondary,
          fontWeight: 700,

          "&::before": {
            backgroundColor: appColors?.grays?.[0],
            color: appColors?.grays?.[1],
            fontWeight: 400,
          },
        },

        // &:focus + .slider {
        //   box-shadow: 0 0 1px #2196F3,
        // }
      },

      ".slider": {
        ...borderRadius(8),
        ...flexbox("row", "center", "center"),
        ...fontbox(appFonts?.inter, 14, 600, 20),
        ...posAbsolute(0, 0, 0, 0),
        ...transition("all 0.4s"),
        backgroundColor: appColors?.grays?.[0],
        border: `1px solid ${theme?.border?.primary}`,
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        cursor: "pointer",

        "&::before, &::after": {
          ...fullHeight(),
          lineHeight: "40px",
          textAlign: "center",
          width: "50%",
        },

        "&::before": {
          ...borderRadius("7px 0 0 7px"),
          backgroundColor: appColors?.blues?.bgActive,
          color: theme?.text?.secondary,
          content: '"No"',
          fontWeight: 700,
        },

        "&::after": {
          ...borderRadius("0 7px 7px 0"),
          content: '"Yes"',
        },

        "&.statusSwitch": {
          "&::before": {
            content: '"Active"',
          },

          "&::after": {
            content: '"Inactive"',
          },
        },
      },
    },
  },
});
