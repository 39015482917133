import { useCallback, useRef } from "react";
import { Toast } from "primereact/toast";

const useToast = () => {
  const toastRef = useRef();

  const toast = useCallback(
    (message, type = "success") => {
      toastRef.current?.show({
        severity: type,
        summary: type.toUpperCase(),
        detail: message,
        life: 5000,
      });
    },
    [toastRef],
  );

  return { toast, toastRef };
};

export { useToast, Toast };
