import { flexbox, paddingX, paddingY, theme } from "emotion/utils";

export const css = {
  container: (image) => ({
    ...flexbox("column", "flex-start", "center"),
    ...paddingX(16),
    ...paddingY(48),
    backgroundColor: theme.colors.headerBg,
    backgroundImage: `url(${image})`,
    backgroundPosition: "top center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",

    "@media screen and (min-width: 576px)": {
      ...paddingX(38),
      ...paddingY(64),
    },
    "@media screen and (min-width: 992px)": {
      ...paddingX(100),
    },
    "@media screen and (min-width: 1200px)": {
      ...paddingX(200),
    },

    frame: {
      ...flexbox("column", "center", "flex-start", 45),
      maxWidth: 820, // 1400,
      width: "80%",

      "@media screen and (min-width: 992px)": {
        ...flexbox("row", "center", "flex-start", 68),
        ...paddingY(128),
      },
    },

    "h2, p": {
      color: theme.colors.white,
    },
  }),
};
