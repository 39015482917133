/**@jsxImportSource @emotion/react */

import React, { useState, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import Client from "client";

import { FormErrorMessage } from "validation/form-error-message";
import ConfirmationModal, {
  useConfirmationModal,
} from "confirmation-modal/confirmation-modal";
import CustomModal from "components/custom-modal/custom-modal";
import { FormElement } from "components/form-element/form-element";
import Button from "components/button/button";

import { css } from "./css";

const validate = (values) => {
  // const requiredFields = ["name", "address", "notes"];
  const fields = {
    name: "Location name is required",
    address: "Location Address is required",
    // notes: "Location Notes are required",
  };

  const errors = {};
  Object.keys(fields).forEach((field) => {
    if (!values[field]?.trim()) {
      errors[field] = fields[field];
    }
  });

  return errors;
};

export default function LocationModal({
  setShowModal = () => {},
  showModal = true,
  isEditing,
  location,
  onSuccess = () => {},
}) {
  const [inputValues, setInputValues] = useState({});
  useEffect(() => {
    setInputValues(isEditing ? location : {});
  }, [isEditing, location]);
  const {
    showModal: showConfirmationModal,
    handleExit,
    handleClose: handleConfirmationClose,
  } = useConfirmationModal();
  const [modalHeight, setModalHeight] = useState(0);
  const calculateModalHeight = () => {
    setTimeout(() => {
      const modalDialog = document.querySelector(".modal-content");
      if (modalDialog) {
        console.log("This Modal Height = " + modalDialog.offsetHeight + "px");
        setModalHeight(modalDialog.offsetHeight);
      }
    }, 10); // delay
  };
  useEffect(() => {
    if (showConfirmationModal) {
      calculateModalHeight();
    }
  }, [showConfirmationModal]);

  const {
    mutateAsync: submitRequest,
    isLoading,
    isError,
  } = useMutation({
    mutationKey: "create-edit-location",
    mutationFn: async (data) => {
      if (location?.id) {
        return Client.put(`/locations/${location.id}`, data);
      } else {
        return Client.post(`/locations`, data);
      }
    },
    onSuccess: (data) => {
      setShowModal(false);
      onSuccess(data);
    },
  });

  const onSubmit = async (values) => {
    submitRequest(values);
  };

  const handleClose = () => [setShowModal(false)];
  const [isFormDirty, setIsFormDirty] = useState(false);
  const conditionalClose = () => {
    if (isFormDirty) {
      handleExit();
    } else {
      setShowModal(false);
    }
  };

  const locationToFormik = () => {
    return {
      name: location?.name || "",
      address: location?.address || "",
      map: location?.map?.link || null,
      eventMap: location?.event_map?.link || null,
      notes: location?.notes || "",
    };
  };
  const initialValues = locationToFormik();

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isError) {
    return <div>Error!!!</div>;
  }

  const headerTitle = `${
    isEditing ? "Edit location " + initialValues.name : "Add location"
  }`;

  const btnCancel = (
    <Button
      disabled={isLoading}
      label="Cancel"
      onClick={conditionalClose}
      color="secondary"
      variant="outlined"
    />
  );

  const btnCreate = (formik) => (
    <Button
      disabled={!(formik.isValid && formik.dirty)}
      label="Save"
      onClick={(e) => {
        formik.handleSubmit(e);
        // calculateModalHeight();
      }}
    />
  );

  const footerButtons = (formik) =>
    [btnCancel, btnCreate(formik)].map((btn, index) => (
      <React.Fragment key={index}>{btn}</React.Fragment>
    ));

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
        validationSchema={Yup.object({
          name: Yup.string().label("Name").required(),
          address: Yup.string().label("Address").required(),
          map: Yup.mixed()
            .label("Location Image")
            .when("type", (value, schema) => {
              console.log("testing map");
              console.log(value);
              if (!value[0])
                return Yup.mixed().notRequired().nullable().optional();
              return Yup.mixed().test(
                (value) =>
                  value.type === "application/png" ||
                  value.type === "application/jpg" ||
                  value.type === "application/jpeg" ||
                  typeof value === "string" ||
                  value instanceof String,
              );
            }),
        })}
      >
        {(formik) => (
          <Form
            onChange={(evt) => {
              const clone = structuredClone(formik.values);
              clone[evt.target.name] = evt.target.value;
              setIsFormDirty(
                JSON.stringify(clone) !== JSON.stringify(locationToFormik()),
              );
            }}
          >
            <CustomModal
              headerTitle={headerTitle}
              footerButtons={footerButtons(formik)}
              onHide={conditionalClose}
              size="md"
            >
              <FormElement
                element="InputText"
                id="name"
                name="name"
                formik={formik}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                label="Location name"
                required
              />
              {/* <FormErrorMessage name="name" formik={formik} /> */}

              <FormElement
                element="InputText"
                id="address"
                name="address"
                formik={formik}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.address}
                label="Address"
                required
              />
              {/* <FormErrorMessage name="address" formik={formik} /> */}

              <FormElement
                element="InputTextarea"
                id="notes"
                name="notes"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.notes}
                label="Notes (optional)"
                placeholder="e.g. Park in the parking lot and walk your equipment in. No parking at the entrance to unload!"
              />

              <div css={css().modalRow}>
                <FormElement
                  element="FileUpload"
                  formik={formik}
                  label="Cover image (optional)"
                  name="map"
                />

                <FormElement
                  element="FileUpload"
                  formik={formik}
                  label="Map of the grounds (optional)"
                  name="eventMap"
                  //accept="*/.png"
                  helperText="Valid formats: PNG, JPG"
                />
              </div>
            </CustomModal>
          </Form>
        )}
      </Formik>

      <ConfirmationModal
        showModal={showConfirmationModal}
        handleExit={handleExit}
        handleClose={handleConfirmationClose}
        handleGoBack={handleConfirmationClose}
        setShowModal={setShowModal}
        modalHeight={modalHeight}
        handleConfirmation={handleClose}
      />
    </>
  );
}
