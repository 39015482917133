import React, { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";

import AuthContext from "../context/auth-context";

const HOME_PATH = "/admin";

function UnProtectedLayout(props) {
  const { userData } = useContext(AuthContext);

  if (userData && userData.id) {
    if (userData.announcer) {
      return <Navigate to={"announcer"} replace></Navigate>;
    }
    if (userData.member) {
      return <Navigate to={"rider"} replace></Navigate>;
    }
    return <Navigate to={HOME_PATH} replace></Navigate>;
  }

  return <Outlet></Outlet>;
}

export default UnProtectedLayout;
