/** @jsxImportSource @emotion/react */

import { useRef } from "react";
import { useMutation } from "@tanstack/react-query";

import Client from "client";

import CustomButton from "components/custom-button/custom-button";
import CustomModal from "components/custom-modal/custom-modal";

import { fontbox, theme } from "emotion/utils";

export default function ShowCancelModal({
  setShowModal = () => {},
  baseAward,
  onAccept = () => {},
  activeBtnGroup,
}) {
  const toastRef = useRef();
  const { mutateAsync: submitRequest, isLoading } = useMutation({
    mutationKey: "patch-base-award",
    networkMode: "always",
    mutationFn: async () =>
      Client.patch(`/base-awards/${baseAward.data.id}`, {
        archive: baseAward.archive,
      }),
    onSuccess: () => {
      setShowModal(false);
      onAccept();
    },
    onError: (error) =>
      toastRef.current.show({
        severity: "error",
        summary: "Error",
        detail: error.toString(),
      }),
  });

  const headerTitle =
    activeBtnGroup === "active"
      ? "Archive Base Award"
      : "Reactivate Base Award";

  const btnClose = (
    <CustomButton
      label="Close"
      color="tertiary"
      type="outlined"
      onClick={() => setShowModal(false)}
      align="left"
    />
  );

  const btnArchiveReactivate = (
    <CustomButton
      label={activeBtnGroup === "active" ? "Archive" : "Reactivate"}
      onClick={submitRequest}
      loading={isLoading}
      disabled={isLoading}
    />
  );

  const footerButtons = [btnClose, btnArchiveReactivate];

  return (
    <CustomModal
      headerTitle={headerTitle}
      footerButtons={footerButtons}
      onHide={() => setShowModal(false)}
    >
      <p css={css.dir}>
        Are you sure you want to{" "}
        {activeBtnGroup === "active" ? "archive" : "Reactivate"}{" "}
        <strong>{baseAward.data.name}</strong>?
      </p>
    </CustomModal>
  );
}

const css = {
  dir: {
    ...fontbox(theme.fonts.inter, 16, 400, 16 * 1.44),
    textAlign: "center",
  },
};
