/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import { SketchPicker } from "react-color";

import { FormErrorMessage } from "validation/form-error-message";

import { cssFormElements } from "../css";

const ColorPicker = ({ required, formik, size, ...data }) => {
  const theme = useTheme();

  const elWidth = `${size || "100"}%`;

  const getLabel = data?.label && (
    <label>
      {data.label}
      {required && <span>*</span>}
    </label>
  );

  const elProps = {
    ...Object.keys(data).reduce((acc, key) => {
      if (key !== "data") {
        acc[key] = data[key];
      }
      return acc;
    }, {}),
  };

  const getElement = <SketchPicker {...elProps} />;

  return (
    <div css={cssFormElements(theme, elWidth).colorPicker}>
      {getLabel}
      {getElement}
      {required && <FormErrorMessage name="name" formik={formik} />}
    </div>
  );
};

export default ColorPicker;
