/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Image from "react-bootstrap/Image";
import { useMutation } from "@tanstack/react-query";

import { useQuery } from "hooks";
import Client from "client";

import Icon4hLogoOffical from "components/icons/icon-logo-official";
import FilterBtnGroup from "components/filter-btn-group/filter-btn-group";
import CustomDivTable from "components/custom-table/custom-div-table";
import TitleBlock from "components/title-block/title-block";
import Button from "components/button/button";
import Icon from "components/icon/icon";
import ButtonIcon from "components/button-icon/button-icon";
import CustomDivTableActions from "components/custom-table/custom-div-table-actions";

import PatternModal from "./create-pattern-modal";
// import ShowCancelModal from "./show-cancel-modal";

import { css } from "./css";

export default function Patterns(props) {
  const [patternModal, setPatternModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [archived, setArchived] = useState(false);
  const [activePattern, setActivePattern] = useState(null);

  const navigate = useNavigate();

  const theme = useTheme();

  const { data, refetch } = useQuery(`/patterns?archived=${archived}`);
  console.log("data:", data);

  const { mutate: archivePattern } = useMutation({
    mutationFn: ({ patternId, archive }) =>
      Client.put(`/patterns/${patternId}`, { archive }),
    onSuccess: () => {
      refetch();
    },
  });

  // const fileType = filename?.split('.').pop().toLowerCase();

  const columns = [
    {
      name: "imageId",
      valFn: (it) =>
        !it?.image ? (
          <Icon4hLogoOffical width={100} />
        ) : it?.image?.filename?.split(".").pop().toLowerCase() === "pdf" ? (
          <Icon icon="Page" />
        ) : (
          <Image src={it.image.link} thumbnail width={100} />
        ),
      // valFn: (it) => {
      //   console.log('it:', it?.image?.filename);
      //   return <p>thumbnail</p>;
      // },
      display: "Pattern thumbnail",
    },
    {
      name: "name",
      valFn: (it) => `${it.name ? it.name : "--"}`,
      display: "Pattern name",
    },
    {
      name: "steps",
      display: "# Steps",
      valFn: (it) => it.pattern_step.length,
      align: "right",
      alignHeader: "right",
    },
    {
      name: "archive",
      valFn: (it) => (
        <CustomDivTableActions>
          <ButtonIcon
            icon={<Icon icon="Trash" />}
            onClick={() =>
              archivePattern({ patternId: it.id, archive: !archived })
            }
          />
          <ButtonIcon
            icon={<Icon icon="ChevronRight" />}
            onClick={(it) => {
              setActivePattern(it);
              setPatternModal(true);
            }}
          />
        </CustomDivTableActions>
      ),
      display: "",
      align: "right",
      alignHeader: "right",
    },
  ];

  const btnAddPattern = (
    <Button
      icon={<Icon icon="Plus" />}
      iconPos="left"
      label="Add pattern"
      onClick={() => {
        setPatternModal(true);
      }}
    />
  );

  const filterBtnGroup = (
    <FilterBtnGroup
      options={["Active", "Archived"]}
      activeBtnGroup={archived ? "archived" : "active"}
      setActiveBtnGroup={(v) => setArchived(v === "archived")}
    />
  );

  const actions = [btnAddPattern];

  const titleBlock = (
    <TitleBlock>
      {filterBtnGroup}
      {actions.map((btn, index) => (
        <React.Fragment key={index}>{btn}</React.Fragment>
      ))}
    </TitleBlock>
  );

  const content = (
    <>
      {titleBlock}

      <CustomDivTable
        columns={columns}
        // columns={newColumns}
        rows={data?.data}
        selectionMode="single"
        onSelect={(it) => {
          setActivePattern(it);
          setPatternModal(true);
        }}
        filterKeys={["name"]}
        // activeBtnGroup={activeBtnGroup}
        showSearch={false}
        emptyMessage="No Patterns Found"
      />
    </>
  );

  return (
    <div css={css(theme)}>
      {content}

      {patternModal && (
        <PatternModal
          setShowModal={(showIt) => {
            setActivePattern(null);
            setPatternModal(showIt);
          }}
          onSuccess={refetch}
          activePattern={activePattern}
        />
      )}

      {/*{showCancelModal && (
        <ShowCancelModal
          setShowModal={setShowCancelModal}
          show={activeShow.current}
          onAccept={refetch}
        />
      )}*/}
    </div>
  );
}
