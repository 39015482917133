import {
  appColors,
  appFonts,
  borderRadius,
  flexbox,
  fontbox,
  paddingX,
  paddingXY,
  posAbsolute,
  posRelative,
  square,
  transition,
} from "emotion/utils";

export const css = (theme) => ({
  ...flexbox("row", "flex-start", "flex-start", 12),
  ...paddingX(16),
  ...posRelative(),
  paddingTop: 24,
  paddingBottom: 32,

  "&::after": {
    ...posAbsolute(0, 16, null, 16),
    content: '""',
    backgroundColor: theme.border.secondary,
    height: 1,
  },

  avatar: {
    ...borderRadius(20),
    ...flexbox("row", "center", "center"),
    ...square(40),
    backgroundColor: appColors.grays[100],
    border: `1px solid ${appColors.grays[1]}08`,

    "> div": {
      ...flexbox("row", "center", "center"),
      ...square(40),
    },
  },

  info: {
    flex: 1,
    maxWidth: 177,

    "> p": {
      ...fontbox(appFonts.inter, 14, 400, 20),
      color: theme.text.primary,
      maxWidth: "inherit",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",

      "&:first-of-type": {
        fontWeight: 600,
      },
    },
  },

  btnLogout: {
    alignSelf: "center",
    cursor: "pointer",

    "> div": {
      ...borderRadius(20),
      ...paddingXY(8),
      ...transition("all", 0.3),
      backgroundColor: "transparent",
      border: `1px solid transparent`,
    },

    "&:hover > div": {
      backgroundColor: appColors.grays[100],
      borderColor: `${appColors.grays[1]}08`,
    },
  },
});
