/** @jsxImportSource @emotion/react */

import comprehensiveBG from "images/comprehensive-frame@3x.jpg";

import { cssMarketing } from "../css";
import { css } from "./css";

const Comprehensive = () => {
  return (
    <div css={css.container(comprehensiveBG)}>
      <div css={css.container().frame}>
        <div css={cssMarketing}>
          <h2>Comprehensive</h2>
          <p>
            Competitor, judge, announcer, and administrator apps connected
            together enables users to get show alerts, performance feedback,
            location information and schedule updates all in one place. See
            registrant status, attendees, and scores changes without delay.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Comprehensive;
