/** @jsxImportSource @emotion/react */

import { useState } from "react";

import subscriptionBG from "images/subscription-section.jpg";

import { css } from "./css";
import { cssMarketing } from "../css";

const Subscription = () => {
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  function Submit(e) {
    e.preventDefault();
    console.log("Form Submitted");

    if (!email.trim()) {
      setMessage("Email address cannot be empty.");
      return;
    }

    if (email.length > 80) {
      setMessage("Email address cannot exceed 80 characters.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setMessage("Please enter a valid email address.");
      return;
    }

    // If the email passes the above checks, proceed with the form submission
    const formDatab = new FormData(e.target);
    fetch(
      "https://script.google.com/macros/s/AKfycbxLN2cIz8G6argQbSrEbXSOp_6ujsnY_YM1KuQO4RT3kD-uDEiDo4qp7f736WetKXhM/exec",
      {
        method: "POST",
        body: formDatab,
      },
    )
      .then((res) => {
        const contentType = res.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return res.json();
        } else {
          return res.text();
        }
      })
      .then((data) => {
        console.log(data);
        setMessage(
          "Thanks for your interest! You have been successfully added to our mailing list!",
        );
        setEmail(""); // Clear email state after submission
      })
      .catch((error) => {
        console.log(error);
        setMessage("An error occurred. Please try again later.");
      });
  }
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const content = (
    <div css={cssMarketing}>
      <h2>Join Our Mailing List</h2>
      <p>
        Showmanship is currently in development and for a limited time is being
        offered for 4H horse shows free of charge as a pilot program. Get on our
        mailing list below and we’ll update you as availability expands.
      </p>
    </div>
  );

  const form = (
    <form css={css.form} onSubmit={(e) => Submit(e)}>
      <input
        placeholder="Email Address*"
        name="Email"
        type="email"
        value={email}
        onChange={handleEmailChange}
      />
      <button type="submit" css={css.submitWrapper}>
        Submit
      </button>
    </form>
  );

  return (
    <div css={css.container(subscriptionBG)}>
      {content}
      {form}
      {message && <div css={css.successMessage}>{message}</div>}{" "}
    </div>
  );
};

export default Subscription;
