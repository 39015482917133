/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import React, { useState } from "react";

import { useQuery } from "hooks";

// import { DEFAULT_SELECTS } from "../../constants";

// import css from "./base-classes.module.scss";
import { css } from "./css";
import EmptyBlock from "components/empty-block/empty-block";
import TitleBlock from "components/title-block/title-block";
import FilterBtnGroup from "components/filter-btn-group/filter-btn-group";
import Button from "components/button/button";
import Icon from "components/icon/icon";
import Accordion from "components/accordion/accordion";
import ButtonIcon from "components/button-icon/button-icon";
import CustomDivTableActions from "components/custom-table/custom-div-table-actions";
import ModalClassStem from "./modals/modal-class-stem";
import ModalClassStemEdit from "./modals/modal-class-stem-edit";
import ModalClassStemArchive from "./modals/modal-class-stem-archive";

export default function BaseClassDisciplines() {
  const [showStemModal, setShowStemModal] = useState(false);
  const [showStemModalEdit, setShowStemModalEdit] = useState(false);
  const [showStemModalArchive, setShowStemModalArchive] = useState(false);
  const [activeType, setActiveType] = useState(null);
  const [activeData, setActiveData] = useState(null);
  const [activeDiscipline, setActiveDiscipline] = useState(null);
  const [activeBtnGroupStems, setActiveBtnGroupStems] = useState("all");
  const [activeBtnGroupStatus, setActiveBtnGroupStatus] = useState("active");

  const theme = useTheme();

  const { data, refetch } = useQuery("/class-disciplines");

  const { data: dataClassTypes, refetch: refetchClassTypes } =
    useQuery("/event-types");
  const { data: dataLevels, refetch: refetchLevels } =
    useQuery("/class-levels");
  const { data: dataAgeGroups, refetch: refetchAgeGroups } = useQuery(
    "/class-age-categories",
  );
  const { data: dataClassModifiers, refetch: refetchClassModifiers } =
    useQuery("/modifiers");

  function refetchAll() {
    refetch();
    refetchClassTypes();
    refetchLevels();
    refetchAgeGroups();
    refetchClassModifiers();
  }

  const handleEditStem = (data, type) => {
    setActiveType(type);
    setActiveData(data);
    setShowStemModalEdit(true);
  };

  const handleArchiveStem = (data, type) => {
    setActiveType(type);
    setActiveData(data);
    setShowStemModalArchive(true);
  };

  const getStemEls = (data, type) => {
    const filteredData = (data?.data || []).filter((el) => {
      if (activeBtnGroupStatus === "all") {
        return true;
      }

      if (activeBtnGroupStatus === "active" && !el.archived_at) {
        return true;
      }

      if (activeBtnGroupStatus === "archived" && el.archived_at) {
        return true;
      }

      return false;
    });

    return (
      <div css={css(theme).stemEls}>
        {filteredData.map((el) => {
          // console.log('el', el);

          return (
            <div key={el?.id} css={css(theme).stemEls.stemEl}>
              <div css={css(theme).stemEls.stemEl.title}>{el?.name}</div>

              <CustomDivTableActions>
                <ButtonIcon
                  icon={<Icon icon="Archive" />}
                  onClick={() => handleArchiveStem(el, type)}
                />

                <ButtonIcon
                  icon={<Icon icon="Edit" />}
                  onClick={() => handleEditStem(el, type)}
                />
              </CustomDivTableActions>
            </div>
          );
        })}
      </div>
    );
  };

  const stemOptions = [
    {
      name: "Discipline",
      key: "discipline",
      content: getStemEls(data, "discipline"),
    },
    {
      name: "Class Type",
      key: "class-type",
      content: getStemEls(dataClassTypes, "classType"),
    },
    {
      name: "Level",
      key: "level",
      content: getStemEls(dataLevels, "level"),
    },
    {
      name: "Age Group",
      key: "age-group",
      content: getStemEls(dataAgeGroups, "ageGroup"),
    },
    {
      name: "Modifiers",
      key: "modifiers",
      content: getStemEls(dataClassModifiers, "modifier"),
    },
  ];

  const stemOptionsFiltered = stemOptions.map((option) => option.name);

  const filterBtnGroupStems = (
    <FilterBtnGroup
      options={stemOptionsFiltered}
      activeBtnGroup={activeBtnGroupStems}
      setActiveBtnGroup={setActiveBtnGroupStems}
    />
  );

  const filterBtnGroupStatus = (
    <FilterBtnGroup
      options={["Active", "Archived"]}
      activeBtnGroup={activeBtnGroupStatus}
      setActiveBtnGroup={setActiveBtnGroupStatus}
    />
  );

  const btnAddStem = (
    <Button
      icon={<Icon icon="Plus" />}
      iconPos="left"
      label="Add stem"
      onClick={() => setShowStemModal(true)}
    />
  );

  const actions = [btnAddStem];

  const actionsBlock = actions.map((btn, index) => (
    <React.Fragment key={index}>{btn}</React.Fragment>
  ));

  const titleBlock = (
    <TitleBlock>
      {filterBtnGroupStems}
      {filterBtnGroupStatus}
      {actionsBlock}
    </TitleBlock>
  );

  const accordianEls = stemOptions.filter((opt) => {
    if (activeBtnGroupStems === "all") {
      return true;
    }

    return activeBtnGroupStems === opt.key;
  });

  const accordion = (
    <Accordion
      // multiple={true}
      tabs={accordianEls}
      // data={data}
    />
  );

  const classBlock = (
    <>
      {titleBlock}

      {accordion}

      {/* BELOW IS V2 FOR REFERENCE
      <PageHeader title="Discipline" component={pageHeaderGroupDiscipline} />      
      <PageHeader title="Class Type" component={pageHeaderGroupClassType} />
      <BaseClasses discipline={activeDiscipline} eventType={activeEventType} />
      */}
    </>
  );

  const emptyBlock = (
    <EmptyBlock
      title="No Class Stems Yet!"
      subtitle="Start by adding a Stem."
      btnLabel="Add a Stem"
      onClick={() => {
        setShowStemModal(true);
      }}
    />
  );

  const activeDiscArray = [undefined, null, ""];

  const content = activeDiscArray.includes(activeDiscipline)
    ? classBlock
    : emptyBlock;

  const modals = (
    <>
      {showStemModal && (
        <ModalClassStem setShowModal={setShowStemModal} refetch={refetchAll} />
      )}
      {showStemModalEdit && (
        <ModalClassStemEdit
          setShowModal={setShowStemModalEdit}
          refetch={refetchAll}
          data={activeData}
          type={activeType}
        />
      )}
      {showStemModalArchive && (
        <ModalClassStemArchive
          setShowModal={setShowStemModalArchive}
          refetch={refetchAll}
          data={activeData}
          type={activeType}
        />
      )}
    </>
  );

  return (
    <div css={css(theme)}>
      {content}
      {modals}
    </div>
  );
}
