import React, { useState, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { Formik, Form } from "formik";

import { useQuery } from "hooks";

import Modal from "react-bootstrap/Modal";
import Client from "client";

import FormSelect from "components/form-select";

//import { required } from "validation/form";
import ConfirmationModal, {
  useConfirmationModal,
} from "confirmation-modal/confirmation-modal";
import CustomButton from "components/custom-button/custom-button";
import CustomModal from "components/custom-modal/custom-modal";

const validate = (values) => {
  const fields = {
    show_id: "Show is required",
  };

  const errors = {};
  Object.keys(fields).forEach((field) => {
    if (!values[field]?.trim()) {
      errors[field] = fields[field];
    }
  });
  return errors;
};

export default function ShowModal({
  setShowModal = () => {},
  showModal = true,
  show,
  locationId,
  dailyOrder,
  showClass,
  onSuccess = () => {},
}) {
  const {
    showModal: showConfirmationModal,
    handleExit,
    handleClose: handleConfirmationClose,
  } = useConfirmationModal();
  const [modalHeight, setModalHeight] = useState(0);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const conditionalClose = () => {
    if (isFormDirty) {
      handleExit();
    } else {
      setShowModal(false);
    }
  };

  const showClassToFormik = () => {
    return {
      show_id: null,
    };
  };

  const calculateModalHeight = () => {
    setTimeout(() => {
      const modalDialog = document.querySelector(".modal-content");
      if (modalDialog) {
        console.log("This Modal Height = " + modalDialog.offsetHeight + "px");
        setModalHeight(modalDialog.offsetHeight);
      }
    }, 10); // delay
  };
  useEffect(() => {
    if (showConfirmationModal) {
      calculateModalHeight();
    }
  }, [showConfirmationModal]);

  const initialValues = showClassToFormik();

  const {
    mutateAsync: onSubmit,
    isLoading,
    isError,
  } = useMutation({
    mutationKey: "duplicate-show-classes",
    networkMode: "always",
    mutationFn: async (data) => {
      return Client.post(`/shows/byId/${show.id}/duplicate-show-classes`, data);
    },
    onSuccess: (values) => {
      setShowModal(false);
      onSuccess(values);
    },
  });

  const twentyFourHoursInMs = 1000 * 60 * 60 * 24;

  const {
    data: showData,
    isLoading: isShowLoading,
    isError: isShowError,
  } = useQuery("/shows", {
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: twentyFourHoursInMs,
    networkMode: "always",
  });

  console.log(showData);

  // console.log(judgeData)

  if (isLoading || isShowLoading) {
    return <div>Loading...</div>;
  }
  if (isError || isShowError) {
    return <div>Error!!!</div>;
  }

  const btnCreate = (formik) => (
    <CustomButton
      label={showClass ? "Update" : "Create"}
      disabled={!formik.isValid || !Object.values(formik.touched).length}
      onClick={(e) => {
        formik.handleSubmit(e);
        calculateModalHeight();
      }}
    />
  );

  const footerButtons = (formik) => [btnCreate(formik)];

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={onSubmit}
        validate={validate}
      >
        {(formik) => (
          <Form>
            <CustomModal
              headerTitle="Duplicate Previous Show Classes"
              footerButtons={footerButtons(formik)}
              onHide={conditionalClose}
            >
              <FormSelect
                name="show_id"
                label="Show"
                formik={formik}
                onChange={(evt) => {
                  formik?.getFieldHelpers("show_id").setTouched(true, false);
                }}
                options={showData?.data.map((show) => ({
                  value: show.id,
                  display: show.name,
                }))}
                none
                labelPosition="top"
              />
            </CustomModal>
          </Form>
        )}
      </Formik>

      <ConfirmationModal
        showModal={showConfirmationModal}
        handleExit={handleExit}
        handleClose={handleConfirmationClose}
        handleGoBack={handleConfirmationClose}
        setShowModal={setShowModal}
        modalHeight={modalHeight}
      />
    </>
  );
}
