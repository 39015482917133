import React from "react";

import UserPageTemplate from "components/user-page-template/user-page-template";

export default function PaddockManagers() {
  return (
    <UserPageTemplate
      userRoute="/paddock-managers"
      nameOfUser="Paddock Manager"
    />
  );
}
