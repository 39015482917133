import {
  appColors,
  appFonts,
  borderRadius,
  flexbox,
  fontbox,
  paddingXY,
} from "emotion/utils";

export const css = (theme) => ({
  ...flexbox("row", "stretch", "flex-start", 16),

  "> *": {
    flex: 1,
  },

  stat: {
    ...borderRadius(12),
    ...paddingXY(24),
    ...flexbox("column", "flex-start", "center", 24),
    border: `1px solid ${theme?.border?.secondary}`,
    flex: 1,

    row: {
      ...flexbox("row", "center", "flex-start", 12),

      "> div": {
        ...borderRadius("50%"),
        ...paddingXY(12),
        backgroundColor: appColors?.brand?.secondary,
      },
    },

    h3: {
      ...fontbox(appFonts.inter, 16, 600, 24),
      color: theme?.text?.primary,
    },

    p: {
      ...fontbox(appFonts.inter, 36, 600, 44),
      color: theme?.text?.primary,
    },
  },
});
