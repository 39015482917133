/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import React, { useState, useEffect } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { css } from "./css";

export default function FormDateInput(props) {
  const {
    formik,
    name,
    size,
    label,
    datetime,
    min,
    max,
    readonly,
    helperText,
    required,
  } = props;

  const type = datetime ? "datetime-local" : "date";

  const theme = useTheme();

  const elWidth = `${size || 100}`;

  const errorClass =
    formik?.errors[name] && formik?.touched[name] ? "error" : "";

  const [value, setValue] = useState("");
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");

  let minVal = null;
  let maxVal = null;

  const nullIfEpoch = (inbound) => {
    if (
      inbound &&
      moment(inbound).utc().format("MM/DD/YYYY") === "01/01/1970"
    ) {
      return null;
    } else {
      return inbound;
    }
  };

  useEffect(() => {
    if (datetime) {
      if (formik.values[name]) {
        setValue(
          moment(formik.values[name]).utc().format("YYYY-MM-DD HH:mm:ss"),
        );
      }

      if (min) {
        minVal = moment(min).format("YYYY-MM-DD HH:mm:ss");
      }

      if (max) {
        maxVal = moment(max).format("YYYY-MM-DD HH:mm:ss");
      }
    } else {
      const nullOrValue = nullIfEpoch(formik.values[name]);
      setValue(nullOrValue);
      formik.values[name] = nullOrValue;
      setMinValue(nullIfEpoch(min));
      setMaxValue(nullIfEpoch(max));
    }
  }, [min, max]);

  const onChange = (change) => {
    setValue(change);
    formik.handleChange({ target: { name: name, value: change } });
  };

  return (
    <div
      css={css(theme, elWidth)}
      // className="form-group-item"
    >
      <div
      // className="form-group-item__helper-text"
      >
        <label htmlFor={name}>
          {label}
          {required && <span>*</span>}
        </label>
        {helperText && (
          <div
          // style={{
          //   fontSize: 9,
          //   lineHeight: "150%",
          // }}
          >
            {helperText}
          </div>
        )}
      </div>

      <div
        css={css(theme).field}
        // className={`form-group-item__date ${readonly ? "readonly" : ""}`}
      >
        <DatePicker
          showIcon
          showDateSelect={true}
          isClearable={true}
          onChange={onChange}
          className={`${errorClass} ${readonly ? "readonly" : ""}`}
          id={name}
          name={name}
          placeholderText={helperText}
          selected={nullIfEpoch(value)}
          {...(minValue ? { minDate: minValue } : {})}
          {...(maxValue ? { maxDate: maxValue } : {})}
          readOnly={readonly ? true : false}
        />
      </div>
    </div>
  );
}
