/**@jsxImportSource @emotion/react */

import { useQuery } from "hooks";
import { useEffect } from "react";

//import { required } from "validation/form";

export default function useShowClassCreate(props) {
  const { show, formik } = props;

  const {
    data: disciplines,
    isLoading: isDisciplinesLoading,
    error: isDisciplinesError,
  } = useQuery("/class-disciplines");

  const {
    data: eventTypesData,
    isLoading: eventTypesDataIsLoading,
    error: eventTypesDataError,
  } = useQuery("/event-types");

  const {
    data: ageGroupData,
    isLoading: ageGroupDataIsLoading,
    error: ageGroupDataError,
  } = useQuery("/class-age-categories");

  const {
    data: classLevelData,
    isLoading: classLevelDataIsLoading,
    error: classLevelDataError,
  } = useQuery("/class-levels");

  const {
    data: modifierData,
    isLoading: modifierDataIsLoading,
    error: modifierDataError,
  } = useQuery("/modifiers");

  const {
    data: arenaData,
    isLoading: arenaDataIsLoading,
    error: arenaDataError,
  } = useQuery(`/arenas?locationId=${show?.location_id}`, {
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    networkMode: "always",
    enabled: !!show,
  });

  const arenaOptions = arenaData
    ? arenaData?.data.map((a) => ({
        value: a.id,
        label: a.name,
      }))
    : [];

  const disciplineOptions = disciplines
    ? disciplines?.data.map((a) => ({
        value: a.id,
        label: a.name,
      }))
    : [];

  const classTypeOptions = eventTypesData
    ? eventTypesData?.data.map((a) => ({
        value: a.id,
        label: a.name,
      }))
    : [];

  const ageGroupOptions = ageGroupData
    ? ageGroupData?.data.map((a) => ({
        value: a.id,
        label: a.name,
      }))
    : [];

  const levelOptions = classLevelData
    ? classLevelData?.data.map((a) => ({
        value: a.id,
        label: a.name,
      }))
    : [];

  const modifierOptions = modifierData
    ? modifierData?.data.map((a) => ({
        value: a.id,
        label: a.name,
      }))
    : [];

  return {
    arenaOptions,
    disciplineOptions,
    classTypeOptions,
    ageGroupOptions,
    levelOptions,
    modifierOptions,
    isLoading:
      isDisciplinesLoading ||
      eventTypesDataIsLoading ||
      ageGroupDataIsLoading ||
      classLevelDataIsLoading ||
      modifierDataIsLoading ||
      arenaDataIsLoading,
    error:
      isDisciplinesError ||
      eventTypesDataError ||
      ageGroupDataError ||
      classLevelDataError ||
      modifierDataError ||
      arenaDataError,
  };
}
