/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { Card as PrCard } from "primereact/card";

import { css } from "./css";

const Card = ({ children }) => {
  const theme = useTheme();

  return <PrCard css={css(theme)}>{children}</PrCard>;
};

export default Card;
