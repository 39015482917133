import { createContext } from "react";

const AuthContext = createContext({
  refreshToken: null,
  userData: null,
  login: () => {},
  logout: () => {},
});

export default AuthContext;
