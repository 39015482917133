/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { css } from "./css";
import Icon from "components/icon/icon";
import { appColors } from "emotion/utils";

export default function Stats(props) {
  const { data = [], isDetail = false, isOver } = props;

  const theme = useTheme();

  const statEls = data.map((item) => {
    // Don't show Total Attendees stat if show has not ended
    if (!isOver && item.title === "Total Attendees") {
      return false;
    }

    return (
      <div css={css(theme).stat}>
        <div css={css(theme).stat.row}>
          <Icon icon={item.icon} stroke={appColors?.blues?.bgBrandSolid} />

          <h3>{item.title}</h3>
        </div>
        <p>
          {item.title === "Income" ? (
            <>${item.entryFunc ? item.entryFunc() : item.entry}</>
          ) : (
            <>{item.entryFunc ? item.entryFunc() : item.entry}</>
          )}
        </p>
      </div>
    );
  });

  return <div css={css(theme)}>{statEls}</div>;
}
