import {
  appFonts,
  dimensions,
  flexbox,
  fontbox,
  fullHeight,
  marginAuto,
  posAbsolute,
  posRelative,
  square,
} from "emotion/utils";

export const css = (theme) => {
  // console.log('EMPTY BLOCK');
  // console.log("theme", theme);

  return {
    ...flexbox("column", "center", "center", 0),
    ...fullHeight(),
    minHeight: "90vh",

    inner: {
      ...flexbox("column", "center", "center", 24),
      ...posRelative(),
      maxWidth: 512,
      width: "90%",
    },

    "> *": {
      textAlign: "center",
    },

    content: {
      ...flexbox("column", "center", "center", 16),
    },

    graphic: {
      ...dimensions(152, 118),
      ...posRelative(),

      "> div": {
        ...posAbsolute(),

        "&:first-of-type": {
          ...square(104),
          top: 0,
          left: 24,
        },

        "&:nth-of-type(2)": {
          filter:
            "drop-shadow(0px 8px 8px rgba(16, 24, 40, 0.03)) drop-shadow(0px 20px 24px rgba(16, 24, 40, 0.08))",
          top: 16,
          left: 0,
        },

        "&:nth-of-type(3)": {
          top: 14,
          left: 13,
          transform: "scale(0.75)",
        },

        "&:nth-of-type(4)": {
          top: 4,
          right: 16,
          transform: "scale(0.65)",
        },

        "&:nth-of-type(5)": {
          top: 24,
          right: 0,
        },

        "&:nth-of-type(6)": {
          bottom: 2,
          left: 11,
          transform: "scale(1.1)",
        },
      },
    },

    intro: {
      ...flexbox("column", "center", "center", 4),

      h1: {
        ...fontbox(appFonts.inter, 16, 600, 24),
        color: theme?.text?.primary,
      },

      p: {
        ...fontbox(appFonts.inter, 14, 400, 20),
        color: theme?.text?.tertiary,
      },
    },

    actions: {
      "> button": {
        ...marginAuto(),
      },
    },

    dots: {
      ...posAbsolute(null, null, -42, null, -1),
      ...square(480),

      mask: {
        ...posAbsolute(0, 0, 0, 0, 0),
        maskImage:
          "radial-gradient(50% 50% at 50% 50%, #000 0%, rgba(0, 0, 0, 0.00) 100%)",
      },
    },
  };
};
